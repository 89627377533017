import React from 'react'
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

// status 'bg-warning' || 'bg-primary' || 'bg-success'
const DateSticker = ({ dateString, status }) => {

    const thisDate = new Date(dateString)

    return (
        <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
            <div className={`bg-success me-2 p-3 rounded-xxl ${status}`}>
                <h4 className="fw-700 font-lg ls-3 lh-1 text-white mb-0">
                    <div className="fw-700 font-lg ls-3 lh-1 text-white mb-0">
                        {thisDate.getFullYear()}
                    </div>
                    <span className="ls-1 d-block font-xsss text-white fw-600">
                        {months[thisDate.getMonth()]}
                    </span>
                    {thisDate.getDate()}
                </h4>
            </div>
        </div>
    )
}
export default DateSticker