import {
    ART_TA_LIST_REQUEST, ART_TA_LIST_SUCCESS, ART_TA_LIST_FAILURE,
    ART_TA_SAVE_REQUEST, ART_TA_SAVE_SUCCESS, ART_TA_SAVE_FAILURE
} from '../ReduxAction'

const initialState = {
    loading: false,
    error: false,
    message: '',
    data: null,
}

export const artTaReducer = (state = initialState, action) => {
    switch (action.type) {
        case ART_TA_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'list request',
            };
        case ART_TA_LIST_SUCCESS:
            return {
                loading: false,
                error: false,
                message: 'list fulfil',
                data: action.payload,
            };
        case ART_TA_LIST_FAILURE:
            return {
                loading: false,
                error: true,
                message: action.payload,
                data: null,
            };
        case ART_TA_SAVE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'save request',
            };
        case ART_TA_SAVE_SUCCESS:
            return {
                loading: false,
                error: false,
                message: 'save fulfil',
                data: action.payload,
            };
        case ART_TA_SAVE_FAILURE:
            return {
                loading: false,
                error: true,
                message: action.payload,
                data: null,
            };
        default:
            return state;
    }
}