import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const Tag = props => {
  const { id, text, catID, cat } = props

  const catItem = cat.data?.list_data?.filter(item => item.catID === catID)[0]

  return (
    <Link to='/articles' state={{ cat: catItem, tagId: id }}>
      <span className='ti-tag text-grey-500 mb-0 mt-0'>
        {text}
      </span>
    </Link>
  )
}
const mapStateToProps = state => ({
  cat: state.cat,
})
const ConnectedTag = connect(mapStateToProps)(Tag)
export { ConnectedTag as Tag }