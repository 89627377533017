import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { apiObject, axiosConfig } from '../../Constants'

import { Tag } from './Tag'
import Load from '../../components/Load'

const Tags = props => {
    const [tags, setTags] = useState(null)
    const { artId, catID, artTa } = props

    const renderTag = useCallback((tag, key) => {
        return (
            <Tag
                key={key}
                id={tag.id}
                text={tag.text}
                catID={catID}
            />
        )
    }, [tags, catID])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            
            apiObject.post('/artta/list', { artId: artId }, axiosConfig).then(response => {
                if (response?.status === 200) {
                    const newTags = []
                    response.data?.data?.list_data.map(item => {
                        newTags.push({
                            id: item.taid,
                            text: item.ta,
                        })
                    })
                    setTags(newTags)
                }
            })
        }

        return () => mounted = false
    }, [artId, artTa])

    return (
        <>
            {
                tags &&
                <div><span className='text-instagram'>Tags: </span><span className='theme-dark-bg text-dark'>{tags.map((tag, key) => renderTag(tag, key))}</span></div>
            }
            {artTa.loading && <Load />}
        </>
    )
}
const mapStateToProps = state => {
    return {
        artTa: state.artTa
    }
}
const ConnectedTags = connect(mapStateToProps)(Tags)
export { ConnectedTags as Tags }