import { 
    PEER_HUB_CONNECTION_SET,
    CONNECTION_STATE_SET,
    LOCAL_DESCRIPTION_SET,
    REMOTE_DESCRIPTION_SET,
    PEER_CONNECTION_UNSET,
} from '../ReduxAction'

const initialState = {
    peerConnection: null,
    connectionState: null,
    localDescription: null,
    remoteDescription: null,
}

export const callConnectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case PEER_HUB_CONNECTION_SET:
            return {
                ...state,
                peerConnection: action.payload
            }
        case LOCAL_DESCRIPTION_SET:
            return {
                ...state,
                localDescription: action.payload
            }
            
        case REMOTE_DESCRIPTION_SET:
            return {
                ...state,
                remoteDescription: action.payload
            }
        case CONNECTION_STATE_SET:
            return {
                ...state,
                connectionState: action.payload
            }
        case PEER_CONNECTION_UNSET:
            return initialState
        default:
            return state
    }
}