import React, { useState, useRef, useEffect } from 'react'
import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js'
import lamejs from 'lamejs'

import Pagetitle from '../../components/Pagetitle'

export const Mp3Recorder = () => {
    const waveform = useRef()
    const timeline = useRef()

    var mediaRecorder;
    let ws;
    let audioCunks = [];

    function loadWs() {
        ws = WaveSurfer.create({
            container: waveform.current,
            waveColor: "#46a6d8",
            progressColor: "#FFF",
            barWidth: 3,
            barGap: 2,
            height: 130,
            cursorWidth: 1,
            cursorColor: "white",
            responsive: 1000,
            normalize: true,
            plugins: [
                MicrophonePlugin.create(),
                TimelinePlugin.create({ container: timeline.current })
            ]
        });

        ws.microphone.on("deviceReady", function (stream) {
            console.log("Device ready!", stream);

            mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.ondataavailable = function (e) {
                audioCunks.push(e.data);
                ws.loadBlob(new Blob(audioCunks));
            };

            mediaRecorder.onstop = () => {
                ws.loadBlob(new Blob(audioCunks));
            };

            mediaRecorder.start(250);
            //ws.load(URL.createObjectURL(stream));
        });

        ws.microphone.on("deviceError", function (code) {
            console.warn("Device error: " + code);
        });

        // Attach on ready listener to WaveSurfer
        ws.on("ready", function () {
            //console.log("ready !!");
        });
    }

    function startRecording() {
        audioCunks = [];
        ws && ws.destroy();
        loadWs();
        ws.microphone.start();
        ws.microphone.play();
    }

    function stopRecording() {
        if (!ws) {
            return;
        }
        ws.microphone.stop();
        mediaRecorder && mediaRecorder.state !== "inactive" && mediaRecorder.stop();
    }

    function playAudio() {
        if (!ws) {
            return;
        }
        ws.play();
        ws.setVolume(1);
    }

    function downloadFile() {
        if (audioCunks.length > 0) {
            const mp3Blob = analyzeAudioBuffer(ws.backend.buffer);
            window.open(URL.createObjectURL(mp3Blob));
        }
    }

    function analyzeAudioBuffer(aBuffer) {
        let numOfChan = aBuffer.numberOfChannels,
            btwLength = aBuffer.length * numOfChan * 2 + 44,
            btwArrBuff = new ArrayBuffer(btwLength),
            btwView = new DataView(btwArrBuff),
            btwChnls = [],
            btwIndex,
            btwSample,
            btwOffset = 0,
            btwPos = 0;
        setUint32(0x46464952); // "RIFF"
        setUint32(btwLength - 8); // file length - 8
        setUint32(0x45564157); // "WAVE"
        setUint32(0x20746d66); // "fmt " chunk
        setUint32(16); // length = 16
        setUint16(1); // PCM (uncompressed)
        setUint16(numOfChan);
        setUint32(aBuffer.sampleRate);
        setUint32(aBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2); // block-align
        setUint16(16); // 16-bit
        setUint32(0x61746164); // "data" - chunk
        setUint32(btwLength - btwPos - 4); // chunk length

        for (btwIndex = 0; btwIndex < aBuffer.numberOfChannels; btwIndex++)
            btwChnls.push(aBuffer.getChannelData(btwIndex));

        while (btwPos < btwLength) {
            for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) {
                // interleave btwChnls
                btwSample = Math.max(-1, Math.min(1, btwChnls[btwIndex][btwOffset])); // clamp
                btwSample =
                    (0.5 + btwSample < 0 ? btwSample * 32768 : btwSample * 32767) | 0; // scale to 16-bit signed int
                btwView.setInt16(btwPos, btwSample, true); // write 16-bit sample
                btwPos += 2;
            }
            btwOffset++; // next source sample
        }

        let wavHdr = lamejs.WavHeader.readHeader(new DataView(btwArrBuff));

        //Stereo
        let data = new Int16Array(btwArrBuff, wavHdr.dataOffset, wavHdr.dataLen / 2);
        let leftData = [];
        let rightData = [];
        for (let i = 0; i < data.length; i += 2) {
            leftData.push(data[i]);
            rightData.push(data[i + 1]);
        }
        var left = new Int16Array(leftData);
        var right = new Int16Array(rightData);

        //STEREO
        if (wavHdr.channels === 2)
            return bufferToMp3(wavHdr.channels, wavHdr.sampleRate, left, right);
        //MONO
        else if (wavHdr.channels === 1)
            return bufferToMp3(wavHdr.channels, wavHdr.sampleRate, data);

        function setUint16(data) {
            btwView.setUint16(btwPos, data, true);
            btwPos += 2;
        }

        function setUint32(data) {
            btwView.setUint32(btwPos, data, true);
            btwPos += 4;
        }
    }

    function bufferToMp3(channels, sampleRate, left, right = null) {
        const buffer = [];
        const samplesPerFrame = 1152;
        const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
        let remaining = left.length;
        let mp3buf = [];

        for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
            if (!right) {
                let mono = left.subarray(i, i + samplesPerFrame);
                mp3buf = mp3enc.encodeBuffer(mono);
            } else {
                let leftChunk = left.subarray(i, i + samplesPerFrame);
                let rightChunk = right.subarray(i, i + samplesPerFrame);
                mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk);
            }
            if (mp3buf.length > 0) {
                buffer.push(mp3buf); //new Int8Array(mp3buf));
            }
            remaining -= samplesPerFrame;
        }
        const d = mp3enc.flush();
        if (d.length > 0) {
            buffer.push(new Int8Array(d));
        }

        var mp3Blob = new Blob(buffer, { type: "audio/mpeg" });

        return mp3Blob;
    }

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">

                            <Pagetitle title="Mp3 Recorder" />

                            <div ref={waveform} />
                            <div ref={timeline} style={{ paddingTop: '25px' }} />
                            <div className='flex-between' style={{ paddingTop: '50px' }}>
                                <button className='btn btn-primary' onClick={playAudio}>Play</button>
                                <button className='btn btn-danger' onClick={startRecording}>Rec</button>
                                <button className='btn btn-secondary' onClick={stopRecording}>Stop Rec</button>
                                <button className='btn btn-primary' onClick={downloadFile}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/* function mp3Encoding(samples) {
  const mp3encoder = new lamejs.Mp3Encoder(1, 4100, 128);
  const sampleBlockSize = 1152; //can be anything but make it a multiple of 576 to make encoders life easier

  var mp3Data = [];
  for (let i = 0; i < samples.length; i += sampleBlockSize) {
    const sampleChunk = samples.subarray(i, i + sampleBlockSize);
    const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
    if (mp3buf.length > 0) {
      mp3Data.push(mp3buf);
    }
  }

  const mp3buf = mp3encoder.flush(); //finish writing mp3

  mp3Data.push(mp3buf);

  console.debug(mp3Data);

  const blob = new Blob(mp3Data, { type: "audio/mp3" });
  return window.URL.createObjectURL(blob);
} */
