import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Form, Formik, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { QUOTE_INSERT, QUOTE_UPDATE } from '../Redux/ReduxSaga/triggers'

const SubmitArea = () => {
    const { submitForm, errors } = useFormikContext()

    const handleClickSubmit = () => {
        if (Object.keys(errors).length > 0) {
            alert(JSON.stringify(errors, null, 2))
        } else {
            submitForm()
        }

    }

    return (
        <div className="col-lg-12 submit">
            <a onClick={handleClickSubmit} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
        </div>
    )
}

const QuoteDetail = props => {
    const location = useLocation()
    const navigate = useNavigate()
    const { quote } = location.state
    const { dispatch, authorList } = props
    const [imageholder, setImageholder] = useState(quote?.portraitLink || '')

    const initialValues = {
        qID: quote?.qID || 0,
        authorID: quote?.authorID || 0,
        quoteText: quote?.quoteText || '',
    }

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            authorID: Yup.number().min(1, 'Required'),
            quoteText: Yup.string().required('Required'),
        })
    })

    const handleSubmit = values => {

        if (values.qID) {
            dispatch({
                type: QUOTE_UPDATE,
                payload: values
            })
        } else {
            dispatch({
                type: QUOTE_INSERT,
                payload: values
            })
        }

        navigate('/quotes')

    }

    const handleImageErrror = () => {
        setImageholder("https://via.placeholder.com/300x300.png")
    }

    const compare = (a, b) => {
        if (a.author < b.author) {
            return -1
        }
        if (a.author > b.author) {
            return 1
        }
        return 0
    }

    return (
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/quotes" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Quote Details</h4>
                            </div>
                            <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 text-center">
                                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img onError={handleImageErrror} src={imageholder} alt="avater" className="shadow-sm rounded-3 w-100" /></figure>
                                        <h2 className="fw-700 font-sm text-grey-900 mt-3">{quote?.author}</h2>
                                    </div>
                                </div>

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    <Form>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">AuthorID</label>
                                                    <Field name="authorID" type="number" as="select" className="form-control">
                                                        {
                                                            authorList?.sort(compare).map((item, key) => {
                                                                return (
                                                                    <option key={key} value={item.authorID}>{item.author}</option>
                                                                )
                                                            })
                                                        }
                                                    </Field>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">QuoteText</label>
                                                    <Field name="quoteText" type="text" as="textarea" className="form-control textarea-body" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <SubmitArea />
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    quoteList: state.quote.data?.list_data,
    authorList: state.author.data?.list_data,
})
const ConnectedPage = connect(mapStateToProps)(QuoteDetail)
export default ConnectedPage