import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ImageList as ImageContainer, ImageListItem } from '@mui/material'
import Pagetitle from '../../components/Pagetitle'

function srcset(image, width, height, rows, cols) {
    return {
        src: `${image.replaceAll(' ', '%20').replaceAll(',', '%2c')}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image.replaceAll(' ', '%20').replaceAll(',', '%2c')}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
}

const ImageList = props => {
    const [imageList, setImageList] = useState([])
    const { navHeader, appFooterHeight } = props
    const { navHeaderHeight, keyword, searchActive } = navHeader

    useEffect(() => {
        if (!props.imageList.length) return
        const newImageList = []
        props.imageList.map(item => {
            newImageList.push({
                img: item.imageUrl,
                title: item.name,
                rows: 1, //Math.ceil(Math.random() * 2),
                cols: 1, //Math.ceil(Math.random() * 2),
            })
        })
        if (keyword.length > 0 && searchActive) {
            const filter = newImageList.filter(item => item.title.toUpperCase().indexOf(keyword.toUpperCase()) >= 0)
            setImageList(filter)
        } else {
            setImageList(newImageList)
        }
    }, [props.imageList, keyword, searchActive])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">
                            <Pagetitle title="Images" />

                            {imageList.length > 0 &&
                                <ImageContainer
                                    sx={{ width: `calc(100vw) - 10px`, height: `calc(100vh - ${navHeaderHeight}px - ${appFooterHeight}px)` }}
                                    variant="quilted"
                                    cols={4}
                                    rowHeight={121}
                                >
                                    {imageList.map((item, key) => {
                                        return (
                                            <ImageListItem key={key} cols={item.cols} rows={item.rows}>
                                                <Link to='/image-view' state={{ image: { name: item.title, imageUrl: item.img } }}>
                                                    <img
                                                        {...srcset(item.img, 50, item.rows, item.cols)}
                                                        alt={item.title}
                                                        loading="lazy"
                                                        style={{ maxWidth: '100%' }} // important!!
                                                    />
                                                </Link>
                                            </ImageListItem>
                                        )
                                    })}
                                </ImageContainer>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        imageList: state.image.data,
        navHeader: state.navHeader,
        appFooterHeight: state.appFooter.height,
    }
}
const ConnectedImageList = connect(mapStateToProps)(ImageList)
export { ConnectedImageList as ImageList }