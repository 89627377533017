import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { WavePlayer } from '../WavePlayer'
import { compressImage } from './compressImage'
import { audioConvert } from './audioConvert'

import { blobToBase64 } from './blobToBase64'
import { IMAGE_LIST, IMAGE_OFFLINE_LIST, UPLOAD_FILE } from '../../Redux/ReduxSaga/triggers'
import Pagetitle from '../../components/Pagetitle'
import Load from '../../components/Load'

const FileSelector = props => {
    const [loadingCaption, setLoadingCaption] = useState('')
    const [loading, setLoading] = useState(false)
    const [fileType, setFileType] = useState('image')
    const [selectedFile, setSelectedFile] = useState()
    const [uploadFileError, setUploadFileError] = useState()
    const [preview, setPreview] = useState()
    const [compressedSize, setCompressedSize] = useState(null)

    const { dispatch, uploadFile } = props

    const { accept } = props
    const startRef = useRef(true)
    const inputRef = useRef()

    const handleClickAudioRadioButton = () => {
        resetUI()
        setFileType('audio')
    }

    const handleClickImageRadioButton = () => {
        resetUI()
        setFileType('image')
    }

    const resetUI = () => {
        setSelectedFile(null)
        setPreview(null)
        setCompressedSize(null)
        inputRef.current.value = ''
    }

    const handleInputChange = e => {

        if (e.target.files.length) {
            const file = e.target.files[0]
            setSelectedFile(file)
            setPreview(null)

            if (file.type.split('/')[0] === 'image') {
                const blob = new Blob([file])
                if (file.type !== 'image/gif') {
                    compressImage(blob).then(blob => {
                        setCompressedSize(blob.size)
                        blobToBase64(blob).then(base64 => {
                            setPreview(base64)
                        })
                    })
                } else {
                    blobToBase64(blob).then(base64 => {
                        setCompressedSize(blob.size)
                        setPreview(base64)
                    })
                }
            } else if (file.type.split('/')[0] === 'audio') {

                const reader = new FileReader()
                reader.onabort = e => {
                    // console.log("onabort", e)
                }
                reader.onerror = e => {
                    // console.log("onerror", e)
                }
                reader.onload = e => {
                    // console.log("onload", e)
                }
                reader.onloadend = e => {
                    // console.log("onloadend", e)
                    setLoading(false)
                    if (file.type.split('/')[1] === 'wav') {
                        setLoading(true)
                        setLoadingCaption('Compressing audio data...')
                        setTimeout(() => {
                            const data = audioConvert(e.target.result)
                            setCompressedSize(data.blob.size)
                            blobToBase64(data.blob).then(base64 => {
                                setPreview(base64)
                                setLoading(false)
                            })
                        }, 100)
                    } else if (file.type.split('/')[1] === 'mpeg') {
                        setPreview(e.target.result)
                        setLoading(false)
                    } else return

                }
                reader.onloadstart = e => {
                    setLoading(true)
                    setLoadingCaption('Reading file')
                }
                reader.onprogress = e => {
                    // console.log("onprogress", e)
                }
                if (file.type.split('/')[1] === 'wav') {
                    reader.readAsArrayBuffer(file)
                } else if (file.type.split('/')[1] === 'mpeg') {
                    reader.readAsDataURL(file)
                }
            } else return


        } else {
            setSelectedFile(null)
        }

    }

    const handleClickUpload = () => {
        setLoading(true)
        setLoadingCaption('Upload the result to the server...')
        let newName = selectedFile.name
        let newType = selectedFile.type

        if (newType === 'audio/wav') {
            newName = newName.substring(0, newName.lastIndexOf('.')) + '.mp3'
            newType = 'audio/mp3'
        }

        dispatch({
            type: UPLOAD_FILE,
            payload: {
                fileName: newName,
                type: newType,
                data: preview.substring(preview.lastIndexOf(',') + 1),
            }
        })

    }

    useEffect(() => {
        if (startRef.current) {
            startRef.current = false
            return
        }

        if (!uploadFile.loading) {
            if (!uploadFile.error) {
                setTimeout(() => {
                    setLoading(false)
                    resetUI()
                    dispatch({ type: IMAGE_LIST })
                    dispatch({ type: IMAGE_OFFLINE_LIST })
                }, 100)
            } else {
                setUploadFileError(uploadFile.message)
            }

        }

    }, [uploadFile.loading])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">
                            <Pagetitle title="Upload" />

                            <div>
                                <div className='mt-5 mb-2'>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio1"
                                            value='image'
                                            checked={fileType === 'image'}
                                            onChange={() => { }}
                                            onClick={() => {
                                                setPreview()
                                                setSelectedFile()
                                                setTimeout(handleClickImageRadioButton)
                                            }}
                                        />
                                        <label className="form-check-label text" htmlFor="inlineRadio1">Image</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio2"
                                            value='audio'
                                            checked={fileType === 'audio'}
                                            onChange={() => { }}
                                            onClick={() => {
                                                setPreview()
                                                setSelectedFile()
                                                setTimeout(handleClickAudioRadioButton)
                                            }}
                                        />
                                        <label className="form-check-label text" htmlFor="inlineRadio2">Audio</label>
                                    </div>
                                </div>
                                <div className="custom-file mt-5 text">
                                    <input
                                        ref={inputRef}
                                        className='custom-file'
                                        type="file"
                                        name="fileselector"
                                        accept={accept || fileType === 'audio' ? 'audio/wav, audio/mp3' : 'image/png, image/gif, image/jpeg'} onChange={handleInputChange} />
                                </div>

                                <div className='text'>
                                    {selectedFile &&
                                        <>
                                            <div>File name: {selectedFile?.name}</div>
                                            <div>File type: {`${selectedFile?.type}`}</div>
                                            <div>Size in bytes: {`${selectedFile?.size}`}</div>
                                            <div>
                                                Last modified date:{' '}
                                                {selectedFile ? new Intl.DateTimeFormat('en-EN', { dateStyle: 'full', timeStyle: 'long' }).format(selectedFile?.lastModifiedDate) : ''}
                                            </div>
                                        </>
                                    }
                                    {compressedSize && <div>Compressed: {`${compressedSize}`}</div>}
                                    {loading &&
                                        <div style={{ paddingTop: '20px' }}>
                                            <div className='flex-between'>
                                                <div>{loadingCaption}</div>
                                                <Load />
                                                {/* <img src='simpleLoad.gif' alt={loadingCaption} width={30} /> */}
                                            </div>
                                        </div>
                                    }
                                    {preview && selectedFile?.type.split('/')[0] === 'image' &&
                                        <div className='miuka' style={{ paddingTop: '10px' }}>
                                            <img className='img-fluid img-thumbnail' src={preview} alt='selected-file' />
                                        </div>
                                    }
                                    {preview && selectedFile?.type.split('/')[0] === 'audio' &&
                                        <div style={{ padding: '10px 0px 10px 0px' }}>
                                            <WavePlayer path={preview} />
                                        </div>
                                    }
                                    {selectedFile && preview &&
                                        <>
                                            <div style={{ paddingTop: '30px' }}>
                                                <button className='btn btn-primary' onClick={handleClickUpload}>Upload</button>
                                            </div>
                                            <div className='text-danger'>{uploadFileError}</div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className='h100' />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return { uploadFile: state.uploadFile }
}
const ConnectedComponent = connect(mapStateToProps)(FileSelector)
export { ConnectedComponent as FileSelector }