import React, { useState, useRef, useEffect } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { drawing_script } from './drawingCode'
import './style.css'

export const ColorHarmony = () => {
    const [myWorker, setMyWorker] = useState()
    const [isLoading, setIsLoading] = useState()
    const [now, setNow] = useState(0)
    const [myDegree, setMyDegree] = useState()
    const [examBOH, setExamBOH] = useState()
    const [examA, setExamA] = useState()
    const [examB, setExamB] = useState()
    const [examRatio, setExamRatio] = useState()
    const [examX0, setExamX0] = useState()
    const [examY0, setExamY0] = useState()
    const [examRY, setExamRY] = useState()
    const [OXValue, setOXValue] = useState()
    const [OYValue, setOYValue] = useState()
    const [bAxisHValue, setBAxisHValue] = useState()
    const [bStepValue, setBStepValue] = useState()
    const [xStepValue, setXStepValue] = useState()
    const [yStepValue, setYStepValue] = useState()
    const [yMValue, setYMValue] = useState()
    const [yRValue, setYRValue] = useState()
    const [yCValue, setYCValue] = useState()
    const [yGValue, setYGValue] = useState()
    const [xVPLValue, setXVPLValue] = useState()
    const [xVPRValue, setXVPRValue] = useState()
    const [yHorizonValue, setYHorizonValue] = useState()
    const [VPLVPR, setVPLVPR] = useState()
    const [VPLVPRO, setVPLVPRO] = useState()
    const [VPLVPRG, setVPLVPRG] = useState()
    const [VPLVPRB, setVPLVPRB] = useState()
    const [VPLVPRC, setVPLVPRC] = useState()
    const [VP_O, setVP_O] = useState()
    const [VP_B, setVP_B] = useState()
    const [OR, setOR] = useState()
    const [BM, setBM] = useState()
    const [yYValue, setYYValue] = useState()

    const refMunsellSphere = useRef()
    const refMunshellTop = useRef()
    const refTheResult = useRef()
    const refCC = useRef()
    const refCCTop = useRef()

    const doTriad = () => {
        setIsLoading(true)
        myWorker.postMessage({
            type: 'doTriad',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }

    const doExam1 = () => {
        setIsLoading(true)
        myWorker.postMessage({
            type: 'doExam1',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }

    const doExam2 = () => {
        setIsLoading(true)
        myWorker.postMessage({
            type: 'doExam2',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }

    const doExam3 = () => {
        setIsLoading(true)
        myWorker.postMessage({
            type: 'doExam3',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }

    const doExam4 = () => {
        setIsLoading(true)
        myWorker.postMessage({
            type: 'doExam4',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }

    const doExam5 = () => {
        setIsLoading(true)
        myWorker.postMessage({
            type: 'doExam5',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }

    const doExam6 = () => {
        setIsLoading(true)
        myWorker.postMessage({
            type: 'doExam6',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }

    const resetAllAvailbles = e => {
        setMyDegree(e.data.myDegree)
        setExamBOH(e.data.examBOH)
        setExamA(e.data.examA)
        setExamB(e.data.examB)
        setExamRatio(e.data.examRatio)
        setExamX0(e.data.examX0)
        setExamY0(e.data.examY0)
        setExamRY(e.data.examRY)
        setOXValue(e.data.OXValue)
        setOYValue(e.data.OYValue)
        setBAxisHValue(e.data.bAxisHValue)
        setBStepValue(e.data.bStepValue)
        setXStepValue(e.data.xStepValue)
        setYStepValue(e.data.yStepValue)
        setYMValue(e.data.yMValue)
        setYRValue(e.data.yRValue)
        setYCValue(e.data.yCValue)
        setYGValue(e.data.yGValue)
        setXVPLValue(e.data.xVPLValue)
        setXVPRValue(e.data.xVPRValue)
        setYHorizonValue(e.data.yHorizonValue)
        setVPLVPR(e.data.VPLVPR)
        setVPLVPRO(e.data.VPLVPRO)
        setVPLVPRG(e.data.VPLVPRG)
        setVPLVPRB(e.data.VPLVPRB)
        setVPLVPRC(e.data.VPLVPRC)
        setVP_O(e.data.VP_O)
        setVP_B(e.data.VP_B)
        setOR(e.data.OR)
        setBM(e.data.BM)
        setYYValue(e.data.yYValue)
    }

    useEffect(() => {
        if (!myWorker || !refMunshellTop.current || !refTheResult || !refCC || !refCCTop) return

        myWorker.postMessage({
            type: 'drawMunshellSphere',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
        //refCC.current.getContext("2d").clearRect(0, 0, 800, 800)
        myWorker.postMessage({
            type: 'drawTheCube',
            myDegree,
            examBOH,
            examA,
            examB,
            examRatio,
            examX0,
            examY0,
            examRY,
            OXValue,
            OYValue,
            bAxisHValue,
            bStepValue,
            xStepValue,
            yStepValue,
            yMValue,
            yRValue,
            yCValue,
            yGValue,
            xVPLValue,
            xVPRValue,
            yHorizonValue,
            VPLVPR,
            VPLVPRO,
            VPLVPRG,
            VPLVPRB,
            VPLVPRC,
            VP_O,
            VP_B,
            OR,
            BM,
            yYValue,
        })
    }, [myWorker, refMunshellTop, refTheResult, refCC, refCCTop])

    useEffect(() => {

        const handleData = e => {
            const { type, result } = e.data
            switch (type) {
                case 'drawMunshellSphere':
                    refMunsellSphere.current.getContext('bitmaprenderer').transferFromImageBitmap(result)
                    resetAllAvailbles(e)
                    break
                case 'drawTheCube':
                    refCC.current.getContext('bitmaprenderer').transferFromImageBitmap(result)
                    resetAllAvailbles(e)
                    break
                case 'summary':
                    setIsLoading(false)
                    refTheResult.current.getContext('bitmaprenderer').transferFromImageBitmap(result)
                    break
                case 'MunshellTop':
                    setIsLoading(false)
                    refMunshellTop.current.getContext('bitmaprenderer').transferFromImageBitmap(result)
                    break
                case 'showRGBArray':
                    refCCTop.current.getContext('bitmaprenderer').transferFromImageBitmap(result)
                    break
                case 'progress':
                    const { current, gate } = e.data
                    setNow(Math.round(current / gate * 100))
                    break
                default:
                    break
            }
        }

        const handleError = e => {
            console.log("Worker error: ", e)
        }

        const worker = new Worker(drawing_script)

        worker.addEventListener("message", handleData)
        worker.addEventListener("error", handleError)

        setMyWorker(worker)

        return () => {
            console.log("Terminating web worker")
            worker.removeEventListener("message", handleData)
            worker.removeEventListener("error", handleError)
            worker.terminate()
        }

    }, [])

    useEffect(() => {
        if (!isLoading) setNow(0)
    }, [isLoading])

    return (
        <>

            <div className='munshellDiv' id="MunsellDemo" >
                <canvas width={382} height={573} className="munshellMain" ref={refMunsellSphere}>
                </canvas>
                <canvas width={382} height={382} className="munshellTop" ref={refMunshellTop}>
                </canvas>
                <canvas width={382} height={191} top={382} className="result" ref={refTheResult}>
                </canvas>
            </div>
            <>
                {isLoading ?
                    <div style={{
                        width: '382px',
                        height: '28px',
                    }}>
                        <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                    </div>
                    :
                    <div style={{
                        width: '382px',
                        height: '28px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <button style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="examButton-triad" onClick={doTriad}> Triad </button>
                        <button style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="examButton" onClick={doExam1}> 1 </button>
                        <button style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="examButton" onClick={doExam2}> 2 </button>
                        <button style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="examButton" onClick={doExam3}> 3 </button>
                        <button style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="examButton" onClick={doExam4}> 4 </button>
                        <button style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="examButton" onClick={doExam5}> 5 </button>
                        <button style={{ visibility: isLoading ? 'hidden' : 'visible' }} className="examButton" onClick={doExam6}> 6 </button>
                    </div>
                }
            </>
            <div className="RGBDiv" id="divCube">
                <canvas width={382} height={450} className="cube" ref={refCC}>
                </canvas>
                <canvas width={382} height={450} className="cube-top" ref={refCCTop}>
                </canvas>
            </div>
            <div style={{ fontColor: 'gray' }} >
                Một số giả định của Ostwald và Munshell.
            </div>
        </>
    )
}