import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'

import { put, call } from 'redux-saga/effects'
import { 
    ART_LIST_REQUEST, 
    ART_LIST_SUCCESS, 
    ART_LIST_FAILURE,
    ART_INSERT_REQUEST, 
    ART_INSERT_SUCCESS, 
    ART_INSERT_FAILURE,
    ART_UPDATE_REQUEST, 
    ART_UPDATE_SUCCESS, 
    ART_UPDATE_FAILURE,
    ART_UPDATE_CREATED_DATE_REQUEST, 
    ART_UPDATE_CREATED_DATE_SUCCESS, 
    ART_UPDATE_CREATED_DATE_FAILURE,
    ART_DELETE_REQUEST, 
    ART_DELETE_SUCCESS, 
    ART_DELETE_FAILURE,
} from '../ReduxAction'

export function* getArtListAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: ART_LIST_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/art/article-list', action.payload || {}, axiosConfig)
            return res
        })

        if (response?.status === 200) {
            yield put({ type: ART_LIST_SUCCESS, payload: response.data })
        } else {
            yield put({ type: ART_LIST_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: ART_LIST_FAILURE, payload: error?.message })
        console.group("getArtListAsync error")
        console.log(error)
        console.groupEnd()

    }
}

export function* insertArtAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: ART_INSERT_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/art/article-insert', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ART_INSERT_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: ART_INSERT_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: ART_INSERT_FAILURE, payload: error.message })
    }
}

export function* updateArtAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: ART_UPDATE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/art/article-update', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ART_UPDATE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: ART_UPDATE_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: ART_UPDATE_FAILURE, payload: error.message })
    }
}

export function* updateArtCreatedDateAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: ART_UPDATE_CREATED_DATE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/art/article-update-created-date', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ART_UPDATE_CREATED_DATE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: ART_UPDATE_CREATED_DATE_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: ART_UPDATE_CREATED_DATE_FAILURE, payload: error.message })
    }
}

export function* deleteArtAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: ART_DELETE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/art/article-delete', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ART_DELETE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: ART_DELETE_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: ART_DELETE_FAILURE, payload: error.message })
    }
}