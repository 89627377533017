import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LocalImage } from '../_components'
import { ParagItem } from './ParagItem'
import { TagInput } from '../_components/TagInput'
import { Tags } from '../_components/Tags'
import Load from '../components/Load'
import Pagetitle from '../components/Pagetitle'
import DateSticker from '../components/DateSticker'
import { PARAG_LIST, ART_UPDATE_CREATED_DATE, ART_HISTORY_INSERT, UHA_SAVE } from '../Redux/ReduxSaga/triggers'
import { dateToStringInput } from '../_helpers'

const ArticlePage = props => {
    const [nextParagQueue, setNextParagQueue] = useState(10)
    const [editDate, setEditDate] = useState(false)
    const { dispatch, auth, art, parag, navHeader } = props
    const paragList = parag.data?.list_data
    const location = useLocation()
    const [article, setArticle] = useState(location.state.article)
    const [artCreatedDate, setArtCreatedDate] = useState(new Date(article.artCreatedDate))
    const [gotoEnd, setGotoEnd] = useState(true)
    const [foundParagraphs, setFoundParagraphs] = useState([])

    const initialRef = useRef(true)
    const endAreaRef = useRef()

    const handleCreatedDateChange = e => {
        setArtCreatedDate(new Date(e.target.value))
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (parag.loading) return
            const theNextQueue = paragList?.reduce((prev, curr) => {
                return prev.paragQueue < curr.paragQueue ? prev.paragQueue : curr.paragQueue;
            }, 0) + 10
            setNextParagQueue(theNextQueue)
        }

        return () => mounted = false
    }, [paragList])

    useEffect(() => {
        let mounted = true

        if (mounted) {

            dispatch({
                type: UHA_SAVE,
                payload: {
                    artId: article.id
                }
            })

            dispatch({
                type: ART_HISTORY_INSERT,
                payload: {
                    artId: article.id
                }
            })
        }

        return () => mounted = false
    }, [])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            dispatch({
                type: PARAG_LIST,
                payload: {
                    art_id: article?.id
                }
            })
        }
        return () => mounted = false
    }, [article?.id])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (initialRef.current) {
                initialRef.current = false
                return
            }
            if (!parag.changing) {
                dispatch({
                    type: PARAG_LIST,
                    payload: {
                        art_id: article.id
                    }
                })
            }
        }
        return () => mounted = false
    }, [parag.changing])

    useEffect(() => {

        if (!!!paragList) return
        if (!!!navHeader.keyword) {
            setFoundParagraphs([])
            return
        }

        const newFoundParagraphs = [];

        paragList.map(x => {
            if (!!x.paragBody) {
                if (x.paragBody.toUpperCase().indexOf(navHeader.keyword.toUpperCase()) >= 0) {
                    newFoundParagraphs.push(x.id)
                }
            }
        })
        setFoundParagraphs(newFoundParagraphs)

    }, [navHeader.keyword, paragList])

    const handleClickDatePicker = () => {
        if (auth.data?.isAdmin) setEditDate(true)
    }

    const handleCancel = () => {
        setEditDate(false)
    }

    const handleSave = () => {
        dispatch({
            type: ART_UPDATE_CREATED_DATE,
            payload: {
                artID: article.id,
                artCreatedDate: artCreatedDate,
            }
        })
        setEditDate(false)
    }

    useEffect(() => {
        if (!initialRef.current && !art.changing) {
            setArticle({
                ...article,
                artCreatedDate
            })
        }
    }, [art, artCreatedDate, initialRef])

    useEffect(() => {
        if (auth.data?.isAdmin && gotoEnd) {
            endAreaRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    })

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: `100%` }}>
                    <div className="row">
                        <div className="col-lg-12">

                            {parag.loading ? <Load /> :
                                <>
                                    <Pagetitle
                                        title={(
                                            <div className="container ps-0 pe-0">
                                                <div className="flex-start bg-white bg-transparent-card rounded-xxl shadow-xss pt-1 pb-1 mb-2 mt-2">
                                                    <span className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center text">{article?.artCaption}</span>
                                                </div>
                                            </div>
                                        )}
                                        showAdd={auth.data?.isAdmin ? {
                                            to: '/parag-detail',
                                            state: {
                                                paragraph: {
                                                    artID: article.id,
                                                    paragQueue: nextParagQueue,
                                                },
                                                article
                                            }
                                        } : null}
                                    />
                                    <div><LocalImage src={article.artImgLink} alt='' /></div>
                                    <div className='art-body parag'>
                                        {article.artBody}
                                    </div>
                                    {
                                        paragList?.map((item, key) => {
                                            const inFoundList = foundParagraphs.indexOf(item.id) >= 0
                                            return <ParagItem key={key} paragraph={item} article={article} inFoundList={inFoundList} />
                                        })
                                    }
                                    <div ref={endAreaRef}></div>
                                    {
                                        art.changing ? <Load /> :
                                            <div className='mt-5' onClick={handleClickDatePicker}>
                                                <DateSticker dateString={article.artCreatedDate} status='bg-success' />
                                            </div>
                                    }
                                    {editDate &&
                                        <>
                                            <button onClick={handleSave} className="btn btn-primary m-2">Save</button>
                                            <button onClick={handleCancel} className="btn btn-secondary m-2">Cancel</button>
                                            <div>
                                                <input type="date" value={dateToStringInput(artCreatedDate)} onChange={handleCreatedDateChange}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className='h100' />
                                    <Tags artId={article.id} catID={article?.catID} />
                                    <div className='h100' />
                                    {auth.data?.isAdmin &&
                                        <div>
                                            <label>
                                                <input type="checkbox" checked={gotoEnd} onChange={e => setGotoEnd(e.target.checked)} />
                                                {` Go to end`}</label>
                                        </div>
                                    }
                                    {auth.data?.isAdmin && <TagInput artId={article.id} />}
                                    <div className='h100' />

                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    parag: state.parag,
    artTa: state.artTa,
    art: state.art,
    navHeader: state.navHeader,
})
const ConnectedPage = connect(mapStateToProps)(ArticlePage)
export default ConnectedPage