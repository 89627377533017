export const paging = {
	size: {
		small: 10,
		medium: 20,
		large: 50,
		extra: 100,
	},
	size_arr: [5, 10, 20, 50, 100], //bản MIT free chỉ được 100 rows
}

export const rowsPerPage = 10

