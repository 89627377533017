import {
    FCM_SEND_NOTIFICATION_REQUEST,
    FCM_SEND_NOTIFICATION_SUCCESS,
    FCM_SEND_NOTIFICATION_FAILURE,

} from '../ReduxAction'

interface IFcm {
    deviceToken: string;
    sdkResponse?: any;
}

interface IFcmState {
    loading: boolean;
    error: boolean;
    message: string;
    data: IFcm | null;
}

const initialState: IFcmState = {
    loading: false,
    error: false,
    message: '',
    data: null
}

export const fcmReducer = (state: IFcmState = initialState, action: { type: string; payload: any; }): IFcmState => {
    switch (action.type) {
        case FCM_SEND_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'Sending Notification requested',
            }
        case FCM_SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'Sending Notification Success',
                data: {
                    ...state.data,
                    sdkResponse: action.payload
                } as IFcm
            }
        case FCM_SEND_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
            }

        default:
            return state
    }
}