import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'

import { put, call } from 'redux-saga/effects'

import { 
    CAT_LIST_REQUEST, 
    CAT_LIST_SUCCESS, 
    CAT_LIST_FAILURE,
    CAT_INSERT_REQUEST, 
    CAT_INSERT_SUCCESS, 
    CAT_INSERT_FAILURE,
    CAT_UPDATE_REQUEST, 
    CAT_UPDATE_SUCCESS, 
    CAT_UPDATE_FAILURE,
    CAT_DELETE_REQUEST, 
    CAT_DELETE_SUCCESS, 
    CAT_DELETE_FAILURE,
} from '../ReduxAction'

export function* getCatListAsync(action) {
        try {
        action = actionIntercept(action)
        yield put({ type: CAT_LIST_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/cat/category-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: CAT_LIST_SUCCESS, payload: response.data })
        } else {
            yield put({ type: CAT_LIST_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: CAT_LIST_FAILURE, payload: error.message })
    }
}

export function* insertCatAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: CAT_INSERT_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/cat/category-insert', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: CAT_INSERT_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: CAT_INSERT_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: CAT_INSERT_FAILURE, payload: error.message })
    }
}

export function* updateCatAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: CAT_UPDATE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/cat/category-update', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: CAT_UPDATE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: CAT_UPDATE_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: CAT_UPDATE_FAILURE, payload: error.message })
    }
}

export function* deleteCatAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: CAT_DELETE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/cat/category-delete', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: CAT_DELETE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: CAT_DELETE_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: CAT_DELETE_FAILURE, payload: error.message })
    }
}