import { store } from '..'
import { PENDING_ACTION_ADD } from '../Redux/ReduxAction'
import { SILENTLY_REFRESH_TOKEN } from '../Redux/ReduxSaga/triggers'

const errorInterceptor = axiosInstance => {

    axiosInstance.interceptors.response.use(
        response => response,
        error => {

            if (error.config?.url.toLowerCase().indexOf(('refreshtoken' || 'refresh_token').toLowerCase()) >= 0) {
                return Promise.reject(error)
            }

            if (error?.response?.status === 401) {

                console.group("Unauthorized Error");
                console.error("url:", error.config?.url)
                console.error("data:", error.config?.data)
                console.groupEnd();

                store.dispatch({
                    type: PENDING_ACTION_ADD,
                    payload: JSON.parse(error.config.data)
                })

                if (!store.getState().silentlyRT.loading)
                    store.dispatch({
                        type: SILENTLY_REFRESH_TOKEN,
                        payload: {
                            token: store.getState().auth.data.refreshToken
                        }
                    })
            }

            return Promise.reject(error)

        }
    )
}

export default errorInterceptor