import React, { useState } from 'react'

export const LocalImage = ({ src }) => {
    const [source, setSource] = useState(src)

    const handleError = () => {
        setSource(`https://source.unsplash.com/random/1200x800?sig=${Math.ceil(Math.random() * 20)}`)
    }

    return (
        <img onError={handleError} src={source} className='img-fluid img-thumbnail' />
    )
}