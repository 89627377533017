import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Load from '../components/Load'
import { apiObject, axiosConfig } from "../Constants"
import { authHeader } from "../_services"
import { randomAvatarSrc } from "../_helpers"
import { Pagination } from '../_components'

const ArtHistory = props => {
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [artHistory, setArtHistory] = useState(null)
    const [pageCount, setPageCount] = useState(0)

    const { navHeader } = props
    const { keyword, searchActive } = navHeader


    const paginationPageSizeInputName = 'artHistoryPagination'
    const propsOfPagination = {
        currentPage, setCurrentPage,
        pageSize, setPageSize,
        pageCount, setPageCount,
        paginationPageSizeInputName,
    }

    const reload = () => {
        let mounted = true
        if (mounted) {
            setLoading(true)
            
            apiObject.post('/ArtHistory/art-history-list', {
                keyword: searchActive ? keyword : '',
                page: currentPage,
                pageSize: pageSize,
            }, axiosConfig).then(response => {
                if (response?.status === 200) {
                    setArtHistory(response.data.list_data)
                    const newPageCount = Math.ceil(response.data.total_row / pageSize)
                    setPageCount(newPageCount)
                    if (currentPage > newPageCount) setPage(1)
                }
                setLoading(false)
            })
        }
        return () => mounted = false
    }

    useEffect(() => {
        let mounted = true
        if (mounted) reload()
        return () => mounted = false
    }, [currentPage, pageSize])

    useEffect(() => {
        let mounted = true
        let id
        if (mounted) {
            if (searchActive) {
                id = setTimeout(() => reload(), 500)
            }
        }
        return () => { if (!!id) clearTimeout(id) }
    }, [keyword, searchActive])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: `100%` }}>
                    <div className="row">
                        <div className="col-lg-12">

                            <Pagination {...propsOfPagination} />
                            {loading && <Load />}

                            <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                <ul className="email-message"
                                    style={{
                                        paddingInlineStart: '0px !important',
                                        marginBlockStart: '0px !important',
                                        marginBlockEnd: '0px !important',
                                    }}>

                                    {!!artHistory && artHistory?.map((item, index) => {
                                        let avatarUrl = randomAvatarSrc()
                                        if (item.googleProfile) {
                                            const googleProfile = JSON.parse(item.googleProfile)
                                            avatarUrl = googleProfile.Picture
                                        }

                                        return (

                                            <li key={index} >
                                                <Link to='/article' state={{ id: item.artID }} className={`rounded-3 bg-lightblue theme-light-bg`} >
                                                    <div className="email-user">
                                                        <span className="btn-round-xss ms-0 bg-success me-2"></span>
                                                        <img src={avatarUrl} alt="user" className="w35 me-2" />
                                                        <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700">{`${item.u} (${item.email})`}</h6>
                                                    </div>
                                                    <div className="email-subject text-grey-900 text-dark fw-600 font-xssss">
                                                        <i className="feather-star font-xss text-warning me-2"></i>
                                                        {item.artCaption}
                                                    </div>
                                                    <div className="email-text text-grey-500 fw-600 font-xssss">{item.artBody}</div>
                                                    <span className="email-file">
                                                        <i className="feather-paperclip font-xss btn-round-sm text-grey-500 me-2 p-0"></i>
                                                    </span>
                                                    <div className="email-time text-grey-500 fw-600">
                                                        {new Date(item.hTime).toLocaleDateString('vi-VI', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: '2-digit',
                                                        })}
                                                    </div>
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        navHeader: state.navHeader,
    }
}
const ConnectedArtHistory = connect(mapStateToProps)(ArtHistory)
export { ConnectedArtHistory as ArtHistory }