import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'

import { put, call } from 'redux-saga/effects'
import { 
    AUTHOR_LIST_REQUEST, 
    AUTHOR_LIST_SUCCESS, 
    AUTHOR_LIST_FAILURE,
    AUTHOR_INSERT_REQUEST, 
    AUTHOR_INSERT_SUCCESS, 
    AUTHOR_INSERT_FAILURE,
    AUTHOR_UPDATE_REQUEST, 
    AUTHOR_UPDATE_SUCCESS, 
    AUTHOR_UPDATE_FAILURE,
    AUTHOR_DELETE_REQUEST, 
    AUTHOR_DELETE_SUCCESS, 
    AUTHOR_DELETE_FAILURE,
} from '../ReduxAction'

export function* getAuthorListAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: AUTHOR_LIST_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/author/author-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_LIST_SUCCESS, payload: response.data })
        } else {
            yield put({ type: AUTHOR_LIST_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_LIST_FAILURE, payload: error.message })
    }
}

export function* insertAuthorAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: AUTHOR_INSERT_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/author/author-insert', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_INSERT_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: AUTHOR_INSERT_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_INSERT_FAILURE, payload: error.message })
    }
}

export function* updateAuthorAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: AUTHOR_UPDATE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/author/author-update', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_UPDATE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: AUTHOR_UPDATE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_UPDATE_FAILURE, payload: error.message })
    }
}

export function* deleteAuthorAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: AUTHOR_DELETE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/author/author-delete', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_DELETE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: AUTHOR_DELETE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_DELETE_FAILURE, payload: error.message })
    }
}