import {
    ALBUM_LIST_REQUEST,
    ALBUM_LIST_SUCCESS,
    ALBUM_LIST_FAILURE,
    ALBUM_INSERT_REQUEST,
    ALBUM_INSERT_SUCCESS,
    ALBUM_INSERT_FAILURE,
    ALBUM_UPDATE_REQUEST,
    ALBUM_UPDATE_SUCCESS,
    ALBUM_UPDATE_FAILURE,
    ALBUM_DELETE_REQUEST,
    ALBUM_DELETE_SUCCESS,
    ALBUM_DELETE_FAILURE,
} from '../ReduxAction'

const initialState = {
    loading: false,
    changing: false,
    error: false,
    message: '',
    data: null,
}

export const albumReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALBUM_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'list request',
            };
        case ALBUM_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'list fulfil',
                data: action.payload,
            };
        case ALBUM_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                data: null,
            };
        case ALBUM_INSERT_REQUEST:
        case ALBUM_UPDATE_REQUEST:
        case ALBUM_DELETE_REQUEST:
            return {
                ...state,
                changing: true
            };
        case ALBUM_INSERT_SUCCESS:
        case ALBUM_UPDATE_SUCCESS:
        case ALBUM_DELETE_SUCCESS:
        case ALBUM_INSERT_FAILURE:
        case ALBUM_UPDATE_FAILURE:
        case ALBUM_DELETE_FAILURE:
            return {
                ...state,
                changing: false
            };

        default:
            return state;
    }
}