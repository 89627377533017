import {
    SILENTLY_REFRESH_TOKEN_REQUEST,
    SILENTLY_REFRESH_TOKEN_SUCCESS,
    SILENTLY_REFRESH_TOKEN_FAILURE,
    LOGIN_SUCCESS,
    GOOGLE_LOGIN_SUCCESS,
    REFRESH_TOKEN_SUCCESS,
    LOGOUT
} from '../ReduxAction'

const initialState = {
    loading: false,
    error: false,
    message: '',
    count: null,
    signalState: 1, // by default, hub connection need to start at the begining
    refreshingCall: () => null
}

export const silentlyRefreshTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case SILENTLY_REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'silentlyRefreshToken request',
                signalState: 0,
            };
        case SILENTLY_REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'silentlyRefreshToken fulfil',
                count: (state.count || 0) + 1,
                signalState: 1, // need to recreate hubConnction
            };
        case SILENTLY_REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload || "Failed with a null payload",
                signalState: 0,
            };
        case LOGOUT:
            return initialState
        case LOGIN_SUCCESS:
        case GOOGLE_LOGIN_SUCCESS:
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                count: 0
            }
        default:
            return state;
    }
}