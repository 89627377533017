import React from 'react'
import { TooltipWrapper } from '..'
import { useFormikContext } from 'formik'

export const EditModeIndicator = props => {
    const { editMode, noneFormikDirty } = props
    const color = editMode === 'add' ? 'green' : editMode === 'modify' ? 'blue' : 'gray'
    const title = `common.${editMode}mode`
    const formik = useFormikContext()

    return (
        <TooltipWrapper title={title}>
            <div className='edit-mode-indicator'
                style={{
                    backgroundColor: color,
                    borderColor: formik ? (formik?.dirty ? 'orange' : 'transparent') : noneFormikDirty ? 'orange' : 'transparent',
                }}
            />
        </TooltipWrapper>
    )
}