import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const Pagetitle = props => {
    const { auth, title, showSearch, showAdd, showModify } = props

    const renderRightPart = () => {
        return (<>
            {showAdd && auth.data?.isAdmin &&
                <Link
                    to={showAdd.to}
                    state={showAdd.state}
                >
                    <div className="ms-auto pointer"><i className="ti-plus text-grey-900 btn-round-md font-xss text"></i></div>
                </Link>
            }
            {showModify && auth.data?.isAdmin &&
                <Link
                    to={showModify.to}
                    state={showModify.state}
                    className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3"
                >
                    <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md font-xss text"></i></div>
                </Link>
            }
            {showSearch &&
                <>
                    <form action="#" className="pt-0 pb-0 ms-auto">
                        <div className="search-form-2 ms-2">
                            <i className="ti-search font-xss"></i>
                            <input type="text" className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                        </div>
                    </form>
                    <a href="/" className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3"><i className="feather-filter font-xss text-grey-500"></i></a>
                </>
            }
        </>)
    }

    return (
        <div className='flex-start'>
            {

                typeof title === 'string' ?
                    <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                            {title}
                            {renderRightPart()}
                        </h2>
                    </div>
                    :
                    <>
                        {title}
                        {renderRightPart()}
                    </>
            }
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.auth
})
const ConnectedPage = connect(mapStateToProps)(Pagetitle)
export default ConnectedPage