import React, { useRef, useEffect, useState } from 'react'
import { wavelengthToColor } from '../_helpers'

export const TheSpectrum = () => {
    const refTheSpectrum = useRef()
    const [ctx, setCtx] = useState()
    const i = useRef(400)
    const setI = value => i.current = value

    const scanColor = () => {
        if (i.current > 670) setI(400)
        const cs = wavelengthToColor(i.current)
        ctx.clearRect(0, 15, 382, 10)
        ctx.fillStyle = cs[0]
        ctx.fillRect(i.current - 400 - 10, 15, 10, 10)
        setI(i.current + 1)
    }

    useEffect(() => {
        const TheSpectrum = refTheSpectrum.current
        setCtx(TheSpectrum.getContext('2d'))
    }, [])

    useEffect(() => {
        if (!ctx) return

        for (let x = 400; x <= 670; x++) {
            ctx.fillStyle = wavelengthToColor(x)[0]
            ctx.fillRect(x - 400, 0, 1, 10)
        }

        const id = setInterval(scanColor, 10)

        return () => {
            clearInterval(id)
        }
    }, [ctx])

    return (
        <canvas ref={refTheSpectrum} width={382} height={75} className="TheSpectrum"></canvas>
    )
}
