import React, { useState, useContext, useRef, useEffect } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Context } from './Context'
import './style.css'

const NodeMenu = props => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = !!anchorEl

    const { langCode, nodes, setNodes, edges, setEdges, setNodrag } = useContext(Context)
    const { setShowMenu } = props
    const ref = useRef()

    const handleClose = () => {
        setNodrag(false)
        setAnchorEl(null)
        setShowMenu(false)
    }

    const handleClickEditLabel = () => {
        handleClose()
        const newLabel = prompt(langCode === 'en' ? 'Input new label' : 'Tiêu đề mới', props.data.label) || props.data.label
        const filter1 = nodes.filter(item => item.id === props.id)
        const filter2 = nodes.filter(item => item.id !== props.id)
        const currentNode = filter1[0]
        currentNode.data.label = newLabel
        filter2.push(currentNode)
        setNodes(filter2)
    }

    const handleClickDeleteNode = () => {
        handleClose()
        const nodeFilter = nodes.filter(item => item.id !== props.id)
        setNodes(nodeFilter)
        const edgeFilter = edges.filter(item => item.source !== props.id && item.target !== props.id)
        setEdges(edgeFilter)
    }

    useEffect(() => {
        setAnchorEl(ref.current)
    }, [ref])

    return (
        <div>
            <div ref={ref} />
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClickDeleteNode}>
                    {langCode === 'en' ? 'Delete node' : 'Xóa bước'}
                </MenuItem>
                <MenuItem onClick={handleClickEditLabel}>
                    {langCode === 'en' ? 'Edit label' : 'Chỉnh tiêu đề'}
                </MenuItem>
            </Menu>
        </div>
    )
}
export { NodeMenu }