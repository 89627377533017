import {
    USER_ACTIVITY_SET,
} from '../ReduxAction'

const initialState = []

export const userActivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ACTIVITY_SET:
            return action.payload
        default:
            return state
    }
}