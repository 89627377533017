import React from 'react'
import Home from '../pages/Home'
import ParagTemplateList from '../ParagTemplate/TemplateList'
import ParagTemplateDetail from '../ParagTemplate/TemplateDetail'
import FlowChart from '../FlowChart/FlowChart'
import FlowChartSave from '../FlowChart/FlowChartSave'
import DeleteConfirm from '../DeleteConfirm'
import CatList from '../Cat/CatList'
import CatDetail from '../Cat/CatDetail'
import ReduxStoreViewer from '../_components/ReduxStoreViewer'
import { ArticlesPage } from '../Articles'
import ArticlePage from '../Article/ArticlePage'
import { ArticleDetailPage } from '../ArticleDetail'
import ParagDetailPage from '../ParagDetail/ParagDetailPage'
import AuthorList from '../Author/AuthorList'
import AuthorDetail from '../Author/AuthorDetail'
import AlbumList from '../Album/AlbumList'
import AlbumDetail from '../Album/AlbumDetail'
import PictureList from '../Picture/PictureList'
import PictureDetail from '../Picture/PictureDetail'
import QuoteList from '../Quote/QuoteList'
import QuoteDetail from '../Quote/QuoteDetail'
import AuthorImageList from '../AuthorImage/AuthorImageList'
import AuthorImageDetail from '../AuthorImage/AuthorImageDetail'
import { SwaggerPage } from '../Swagger'
import OnlineUsers from '../OnlineUsers/OnlineUsers'
import Chat from '../pages/Chat'
import VideoOffer from '../VideoCall/VideoOffer'
import VideoAnswer from '../VideoCall/VideoAnswer'
import { SheetJSApp } from '../SheetJSApp'
import { ColorSphere } from '../ColorSphere/ColorSphere'
import { ColorHarmony } from '../ColorHarmony/ColorHarmony'
import { TheSpectrum } from '../TheSpectrum/TheSpectrum'
import { DavidNovick } from '../DavidNovick/DavidNovick'
import { MiukaSphere } from '../MiukaSphere'
import { Reflect } from '../Reflect'
import { ReflectModel } from '../ReflectModel'
import { Settings } from '../pages/Settings'
import { FileSelector } from '../_components'
import { Mp3Recorder } from '../_components'
import { ImageList } from '../_components'
import { ImageOfflineList } from '../_components'
import { ImageView } from '../_components'
import { AudioList } from '../_components'
import { AudioView } from '../_components'
import { Account } from '../pages/Account'
import { ArtHistory } from '../ArtHistory'
import { ArtTime } from '../ArtTime'
import FirebaseSDK from '../FirebaseSDK'

export const privateComponents = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/home',
        element: <Home />,
    },
    {
        path: '/deleteconfirm',
        element: <DeleteConfirm />,
    },
        {
        path: '/upload-file',
        element: <FileSelector />,
    },
    {
        path: '/mp3-recorder',
        element: <Mp3Recorder />,
    },
    {
        path: '/image-list',
        element: <ImageList />,
    },
    {
        path: '/image-offline-list',
        element: <ImageOfflineList />,
    },
    {
        path: '/image-view',
        element: <ImageView />,
    },
    {
        path: '/audio-list',
        element: <AudioList />,
    },
    {
        path: '/audio-view',
        element: <AudioView />,
    },
    {
        path: '/colorsphere',
        element: <ColorSphere />,
    },
    {
        path: '/colorharmony',
        element: <ColorHarmony />,
    },
    {
        path: '/thespectrum',
        element: <TheSpectrum />,
    },
    {
        path: '/david-novick',
        element: <DavidNovick />,
    },
    {
        path: '/miuka-sphere',
        element: <MiukaSphere />,
    },
    {
        path: '/reflect',
        element: <Reflect />,
    },
    {
        path: '/reflect-model',
        element: <ReflectModel />,
    },
    {
        path: '/account-settings',
        element: <Settings />,
    },
    {
        path: '/account-information',
        element: <Account />,
    },
    {
        path: '/articles',
        element: <ArticlesPage />,
    },
    {
        path: '/article',
        element: <ArticlePage />,
    },
    {
        path: '/art-detail',
        element: <ArticleDetailPage />,
    },
    {
        path: '/parag-detail',
        element: <ParagDetailPage />,
    },
    {
        path: '/parag-template-list',
        element: <ParagTemplateList />
    },
    {
        path: '/parag-template-detail',
        element: <ParagTemplateDetail />
    },
    {
        path: '/swaggerui',
        element: <SwaggerPage />,
    },
    {
        path: '/onlineusers',
        element: <OnlineUsers />
    },
    {
        path: '/sheet-js-app',
        element: <SheetJSApp />
    },
    {
        path: '/art-history',
        element: <ArtHistory />
    },
    {
        path: '/art-time',
        element: <ArtTime />
    },
    {
        path: '/authors',
        element: <AuthorList />
    },
    {
        path: '/authordetail',
        element: <AuthorDetail />
    },
    {
        path: '/albums',
        element: <AlbumList />
    },
    {
        path: '/albumdetail',
        element: <AlbumDetail />
    },
    {
        path: '/pictures',
        element: <PictureList />
    },
    {
        path: '/picturedetail',
        element: <PictureDetail />
    },
    {
        path: '/quotes',
        element: <QuoteList />
    },
    {
        path: '/quotedetail',
        element: <QuoteDetail />
    },
    {
        path: '/authorimages',
        element: <AuthorImageList />
    },
    {
        path: '/authorimagedetail',
        element: <AuthorImageDetail />
    },
    {
        path: '/categories',
        element: <CatList />
    },
    {
        path: '/categorydetail',
        element: <CatDetail />
    },
    {
        path: '/chat',
        element: <Chat />
    },
    {
        path: '/videooffer',
        element: <VideoOffer />
    },
    {
        path: '/videoanswer',
        element: <VideoAnswer />
    },
    {
        path: '/reduxstore',
        element: <ReduxStoreViewer />
    },
    {
        path: '/flowchart',
        element: <FlowChart />
    },
    {
        path: '/flowchart-save',
        element: <FlowChartSave />
    },
    {
        path: '/firebase-sdk',
        element: <FirebaseSDK />
    },
]