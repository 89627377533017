import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'

import { put, call } from 'redux-saga/effects'
import {
    QUOTE_LIST_REQUEST,
    QUOTE_LIST_SUCCESS,
    QUOTE_LIST_FAILURE,
    QUOTE_INSERT_REQUEST,
    QUOTE_INSERT_SUCCESS,
    QUOTE_INSERT_FAILURE,
    QUOTE_UPDATE_REQUEST,
    QUOTE_UPDATE_SUCCESS,
    QUOTE_UPDATE_FAILURE,
    QUOTE_DELETE_REQUEST,
    QUOTE_DELETE_SUCCESS,
    QUOTE_DELETE_FAILURE,
} from '../ReduxAction'

export function* getQuoteListAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: QUOTE_LIST_REQUEST })
        const response = yield call(async () => {

            const res = await apiObject.post('/quote/quote-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: QUOTE_LIST_SUCCESS, payload: response.data })
        } else {
            yield put({ type: QUOTE_LIST_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: QUOTE_LIST_FAILURE, payload: error.message })
    }
}

export function* insertQuoteAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: QUOTE_INSERT_REQUEST })
        const response = yield call(async () => {

            const res = await apiObject.post('/quote/quote-insert', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: QUOTE_INSERT_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: QUOTE_INSERT_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: QUOTE_INSERT_FAILURE, payload: error.message })
    }
}

export function* updateQuoteAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: QUOTE_UPDATE_REQUEST })
        const response = yield call(async () => {

            const res = await apiObject.post('/quote/quote-update', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: QUOTE_UPDATE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: QUOTE_UPDATE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: QUOTE_UPDATE_FAILURE, payload: error.message })
    }
}

export function* deleteQuoteAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: QUOTE_DELETE_REQUEST })
        const response = yield call(async () => {

            const res = await apiObject.post('/quote/quote-delete', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: QUOTE_DELETE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: QUOTE_DELETE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: QUOTE_DELETE_FAILURE, payload: error.message })
    }
}