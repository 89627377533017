import React from 'react'
import { Link } from 'react-router-dom'
import { PARAG_TEMPLATE_DELETE } from '../Redux/ReduxSaga/triggers'

const TemplateCard = props => {
    const { template } = props

    return (
        <div className='position-relative'>
            <Link to={'/parag-template-detail'} state={{ template }} >
                <div
                    className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 pe-4 pt-4 pb-4 cursor-pointer"
                    style={{ paddingLeft: "120px" }}
                >
                    <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">
                        {template.templateName}
                    </h4>
                    <h5 className="font-xssss mb-2 text-grey-500 fw-600 align-middle">
                        <span className="text-grey-900 font-xssss text-dark align-middle">{template.paragBody}</span>
                    </h5>
                </div>
            </Link>
            <Link
                className='position-absolute top-0 right-15 p-2'
                to={'/deleteconfirm'}
                state={{
                    action: {
                        type: PARAG_TEMPLATE_DELETE,
                        payload: template
                    },
                    dboName: 'ParagTemplate',
                    slide: 'paragTemplate',
                    title: template.templateName
                }}
            >
                <i className="feather-x font-xl text-grey-500"></i>
            </Link>
        </div>
    )
}
export default TemplateCard