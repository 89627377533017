import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { apiObject, axiosConfig } from '../../Constants'

import { IMAGE_LIST, IMAGE_OFFLINE_LIST } from '../../Redux/ReduxSaga/triggers'
import Pagetitle from '../../components/Pagetitle'

export const ImageView = () => {
    const [message, setMessage] = useState('')
    const [artList, setArtList] = useState(null)
    const [authorList, setAuthorList] = useState(null)
    const [pList, setPList] = useState(null)
    const [paragList, setParagList] = useState(null)
    const [copied, setCopied] = useState('Copy to clipboard')

    const location = useLocation()
    const { image } = location.state

    const dispatch = useDispatch()

    const srcRef = useRef()

    const handleClickLocation = () => {
        
        apiObject.post('/file/image-location', { fileName: image.name }, axiosConfig).then(response => {
            if (response?.status === 200) {
                setArtList(response.data.artList)
                setAuthorList(response.data.authorList)
                setPList(response.data.pList)
                setParagList(response.data.paragList)
                if (response.data.artList.length || response.data.authorList.length || response.data.pList.length || response.data.paragList.length) {
                    setMessage('')
                } else {
                    setMessage('No Location Found')
                }
            } else {
                setMessage(JSON.stringify(response.originalError, null, 2))
            }
        })
    }

    const handleClickDelete = () => {
        
        apiObject.post('/file/delete-image', { fileName: image.name }, axiosConfig).then(response => {
            if (response?.status === 200) {
                setMessage('Deleted!')
                dispatch({ type: IMAGE_LIST })
                dispatch({ type: IMAGE_OFFLINE_LIST })
            } else {
                setMessage(JSON.stringify(response.originalError, null, 2))
            }
        })
    }

    const handleClickDownload = () => {

    }

    const renderArtList = () => {
        return (
            <div style={{ paddingTop: '20px' }}>{artList.map((item, key) => {
                return (
                    <Link key={key} to='/art-detail' state={{ article: item }}>{item.artCaption}</Link>
                )
            })}</div>
        )
    }

    const renderParagList = () => {
        return (
            <div style={{ paddingTop: '20px', wordWrap: 'break-word', }}>{paragList.map((item, key) => {
                return (
                    <Link key={key} to='/parag-detail' state={{ id: item.paragID, artID: item.artID }}>
                        <div>
                            <div>{item.paragBody}</div>
                            <div>{item.paragImgLink}</div>
                        </div>
                    </Link>
                )
            })
            }
            </div>
        )
    }

    const renderPList = () => {
        return (
            <div style={{ paddingTop: '20px' }}>{pList.map((item, key) => {
                return (
                    <Link key={key} to='/picture-detail' state={{ id: item.pID }}>{item.pCaption}</Link>
                )
            })}</div>
        )
    }

    const renderAuthorList = () => {
        return (
            <div style={{ paddingTop: '20px' }}>{authorList.map((item, key) => {
                return (
                    <Link key={key} to='/author-detail' state={{ id: item.authorID }}>{item.author}</Link>
                )
            })}</div>
        )
    }

    const handleClickCopyToClipboard = () => {
        srcRef.current.select()
        srcRef.current.setSelectionRange(0, 99999)
        navigator.clipboard.writeText(srcRef.current.value)
        setCopied('Copied')
    }

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">

                            <Pagetitle title="Image viewer" />

                            <textarea ref={srcRef} className='form-control textarea-small' readOnly>{`/Content/shared_pictures/${image.name}`}</textarea>

                            <button className='btn btn-primary mt-2' onClick={handleClickCopyToClipboard}>{copied}</button>

                            <div className='miuka' style={{ paddingTop: '50px' }}>
                                <img className='img-fluid img-thumbnail' src={image.imageUrl} alt='selected-file' />
                            </div>
                            <div className='flex-between' style={{ paddingTop: '50px' }}>

                                <button className='btn btn-secondary' onClick={handleClickDownload}>Download</button>
                                <button className='btn btn-primary' onClick={handleClickLocation}>Location</button>
                                {
                                    artList
                                    && paragList
                                    && pList
                                    && authorList
                                    && !artList?.length
                                    && !paragList?.length
                                    && !pList?.length
                                    && !authorList?.length
                                    && <button className='btn btn-danger' onClick={handleClickDelete}>Delete</button>
                                }
                            </div>
                            {message.length > 0 && <div style={{ paddingTop: '20px' }}>{message}</div>}
                            {artList && artList?.length > 0 && renderArtList()}
                            {paragList && paragList?.length > 0 && renderParagList()}
                            {pList && pList?.length > 0 && renderPList()}
                            {authorList && authorList?.length > 0 && renderAuthorList()}

                            <div className='h100' />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}