// Unlimited deeply nested Menu bar
import React, { useState, createContext } from 'react'
import { connect } from 'react-redux'
import { RenderChildren } from './RenderChildren'
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import { style } from '../../App/appStyle'

export const MiuzikSideBarContext = createContext()

const MiuzikSideBar = props => {
    const [nodesOpen, setNodesOpen] = useState([])
    const [collapsed, setCollapsed] = useState(false)

    const { auth, cat, menuItems, menuItemWidth, stepIndent } = props
    const defaultContextValues = {
        menuItemWidth, // Chiều rộng tối đa của menu, manual set (px)
        stepIndent, // Độ thụt theo mỗi cấp (tier) (px)
        nodesOpen, setNodesOpen, // Các menu node đang được mở
    }

    const handleClickToogleArrow = () => {
        setCollapsed(!collapsed)
    }

    return (
        <MiuzikSideBarContext.Provider value={defaultContextValues}>
            <div
                className='sidebar-container'
                style={{
                    ...style.sideBarContainer,
                    // width: collapsed ? `70px` : `${menuItemWidth + 100}px`
                }}
            >
                <div style={style.sideBarLogo}>
                    {collapsed? `LOGO`: `BIG LOG`}
                </div>
                {collapsed ?
                    <div style={style.flexContainerFlexStart}>
                        <div style={style.toogleRightIconWrapper}>
                            <BsArrowRightCircle size={25} style={style.arrowForward} onClick={handleClickToogleArrow} />
                        </div>
                    </div>
                    :
                    <div style={style.flexContainerFlexEnd}>
                        <div style={style.toogleLeftIconWrapper}>
                            <BsArrowLeftCircle size={25} style={style.arrowBackward} onClick={handleClickToogleArrow} />
                        </div>
                    </div>
                }
                <RenderChildren childrenItems={menuItems} currentTier={1} parentKey={0} />
            </div>
        </MiuzikSideBarContext.Provider>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        cat: state.cat
    }
}
const ConnectedMiuzikSideBar = connect(mapStateToProps)(MiuzikSideBar)
export { ConnectedMiuzikSideBar as MiuzikSideBar}