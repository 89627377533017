import { mediaStreamConstraints } from './constants'

export const startMediaStream = async ({
    peerConnection,
    localVideoRef,
    setLocalStream,
    setTracksAdded
}) => {
    const newStream = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
    localVideoRef.current.srcObject = newStream
    setLocalStream(newStream)
    newStream.getTracks().forEach(track => {
        peerConnection.addTrack(track, newStream) // The 2nd argument is important
    })
    setTracksAdded(true)
}
