import { authHeader } from "../_services";

const updateHeaderInterceptor = axiosInstance => {

    axiosInstance.interceptors.request.use(config => {

        const jwtToken = `${authHeader().Authorization}`;
        config.headers["Authorization"] = jwtToken;
        config.headers["Content-Type"] = 'application/json';
        return config;

    }, error => {

        console.error("updateHeaderInterceptor error", error)

    })

};

export default updateHeaderInterceptor;