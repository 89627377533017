import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { randomAvatarSrc } from '../_helpers'

const OnlineUsers = props => {
    const [isOpen, setIsOpen] = useState(false)
    const [chatMembers, setChatMembers] = useState([])
    const [reloadFlag, setReloadFlag] = useState(false)

    const { auth, chat, userActivity } = props

    const statusClass = (time1, time2) => {
        const milisecondDiff = time2 - time1
        const minuteDiff = milisecondDiff / 60000
        if (minuteDiff < 0.5) {
            return 'bg-success'
        } else if (minuteDiff < 1) {
            return 'bg-warning'
        } else if (minuteDiff < 1.5) {
            return 'bg-warning'
        } else if (minuteDiff < 2) {
            return 'bg-danger'
        } else return 'bg-light'
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            const newChatMembers = []
            userActivity?.map(item => {
                if (item.userId !== auth.data?.id)
                    newChatMembers.push({
                        userId: item.userId,
                        hubConnectionId: item.hubConnectionId,
                        imageUrl: item.avatar || randomAvatarSrc(),
                        name: item.userName,
                        status: statusClass(item.accessTime, new Date().getTime()),
                    })
            })
            setChatMembers(newChatMembers)
        }

        return () => {
            mounted = false
        }

    }, [userActivity, reloadFlag])

    // Regularly reload page each 30s
    useEffect(() => {
        let mounted = true
        let id

        if (mounted) {
            id = setInterval(() => setReloadFlag(!reloadFlag), 30000)
        }

        return () => {
            if (id) clearInterval(id)
            mounted = false
        }
    }, [reloadFlag])

    const toggleOpen = () => setIsOpen(!isOpen)
    const menuClass = `${isOpen ? " d-block" : ""}`

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: `100%` }}>
                    <div className="row">
                        <div className="col-lg-12">

                            <ul className="list-group list-group-flush">

                                {chatMembers.map((value, index) => {
                                    const notRed = chat.filter(item => item.userId === value.userId && !item.data.red).length
                                    return (
                                        <Link key={index} to='/chat' state={{ partner: value }}>
                                            <li className="bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center">
                                                <figure className="avatar float-left mb-0 me-2">
                                                    <img src={value.imageUrl} alt="avater" className="w35" />
                                                </figure>
                                                <h3 className="fw-700 mb-0 mt-0">
                                                    <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer" onClick={toggleOpen}>{value.name}</span>
                                                </h3>
                                                {!!notRed && <span className="badge-right">{notRed}</span>}
                                                <span className={`${value.status} ms-auto btn-round-xss`}></span>
                                            </li>
                                        </Link>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    userActivity: state.userActivity,
})
const ConnectedPage = connect(mapStateToProps)(OnlineUsers)
export default ConnectedPage