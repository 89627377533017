import React from 'react'
import Tooltip from '@mui/material/Tooltip'

export const TooltipWrapper = ({ disabled, children, title }) => {
  
    if (disabled) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return (
            <Tooltip title={title}>
                {children}
            </Tooltip>
        )
    }
}