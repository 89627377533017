import { NAV_HEADER_SET } from '../ReduxAction'

const initialState = {
    keyword: '',
    searchActive: false,
    height: null,
}

export const navHeaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case NAV_HEADER_SET:
            return action.payload
        default:
            return state
    }
}