import { store } from '../index.js'

export const mergeActivities = incomingData => {

    const currentData = store.getState().userActivity

    const result = []

    const addItemToResult = item => {
        const resultFilter = result.filter(element => element.userId === item.userId)
        if (resultFilter.length) {
            const index = result.indexOf(resultFilter[0]);
            if (index !== -1) {
                result.splice(index, 1);
            }
        }
        result.push(item)
    }

    incomingData.map(item1 => {
        const filter = currentData.filter(item2 => item2.userId === item1.userId)
        if (filter.length) {
            if (item1.accessTime > filter[0].accessTime) {
                addItemToResult(item1)
            } else {
                addItemToResult(filter[0])
            }
        } else {
            addItemToResult(item1)
        }
    })

    currentData.map(item2 => {
        const filter = incomingData.filter(item1 => item1.userId === item2.userId)
        if (filter.length) {
            if (item2.accessTime > filter[0].accessTime) {
                addItemToResult(item2)
            } else {
                addItemToResult(filter[0])
            }
        } else {
            addItemToResult(item2)
        }
    })

    return result
}