import { useMemo } from 'react'
import * as Yup from 'yup'

export const Validation = () => {

    const validationSchema = useMemo(() => {
		return Yup.object().shape({
            paragQueue: Yup.number().min(1, "Required")
        })
    })

    return validationSchema
}
