import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { QUOTE_DELETE } from '../Redux/ReduxSaga/triggers'

const QuoteCard = props => {
    const { quote } = props

    const [imageholder, setImageholder] = useState(quote?.portraitLink)

    const handleImageError = () => {
        setImageholder("https://via.placeholder.com/300x300.png")
    }

    return (
        <div className='position-relative'>
            <Link to={'/quotedetail'} state={{ quote }} >
                <div
                    className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 pe-4 pt-4 pb-4 cursor-pointer"
                    style={{ paddingLeft: "120px" }}
                >
                    <div className="position-absolute p-2 bg-transparent ms-4 left-0 d-flex flex-column justify-content-center text-center top-0 bottom-0">
                        <div className='flex-column'>
                            <img onError={handleImageError} src={imageholder} alt="author-avater" className="bg-transparent w65 pb-1" />
                        </div>
                    </div>
                    <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">
                        {quote.author}
                    </h4>
                    <h5 className="font-xssss mb-2 text-grey-500 fw-600 align-middle">
                        <span className="text-grey-900 font-xssss text-dark align-middle">{quote.quoteText}</span>
                        <i className='feather-date'></i>
                    </h5>
                </div>
            </Link>
            <Link
                className='position-absolute top-0 right-15 p-2'
                to={'/deleteconfirm'}
                state={{
                    action: {
                        type: QUOTE_DELETE,
                        payload: quote
                    },
                    dboName: 'Q',
                    slide: 'quote',
                    title: quote.quoteText
                }}
            >
                <i className="feather-x font-xl text-grey-500"></i>
            </Link>
        </div>
    )
}
export default QuoteCard