import { 
    CALL_ANSWER_PENDING_SET,
    CALL_ANSWER_SET, 
    CALL_ANSWER_UNSET, 
    CALL_OFFER_UNSET,
    NEW_ANSWER_ICE_CANDIDATE_SET,
    NEW_ANSWER_ICE_CANDIDATE_ADDED_SUCCESS,
} from '../ReduxAction'

const initialState = {
    calleeId: null,
    pending: false,
    answer: null,
    iceCandidates: [],
}

export const callAnswerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CALL_ANSWER_PENDING_SET:
            return {
                ...state,
                calleeId:  action.payload.calleeId,
                pending: action.payload.pending,
            }
        case CALL_OFFER_UNSET:
            return {
                ...state,
                calleeId: null,
                pending: false,
            }
        case CALL_ANSWER_SET:
            return {
                ...state,
                calleeId: action.payload.userId,
                answer: action.payload,
            }
        case CALL_ANSWER_UNSET:
            if (action.payload.userId === state.calleeId) {
                return initialState
            } else return state
        case NEW_ANSWER_ICE_CANDIDATE_SET:
            return {
                ...state,
                iceCandidates: [
                    ...state.iceCandidates,
                    {
                        ...action.payload,
                        state: 'incoming',
                        stateCode: 1,
                    }
                ]
            }
        case NEW_ANSWER_ICE_CANDIDATE_ADDED_SUCCESS:
            const candidatesFilter = state.iceCandidates.filter(item => JSON.stringify(item) === JSON.stringify(action.payload))
            if (candidatesFilter.length) {
                candidatesFilter[0].state = 'added'
                candidatesFilter[0].stateCode = 2
                const newCandidates = state.iceCandidates.filter(item => JSON.stringify(item) !== JSON.stringify(action.payload))
                newCandidates.push(candidatesFilter[0])
                return {
                    ...state,
                    iceCandidates: newCandidates
                }
            } else {
                return state
            }
        default:
            return state
    }
}