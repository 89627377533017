import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { store } from '../index'
import { createHubConnection } from '../_services'
import { VISIBILITY_STATE_VISIBLE, VISIBILITY_STATE_HIDDEN, HUB_CONNECTION_AUTOSTART_SET } from '../Redux/ReduxAction'
import { SILENTLY_REFRESH_TOKEN } from '../Redux/ReduxSaga/triggers'

const HubConnectionHolder = props => {
    const [refreshTokenBusy, setRefreshTokenBusy] = useState(null)
    const { dispatch, auth, hubConnection, visibilityState, silentlyRT } = props

    // when "loading" value of silentlyRT become false, we free this refreshTokenBusy
    // it is important to do this with delay 1s to bypass timer interval
    useEffect(() => {
        if (!silentlyRT.loading) {
            setTimeout(() => {
                setRefreshTokenBusy(false)

            }, 1000)
        }
    }, [silentlyRT.loading])

    useEffect(() => {
        if (refreshTokenBusy)
            dispatch({
                type: SILENTLY_REFRESH_TOKEN,
                payload: { token: store.getState().auth.data?.refreshToken }
            })
    }, [refreshTokenBusy])

    const updateVisibility = () => {
        if (document.visibilityState === 'visible') {
            dispatch({ type: VISIBILITY_STATE_VISIBLE })
        } else {
            dispatch({ type: VISIBILITY_STATE_HIDDEN })
        }
    }

    useEffect(() => {
        document.addEventListener("visibilitychange", updateVisibility)

        return () => document.removeEventListener("visibilitychange", updateVisibility)
    }, [])

    useEffect(() => {
        if (silentlyRT.signalState === 1) {
            dispatch({
                type: HUB_CONNECTION_AUTOSTART_SET,
                payload: true,
            })
        }
    }, [silentlyRT.signalState])

    // one-time creation of the hub connection
    useEffect(() => {
        if (!!!hubConnection.origin) createHubConnection()
    }, [hubConnection.origin])

    return (
        <></>
    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    hubConnection: state.hubConnection,
    visibilityState: state.visibilityState,
    silentlyRT: state.silentlyRT,
})
const Connected = connect(mapStateToProps)(HubConnectionHolder)
export default Connected