import React, { useState, useEffect, useRef } from "react"
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CALL_ANSWER_REFUSE } from '../_services'
import { NEW_OFFER_ICE_CANDIDATE_ADDED_SUCCESS, PEER_CONNECTION_UNSET, CALL_OFFER_UNSET } from '../Redux/ReduxAction'
import { createAnswerConnection } from './createAnswerConnection'
import { startMediaStream } from './startMediaStream'
import { createAnswer } from './createAnswer'
import Indicator from './Indicator'

/*
    Processes queue:
    1. Create peerConnection
    2. startMediaStream
    3. offer a call

*/

const VideoAnswer = props => {

    const [tracksAdded, setTracksAdded] = useState(false)
    const [localStream, setLocalStream] = useState(null)
    const { dispatch, auth, hubConnection, appFooter, callOffer, peerConnection, remoteDescription } = props

    const { offer } = callOffer //location.state?.offer

    const navigate = useNavigate()
    const localVideoRef = useRef()
    const remoteVideoRef = useRef()

    const handleHangup = async () => {
        stopLocalStream()

        const activity = {
            type: CALL_ANSWER_REFUSE,
            userId: auth?.data?.id,
            user: auth?.data?.username,
            avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
            message: `${auth?.data?.username} stopped`,
        }
        await hubConnection.origin?.invoke("SendPrivateMessage", activity, offer.user)
        navigate('/')
    }

    const stopLocalStream = () => {
        if (localStream) {
            localStream.getTracks().forEach(function (track) {
                track.stop()
            })
            setLocalStream(null)
            peerConnection.close()
            dispatch({ type: PEER_CONNECTION_UNSET })
            dispatch({ type: CALL_OFFER_UNSET })
        }
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (tracksAdded && hubConnection) {
                createAnswer({ peerConnection, callerName: offer.user })
            }
        }

        return () => mounted = false
    }, [tracksAdded && hubConnection])

    useEffect(() => {
        let mounted = true
        if (mounted) {

            if (offer) {
                createAnswerConnection(offer.user, remoteVideoRef, offer.data)
            } else {
                if (localStream) {
                    stopLocalStream()
                    navigate(-1)
                }
            }
        }

        return () => mounted = false

    }, [offer])

    useEffect(() => {
        if (peerConnection) {
            startMediaStream({ peerConnection, localVideoRef, setLocalStream, setTracksAdded })
        }
    }, [peerConnection])

    useEffect(() => {
        return () => {
            if (localStream) stopLocalStream()
        }
    }, [localStream])

    const onAddIceCandidateSuccess = iceCandidate => {
        dispatch({
            type: NEW_OFFER_ICE_CANDIDATE_ADDED_SUCCESS,
            payload: iceCandidate
        })
    }

    useEffect(() => {
        let mounted = true

        if (mounted) {
            // Listen for remote ICE candidates and add them to the local RTCPeerConnection
            if (callOffer.iceCandidates && remoteDescription) {
                try {
                    callOffer.iceCandidates.filter(el => el.stateCode === 1).map(item => {
                        peerConnection.addIceCandidate(item)
                            .then(() => onAddIceCandidateSuccess(item))
                            .catch(e => console.log(e))
                    })

                } catch (e) {
                    console.error('Error adding received ice candidate from the offer', e)
                }
            }
        }

        return () => mounted = false

    }, [callOffer.iceCandidates, remoteDescription])

    return (

        <div className="video-call">
            <Indicator
                type='callee'
                localStream={localStream}
            />

            <video ref={localVideoRef} muted="muted" className="local-video" autoPlay playsInline></video>
            <video ref={remoteVideoRef} className="remote-video" autoPlay playsInline></video>

            <div className="video-call-button-group"
                style={{
                    bottom: `${appFooter.height + 10}px`
                }}
            >
                <button className="btn btn-danger" onClick={handleHangup} disabled={!!!localStream}>Hang Up</button>
            </div>

        </div>

    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    hubConnection: state.hubConnection,
    chat: state.chat,
    navHeader: state.navHeader,
    appFooter: state.appFooter,
    peerConnection: state.call.connection.peerConnection,
    localDescription: state.call.connection.localDescription,
    remoteDescription: state.call.connection.remoteDescription,
    callOffer: state.call.offer,
})
const ConnectedPage = connect(mapStateToProps)(VideoAnswer)
export default ConnectedPage