import React, { useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'

import { WaitingScreen } from '../_components'
import { LoginViaGoogle } from './LoginViaGoogle'
import { INITIAL_SCREEN_SET } from '../Redux/ReduxAction'
import { LOGIN, GOOGLE_LOGIN, SEND_ACTIVATE_LINK } from '../Redux/ReduxSaga/triggers'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import * as Yup from 'yup'
import Load from '../components/Load'
import { randomImageSrc } from '../_helpers'

const googleClientId = '520974910345-960dha19n9all640nvlektujh0k6bbuq.apps.googleusercontent.com'

const Submit = () => {
    const { submitForm } = useFormikContext()
    return (
        <div
            onClick={() => submitForm()}
            className="form-group mb-1"
            style={{ cursor: 'pointer' }} >
            <div className='form-group mb-1' style={{ cursor: 'pointer' }}>
                <span className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 cursor-pointer">Login</span>
            </div>
        </div>
    )
}

const SendLinkSuggest = props => {
    const { dispatch, auth } = props

    const resendActivateLink = () => {
        dispatch({
            type: SEND_ACTIVATE_LINK,
            payload: {
                username: auth.data?.username,
                email: auth.data?.email,
            }
        })
    }

    return (
        <div>
            {auth.sendActivateLinkStatus === 2 ?
                <h6 className='text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32'>{`Please check your ${auth.data?.email} inbox and click the activate link we've sent you. Happy deading, ${auth.data?.username}!`}</h6>
                :
                <h6 className='text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32'>Your Account Is Not Activated. <a style={{ cursor: 'pointer' }} onClick={resendActivateLink} className='fw-700 ms-1'>Send Activate Link Again?</a></h6>
            }
        </div>
    )
}

const Login = props => {
    const { auth, userConfig, dispatch } = props
    const [remember, setRemember] = useState(userConfig?.remember || JSON.parse(localStorage.getItem('userConfig'))?.remember || false)
    const [wrapperClass, setWrapperClass] = useState('login-wrapper')
    document.title = 'Login'

    const initialValues = {
        email: window.location.hostname === 'localhost' ? 'nvt5472@gmail.com' : '',
        password: window.location.hostname === 'localhost' ? 'a' : '',
    }

    const handleClickRegister = () => {
        dispatch({
            type: INITIAL_SCREEN_SET,
            payload: 'Register'
        })
    }

    const handleClickForgot = () => {
        dispatch({
            type: INITIAL_SCREEN_SET,
            payload: 'Forgot'
        })
    }

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            email: Yup.string().email("Please enter a vaild email").required("Required"),
            password: Yup.string().required("Required"),
        })
    })

    const handleSubmit = values => {
        const correctedValues = {
            ...values,
            email: values.email.toLowerCase(),
            rememberMe: remember
        }
        const body = {
            ...correctedValues
        }
        console.warn("WARNING")
        dispatch({
            type: LOGIN,
            payload: body
        })
    }

    const handleGoogleSuccess = response => {
        /*
        dispatch({
            type: GOOGLE_LOGIN,
            payload: {
                tokenId: response.tokenId,
                profileObj: response.profileObj,
            }
        })
        */
    }

    const handleGoogleFailure = response => {
        console.log(response)
    }

    useEffect(() => {
        if (auth.googleStatus !== 1) {
            setWrapperClass('')
        }
    }, [auth.googleStatus])

    return (
        <>
            <div className={`main-wrap ${wrapperClass}`}>
                <div className='nav-header bg-transparent shadow-none border-0'>
                    <div className='nav-top w-100'>
                        <a href='/'><i className="text-success display1-size me-2 ms-0"><img src="favicon.ico" alt='Histaff icon' width={25} /></i><span className='d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0 miuka-text-logo'>MiukaFoto. </span> </a>
                        <button className='nav-menu me-0 ms-auto'></button>
                        <a className='header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl' disabled>Login</a>
                        <a style={{ cursor: 'pointer' }} onClick={handleClickRegister} className='header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl'>Register</a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat'
                        style={{ backgroundImage: `url(${randomImageSrc(800, 950)})` }}></div>
                    <div className='col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden'>
                        <div className='card shadow-none border-0 ms-auto me-auto login-card pti-10'>
                            <div className='card-body rounded-0 text-left'>
                                <h2 className='fw-700 display1-size display2-md-size mb-3'>Đăng nhập <br />với e-mail của bạn</h2>

                                <Formik
                                    enableReinitialize
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                    validateOnBlur={true}
                                    validateOnChange={true}
                                >

                                    <Form>
                                        <div className='form-group icon-input mb-3'>
                                            <i className='font-sm ti-email text-grey-500 pe-0'></i>
                                            <Field type="text" name="email" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Email" />
                                            <ErrorMessage name="email" className='text-danger' />
                                        </div>
                                        <div className='form-group icon-input mb-1'>
                                            <Field type="password" name="password" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Password" />
                                            <ErrorMessage name="password" className='text-danger' />
                                            <i className='font-sm ti-lock text-grey-500 pe-0'></i>
                                        </div>
                                        <div className='form-check text-left mb-3'>
                                            <input type='checkbox' value={remember} onChange={e => setRemember(e.target.checked)} className='form-check-input mt-2' id='exampleCheck5' />
                                            <label className='form-check-label font-xsss text-grey-500'>Lưu phiên</label>
                                            <a onClick={handleClickForgot} className='fw-600 font-xsss text-grey-700 mt-1 float-right'>Bạn lại quên mật khẩu?</a>
                                        </div>

                                        <div className='col-sm-12 p-0 text-left'>
                                            {auth.error && auth.data?.errorCode !== 3 && <div className='text-danger'>{auth.message}</div>}
                                            {!auth.error && auth.data?.errorCode === 3 && <SendLinkSuggest {...props} email={auth.data?.email.toLowerCase()} />}
                                            <Submit />
                                            <h6 className='text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32'>Chưa có tài khoản? <a style={{ cursor: 'pointer' }} onClick={handleClickRegister} className='fw-700 ms-1'>Đăng ký</a></h6>

                                        </div>

                                    </Form>
                                </Formik>

                                <div className='col-sm-12 p-0 text-center mt-2'>
                                    <h6 className='mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3'>Tiện hơn nếu bạn đăng nhập bằng Google </h6>
                                    <div className='form-group mb-1'>
                                        <LoginViaGoogle
                                            clientId={googleClientId}
                                            remember={remember}
                                            render={props => {
                                                return (
                                                    <button onClick={props.onClick} disabled={props.disabled} className='form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2'><img src='assets/images/icon-1.png' alt='icon' className='ms-2 w40 mb-1 me-5' />Sign in with Google</button>
                                                )
                                            }}
                                            buttonText='Đăng nhập với Google'
                                            onSuccess={handleGoogleSuccess}
                                            onFailure={handleGoogleFailure}
                                            cookiePolicy={'single_host_origin'}
                                            isSignedIn={remember}
                                        />
                                    </div>
                                </div>
                            </div>
                            {auth.loading && <Load />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
const mapStateToProps = state => ({
    initialScreen: state.initialScreen,
    auth: state.auth,
    userConfig: state.userConfig,
})
const ConnectedLogin = connect(mapStateToProps)(Login)
export { ConnectedLogin as Login }