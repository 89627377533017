import { HubConnectionBuilder } from '@microsoft/signalr'
import addNotification from 'react-push-notification'
import { store } from '../index'
import {
    HUB_CONNECTION_SET, HUB_CONNECTION_STATE_SET,
    USER_ACTIVITY_SET,
    CHAT_SET,
    UPDATE_PARTNER_CHAT_RED_MESSAGES,
    CALL_OFFER_SET,
    CALL_OFFER_UNSET,
    CALL_ANSWER_SET,
    CALL_ANSWER_UNSET,
    NEW_OFFER_ICE_CANDIDATE_SET,
    NEW_ANSWER_ICE_CANDIDATE_SET,
    SILENTLY_RT_SIGNAL_STATE_SET,
} from '../Redux/ReduxAction'
import {
    CHAT,
    ONLINE,
    PARTNER_CHAT_RED_MESSAGES,
    LOG_INFO,
    CRUD,
    CALL_OFFER,
    CALL_OFFER_CANCEL,
    CALL_ANSWER,
    CALL_ANSWER_REFUSE,
    NEW_OFFER_ICE_CANDIDATE,
    NEW_ANSWER_ICE_CANDIDATE,
} from './MessageTypes'
import {
    FLOW_CHART_LIST
} from '../Redux/ReduxSaga/triggers'
import { mergeActivities } from './mergeActivities'
import { CURRENT_NETWORK_ENV, NETWORK_ENV_LOCALHOST, NETWORK_ENV_SERVER } from '../Constants/networkEnv'

export const createHubConnection = () => {

    console.log("createHubConnection")

    let server
    switch (CURRENT_NETWORK_ENV) {
        case NETWORK_ENV_LOCALHOST:
            server = ''
            break
        case NETWORK_ENV_SERVER:
            server = 'https://miukafoto.com'
            break
        default:
            server = ''
            break
    }

    /*

    Note:
    The access token function provided is called before every HTTP request made by 
    SignalR. If the token needs to be renewed in order to keep the connection active, do 
    so from within this function and return the updated token. The token may need to 
    be renewed so it doesn't expire during the connection.


    ASP>NET Core 5.0:
    If a token expires during the lifetime of a connection, the connection continues to 
    work. LongPolling and ServerSentEvent connections fail on subsequent requests if 
    they don't send new access tokens.


    ASP>NET Core 6.0:
    If a token expires during the lifetime of a connection, by default the connection 
    continues to work. LongPolling and ServerSentEvent connections fail on subsequent 
    requests if they don't send new access tokens. For connections to close when the 
    authentication token expires, set CloseOnAuthenticationExpiration.

    */
    const connection = new HubConnectionBuilder()
        .withUrl(`${server}/hubs/signal`, {
            headers: {
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": true,
            },
            withCredentials: false,
            accessTokenFactory: () => store.getState().auth.data?.jwtToken,
        })
        .build()

    connection.on("ReceiveSystemMessage", message => {
        console.log("System Message: ", message)
    })

    connection.on("ReceiveMessage", activity => {

        //console.log("activity", activity)
        if (activity.type === CALL_ANSWER_REFUSE) console.log(CALL_ANSWER_REFUSE, activity)

        switch (activity.type) {
            case CHAT:
                store.dispatch({
                    type: CHAT_SET,
                    payload: activity
                })
                break
            case PARTNER_CHAT_RED_MESSAGES:
                console.log("PARTNER_CHAT_RED_MESSAGES activity: ", activity)
                store.dispatch({
                    type: UPDATE_PARTNER_CHAT_RED_MESSAGES,
                    payload: {
                        activity,
                        myId: store.getState().auth.data?.id,
                    }
                })
                break
            case CALL_OFFER:
                store.dispatch({
                    type: CALL_OFFER_SET,
                    payload: activity
                })
                break
            case CALL_OFFER_CANCEL:
                store.dispatch({
                    type: CALL_OFFER_UNSET,
                    payload: activity
                })
                break
            case CALL_ANSWER:
                store.dispatch({
                    type: CALL_ANSWER_SET,
                    payload: activity
                })
                break
            case CALL_ANSWER_REFUSE:
                store.dispatch({
                    type: CALL_ANSWER_UNSET,
                    payload: activity
                })
                break
            case NEW_OFFER_ICE_CANDIDATE:
                store.dispatch({
                    type: NEW_OFFER_ICE_CANDIDATE_SET,
                    payload: activity.data
                })
                break
            case NEW_ANSWER_ICE_CANDIDATE:
                store.dispatch({
                    type: NEW_ANSWER_ICE_CANDIDATE_SET,
                    payload: activity.data
                })
                break
            case ONLINE:
                const { userId, userName, pathname, avatar, hubConnectionId, accessTime, userActivity } = activity.data
                const merged = mergeActivities(userActivity)
                const found = merged.filter(item => item.userId === userId)
                if (found.length) {
                    const newState = { userId, userName, pathname, avatar, hubConnectionId, accessTime }
                    merged.filter(item => item.userId !== userId).push(newState)
                } else {
                    merged.push({ userId, userName, pathname, avatar, hubConnectionId, accessTime })
                }
                store.dispatch({
                    type: USER_ACTIVITY_SET,
                    payload: merged,
                })
                break
            case LOG_INFO:
                addNotification({
                    title: `MiukaFoto`,
                    message: activity.message,
                    theme: 'darkblue',
                    native: true, // when using native, your OS will handle theming.
                    duration: 15000,
                    closeButton: 'OK',
                })
                break
            case CRUD:
                switch (activity.data.dboName) {
                    case 'Aut':
                        break
                    case 'FlowChart':
                        store.dispatch({
                            type: FLOW_CHART_LIST
                        })
                        break
                    default:
                        break
                }
                break
            default:
                break
        }
    })

    async function start() {
        try {
            await connection.start()
            console.log("SignalR Connected: ", connection)

            // store.dispatch({
            //     type: HUB_CONNECTION_AUTOSTART_SET,
            //     payload: true,
            // })

            store.dispatch({
                type: SILENTLY_RT_SIGNAL_STATE_SET,
                payload: 0
            })

            store.dispatch({
                type: HUB_CONNECTION_SET,
                payload: connection,
            })

            store.dispatch({
                type: HUB_CONNECTION_STATE_SET,
                payload: connection._connectionState,
            })

        } catch (err) {
            console.log("connection start error: ", err)

            if (connection.connectionState !== 'Connected') {
                store.dispatch({
                    type: HUB_CONNECTION_STATE_SET,
                    payload: connection._connectionState,
                })

                setTimeout(start, 5000)

            }
        }
    }

    connection.onclose(async () => {
        // the connection is auto closed after an amount of time
        console.log(`SignalR connection has been stopped.`)

        store.dispatch({
            type: HUB_CONNECTION_STATE_SET,
            payload: connection._connectionState,
        })

        console.log("store.getState().hubConnection.autoStart", store.getState().hubConnection.autoStart)

        if (store.getState().hubConnection.autoStart) await start()
    })

    connection.onreconnecting(async () => {
        console.log(`SignalR connection reconnecting`)
    })

    connection.onreconnected(async () => {
        console.log(`SignalR connection reconnected`)
    })

    // Start the connection.
    start()
}