import React, { useMemo } from 'react'
import { connect } from 'react-redux'

const SignalStateIndicator = props => {
    const { state } = props

    const statusClass = useMemo(() => {
        switch (state) {
            case 'Connected':
                return 'bg-success'
            case 'Connecting':
                return 'bg-warning'
            case 'Disconnected':
                return 'bg-danger'
            case 'Reconnecting':
                return 'bg-light'
            default:
                return 'bg-light'
        }
    }, [state])

    return (
        <span className={`${statusClass} ms-auto btn-round-xss`} style={{ marginRight: '0.5em'}}></span>
    )
}

const mapStateToProps = state => ({
    state: state.hubConnection.state
})
const ConntectedComponent = connect(mapStateToProps)(SignalStateIndicator)
export default ConntectedComponent