import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Form, Formik, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PARAG_TEMPLATE_INSERT, PARAG_TEMPLATE_UPDATE } from '../Redux/ReduxSaga/triggers'

const SubmitArea = () => {
    const { submitForm, errors } = useFormikContext()

    const handleClickSubmit = () => {
        if (Object.keys(errors).length > 0) {
            alert(JSON.stringify(errors, null, 2))
        } else {
            submitForm()
        }
        
    }

    return (
        <div className="col-lg-12 submit">
            <a onClick={handleClickSubmit} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
        </div>
    )
}

const TemplateDetail = props => {
    const location = useLocation()
    const navigate = useNavigate()
    const { template } = location.state
    const { dispatch } = props

    const initialValues = {
        paragTemplateId: template?.paragTemplateId || 0,
        templateName: template?.templateName || '',
        paragBody: template?.paragBody || ''
    }

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            templateName: Yup.string().required('Required'),
            paragBody: Yup.string().required('Required'),
        })
    })

    const handleSubmit = values => {
        console.log("values", values)
        if (values.paragTemplateId) {
            dispatch({
                type: PARAG_TEMPLATE_UPDATE,
                payload: values
            })
        } else {
            dispatch({
                type: PARAG_TEMPLATE_INSERT,
                payload: values
            })
        }

        navigate('/parag-template-list')
        
    }

    return (
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/parag-template-list" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Template Details</h4>
                            </div>
                            <div className="card-body p-lg-5 p-4 w-100 border-0 ">

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    <Form>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Template Name</label>
                                                    <Field name="templateName" type="text" className="form-control" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Template Body</label>
                                                    <Field name="paragBody" type="text" as="textarea" className="text form-control textarea-small" />
                                                </div>
                                            </div>

                                            <SubmitArea />

                                        </div>

                                    </Form>
                                </Formik>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    templateList: state.paragTemplate.data?.list_data
})
const ConnectedPage = connect(mapStateToProps)(TemplateDetail)
export default ConnectedPage