import React, { Fragment, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { INITIAL_SCREEN_SET } from '../Redux/ReduxAction'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { TextError } from '../_components'
import Load from '../components/Load'

import { apiObject, axiosConfig } from '../Constants'

const Submit = props => {
    const { submitForm } = useFormikContext()

    return (
        <div
            onClick={() => props.acceptTerm && submitForm()}
            className="form-group mb-1"
            style={{ cursor: 'pointer' }}
            disabled={!props.acceptTerm}>
            <a className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">Register</a>
        </div>
    )
}

const Register = props => {
    document.title = 'Register'
    const [acceptTerm, setAcceptTerm] = useState(false)
    const [responseData, setResponseData] = useState('')
    const [waitting, setWaitting] = useState(false)

    const initialValues = {
        yourName: '',
        yourEmail: '',
        yourPassword: '',
        yourConfirmPassword: '',
    }

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            yourName: Yup.string().required("Required"),
            yourEmail: Yup.string().email("Please enter a vaild email").required("Required"),
            yourPassword: Yup.string().required("Required"),
            yourConfirmPassword: Yup.string().required("Required")
                .oneOf([Yup.ref('yourPassword'), null], 'Passwords must match'),
        })
    })

    const handleSubmit = (values) => {
        const correctedValues = {
            ...values,
            yourEmail: values.yourEmail.toLowerCase()
        }
        setWaitting(true)
        apiObject.post('/users/register', correctedValues, axiosConfig).then(response => {
            if (response.data?.message) setResponseData(response.data)
            if (response.data?.success) {
                setWaitting(true)
                apiObject.post('/users/send-register-link', {
                    Username: correctedValues.yourName,
                    Email: correctedValues.yourEmail
                }, axiosConfig).then(response => {
                    setResponseData(response.data)
                    setWaitting(false)
                })
            } else {
                setWaitting(false)
            }
        })
    }

    const { dispatch } = props

    const handleClickLogin = () => dispatch({
        type: INITIAL_SCREEN_SET,
        payload: 'Login'
    })

    return (
        <Fragment>
            <div className="main-wrap">
                <div className="nav-header bg-transparent shadow-none border-0">
                    <div className="nav-top w-100">
                        <a href="/"><i className="text-success display1-size me-2 ms-0"><img src="favicon.ico" alt='Histaff icon' width={25} /></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0 miuka-text-logo">MiukaFoto. </span> </a>
                        <button className="nav-menu me-0 ms-auto"></button>
                        <a style={{ cursor: 'pointer' }} onClick={handleClickLogin} className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                        <a className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl" disabled>Register</a>
                    </div>
                </div>


                <div className="row">
                    <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{ backgroundImage: `url("https://via.placeholder.com/800x950.png")` }}></div>
                    <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-4">Create <br />your account</h2>
                                <Formik
                                    enableReinitialize
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                    validateOnBlur={true}
                                    validateOnChange={true}
                                >
                                    <Form>

                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <Field type="text" name="yourName" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Name" />
                                            <ErrorMessage name="yourName" component={TextError} />
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                            <Field type="email" name="yourEmail" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Email Address" />
                                            <ErrorMessage name="yourEmail" component={TextError} />
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <Field type="Password" name="yourPassword" className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Password" />
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            <ErrorMessage name="yourPassword" component={TextError} />
                                        </div>
                                        <div className="form-group icon-input mb-1">
                                            <Field type="Password" name="yourConfirmPassword" className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Confirm Password" />
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            <ErrorMessage name="yourConfirmPassword" component={TextError} />
                                        </div>
                                        <div className="form-check text-left mb-3">
                                            <input type="checkbox" value={acceptTerm} onChange={e => setAcceptTerm(e.target.checked)} className="form-check-input mt-2" id="exampleCheck2" />
                                            <label className="form-check-label font-xsss text-grey-500">Accept Term and Conditions</label>
                                        </div>

                                        <div className="col-sm-12 p-0 text-left">
                                            <Submit acceptTerm={acceptTerm} />
                                            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have account <a style={{ cursor: 'pointer' }} onClick={handleClickLogin} className="fw-700 ms-1">Login</a></h6>
                                        </div>
                                    </Form>
                                </Formik>

                                {!!responseData && !!responseData.success &&
                                    <div className='text-success'>{responseData.message}</div>}
                                {!!responseData && !!!responseData.success &&
                                    <div className='text-danger'>{responseData.message}</div>}
                                {waitting && <Load />}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
const mapStateToProps = state => ({ initialScreen: state.initialScreen })
const ConnectedRegister = connect(mapStateToProps)(Register)
export { ConnectedRegister as Register }