/*

=====transferToImageBitmap()=====

Returns a newly created ImageBitmap object with the image in the OffscreenCanvas object.
The image in the OffscreenCanvas object is replaced with a new blank image!!!

Trả về một đối tượng ImageBitmap mới được tạo với hình ảnh trong đối tượng OffscreenCanvas.
Hình ảnh trong đối tượng OffscreenCanvas được thay thế bằng một hình ảnh trống mới!!!

Chính vì vậy, phương pháp offscreen.transferToImageBitmap() không dùng được trong setInterval callback
OffscreenCanvas không lưu được giá trị xuyên suốt của ImageBitmap

*/

const drawingCode = () => {

    self.onmessage = function (e) {
        let
            myDegree = e.data.myDegree,
            examBOH = e.data.examBOH,
            examA = e.data.examA,
            examB = e.data.examB,
            examRatio = e.data.examRatio,
            examX0 = e.data.examX0,
            examY0 = e.data.examY0,
            examRY = e.data.examRY,
            OXValue = e.data.OXValue,
            OYValue = e.data.OYValue,
            bAxisHValue = e.data.bAxisHValue,
            bStepValue = e.data.bStepValue,
            xStepValue = e.data.xStepValue,
            yStepValue = e.data.yStepValue,
            yMValue = e.data.yMValue,
            yRValue = e.data.yRValue,
            yCValue = e.data.yCValue,
            yGValue = e.data.yGValue,
            xVPLValue = e.data.xVPLValue,
            xVPRValue = e.data.xVPRValue,
            yHorizonValue = e.data.yHorizonValue,
            VPLVPR = e.data.VPLVPR,
            VPLVPRO = e.data.VPLVPRO,
            VPLVPRG = e.data.VPLVPRG,
            VPLVPRB = e.data.VPLVPRB,
            VPLVPRC = e.data.VPLVPRC,
            VP_O = e.data.VP_O,
            VP_B = e.data.VP_B,
            OR = e.data.OR,
            BM = e.data.BM,
            yYValue = e.data.yYValue

        switch (e.data.type) {
            case 'drawMunshellSphere':
                drawMunshellSphere(4)
                break
            case 'drawTheCube':
                drawTheCube()
                break
            case 'doTriad':
                doTriad()
                break
            case 'doExam1':
                doExam1()
                break
            case 'doExam2':
                doExam2()
                break
            case 'doExam3':
                doExam3()
                break
            case 'doExam4':
                doExam4()
                break
            case 'doExam5':
                doExam5()
                break
            case 'doExam6':
                doExam6()
                break
            default:
        }

        function drawSphere(offscreen, ctx, w, h, eleft, ang1, ang2, param, startH, abRatio, step, noratio = false) {
            try {
                var d1, d2;
                let a = (w - 2 * eleft) / 2;
                var b = 0;
                if (abRatio == 0) {
                    if (!noratio) {
                        while (b < a / 2 || b > a * 3 / 5)
                            b = Math.random() * a;
                        abRatio = b / a;
                    } else {
                        abRatio = 0;
                        b = 0;
                    }
                } else b = a * abRatio;
                let etop = w / 2 - b;
                let tiltAng = Math.asin(b / a); // the tilt angle
                let sinOfTiltAng = Math.sin(tiltAng);
                let cosOfTiltAng = Math.cos(tiltAng);

                let rY = a * Math.cos(tiltAng); // the projection height of the axis rY (in viewer's eye)
                var higher = 0; // running radius projection
                var eleft1, etop1, a1, b1;
                var t, x1, y1, x2, y2, fi, i;
                var fx = w / 2; // tâm x
                var H, S, L;
                var x_, y_;
                var ang1xMax, ang1yMax, ang2xMax, ang2yMax;
                let hRandom = 0
                if (!myDegree) {
                    hRandom = Math.floor(Math.random() * 360)
                } else {
                    hRandom = myDegree
                    myDegree = hRandom + 1
                }
                if (startH >= 0) hRandom = startH
                if (startH == -1000) {
                    hRandom = Math.floor(Math.random() * 360)
                    examBOH = hRandom
                    examA = a
                    examB = b
                    examRatio = abRatio
                    examRY = rY
                }
                ctx.lineWidth = 2

                switch (param) {
                    case 1:
                        d1 = -rY; d2 = 0;
                        break;
                    case 2:
                        d1 = 0; d2 = rY;
                        break;
                    case 3:
                        d1 = -rY * 2 / 3; d2 = rY * 2 / 3;
                        break;
                    case 4:
                        d1 = -rY; d2 = rY;
                        break;
                    case 5:
                        d1 = 0; d2 = 0;
                        break;
                    default:
                }
                fi = ang1 / 360 * Math.PI * 2;
                ang1xMax = eleft + a + a * Math.cos(fi);
                ang1yMax = etop + b - b * Math.sin(fi);
                fi = ang2 / 360 * Math.PI * 2;
                ang2xMax = eleft + a + a * Math.cos(fi);
                ang2yMax = etop + b - b * Math.sin(fi);

                for (higher = d1; higher <= d2; higher += step) {
                    L = 50 + 50 * higher / rY;
                    a1 = Math.sqrt(a * a - (higher / cosOfTiltAng) * (higher / cosOfTiltAng)); //Pythagorean theorem
                    eleft1 = eleft + (a - a1);
                    b1 = a1 * sinOfTiltAng;
                    etop1 = w / 2 - (higher + b1); // The top and the left of the Sphere are equal
                    S = a1 / a * 100;
                    t = ang1; // góc toạ độ của hình tròn xạ ảnh (ellipse)
                    H = 0;
                    fi = t / 360 * Math.PI * 2; // góc toạ độ của hình tròn gốc
                    x1 = eleft1 + a1 + a1 * Math.cos(fi);
                    y1 = etop1 + b1 - b1 * Math.sin(fi);

                    for (t = ang1; t <= ang2; t++) {
                        H = t - ang1 + hRandom;
                        if (H > 360) H = H - 360;
                        if (H < 0) H = H + 360;
                        fi = t / 360 * Math.PI * 2;
                        x2 = eleft1 + a1 + a1 * Math.cos(fi);
                        y2 = etop1 + b1 - b1 * Math.sin(fi);

                        ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";

                        ctx.beginPath();
                        ctx.moveTo(x1, y1);
                        ctx.lineTo(x2, y2);
                        ctx.stroke();
                        ctx.closePath();
                        x1 = x2;
                        y1 = y2;
                    }
                }

                ctx.lineWidth = 2;

                // The first radius
                x_ = w / 2;
                y_ = etop + b;
                for (i = 0; i <= 140; i++) {
                    x2 = x_ + (ang1xMax - eleft - a) / 100;
                    y2 = y_ + (ang1yMax - etop - b) / 100;
                    ctx.strokeStyle = "HSL(" + hRandom + ", " + i + "%, 50%)";

                    ctx.beginPath();
                    ctx.moveTo(x_, y_);
                    ctx.lineTo(x2, y2);
                    ctx.stroke();
                    ctx.closePath();
                    x_ = x2;
                    y_ = y2;
                }

                // The second radius
                x_ = w / 2;
                y_ = etop + b;
                for (i = 0; i <= 140; i++) {
                    x2 = x_ + (ang2xMax - eleft - a) / 100;
                    y2 = y_ + (ang2yMax - etop - b) / 100;
                    ctx.strokeStyle = "HSL(" + (ang2 - ang1 + hRandom) + ", " + i + "%, 50%)";

                    ctx.beginPath();
                    ctx.moveTo(x_, y_);
                    ctx.lineTo(x2, y2);
                    ctx.stroke();
                    ctx.closePath();
                    x_ = x2;
                    y_ = y2;
                }

                // Trục đứng
                // Create gradient
                var grd = ctx.createLinearGradient(fx - 1, w / 2 - rY - 30, fx + 1, w / 2 + rY + 10);
                grd.addColorStop(0, "white");
                grd.addColorStop(1, "black");

                // Fill with gradient
                ctx.fillStyle = grd;
                ctx.fillRect(fx - 1, w / 2 - rY - 30, 2, w / 2 + rY + 10); // x1, y1, width, height
                ctx.fillStyle = "white";

                self.postMessage({
                    type: 'drawMunshellSphere',
                    result: offscreen.transferToImageBitmap(),
                    myDegree, examBOH, examA, examB, examRatio, examX0, examY0, examRY
                })

            }
            catch (e) {
                console.log("drawSphere error: ", e.message)
            }
        }

        function drawTheCube() {
            try {
                const offscreenCC = new OffscreenCanvas(382, 450)
                const CCW = 382;
                const XCenter = CCW / 2, YCenter = XCenter;
                const FrontSkew = 18;
                const pigScale = 4;
                const pigCount = 256 / pigScale;
                const RealW = 200;
                const SeeW = RealW * Math.cos(FrontSkew / 360 * 2 * Math.PI);
                const RealD = Math.sqrt(RealW * RealW * 2);
                const SeeD = RealD * Math.sin(FrontSkew / 360 * 2 * Math.PI);
                let OX = XCenter;
                let OY = YCenter - SeeD / 2 + SeeW / 2;
                OXValue = OX;
                OYValue = OY;
                const ctx = offscreenCC.getContext("2d");
                var xStep, yStep, bStep;
                const bHeightDownRatio = 0.8;
                const bAxisH = SeeW * bHeightDownRatio;
                bAxisHValue = bAxisH;
                let yB = OY - bAxisH;

                bStep = bAxisH / pigCount;
                let bCorrect = 0.05;
                ctx.lineWidth = 0;
                var xVPL, xVPR;
                var leftEdgeTop, leftEdgeBottom;
                var rightEdgeTop, rightEdgeBottom;
                var rightEdgeLeft, leftEdgeLeft;

                xStep = (RealD / 2) / pigCount;
                yStep = (SeeD / 2) / pigCount;

                bStepValue = bStep;
                xStepValue = xStep;
                yStepValue = yStep;

                let s0 = 1.5; // real size of the futhest point
                let d0 = 120; // distance from viewer's eye to that point
                var cr = 4;
                var r, g, b;
                ctx.strokeStyle = "gray";
                var edgeHigher = 0;

                //RedChannel
                g = 0;
                var i;
                for (b = 0; b <= pigCount; b += pigScale) {
                    i = 0;
                    for (r = 0; r <= pigCount; r += pigScale) {
                        ctx.fillStyle = "RGB(" + r * pigScale + ", 0, " + b * pigScale + ")";
                        if (g > 0)
                            d = d0 - Math.sqrt(r * r + g * g) * Math.cos(Math.PI / 4 - Math.atan(r / g)) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                        else
                            d = d0 - r * Math.cos(Math.PI / 4) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                        cr = projectionSize(s0, d0, d);
                        ctx.beginPath();
                        ctx.arc(OX - r * xStep, OY - b * bStep - i * bCorrect * b + r * yStep, cr, 0, Math.PI * 2);
                        if (i > 0) edgeHigher += bCorrect;
                        if (b == 0 && r == pigCount) {
                            leftEdgeBottom = OY - b * bStep - i * bCorrect * b + r * yStep;
                            leftEdgeLeft = OX - r * xStep;
                        } else if (b == pigCount && r == pigCount)
                            leftEdgeTop = OY - b * bStep - i * bCorrect * b + r * yStep;
                        i++;
                        ctx.fill();
                        ctx.closePath();
                    }
                }
                ctx.font = "20px Tahoma";
                ctx.fillStyle = "Magenta";
                ctx.fillText("M", leftEdgeLeft - 20, leftEdgeTop - 20);

                yMValue = leftEdgeTop;
                yRValue = leftEdgeBottom;

                //GreenChannel
                r = 0;
                for (var b = 0; b <= pigCount; b += pigScale) {
                    i = 0;
                    for (var g = 0; g <= pigCount; g += pigScale) {
                        ctx.fillStyle = "RGB(0, " + g * pigScale + ", " + b * pigScale + ")";
                        if (g > 0)
                            d = d0 - Math.sqrt(r * r + g * g) * Math.cos(Math.PI / 4 - Math.atan(r / g)) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                        else
                            d = d0 - r * Math.cos(Math.PI / 4) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                        cr = projectionSize(s0, d0, d);
                        ctx.beginPath();
                        ctx.arc(OX + g * xStep, OY - b * bStep - i * bCorrect * b + g * yStep, cr, 0, Math.PI * 2);
                        if (b == 0 && g == pigCount) {
                            rightEdgeBottom = OY - b * bStep - i * bCorrect * b + g * yStep;
                            rightEdgeLeft = OX + g * xStep;
                        } else if (b == pigCount && g == pigCount)
                            rightEdgeTop = OY - b * bStep - i * bCorrect * b + g * yStep;
                        i++;
                        ctx.fill();
                        ctx.closePath();
                    }
                }

                yCValue = rightEdgeTop;
                yGValue = rightEdgeBottom;

                ctx.font = "20px Tahoma";
                ctx.fillStyle = "Cyan";
                ctx.fillText("C", rightEdgeLeft + 6, rightEdgeTop - 20);

                let edgeHeigth = rightEdgeBottom - rightEdgeTop;
                let FromBAxisToVPL =
                    bAxisH * (RealD / 2) / (edgeHeigth - bAxisH);

                xVPL = OX - FromBAxisToVPL;
                xVPR = OX + FromBAxisToVPL;
                xVPLValue = xVPL;
                xVPRValue = xVPR;

                let alpha = Math.atan(
                    (rightEdgeTop - (OY - bAxisH)) / (rightEdgeLeft - OX)
                );
                let yHorizon = OY - bAxisH - (OX - xVPL) * Math.tan(alpha);
                yHorizonValue = yHorizon;
                //White point
                let beta = Math.atan((yHorizon - leftEdgeTop) / (leftEdgeLeft - xVPL));
                let yW = yHorizon - FromBAxisToVPL * Math.tan(beta);
                ctx.fillStyle = "white";
                ctx.font = "24px Tahoma";
                ctx.fillText("W", OX - 5, yW - 25);

                ctx.strokeStyle = "darkgray";
                ctx.setLineDash([5, 3]);
                ctx.beginPath();
                ctx.moveTo(xVPL, yHorizon);
                ctx.lineTo(leftEdgeLeft - 2, yHorizon);
                ctx.moveTo(rightEdgeLeft + 2, yHorizon);
                ctx.lineTo(xVPR, yHorizon);
                ctx.moveTo(xVPL, yHorizon);
                ctx.lineTo(OX, yW);
                ctx.moveTo(xVPR, yHorizon);
                ctx.lineTo(OX, yW);
                ctx.stroke();
                ctx.closePath();

                // From VPL to VPR
                let A = xVPR - xVPL;

                VPLVPR = A;

                let R1 = Math.atan((OY - yHorizon) / A * 2);
                let R2 = Math.atan((rightEdgeBottom - yHorizon) / (A / 2 - (rightEdgeLeft - OX)));
                VPLVPRO = R1;
                VPLVPRG = R2;

                let R3 = Math.atan((yHorizon - yB) / A * 2);
                let R4 = Math.atan((yHorizon - yW) / A * 2);
                VPLVPRB = R3;
                VPLVPRC = R4;

                let vp_o = Math.sqrt(Math.pow(A / 2, 2) + Math.pow(OY - yHorizon, 2));
                let vp_b = Math.sqrt(Math.pow(A / 2, 2) + Math.pow(yHorizon - yB, 2));
                VP_O = vp_o;
                VP_B = vp_b;

                let or = Math.sqrt(Math.pow(OX - leftEdgeLeft, 2) + Math.pow(leftEdgeBottom - OY, 2));
                let bm = Math.sqrt(Math.pow(OX - leftEdgeLeft, 2) + Math.pow(yB - leftEdgeTop, 2));
                OR = or;
                BM = bm;

                //Bottom surface 
                var x_ = OX;
                var y_ = OY;
                b = 0;
                var L; // running angle between the horizon and the line connecting to VPL
                var X1, X2;
                var way;
                var wayStep, xStep_, yStep_;
                for (var r = 0; r <= pigCount; r += pigScale) {
                    L = Math.atan((y_ - yHorizon) / (x_ - xVPL));
                    X1 = A * Math.sin(R1) / (
                        Math.cos(L) * Math.sin(R1) + Math.sin(L) * Math.cos(R1)
                    );
                    X2 = A * Math.sin(R2) / (
                        Math.cos(L) * Math.sin(R2) + Math.sin(L) * Math.cos(R2)
                    );
                    way = X2 - X1;
                    wayStep = way / pigCount;
                    xStep_ = wayStep * Math.cos(L);
                    yStep_ = wayStep * Math.sin(L);
                    for (var g = 0; g <= pigCount; g += pigScale) {
                        ctx.fillStyle = "RGB(" + r * pigScale + ", " + g * pigScale + ", 0)";
                        if (g > 0)
                            d = d0 - Math.sqrt(r * r + g * g) * Math.cos(Math.PI / 4 - Math.atan(r / g)) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                        else
                            d = d0 - r * Math.cos(Math.PI / 4) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                        cr = projectionSize(s0, d0, d);
                        ctx.beginPath();
                        ctx.arc(x_ + g * xStep_, y_ + g * yStep_, cr, 0, Math.PI * 2);
                        ctx.fill();
                        ctx.closePath();
                    }
                    x_ -= xStep * pigScale;
                    y_ += yStep * pigScale;
                }
                x_ -= xStep * pigScale;
                y_ += yStep * pigScale;
                let yY = y_ + pigCount * yStep_;
                yYValue = yY;
                ctx.lineWidth = 4;
                ctx.font = "24px Tahoma";
                ctx.fillText("Y", OX - 5, yY + 25);

                ctx.lineWidth = 4;
                ctx.font = "20px Tahoma";
                //Red axis
                ctx.strokeStyle = "RGB(255, 0, 0)";
                ctx.fillStyle = "red";
                ctx.beginPath();
                ctx.moveTo(OX - RealD / 2, OY + SeeD / 2);
                x_ = OX - RealD / 2 - 20 * RealD / Math.sqrt(RealD * RealD + SeeD * SeeD);
                y_ = OY + SeeD / 2 + 20 * SeeD / Math.sqrt(RealD * RealD + SeeD * SeeD);
                ctx.lineTo(x_, y_);
                ctx.stroke();
                ctx.fillText("R", x_, y_ + 30);
                ctx.closePath();
                //Green axis
                ctx.strokeStyle = "RGB(0, 255, 0)";
                ctx.fillStyle = "green";
                ctx.beginPath();
                ctx.moveTo(OX + RealD / 2, OY + SeeD / 2);
                x_ = OX + RealD / 2 + 20 * RealD / Math.sqrt(RealD * RealD + SeeD * SeeD);
                y_ = OY + SeeD / 2 + 20 * SeeD / Math.sqrt(RealD * RealD + SeeD * SeeD); ctx.lineTo(x_, y_);
                ctx.stroke();
                ctx.fillStyle = "RGB(0, 255, 0)";
                ctx.fillText("G", x_ - 15, y_ + 30);
                ctx.closePath();
                //blue axis
                ctx.lineWidth = 1;
                ctx.strokeStyle = "RGB(0, 0, 255)";
                ctx.beginPath();
                ctx.moveTo(OX, OY - SeeW * bHeightDownRatio);
                ctx.lineTo(OX, OY - SeeW * bHeightDownRatio - 20);
                ctx.stroke();
                ctx.font = "16px Tahoma";
                ctx.fillStyle = "blue";
                ctx.fillText("B", OX + 1, OY - SeeW * bHeightDownRatio - 5);
                ctx.closePath();

                // using transferToImageBitmap()
                self.postMessage({
                    type: 'drawTheCube',
                    result: offscreenCC.transferToImageBitmap(),
                })

            }
            catch (e) {
                console.log("drawTheCube error: ", e.message)
            }
        }

        // s0: real size of the futhest point
        // d0: distance to the futhest point
        // d: distance to current point
        function projectionSize(s0, d0, d) {
            try {
                return s0 * d0 / d;
            }
            catch (e) {
                console.log("projectionSize error: ", e.message)
                return -1;
            }
        }

        function hueCorrect(h) {
            while (h < 0) h += 360;
            while (h > 360) h -= 360;
            return h;
        }

        function drawMunshellSphere(param, startH, noratio = false) {
            try {
                const offscreen = new OffscreenCanvas(382, 573)
                const myContext = offscreen.getContext('2d')
                var w = 360 //382
                var h = 573
                if (!noratio) {
                    drawSphere(offscreen, myContext, w, h, 50, -30, 240, param, -1000, 0, 1);
                } else {
                    drawSphere(offscreen, myContext, w, h, 50, -30, 240, param, -1000, 0, 1, true);
                }
            }
            catch (e) {
                console.log("drawMunshellSphere error: ", e.message);
            }
        }

        function doTriad() {
            try {
                drawMunshellSphere(4, -1);

                const offscreenMunshellTop = new OffscreenCanvas(382, 382)
                let ctx = offscreenMunshellTop.getContext('2d')
                var w = 360
                var h = 360
                var H, L;
                var S = 0;
                while (S < 50)
                    S = Math.random() * 100;
                H = Math.random() * 360;
                L = 0;
                var maxLhaft = Math.sqrt(1 - S * S / 100 / 100);
                var minL = 50 - maxLhaft * 50;
                var maxL = 50 + maxLhaft * 50;
                while (L < minL) L = Math.random() * maxL;

                var i;

                var a = examA;
                var startH = examBOH;
                var abRatio = examRatio;
                let tiltAng = Math.asin(abRatio); // the tilt angle
                let rY = a * Math.cos(tiltAng); // the projection height of the axis rY (in viewer's eye)
                var maxA = a / 100 * S;

                // Vẽ mặt cắt ở tầng trên
                drawCycleInside(ctx, w, h, -30, 330, startH, abRatio, a, S, L, maxA, rY);

                // kẻ 3 đường từ tâm (cách nhau 120 độ)
                var x_, y_, x, y;
                var xy;

                ctx.lineWidth = 4;
                var Triad = [H, H - 120, H + 120];

                if (Triad[1] < 0) Triad[1] += 360;
                if (Triad[1] > 360) Triad[1] -= 360;
                if (Triad[2] < 0) Triad[2] += 360;
                if (Triad[2] > 360) Triad[2] -= 360;

                for (var v = 0; v < 3; v++) {
                    xy = getCoordinates(Triad[v], S, L);
                    x = xy.x;
                    y = xy.y;
                    x_ = w / 2;
                    y_ = xy.yCut;
                    for (i = 0; i < S; i++) {
                        ctx.beginPath();
                        ctx.strokeStyle = "HSL(" + Triad[v] + ", " + i + "%, " + L + "%)";
                        ctx.moveTo(x_, y_);
                        x_ += (x - w / 2) / 100;
                        y_ += (y - xy.yCut) / 100;
                        ctx.lineTo(x_, y_);
                        ctx.stroke();
                        ctx.closePath();
                    }
                }

                self.postMessage({
                    type: 'MunshellTop',
                    result: offscreenMunshellTop.transferToImageBitmap()
                })

                const offscreenTheResult = new OffscreenCanvas(382, 191)
                ctxResult = offscreenTheResult.getContext("2d");
                ctxResult.lineWidth = 55;

                y_ = 70;

                var ResW = w - 100;
                var ResStep = Math.floor(ResW / 5);
                ResW = ResStep * 5;
                x_ = Math.floor((w - ResW) / 2);

                var arr = [];
                for (v = 0; v < 3; v++) {
                    rgbColor = hexToRGB(HSLToHex(Triad[v], S, L));
                    arr.push([Math.sqrt(Math.pow(rgbColor.r, 2) + Math.pow(rgbColor.g, 2) + Math.pow(rgbColor.b, 2)), rgbColor.r, rgbColor.g, rgbColor.b]);
                }
                for (v = 0; v < 3; v++) {
                    ctxResult.beginPath();
                    ctxResult.strokeStyle = "HSL(" + Triad[v] + ", " + S + "%, " + L + "%)";
                    ctxResult.moveTo(x_, y_);
                    x_ += ResStep;
                    ctxResult.lineTo(x_, y_);
                    x_ += ResStep;
                    ctxResult.stroke();
                    ctxResult.closePath();
                }

                drawTheCube();
                showRGBArray(arr);

                ctxResult.fillStyle = "gray";
                ctxResult.font = "16px Tahoma";
                var tL = 40;
                ctxResult.fillText("Ostwald triad: ba điểm tạo tam giác đều.", tL, 170);

                self.postMessage({
                    type: 'summary',
                    result: offscreenTheResult.transferToImageBitmap()
                })

            }
            catch (e) {
                console.log("Triad demo error: " + e.message);
            }
        }

        function drawCycleInside(context, w, h, ang1, ang2, startH, abRatio, aOriginal, S, L, maxA, rY) {
            try {
                var H;
                let a = S / 100 * maxA;
                var b = a * abRatio;
                let etop = w / 2 + rY - 2 * rY * L / 100 - b;
                let eleft = w / 2 - a;

                var x_, y_, x, y;
                let hRandom = startH;
                const ctx = context

                ctx.lineWidth = 2;
                fi = ang1 / 360 * Math.PI * 2;
                t = ang1; // góc toạ độ của hình tròn xạ ảnh (ellipse)
                fi = t / 360 * Math.PI * 2; // góc toạ độ của hình tròn gốc
                x_ = eleft + a + a * Math.cos(fi);
                y_ = etop + b - b * Math.sin(fi);
                for (t = ang1; t <= ang2; t += 0.25) {
                    H = t - ang1 + hRandom;
                    if (H > 360) H = H - 360;
                    if (H < 0) H = H + 360;
                    fi = t / 360 * Math.PI * 2;
                    x = eleft + a + a * Math.cos(fi);
                    y = etop + b - b * Math.sin(fi);

                    ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";

                    ctx.beginPath();
                    ctx.moveTo(x_, y_);
                    ctx.lineTo(x, y);
                    ctx.stroke();
                    ctx.closePath();
                    x_ = x;
                    y_ = y;
                }

            }
            catch (e) {
                console.log("drawCycleInside error: ", e.message)
            }
        }

        function profileCutting(ctx, w, h, startH, a, b) {
            try {
                var H, S, L, t;
                let ox = w / 2;
                let oy = ox;
                var x_ = ox + a;
                var y_ = oy;
                for (t = 0; t <= 360; t += 1) {
                    if (t <= 90 || t > 270) {
                        H = startH;
                    } else {
                        H = startH + 180;
                    }
                    fi = t / 360 * Math.PI * 2;
                    x = ox + a * Math.cos(fi);
                    y = oy - b * Math.sin(fi);

                    if (Math.sin(fi) > 0) {
                        ctx.lineWidth = 2;
                    } else {
                        ctx.lineWidth = 0.5;
                    }

                    S = Math.cos(fi) * 100;
                    L = 50 + Math.sin(fi) * 100;
                    ctx.strokeStyle = "HSL(" + H + "," + S + "," + L + ")"
                    if ((Math.sin(fi) < 0 && (t % 2) == 0) || Math.sin(fi) >= 0) {
                        ctx.beginPath();
                        ctx.moveTo(x_, y_);
                        ctx.lineTo(x, y);
                        ctx.stroke();
                        ctx.closePath();
                    }
                    x_ = x;
                    y_ = y;
                }
            }
            catch (e) {
                console.log("profileCutting: " + e.message);
            }
        }

        function drawMaxCycleInside(ctx, w, h, ang1, ang2, startH, abRatio, aOriginal, rY, LDelta) {
            try {
                ctx.fillStyle = "gray";
                ctx.font = "10px Tahoma";
                var H;
                let tiltAng = Math.asin(abRatio);
                let cosOfTiltAng = Math.cos(tiltAng);
                let a = Math.sqrt(Math.pow(aOriginal, 2) - Math.pow(LDelta, 2));
                var b = a * abRatio;
                let etop = w / 2 - LDelta * cosOfTiltAng - b;
                let eleft = w / 2 - a;
                let ox = eleft + a;
                let oy = etop + b;
                var x_, y_, x, y;
                let hRandom = startH;
                ctx.lineWidth = 1;
                fi = ang1 / 360 * Math.PI * 2;
                t = ang1; // góc toạ độ của hình tròn xạ ảnh (ellipse)
                fi = t / 360 * Math.PI * 2; // góc toạ độ của hình tròn gốc
                x_ = ox + a * Math.cos(fi);
                y_ = oy - b * Math.sin(fi);
                for (t = ang1; t <= ang2; t += 1) {
                    H = t - ang1 + hRandom;
                    H = hueCorrect(H);
                    fi = t / 360 * Math.PI * 2;
                    x = ox + a * Math.cos(fi);
                    y = oy - b * Math.sin(fi);

                    if (Math.sin(fi) > 0) {
                        ctx.lineWidth = 0.5;
                    } else {
                        ctx.lineWidth = 2;
                    }

                    ctx.strokeStyle = "gray";
                    if ((Math.sin(fi) > 0 && (t % 2) == 0) || Math.sin(fi) <= 0) {
                        ctx.beginPath();
                        ctx.moveTo(x_, y_);
                        ctx.lineTo(x, y);
                        ctx.stroke();
                        ctx.closePath();
                    }
                    x_ = x;
                    y_ = y;
                }
            }
            catch (e) {
                console.log("drawMaxCycleInside: " + e.message);
            }
        }

        function drawCycleResult(ctx, w, h, ang1, ang2, startH, aOriginal, S, L) {
            try {
                ctx.lineWidth = 2;
                var H = startH;
                var arr = [];
                var toDiv = 36;
                for (var t = 30; t <= 390; t++) {
                    H = hueCorrect(H);
                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";
                    ctx.arc(191, 40, 30, t / 360 * Math.PI * 2, (t + 1) / 360 * Math.PI * 2);
                    ctx.stroke();
                    ctx.closePath();
                    H--;
                }
                var ResW = w - 70;
                var ResStep = Math.floor(ResW / toDiv);
                ResW = ResStep * toDiv;
                var x_ = Math.floor((w - ResW) / 2);
                H = startH;
                ctx.lineWidth = 50;
                var rgbColor;
                for (var t = 1; t <= toDiv; t++) {
                    H = hueCorrect(H);
                    rgbColor = hexToRGB(HSLToHex(H, S, L));
                    arr.push([Math.sqrt(Math.pow(rgbColor.r, 2) + Math.pow(rgbColor.g, 2) + Math.pow(rgbColor.b, 2)), rgbColor.r, rgbColor.g, rgbColor.b]);
                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";
                    ctx.moveTo(x_, 120);
                    x_ += ResStep;
                    ctx.lineTo(x_, 120);
                    ctx.stroke();
                    ctx.closePath();
                    H += (360 / toDiv);
                }
                drawTheCube();
                showRGBArray(arr, true);

                ctx.fillStyle = "gray";
                ctx.font = "16px Tahoma";
                var tL = 5;
                ctx.fillText("Giả định thứ nhất: Chung Lightness và Saturation", tL, 170);
            }
            catch (e) {
                console.log("drawCycleResult error: ", e.message)
            }
        }

        function doExam1() {
            try {
                drawMunshellSphere(4, -1);

                // MunshellTop
                var myCanvas = new OffscreenCanvas(382, 382);
                var myContext = myCanvas.getContext("2d");
                var w = 360;
                var h = 360;

                var a = examA;
                var startH = examBOH;
                var abRatio = examRatio;

                var S, L;
                S = 0;
                L = 0;
                while (L < 10 || L > 90)
                    L = Math.random() * 100;
                let tiltAng = Math.asin(abRatio); // the tilt angle
                let cosOfTiltAng = Math.cos(tiltAng);
                let rY = a * Math.cos(tiltAng); // the projection height of the axis rY (in viewer's eye)
                var higher = Math.abs(rY - 2 * rY * L / 100);
                var maxA = Math.sqrt(a * a - (higher / cosOfTiltAng) * (higher / cosOfTiltAng));
                while (S < 10)
                    S = Math.random() * 100;

                drawCycleInside(myContext, w, h, -30, 330, startH, abRatio, a, S, L, maxA, rY);

                self.postMessage({
                    type: 'MunshellTop',
                    result: myCanvas.transferToImageBitmap()
                })


                // TheResult
                var TheResult = new OffscreenCanvas(382, 191)
                var ctx = TheResult.getContext("2d");
                var w = TheResult.width;
                var h = TheResult.height;
                drawCycleResult(ctx, w, h, -30, 330, startH, a, S, L)

                self.postMessage({
                    type: 'summary',
                    result: TheResult.transferToImageBitmap()
                })

            }
            catch (e) {
                console.log("doExam1 error: ", e.message)
            }
        }

        function doExam2() {
            try {
                drawMunshellSphere(4, -1);
                // MunshellTop
                var myCanvas = new OffscreenCanvas(382, 382);
                var ctx = myCanvas.getContext("2d");
                var w = 360;
                var h = 360;

                var S, H, L;
                S = Math.random() * 100;
                H = Math.random() * 360;
                var maxLhaft = Math.sqrt(1 - S * S / 100 / 100);
                var minL = 50 - maxLhaft * 50;
                var maxL = 50 + maxLhaft * 50;
                var i;

                var a = examA;
                var startH = examBOH;
                var abRatio = examRatio;
                let tiltAng = Math.asin(abRatio); // the tilt angle
                let rY = a * Math.cos(tiltAng); // the projection height of the axis rY (in viewer's eye)
                var maxA = a;

                drawCycleInside(ctx, w, h, -30, 330, startH, abRatio, a, S, 50, maxA, rY)

                var x1, y1, x2, y2, x_, y_;
                var xy1 = getCoordinates(H, S, minL);
                x1 = xy1.x;
                y1 = xy1.y;
                var xy2 = getCoordinates(H, S, maxL);
                x2 = xy2.x;
                y2 = xy2.y;
                ctx.lineWidth = 4;
                x_ = xy1.x;
                y_ = xy1.y;
                for (i = xy1.l; i < xy2.l; i++) {
                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + i + "%)";
                    ctx.moveTo(x_, y_);
                    y_ -= 2 * rY / 100;
                    ctx.lineTo(x_, y_);
                    ctx.stroke();
                    ctx.closePath();
                }

                ctx.lineWidth = 2;
                x_ = w / 2;
                y_ = w / 2;
                for (i = 0; i < 100; i++) {
                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + H + ", " + i + "%, 50%)";
                    ctx.moveTo(x_, y_);
                    x_ += (xy2.x0 - w / 2) / 100;
                    y_ += (xy2.y0 - w / 2) / 100;
                    ctx.lineTo(x_, y_);
                    ctx.stroke();
                    ctx.closePath();
                }

                self.postMessage({
                    type: 'MunshellTop',
                    result: myCanvas.transferToImageBitmap()
                })

                var TheResult = new OffscreenCanvas(382, 191)
                var ctxResult = TheResult.getContext("2d");
                ctxResult.lineWidth = 100;

                y_ = 70;
                var ResW = w - 100;
                var ResStep = Math.floor(ResW / (xy2.l - xy1.l));
                x_ = Math.floor((w - (ResStep * (xy2.l - xy1.l))) / 2);
                var arr = [];
                var toDiv = 24;
                var rgbColor;
                var m = 0;
                let way = (xy2.l - xy1.l) / toDiv;
                for (i = xy1.l; i < xy2.l; i += way) {
                    rgbColor = hexToRGB(HSLToHex(H, S, i));
                    arr.push([Math.sqrt(Math.pow(rgbColor.r, 2) + Math.pow(rgbColor.g, 2) + Math.pow(rgbColor.b, 2)), rgbColor.r, rgbColor.g, rgbColor.b]);
                }
                for (i = xy1.l; i < xy2.l; i += 1) {
                    ctxResult.beginPath();
                    ctxResult.strokeStyle = "HSL(" + H + ", " + S + "%, " + i + "%)";
                    ctxResult.moveTo(x_, y_);
                    x_ += ResStep;
                    ctxResult.lineTo(x_, y_);
                    ctxResult.stroke();
                    ctxResult.closePath();
                }

                drawTheCube();
                showRGBArray(arr);

                ctxResult.fillStyle = "gray";
                ctxResult.font = "16px Tahoma";
                var tL = 50;
                ctxResult.fillText("Giả định thứ hai: Chung Hue và Saturation", tL, 170);

                self.postMessage({
                    type: 'summary',
                    result: TheResult.transferToImageBitmap()
                })

            }
            catch (e) {
                console.log("doExam2 error: " + e.message);
            }
        }

        function doExam3() {
            try {
                drawMunshellSphere(4, -1);

                //MunshellTop
                var myCanvas = new OffscreenCanvas(382, 382);
                var ctx = myCanvas.getContext("2d");
                var w = 360;
                var h = 360;

                var H, L;
                var S = 0;
                while (S < 50)
                    S = Math.random() * 100;
                H = Math.random() * 360;
                L = 0;
                var maxLhaft = Math.sqrt(1 - S * S / 100 / 100);
                var minL = 50 - maxLhaft * 50;
                var maxL = 50 + maxLhaft * 50;
                while (L < minL) L = Math.random() * maxL;

                var i;

                var a = examA;
                var startH = examBOH;
                var abRatio = examRatio;
                var b = a * abRatio;
                var eleft = w / 2 - S / 100 * a;
                let tiltAng = Math.asin(abRatio); // the tilt angle
                let sinOfTiltAng = Math.sin(tiltAng);
                let cosOfTiltAng = Math.cos(tiltAng);
                let rY = a * Math.cos(tiltAng); // the projection height of the axis rY (in viewer's eye)
                var maxA = a / 100 * S;

                drawCycleInside(ctx, w, h, -30, 330, startH, abRatio, a, S, L, maxA, rY);

                var x_, y_, x, y;
                var xy = getCoordinates(H, S, L);
                x = xy.x;
                y = xy.y;

                ctx.lineWidth = 4;
                x_ = w / 2;
                y_ = xy.yCut;
                for (i = 0; i < S; i++) {
                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + H + ", " + i + "%, " + L + "%)";
                    ctx.moveTo(x_, y_);
                    x_ += (x - w / 2) / 100;
                    y_ += (y - xy.yCut) / 100;
                    ctx.lineTo(x_, y_);
                    ctx.stroke();
                    ctx.closePath();
                }

                self.postMessage({
                    type: 'MunshellTop',
                    result: myCanvas.transferToImageBitmap()
                })

                var TheResult = new OffscreenCanvas(382, 191)
                ctx = TheResult.getContext("2d");
                ctx.lineWidth = 100;

                y_ = 70;

                var ResW = w - 100;
                var ResStep = Math.floor(ResW / S);
                x_ = Math.floor((w - ResStep * S) / 2);

                var arr = [];
                var color;
                let way = S / 12;
                for (i = 0; i < S; i += way) {
                    rgbColor = hexToRGB(HSLToHex(H, i, L));
                    arr.push([Math.sqrt(Math.pow(rgbColor.r, 2) + Math.pow(rgbColor.g, 2) + Math.pow(rgbColor.b, 2)), rgbColor.r, rgbColor.g, rgbColor.b]);
                }
                for (i = 0; i < S; i++) {
                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + H + ", " + i + "%, " + L + "%)";
                    ctx.moveTo(x_, y_);
                    x_ += ResStep;
                    ctx.lineTo(x_, y_);
                    ctx.stroke();
                    ctx.closePath();
                }

                drawTheCube();
                showRGBArray(arr);

                ctx.fillStyle = "gray";
                ctx.font = "16px Tahoma";
                var tL = 50;
                ctx.fillText("Giả định thứ ba: Chung Hue và Lightness", tL, 170);

                self.postMessage({
                    type: 'summary',
                    result: TheResult.transferToImageBitmap()
                })

            }
            catch (e) {
                console.log("doExam3 error: " + e.message);
            }
        }

        function doExam4() {
            try {
                drawMunshellSphere(4, -1);

                //MunshellTop
                var myCanvas = new OffscreenCanvas(382, 382);
                var ctx = myCanvas.getContext("2d");
                var w = 360;
                var h = 360;

                // TheResult
                var TheResult = new OffscreenCanvas(382, 191)
                var ctxR = TheResult.getContext("2d");

                ctxR.lineWidth = 100;
                yR_ = 0;
                var ResW = w - 100;

                var S = 0;
                while (S < 20)
                    S = Math.random() * 100;
                var H = Math.random() * 360;
                var L = 0;
                var maxLhaft = Math.sqrt(1 - S * S / 100 / 100);
                var minL = 50 - maxLhaft * 50;
                var maxL = 50 + maxLhaft * 50;
                while (L < minL) L = Math.random() * maxL;

                var i;

                var a = examA;
                var startH = examBOH;
                var abRatio = examRatio;
                var b = a * abRatio;
                var eleft = w / 2 - S / 100 * a;
                let tiltAng = Math.asin(abRatio); // the tilt angle
                let sinOfTiltAng = Math.sin(tiltAng);
                let cosOfTiltAng = Math.cos(tiltAng);
                let rY = a * Math.cos(tiltAng); // the projection height of the axis rY (in viewer's eye)
                var maxA = a / 100 * S;

                var x_, y_;
                var h_, s_, l_;

                var xy1 = getCoordinates(H, S, L);
                var H_ = H + 180; // hue doi dien
                H_ = hueCorrect(H_);
                var xy2 = getCoordinates(H_, S, 100 - L);

                // r: do dai tu tam
                var ResStep = Math.floor(ResW / 2 / xy1.r);
                xR_ = Math.floor((w - (ResStep * 2 * xy1.r)) / 2);
                const sqW = 50;
                const sq1 = w / 2 - sqW / 2 - 2;
                const sq2 = w - sq1 - sqW / 2 + 2;

                ctx.lineWidth = 4;
                ctx.strokeStyle = "HSL(" + xy1.h + ", " + xy1.s + "%, " + xy1.l + "%)";

                //Diem thu nhat
                ctx.beginPath();
                ctx.arc(xy1.x, xy1.yCanvas, 2, 0, 2 * Math.PI)
                ctx.stroke();
                ctx.fillStyle = "HSL(" + xy1.h + ", " + xy1.s + "%, " + xy1.l + "%)";
                ctx.font = "16px Tahoma";
                ctx.fillText("1", xy1.x + 10, xy1.yCanvas);
                ctx.stroke();
                ctx.closePath();

                x_ = xy1.x;
                y_ = xy1.yCanvas;
                var x2direction = 0;
                var y2direction = 0;
                if (xy1.x2 !== 0)
                    var x2direction = xy1.x2 / Math.abs(xy1.x2);
                if (xy1.y2 !== 0)
                    var y2direction = xy1.y2 / Math.abs(xy1.y2);

                for (i = xy1.r; i > 0; i--) {
                    h_ = H - startH;
                    h_ = hueCorrect(h_);

                    s_ = S * i / xy1.r;
                    l_ = 50 + 50 * (xy1.y2 - xy1.y1) / rY * i / xy1.r;

                    ctxR.beginPath();
                    ctxR.strokeStyle = "HSL(" + h_ + ", " + s_ + "%," + l_ + "%)";
                    ctxR.moveTo(xR_, yR_);
                    xR_ += ResStep;
                    ctxR.lineTo(xR_, yR_);
                    ctxR.stroke();
                    ctxR.closePath();

                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + h_ + ", " + s_ + "%, " + l_ + "%)";
                    ctx.moveTo(x_, y_);
                    switch (x2direction) {
                        case 1:
                            x_ -= Math.abs(xy1.x2 / xy1.r);
                            break;
                        case -1:
                            x_ += Math.abs(xy1.x2 / xy1.r);
                            break;
                        default:
                    }
                    switch (y2direction) {
                        case 1:
                            y_ += Math.abs(xy1.y2 / xy1.r);
                            break;
                        case -1:
                            y_ -= Math.abs(xy1.y2 / xy1.r);
                            break;
                        default:
                    }
                    ctx.lineTo(x_, y_);
                    ctx.stroke();
                    ctx.closePath();
                }
                x_ = w / 2;
                y_ = w / 2;
                for (i = 0; i < xy1.r; i++) {
                    h_ = H_ - startH;
                    h_ = hueCorrect(h_);
                    s_ = S * i / xy1.r;
                    l_ = 50 - 50 * (xy1.y2 - xy1.y1) / rY * i / xy1.r;

                    ctxR.beginPath();
                    ctxR.strokeStyle = "HSL(" + h_ + ", " + s_ + "%," + l_ + "%)";
                    ctxR.moveTo(xR_, yR_);
                    xR_ += ResStep;
                    ctxR.lineTo(xR_, yR_);
                    ctxR.stroke();
                    ctxR.closePath();

                    ctx.beginPath();
                    ctx.strokeStyle = "HSL(" + h_ + ", " + s_ + "%, " + l_ + "%)";
                    ctx.moveTo(x_, y_);
                    switch (x2direction) {
                        case 1:
                            x_ -= Math.abs(xy1.x2 / xy1.r);
                            break;
                        case -1:
                            x_ += Math.abs(xy1.x2 / xy1.r);
                            break;
                        default:
                    }
                    switch (y2direction) {
                        case 1:
                            y_ += Math.abs(xy1.y2 / xy1.r);
                            break;
                        case -1:
                            y_ -= Math.abs(xy1.y2 / xy1.r);
                            break;
                        default:
                    }
                    ctx.lineTo(x_, y_);
                    ctx.stroke();
                    ctx.closePath();
                }

                // The second point
                ctx.lineWidth = 4;
                ctx.strokeStyle = "HSL(" + xy2.h + ", " + xy2.s + "%, " + xy2.l + "%)";
                ctx.beginPath();
                ctx.arc(xy2.x, xy2.yCanvas, 2, 0, 2 * Math.PI)
                ctx.stroke();
                ctx.fillStyle = "HSL(" + xy2.h + ", " + xy2.s + "%, " + xy2.l + "%)";
                ctx.font = "16px Tahoma";
                ctx.fillText("2", xy2.x + 10, xy2.yCanvas);
                ctx.stroke();
                ctx.closePath();

                let val1 = hexToRGB(HSLToHex(xy1.h, xy1.s, xy1.l));
                let val2 = hexToRGB(HSLToHex(xy2.h, xy2.s, xy2.l));

                ctxR.fillStyle = "RGB(" + val1.r + ", " + val1.g + ", " + val1.b + ")";
                ctxR.fillRect(sq1, 115 * 0 + 100, sqW / 2, 30);
                ctxR.fillStyle = "RGB(" + val2.r + ", " + val2.g + ", " + val2.b + ")";
                ctxR.fillRect(sq2, 115 * 0 + 100, sqW / 2, 30);

                drawTheCube();
                showRGBArray([[0, val1.r, val1.g, val1.b], [0, val2.r, val2.g, val2.b]]);


                ctxR.font = "16px Tahoma";
                ctxR.fillStyle = "gray";

                ctxR.fillText("RGB(" + val1.r + ", " + val1.g + ", " + val1.b + ")", 20, 110);
                ctxR.fillText("HSL(" + Math.floor(xy1.h) + ", " + Math.floor(xy1.s) + ", " + Math.floor(xy1.l) + ")", 20, 130);
                ctxR.fillText("RGB(" + val2.r + ", " + val2.g + ", " + val2.b + ")", sq2 + 40, 110);
                ctxR.fillText("HSL(" + Math.floor(xy2.h) + ", " + Math.floor(xy2.s) + ", " + Math.floor(xy2.l) + ")", sq2 + 40, 130);

                ctxR.fillStyle = "gray";
                var tL = 50;
                ctxR.fillText("Giả định thứ tư: Hai màu đối diện tâm cầu", tL, 170);

                self.postMessage({
                    type: 'MunshellTop',
                    result: myCanvas.transferToImageBitmap()
                })
                self.postMessage({
                    type: 'summary',
                    result: TheResult.transferToImageBitmap()
                })

            }
            catch (e) {
                console.log("doExam4 error: " + e.message);
            }
        }

        function doExam5() {
            try {
                drawMunshellSphere(4, -1);

                //MunshellTop
                var myCanvas = new OffscreenCanvas(382, 382);
                var ctx = myCanvas.getContext("2d");
                var w = 360;
                var h = 360;

                // TheResult
                var TheResult = new OffscreenCanvas(382, 191)
                var ctxR = TheResult.getContext("2d");

                var arr = [];
                var arr1 = []
                var color, rgbColor;

                var S, H, L;
                var i;

                var a = examA;
                var startH = examBOH;
                var abRatio = examRatio;
                var b = a * abRatio;
                var eleft = w / 2 - a;
                var etop = w / 2 - b;
                let tiltAng = Math.asin(abRatio); // the tilt angle
                let sinOfTiltAng = Math.sin(tiltAng);
                let cosOfTiltAng = Math.cos(tiltAng);
                let rY = a * cosOfTiltAng; // the projection height of the axis rY (in viewer's eye)
                var ox = eleft + a;
                var oy = etop + b;
                //Góc thật, góc chiếu
                var alpha, alphaSeen;

                // Pi >= alpha >= 0
                alpha = Math.PI * Math.random();
                if (alpha == Math.PI) alpha += 0.0001;

                // profileCutting(ctx, w, h, startH, a, rY);
                drawMaxCycleInside(
                    ctx, w, h, -30, 330, startH, abRatio, a, rY, 0);

                //Thấp hơn hoặc cao hơn theo kích thước thật
                var LDelta = a * Math.sin(alpha);
                var LDeltaSeen = LDelta * cosOfTiltAng;
                var fi;

                //Bán kính lớn của tiết diện
                let a1 = Math.sqrt(Math.pow(a, 2) - Math.pow(LDelta, 2));
                var b1 = a1 * abRatio;
                var OF =
                    Math.sqrt(Math.pow(a1, 2) + Math.pow(LDeltaSeen, 2));

                ctx.setLineDash([]);

                var tD = Math.asin(Math.sqrt(1 - Math.pow(
                    b * Math.sin(alpha) / a, 2)));
                var bNew = b * Math.cos(alpha) / Math.sin(tD);
                let aNew = a;

                // The highest point (HP)
                var HP = getHPPoint(alpha, a, rY, aNew, bNew);

                // Angle between HP_0 and axis x
                alphaSeen = Math.atan(HP.y / Math.abs(HP.x));
                if (Math.cos(alpha) < 0) alphaSeen = Math.PI - alphaSeen;

                var yT = a * Math.sin(alpha) -
                    a * Math.cos(alpha) * Math.tan(alphaSeen);

                //Real value of distance from this point to O
                var yTReal = yT / abRatio;

                //Real value of startAng in degree 360:
                let startAng = -Math.asin(yTReal / a) / Math.PI * 180;

                //ELLIPSE NGHIÊNG

                ctx.lineWidth = 4;
                ctx.strokeStyle = "gray";
                ctx.setLineDash([5, 3]);
                ctx.beginPath();
                ctx.moveTo(ox - HP.x, oy + HP.y);
                ctx.lineTo(ox + HP.x, oy - HP.y);
                ctx.stroke();
                ctx.closePath();

                var x_, y_, x, y, s, t, t1, t2;

                var xNew0 = aNew;
                var yNew0 = 0;
                var x0 = xNew0 * Math.cos(alpha);
                var y0 = yNew0 * Math.cos(alpha);
                var xDelta = x0 - yNew0 * Math.sin(alpha);
                var yDelta = y0 + xNew0 * Math.sin(alpha);

                x_ = ox + HP.x;
                y_ = oy - HP.y;
                t = startAng;

                ctxR.fillStyle = "gray";
                ctxR.font = "16px Tahoma";
                var tL = 15;
                ctxR.fillText("Giả định thứ năm: Các vòng tròn lớn cắt tâm cầu", tL, 170);
                var xRes;
                var atb; // aboveTheBase
                ctxR.lineWidth = 30;
                var degreeCount = 0;

                var id = setInterval(runEllipse, 10);

                function runEllipse() {
                    if (t > 360 + startAng) {
                        var ResW = 360;
                        var ResStep = 1;
                        let toDiv = arr.length;
                        xRes = Math.floor((382 - ResW) / 2);

                        for (i = 0; i < toDiv; i++) {
                            ctxR.beginPath();
                            ctxR.strokeStyle = "HSL(" + arr[i][0] + ", " + arr[i][1] + "%, " + arr[i][2] + "%)";
                            ctxR.moveTo(xRes, 120);
                            xRes += ResStep;
                            ctxR.lineTo(xRes, 120);
                            ctxR.stroke();
                            ctxR.closePath();
                        } // End of ribbon

                        drawTheCube();
                        arr1.sort();
                        showRGBArray(arr1);

                        self.postMessage({
                            type: 'MunshellTop',
                            result: myCanvas.transferToImageBitmap()
                        })

                        self.postMessage({
                            type: 'summary',
                            result: TheResult.transferToImageBitmap()
                        })

                        clearInterval(id);
                    } else {
                        fi = t / 360 * Math.PI * 2;
                        xNew0 = aNew * Math.cos(fi);
                        yNew0 = bNew * Math.sin(fi);
                        x0 = xNew0 * Math.cos(alpha);
                        y0 = yNew0 * Math.cos(alpha);
                        xDelta = x0 - yNew0 * Math.sin(alpha);
                        yDelta = y0 + xNew0 * Math.sin(alpha);
                        x = ox + xDelta;
                        y = oy - yDelta;

                        // Distance value seen from O to the parallel point
                        atb = xDelta * Math.tan(alphaSeen);
                        yT = yDelta - atb;
                        yTReal = yT / abRatio;
                        L = 50 + 50 * atb / rY;
                        S = 100 * Math.sqrt(1 - Math.pow(L / 50 - 1, 2));
                        H = Math.abs(Math.asin(yTReal / a) / Math.PI * 180);
                        if ((t - startAng) >= 90 && (t - startAng) < 180) H = 180 - H;
                        else if ((t - startAng) >= 180 && (t - startAng) < 270) H = 180 + H;
                        else if ((t - startAng) >= 270 && (t - startAng) < 360) H = 360 - H;
                        H += startH;
                        H = hueCorrect(H);
                        ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";
                        ctxR.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";
                        arr.push([H, S, L]);
                        if ((Math.floor(t) % 5) == 0) {
                            rgbColor = hexToRGB(HSLToHex(H, S, L));
                            arr1.push([Math.sqrt(Math.pow(rgbColor.r, 2) + Math.pow(rgbColor.g, 2) + Math.pow(rgbColor.b, 2)), rgbColor.r, rgbColor.g, rgbColor.b]);
                        }
                        if ((Math.sin(fi) > 0 && (Math.floor(t) % 2) == 0) || Math.sin(fi) < 0) {
                            ctx.setLineDash([]);
                            ctx.lineWidth = 4;
                            ctx.beginPath();
                            ctx.moveTo(x_, y_);
                            ctx.lineTo(x, y);
                            ctx.stroke();
                            ctx.closePath();
                        }

                        t1 = t - startAng - 90;
                        t2 = t - startAng - 90 + 1;

                        if (degreeCount <= 360) {
                            ctxR.beginPath();
                            ctxR.arc(191, 50, 30, t1 / 360 * Math.PI * 2,
                                t2 / 360 * Math.PI * 2);
                            ctxR.stroke();
                            ctxR.closePath();
                            degreeCount++;
                        }
                        x_ = x;
                        y_ = y;
                        t += 1;
                    } // End of if foreground or background

                    // posting progress back to the main threat
                    self.postMessage({
                        type: 'progress',
                        result: null,
                        current: t,
                        gate: 360 + startAng,
                    })

                } // End of current point
            } // End of try
            catch (e) {
                console.log("doExam5 error: " + e.message);
            }
        } // End of doExam5

        function doExam6() {
            try {
                drawMunshellSphere(4, -1);

                //MunshellTop
                var myCanvas = new OffscreenCanvas(382, 382);
                var ctx = myCanvas.getContext("2d");
                var w = 360;
                var h = 360;

                // TheResult
                var TheResult = new OffscreenCanvas(382, 191)
                var ctxR = TheResult.getContext("2d");

                var arr = [];
                var arr1 = []
                var color, rgbColor;

                var S, H, L;
                var i;

                var a = examA;
                var startH = examBOH;
                var abRatio = examRatio;
                var b = a * abRatio;
                var eleft = w / 2 - a;
                var etop = w / 2 - b;
                let tiltAng = Math.asin(abRatio); // the tilt angle
                let sinOfTiltAng = Math.sin(tiltAng);
                let cosOfTiltAng = Math.cos(tiltAng);
                let rY = a * cosOfTiltAng; // the projection height of the axis rY (in viewer's eye)
                ctx.clearRect(0, 0, w, h);
                var ox = eleft + a;
                var oy = etop + b;
                //Góc thật, góc chiếu
                var alpha, alphaSeen;

                // Pi >= alpha >= 0
                alpha = Math.PI * Math.random();
                if (alpha == Math.PI) alpha += 0.0001;

                //profileCutting(ctx, w, h, startH, a, rY);
                drawMaxCycleInside(
                    ctx, w, h, -30, 330, startH, abRatio, a, rY, 0);

                //Thấp hơn hoặc cao hơn theo kích thước thật
                var LDelta = a * Math.sin(alpha);
                var LDeltaSeen = LDelta * cosOfTiltAng;
                var fi;
                var c = 0;
                while (c < 0.2) c = Math.random(); // hệ số co cầu

                //Bán kính lớn của tiết diện
                let a1 = Math.sqrt(Math.pow(a, 2) - Math.pow(LDelta, 2)) * c;
                var b1 = a1 * abRatio;
                var OF =
                    Math.sqrt(Math.pow(a1, 2) + Math.pow(LDeltaSeen, 2));

                ctx.setLineDash([]);

                var tD = Math.asin(Math.sqrt(1 - Math.pow(
                    b * Math.sin(alpha) / a, 2)));
                var bNew = b * Math.cos(alpha) / Math.sin(tD) * c;
                let aNew = a * c;

                // The highest point (HP)
                var HP = getHPPoint(alpha, a * c, rY * c, aNew, bNew);

                // Angle between HP_0 and axis x
                alphaSeen = Math.atan(HP.y / Math.abs(HP.x));
                if (Math.cos(alpha) < 0) alphaSeen = Math.PI - alphaSeen;

                var yT = a * Math.sin(alpha) -
                    a * Math.cos(alpha) * Math.tan(alphaSeen);
                yT *= c;

                //Real value of distance from this point to O
                var yTReal = yT / abRatio;

                //Real value of startAng in degree 360:
                let startAng = -Math.asin(yTReal / a / c) / Math.PI * 180;

                //ELLIPSE NGHIÊNG

                ctx.lineWidth = 4;
                ctx.strokeStyle = "gray";
                ctx.setLineDash([5, 3]);
                ctx.beginPath();
                ctx.moveTo(ox - HP.x, oy + HP.y);
                ctx.lineTo(ox + HP.x, oy - HP.y);
                ctx.stroke();
                ctx.closePath();

                var x_, y_, x, y, s, t1, t2;

                var xNew0 = aNew;
                var yNew0 = 0;
                var x0 = xNew0 * Math.cos(alpha);
                var y0 = yNew0 * Math.cos(alpha);
                var xDelta = x0 - yNew0 * Math.sin(alpha);
                var yDelta = y0 + xNew0 * Math.sin(alpha);

                x_ = ox + HP.x;
                y_ = oy - HP.y;
                t = startAng;

                ctxR.fillStyle = "gray";
                ctxR.font = "16px Tahoma";
                var tL = 25;
                ctxR.fillText("Giả định thứ sáu: Mọi vòng tròn cắt tâm cầu", tL, 170);
                var xRes;
                var atb; // aboveTheBase
                ctxR.lineWidth = 30;
                var degreeCount = 0;

                var id6 = setInterval(runEllipse, 10);

                function runEllipse() {
                    if (t > 360 + startAng) {
                        var ResW = 360;
                        var ResStep = 1;
                        let toDiv = arr.length;
                        xRes = Math.floor((382 - ResW) / 2);

                        for (i = 0; i < toDiv; i += 1) {
                            ctxR.beginPath();
                            ctxR.strokeStyle = "HSL(" + arr[i][0] + ", " + arr[i][1] + "%, " + arr[i][2] + "%)";
                            ctxR.moveTo(xRes, 120);
                            xRes += ResStep;
                            ctxR.lineTo(xRes, 120);
                            ctxR.stroke();
                            ctxR.closePath();
                        } // End of ribbon

                        drawTheCube();
                        arr1.sort();
                        showRGBArray(arr1);

                        self.postMessage({
                            type: 'MunshellTop',
                            result: myCanvas.transferToImageBitmap()
                        })

                        self.postMessage({
                            type: 'summary',
                            result: TheResult.transferToImageBitmap()
                        })

                        clearInterval(id6);
                    } else {
                        fi = t / 360 * Math.PI * 2;
                        xNew0 = aNew * Math.cos(fi);
                        yNew0 = bNew * Math.sin(fi);
                        x0 = xNew0 * Math.cos(alpha);
                        y0 = yNew0 * Math.cos(alpha);
                        xDelta = x0 - yNew0 * Math.sin(alpha);
                        yDelta = y0 + xNew0 * Math.sin(alpha);
                        x = ox + xDelta;
                        y = oy - yDelta;

                        // Distance value seen from O to the parallel point
                        atb = xDelta * Math.tan(alphaSeen);
                        yT = yDelta - atb;
                        yTReal = yT / abRatio;
                        L = 50 * c + 50 * atb / rY * c;
                        S = 100 * Math.sqrt(1 - Math.pow(L / 50 - 1, 2));
                        H = Math.abs(Math.asin(yTReal / a / c) / Math.PI * 180);
                        if ((t - startAng) >= 90 && (t - startAng) < 180) H = 180 - H;
                        else if ((t - startAng) >= 180 && (t - startAng) < 270) H = 180 + H;
                        else if ((t - startAng) >= 270 && (t - startAng) < 360) H = 360 - H;
                        H += startH;
                        H = hueCorrect(H);
                        ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";
                        ctxR.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";
                        arr.push([H, S, L]);
                        if ((Math.floor(t) % 5) == 0) {
                            rgbColor = hexToRGB(HSLToHex(H, S, L));
                            arr1.push([Math.sqrt(Math.pow(rgbColor.r, 2) + Math.pow(rgbColor.g, 2) + Math.pow(rgbColor.b, 2)), rgbColor.r, rgbColor.g, rgbColor.b]);
                        }
                        if ((Math.sin(fi) > 0 && (Math.floor(t) % 2) == 0) || Math.sin(fi) < 0) {
                            ctx.setLineDash([]);
                            ctx.lineWidth = 4;
                            ctx.beginPath();
                            ctx.moveTo(x_, y_);
                            ctx.lineTo(x, y);
                            ctx.stroke();
                            ctx.closePath();
                        }

                        t1 = t - startAng - 90;
                        t2 = t - startAng - 90 + 1;

                        if (degreeCount <= 360) {
                            ctxR.beginPath();
                            ctxR.arc(191, 50, 30, t1 / 360 * Math.PI * 2,
                                t2 / 360 * Math.PI * 2);
                            ctxR.stroke();
                            ctxR.closePath();
                            degreeCount++;
                        }
                        x_ = x;
                        y_ = y;
                        t += 1;
                    } // End of if foreground or background

                    /*

                    THIS CODE COMMENTED BELLOW DOES NOT WORK
                    SEE COMMENT AT THE BEGIN OF THIS MODULE

                    // self.postMessage({
                    //     type: 'MunshellTop',
                    //     result: myCanvas.transferToImageBitmap()
                    // })

                    // self.postMessage({
                    //     type: 'summary',
                    //     result: TheResult.transferToImageBitmap()
                    // })

                    */

                    // posting progress back to the main threat
                    self.postMessage({
                        type: 'progress',
                        result: null,
                        current: t,
                        gate: 360 + startAng,
                    })

                } // End of current point

            } // End of try
            catch (e) {
                console.log("doExam6 error: " + e.message);
            }
        } // End of doExam6

        function getHPPoint(alpha, a, b, a1, b1) {
            // PI > alpha > 0
            try {
                let x = Math.sqrt(Math.pow(a1, 2) * Math.pow(Math.cos(alpha), 2) + Math.pow(b1, 2) * Math.pow(Math.sin(alpha), 2));
                let y = Math.sqrt(Math.pow(b, 2) * (1 - Math.pow(x, 2) / Math.pow(a, 2)));
                if (Math.cos(alpha) < 0) x = -x;
                return { x: x, y: y };
            }
            catch (e) {
                console.log("getHPPoint error: " + e.message);
                return { x: 0, y: 0 };
            }
        }

        function getCoordinates(H, S, L) {
            try {
                H = hueCorrect(H);
                // MunshellTop
                var w = 360
                var x0, y0, x1, y1, x2, y2, x, y;
                H -= examBOH;
                H = hueCorrect(H);
                var a = examA;
                var b = examB;
                var rY = examRY;
                var t = H / 360 * 2 * Math.PI;
                x0 = a * Math.cos(t); // distance from the center to x of the end  of maximum vector at same hue
                y0 = b * Math.sin(t); // distance from the center to y of the end of maximum vector at same hue
                x1 = x0 * S / 100; // distance from the center to x of the end  of main vector
                y1 = y0 * S / 100; // distance from the center to y of the end  of main vector
                x2 = x1; // just moved vertically
                y2 = (2 * rY * L / 100) - rY; // distance from the sphere center to the circle center
                x = w / 2 + x2; // x in the canvas
                y = w / 2 - y1 - y2; // y in the canvas higher or lower with y1
                var yCut = w / 2 - y2;
                var rCut = Math.sqrt(x1 * x1 + y1 * y1);
                var r = Math.sqrt(x2 * x2 + (y2 + y1) * (y2 + y1));
                return { h: H, s: S, l: L, x: x, y: y, yCanvas: y + y1, x0: w / 2 + x0, y0: w / 2 - y0, x1: x1, y1: y1, x2: x2, y2: y2, r: r, yCut: yCut, rCut: rCut };
            }
            catch (e) { console.log("getCoordinates error: ", e.message) }
        }

        function hexToRGB(color) {
            try {
                /* Check for # infront of the value, if it's there, strip it */
                if (color.substring(0, 1) == '#') {
                    color = color.substring(1);
                }
                var rgbColor = {};
                /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */
                rgbColor.r = parseInt(color.substring(0, 2), 16);
                rgbColor.g = parseInt(color.substring(2, 4), 16);
                rgbColor.b = parseInt(color.substring(4), 16);
                return rgbColor;
            } catch (e) {
                console.log("hexToRGB error: " + e.message); return rgbColor;
            }
        }

        function HSLToHex(h, s, l) {
            try {
                s /= 100;
                l /= 100;
                let
                    c = (1 - Math.abs(2 * l - 1)) * s,
                    x = c * (1 - Math.abs((h / 60) % 2 - 1)),
                    m = l - c / 2, r = 0, g = 0, b = 0;
                if (0 <= h && h < 60) {
                    r = c; g = x; b = 0;
                } else if (60 <= h && h < 120) {
                    r = x; g = c; b = 0;
                } else if (120 <= h && h < 180) {
                    r = 0; g = c; b = x;
                } else if (180 <= h && h < 240) {
                    r = 0; g = x; b = c;
                } else if (240 <= h && h < 300) {
                    r = x; g = 0; b = c;
                } else if (300 <= h && h < 360) {
                    r = c; g = 0; b = x;
                }
                // Having obtained RGB, convert channels to hex 
                r = Math.round((r + m) * 255).toString(16);
                g = Math.round((g + m) * 255).toString(16);
                b = Math.round((b + m) * 255).toString(16);
                // Prepend 0s, if necessary 
                if (r.length == 1) r = "0" + r;
                if (g.length == 1) g = "0" + g;
                if (b.length == 1) b = "0" + b;
                return "#" + r + g + b;
            } catch (e) {
                console.log("HSLToHex: " + e.message); return "#" + r + g + b;
            }
        }

        // Hiển thị các phần tử đã được chọn ở lớp trên của Cube
        function ShowRGBPosition(r, g, b, gray, ctx) {
            try {
                let xVPL = xVPLValue;
                let yHorizon = yHorizonValue;

                let VPRr1 = VP_O + r / 256 * OR;
                let r1ToHor = Math.sin(VPLVPRO) * VPRr1;
                let r1alphaL = Math.atan(r1ToHor / (VPLVPR - Math.cos(VPLVPRO) * VPRr1));

                let VPRg1 = VP_O + g / 256 * OR;
                let g1ToHor = Math.sin(VPLVPRO) * VPRg1;
                let g1alphaR = Math.atan(g1ToHor / (VPLVPR - Math.cos(VPLVPRO) * VPRg1));

                let VPRr2 = VP_B + r / 256 * BM;
                let r2ToHor = Math.sin(VPLVPRB) * VPRr2;
                let r2alphaL = Math.atan(r2ToHor / (VPLVPR - Math.cos(VPLVPRB) * VPRr2));

                let VPRg2 = VP_B + g / 256 * BM;
                let g2ToHor = Math.sin(VPLVPRB) * VPRg2;
                let g2alphaR = Math.atan(g2ToHor / (VPLVPR - Math.cos(VPLVPRB) * VPRg2));

                var fromP1AxistToVPL = VPLVPR * Math.tan(g1alphaR) / (Math.tan(r1alphaL) + Math.tan(g1alphaR));
                let xP1 = fromP1AxistToVPL + xVPL;
                let yP1 = yHorizon + fromP1AxistToVPL * Math.tan(r1alphaL);

                var fromP2AxistToVPL = VPLVPR * Math.tan(g2alphaR) / (Math.tan(r2alphaL) + Math.tan(g2alphaR));
                let yP2 = yHorizon - fromP2AxistToVPL * Math.tan(r2alphaL);

                let xP = xP1;
                let yP = yP1 - (yP1 - yP2) / 256 * b;

                if (!gray) {
                    ctx.fillStyle = "RGB(" + r + "," + g + "," + b + ")";
                    ctx.strokeStyle = "RGB(" + r + "," + g + "," + b + ")";
                } else {
                    ctx.fillStyle = "gray";
                    ctx.strokeStyle = "gray";
                }

                const FrontSkew = 18;
                let s0 = 5; // real size of the futhest point
                let d0 = 600; // distance from viewer's eye to that point
                var cr;
                if (g > 0)
                    d = d0 - Math.sqrt(r * r + g * g) * Math.cos(Math.PI / 4 - Math.atan(r / g)) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                else
                    d = d0 - r * Math.cos(Math.PI / 4) * Math.cos(FrontSkew / 360 * Math.PI * 2);
                cr = projectionSize(s0, d0, d);
                ctx.beginPath();
                if (!gray) {
                    ctx.arc(xP, yP, cr, 0, Math.PI * 2);
                } else {
                    ctx.arc(xP, yP, 2, 0, Math.PI * 2);
                }
                ctx.fill();
                ctx.closePath();

            }
            catch (e) {
                console.log("ShowRGBPosition error: ", e.message);
            }
        }

        function showRGBArray(a, extended = false) {

            // refCCTop
            const refCCTop = new OffscreenCanvas(382, 450);
            const ctx = refCCTop.getContext("2d");

            var n = 0;
            while (n < a.length) {
                if (extended) {
                    ShowRGBPosition(0, a[n][2], a[n][3], true, ctx);
                    ShowRGBPosition(a[n][1], 0, a[n][3], true, ctx);
                    ShowRGBPosition(a[n][1], a[n][2], 0, true, ctx);
                }
                n++;
            }
            n = 0;
            while (n < a.length) {
                ShowRGBPosition(a[n][1], a[n][2], a[n][3], false, ctx);
                n++;
            }

            self.postMessage({
                type: 'showRGBArray',
                result: refCCTop.transferToImageBitmap()
            })
        }


    }
}
let code = drawingCode.toString()
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))
const blob = new Blob([code], { type: 'application/javascript' })
export const drawing_script = URL.createObjectURL(blob)