export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'
export const SEND_ACTIVATE_LINK = 'SEND_ACTIVATE_LINK'
export const REFRESH_TOKEN_EXISTS = 'REFRESH_TOKEN_EXISTS' 
export const REFRESH_TOKEN = 'REFRESH_TOKEN' 
export const SILENTLY_REFRESH_TOKEN = 'SILENTLY_REFRESH_TOKEN' 
export const LOGIN = 'LOGIN' 
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN' 
export const LOGOUT = 'LOGOUT'

export const CAT_LIST = 'CAT_LIST' 
export const CAT_INSERT = 'CAT_INSERT' 
export const CAT_UPDATE = 'CAT_UPDATE' 
export const CAT_DELETE = 'CAT_DELETE'

export const AUTHOR_LIST = 'AUTHOR_LIST' 
export const AUTHOR_INSERT = 'AUTHOR_INSERT' 
export const AUTHOR_UPDATE = 'AUTHOR_UPDATE' 
export const AUTHOR_DELETE = 'AUTHOR_DELETE'

export const ALBUM_LIST = 'ALBUM_LIST' 
export const ALBUM_INSERT = 'ALBUM_INSERT' 
export const ALBUM_UPDATE = 'ALBUM_UPDATE' 
export const ALBUM_DELETE = 'ALBUM_DELETE'

export const PICTURE_LIST = 'PICTURE_LIST' 
export const PICTURE_INSERT = 'PICTURE_INSERT' 
export const PICTURE_UPDATE = 'PICTURE_UPDATE' 
export const PICTURE_DELETE = 'PICTURE_DELETE'

export const QUOTE_LIST = 'QUOTE_LIST' 
export const QUOTE_INSERT = 'QUOTE_INSERT' 
export const QUOTE_UPDATE = 'QUOTE_UPDATE' 
export const QUOTE_DELETE = 'QUOTE_DELETE'

export const AUTHOR_IMAGE_LIST = 'AUTHOR_IMAGE_LIST' 
export const AUTHOR_IMAGE_INSERT = 'AUTHOR_IMAGE_INSERT' 
export const AUTHOR_IMAGE_UPDATE = 'AUTHOR_IMAGE_UPDATE' 
export const AUTHOR_IMAGE_DELETE = 'AUTHOR_IMAGE_DELETE' 

export const ART_LIST = 'ART_LIST'
export const ART_BY_ID = 'ART_BY_ID' 
export const ART_INSERT = 'ART_INSERT' 
export const ART_UPDATE = 'ART_UPDATE' 
export const ART_UPDATE_CREATED_DATE = 'ART_UPDATE_CREATED_DATE' 
export const ART_DELETE = 'ART_DELETE' 

export const PARAG_LIST = 'PARAG_LIST'
export const PARAG_INSERT = 'PARAG_INSERT' 
export const PARAG_UPDATE = 'PARAG_UPDATE' 
export const PARAG_DELETE = 'PARAG_DELETE' 

export const PARAG_TEMPLATE_LIST = 'PARAG_TEMPLATE_LIST'
export const PARAG_TEMPLATE_INSERT = 'PARAG_TEMPLATE_INSERT' 
export const PARAG_TEMPLATE_UPDATE = 'PARAG_TEMPLATE_UPDATE' 
export const PARAG_TEMPLATE_DELETE = 'PARAG_TEMPLATE_DELETE' 

export const FLOW_CHART_LIST = 'FLOW_CHART_LIST'
export const FLOW_CHART_INSERT = 'FLOW_CHART_INSERT' 
export const FLOW_CHART_UPDATE = 'FLOW_CHART_UPDATE' 
export const FLOW_CHART_DELETE = 'FLOW_CHART_DELETE' 

export const IMAGE_LIST = 'IMAGE_LIST' 
export const IMAGE_OFFLINE_LIST = 'IMAGE_OFFLINE_LIST' 
export const AUDIO_LIST = 'AUDIO_LIST'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UHA_SAVE = 'UHA_SAVE'
export const ART_HISTORY = 'ART_HISTORY'
export const ART_TIME = 'ART_TIME'
export const ART_HISTORY_INSERT = 'ART_HISTORY_INSERT'
export const ART_TA_LIST = 'ART_TA_LIST'
export const ART_TA_SAVE = 'ART_TA_SAVE'
export const DEVICE_TOKEN_LIST = 'DEVICE_TOKEN_LIST'
export const FCM_SEND_NOTIFICATION = 'FCM_SEND_NOTIFICATION'