import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ALBUM_DELETE } from '../Redux/ReduxSaga/triggers'
import { randomImageSrc } from '../_helpers'

const AlbumCard = props => {
    const { album } = props

    const [imageholder, setImageholder] = useState(album?.ACoverImageSrc || '')

    const handleImageError = () => {
        setImageholder(randomImageSrc(1200, 800))
    }

    return (
        <div className='position-relative'>
            <Link to={'/albumdetail'} state={{ album }} >
                <div
                    className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 pe-4 pt-4 pb-4 cursor-pointer"
                    style={{ paddingLeft: "120px" }}
                >
                    <div className="position-absolute p-2 bg-transparent ms-4 left-0 d-flex flex-column justify-content-center text-center top-0 bottom-0">
                        <div className='flex-column'>
                            <img onError={handleImageError} src={imageholder} alt="author-avater" className="bg-transparent w65 pb-1" />
                        </div>
                    </div>
                    <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">
                        {album.aCaption}
                    </h4>
                    <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">
                        {album.aDescription}
                    </h4>
                </div>
            </Link>
            <Link
                className='position-absolute top-0 right-15 p-2'
                to={'/deleteconfirm'}
                state={{
                    action: {
                        type: ALBUM_DELETE,
                        payload: album
                    },
                    dboName: 'A',
                    slide: 'album',
                    title: album.aCaption
                }}
            >
                <i className="feather-x font-xl text-grey-500"></i>
            </Link>
        </div>
    )
}
export default AlbumCard