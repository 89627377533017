import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { LoadingScreen } from '../_components'

export const SheetJSApp = () => {
    const [sheets, setSheets] = useState([])
    const [selectedSheet, setSelectedSheet] = useState(0)
    const [data, setData] = useState([]) /* Array of Arrays e.g. [['a','b'],[1,2]] */
    const [cols, setCols] = useState([]) /* Array of column objects e.g. { name: 'C', K: 2 } */
    const [wb, setWb] = useState()
    const [loading, setLoading] = useState(false)

    const handleFile = file => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        const rABS = !!reader.readAsBinaryString

        reader.onload = e => {
            setLoading(true)
            /* Parse data */
            const bstr = e.target.result
            const thisWb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
            const theseSheets = []
            Object.keys(thisWb.Sheets).forEach((key, index) => {
                theseSheets.push({
                    index: index,
                    key: key,
                })
            })
            setWb(thisWb)
            setSheets(theseSheets)
            setSelectedSheet(0)
            setLoading(false)
        }

        if (rABS) reader.readAsBinaryString(file)
        else reader.readAsArrayBuffer(file)
    }

    const exportFile = () => {
        /* convert state to workbook */
        const ws = XLSX.utils.aoa_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, 'sheetjs.xlsx')
    }

    const handleSubmit = () => {
        alert(JSON.stringify(wb, null, 2))
        console.log("wb", JSON.stringify(wb, null, 2))
    }

    useEffect(() => {
        if (!wb) return
        
        // console.log("wb", JSON.stringify(wb, null, 2))
        // console.log("selectedSheet", selectedSheet)
        /* Get first worksheet */
        const wsname = wb.SheetNames[selectedSheet]
        const ws = wb.Sheets[wsname]

        if (ws[`!ref`]) {
            setLoading(true)
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
            /* Update state */
            setData(data)
            setCols(make_cols(ws['!ref']))
            setLoading(false)
        } else {
            setData([])
            setCols([])
        }
        
    }, [wb, selectedSheet])

    return (
        <DragDropFile handleFile={handleFile}>
            <div className='row'>
                <div className='col-xs-12'>
                    <DataInput handleFile={handleFile} />
                </div>
            </div>
            {loading && <LoadingScreen catId={1} />}
            {!loading &&
                <>
                    <div className='row'>
                        <div className='col-xs-12'>
                            {sheets.length > 0 &&
                                <Sheets sheets={sheets} selectedSheet={selectedSheet} setSelectedSheet={setSelectedSheet} />
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-6'>
                            <button
                                disabled={!data.length}
                                className='btn btn-success'
                                onClick={exportFile}
                            >
                                Export
                            </button>
                        </div>
                        <div className='col-xs-6'>
                            <button
                                disabled={!data.length}
                                className='btn btn-success'
                                onClick={handleSubmit}
                            >
                                Insert to db
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <OutTable data={data} cols={cols} />
                        </div>
                    </div>
                </>
            }
        </DragDropFile>
    )
}

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void
*/
const DragDropFile = props => {
    const suppress = evt => {
        evt.stopPropagation()
        evt.preventDefault()
    }

    const onDrop = evt => {
        evt.stopPropagation()
        evt.preventDefault()
        const files = evt.dataTransfer.files
        if (files && files[0]) props.handleFile(files[0])
    }

    return (
        <div
            onDrop={onDrop}
            onDragEnter={suppress}
            onDragOver={suppress}
        >
            {props.children}
        </div>
    )
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void
*/
const DataInput = props => {
    const handleChange = e => {
        const files = e.target.files
        if (files && files[0]) props.handleFile(files[0])
    }

    return (
        <form className='form-inline'>
            <div className='form-group'>
                <label htmlFor='file'>Spreadsheet</label>
                <input
                    type='file'
                    className='form-control'
                    id='file'
                    accept={SheetJSFT}
                    onChange={handleChange}
                />
            </div>
        </form>
    )
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >
    cols:Array<{name:string, key:number|string}>
*/
const OutTable = props => {
    return (
        <div className='table-responsive'>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        {props.cols.map(c => (
                            <th key={c.key}>{c.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((r, i) => (
                        <tr key={i}>
                            {props.cols.map(c => (
                                <td key={c.key}>{r[c.key]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

/* list of supported file types */
const SheetJSFT = [
    'xlsx',
    'xlsb',
    'xlsm',
    'xls',
    'xml',
    'csv',
    'txt',
    'ods',
    'fods',
    'uos',
    'sylk',
    'dif',
    'dbf',
    'prn',
    'qpw',
    '123',
    'wb*',
    'wq*',
    'html',
    'htm'
]
    .map(x => '.' + x).join(',')

/* generate an array of column objects */
const make_cols = refstr => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o
}

const Sheets = props => {

    const handleChange = e => {
        props.setSelectedSheet(e.target.value)
    }

    return (
        <select className='form-control' onChange={handleChange}>
            {props.sheets.map((item, index) => {
                return (
                    <option key={index} value={index}>{item.key}</option>
                )
            })}
        </select>
    )
}