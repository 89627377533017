import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Formik, Form, Field, useFormikContext } from 'formik'
import { Validation } from './Validation'
import { EditModeIndicator, Bugger } from '../_components'
import { ART_INSERT, ART_UPDATE, ART_DELETE } from '../Redux/ReduxSaga/triggers'
import Pagetitle from '../components/Pagetitle'
import ImagePicker from '../_components/ImagePicker'

const SubmitArea = props => {
	const { submitForm, errors, dirty } = useFormikContext()
	const { editMode } = props

	const handleClickSubmit = () => {
		if (Object.keys(errors).length > 0) {
			alert(JSON.stringify(errors, null, 2))
		} else {
			submitForm()
		}

	}

	return (
		<button style={{ margin: '5px' }} onClick={handleClickSubmit} className='btn btn-primary' type='button' hidden={!dirty}>{editMode === 'modify' ? 'Save' : 'Add'}</button>
	)
}

const ArticleDetailPage = props => {

	const location = useLocation()
	const { article, cat } = location.state
	const [editMode, setEditMode] = useState(article?.id ? 'read' : 'add')
	const { dispatch, catSlide } = props

	const initialValues = {
		artId: article.id || 0,
		catId: article.catID || 0,
		artCaption: article.artCaption || '',
		artImgLink: article.artImgLink || '',
		artBody: article.artBody || '',
		adminOnly: article.adminOnly || true,
		loginRequired: article.loginRequired || true,
	}

	const navigate = useNavigate()
	const validationSchema = Validation()

	const handleClickClose = () => {
		navigate('/articles', { state: { cat } })
	}

	const handleClickEdit = () => {
		if (editMode === 'read') {
			if (article.id) {
				setEditMode('modify')
			} else {
				setEditMode('add')
			}
		} else {
			setEditMode('read')
		}
	}

	const handleSubmitForm = values => {
		console.log("handleSubmitForm values", values)
		if (!values.artId) {
			dispatch({
				type: ART_INSERT,
				payload: values
			})
		} else {
			dispatch({
				type: ART_UPDATE,
				payload: values
			})
		}
		navigate('/articles', { state: { cat } })
	}

	const compare = (a, b) => {
		if (a.category < b.category) {
			return -1
		}
		if (a.category > b.category) {
			return 1
		}
		return 0
	}

	const catListCopy = [...catSlide.data?.list_data]?.sort(compare)

	return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="row feed-body">
                        <div className="col-lg-12">

							<Pagetitle title="Article Details" />
							<button style={{ margin: '5px' }} className='btn btn-secondary' type='button' onClick={handleClickEdit}>{editMode === 'read' ? 'Edit' : 'X'}</button>

							<Formik
								enableReinitialize
								initialValues={initialValues}
								onSubmit={handleSubmitForm}
								validationSchema={validationSchema}
								validateOnBlur={true}
								validateOnChange={true}
							>
								<Form>
									<div style={{
										paddingLeft: '5px',
										paddingRight: '5px',
									}}>

										<div style={{ paddingTop: '50px' }}>
											<div className='row'>
												<div className='col-md-12 mb-3'>
													<div className="form-group">
														<label className="mont-font fw-600 font-xsss mb-2 text required">Category</label>
														<Field name="catId" type="number" as="select" className="form-control" disabled={editMode === 'read'}>
															{
																catListCopy?.map((item, key) => {
																	return (
																		<option key={key} value={item.catID}>{item.category}</option>
																	)
																})
															}
														</Field>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-md-12 mb-3'>
													<div className="form-group">
														<label className="mont-font fw-600 font-xsss mb-2 text required">Caption</label>
														<Field name="artCaption" type="text" className="form-control" disabled={editMode === 'read'} />
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-md-12 mb-3'>
													<div className="form-group">
														<label className="mont-font fw-600 font-xsss mb-2 text required">Image</label>
														<Field name="artImgLink" type="text" as="textarea" className="form-control textarea-small" disabled={editMode === 'read'} />
														{editMode !== 'read' && <ImagePicker className="mb3" fieldName="artImgLink" />}
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-md-12 mb-3'>
													<div className="form-group">
														<label className="mont-font fw-600 font-xsss mb-2 text required">Body</label>
														<Field name="artBody" type="text" as="textarea" className="form-control textarea-body" disabled={editMode === 'read'} />
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-6 mb-3">
													<div className="form-group">
														<label className="mont-font fw-600 font-xsss mb-2">
															<Field name="adminOnly" type="checkbox" disabled={editMode === 'read'} />
															{` Admin only`}
														</label>
													</div>
												</div>
												<div className="col-lg-6 mb-3">
													<div className="form-group">
														<label className="mont-font fw-600 font-xsss mb-2">
															<Field name="loginRequired" type="checkbox" disabled={editMode === 'read'} />
															{` Login required`}
														</label>
													</div>
												</div>
											</div>

										</div>

										<div style={{
											display: 'flex',
											alignContent: 'center',
											alignItems: 'center',
											justifyContent: 'space-between',
											justifyItems: 'space-between',
										}}>
											<EditModeIndicator editMode={editMode} />
											<div style={{
												display: 'flex',
												alignContent: 'center',
												alignItems: 'center',
												justifyContent: 'space-between',
												justifyItems: 'space-between',
											}}>
												<SubmitArea editMode={editMode} />
												<div style={{ margin: '5px' }}>
													<Link
														to={'/deleteconfirm'}
														state={{
															action: {
																type: ART_DELETE,
																payload: {
																	...article,
																	artId: article.id
																}
															},
															dboName: 'Art',
															slide: 'art',
															title: article.artCaption,
															goBackPath: '/articles',
															goBackState: {
																state: { cat }
															},
														}}
													>
														<button className='btn btn-danger' type='button' hidden={!article.id} >Delete</button>
													</Link>
												</div>
												<button className='btn btn-secondary' type='button' onClick={handleClickClose}>Close</button>
											</div>
										</div>
									</div>
								</Form>
							</Formik>

							<div className='h100' />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	catSlide: state.cat
})
const ContectedPage = connect(mapStateToProps)(ArticleDetailPage)
export { ContectedPage as ArticleDetailPage }