import React from 'react'
import { Pagination as Paginator } from 'react-bootstrap'

import { connect } from 'react-redux'

const Pagination = props => {

    const {
        auth, currentStoreSlide,
        currentPage, setCurrentPage,
        pageSize, setPageSize,
        pageCount,
        paginationPageSizeInputName,
    } = props

    const pageSizeOptions = [5, 10, 20, 50, 100, 500]

    const goFirst = e => {
        e.stopPropagation()
        setCurrentPage(1)
    }

    const goPrevious = e => {
        e.stopPropagation()
        setCurrentPage(currentPage - 1)
    }

    const goNext = e => {
        e.stopPropagation()
        setCurrentPage(currentPage + 1)
    }

    const goLast = e => {
        e.stopPropagation()
        setCurrentPage(pageCount)
    }

    return (
        <div className='pagination flex-between'>

            <Paginator size='lg'>
                <Paginator.First onClick={goFirst} disabled={currentPage <= 1} />
                <Paginator.Prev onClick={goPrevious} disabled={currentPage <= 1} />
                {/* <Paginator.Item active>{currentPage}</Paginator.Item> */}
                <Paginator.Next onClick={goNext} disabled={currentPage >= pageCount} />
                <Paginator.Last onClick={goLast} disabled={currentPage >= pageCount} />
            </Paginator>
            <Paginator size='lg'>
                <Paginator.Item >
                    <div className='flex-between h30'>
                        {(auth.loading || currentStoreSlide?.loading) ?
                            <img src='simpleload.gif' width={20} height={20} />
                            :
                            !currentStoreSlide?.data? <i className='feather-zap-off' />
                            :<span className='text'>{currentPage}</span>
                        }
                    </div>
                </Paginator.Item>
            </Paginator>

        </div>
    )
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}
const ConnectedPagination = connect(mapStateToProps)(Pagination)
export { ConnectedPagination as Pagination }