import { apiObject, axiosConfig } from '../../Constants'
import { put, call } from 'redux-saga/effects'
import { actionIntercept } from '../../_helpers'
import {
    ART_TA_LIST_REQUEST, ART_TA_LIST_SUCCESS, ART_TA_LIST_FAILURE,
    ART_TA_SAVE_REQUEST, ART_TA_SAVE_SUCCESS, ART_TA_SAVE_FAILURE,
} from '../ReduxAction'

import { store } from '../../index'
import { ART_TA_LIST } from './triggers'

export function* artTaListAsync(action) {
        try {
        action = actionIntercept(action)
        yield put({ type: ART_TA_LIST_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/ArtTa/list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ART_TA_LIST_SUCCESS, payload: response.data.data })
        } else {
            yield put({ type: ART_TA_LIST_FAILURE, payload: response?.data?.message })
        }

    } catch (error) {

        yield put({ type: ART_TA_LIST_FAILURE, payload: error.message })

    }
}

export function* artTaSaveAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: ART_TA_SAVE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/ArtTa/save', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ART_TA_SAVE_SUCCESS, payload: response.data.data })
            store.dispatch({ type: ART_TA_LIST })
        } else {
            yield put({ type: ART_TA_SAVE_FAILURE, payload: response?.data?.message })
        }

    } catch (error) {

        yield put({ type: ART_TA_SAVE_FAILURE, payload: error.message })

    }
}