import React, { useEffect, useRef } from 'react'

export const MiukaSphere = () => {
    const canvasCylindr = useRef()

    useEffect(() => drawMySphere(1), [])

    function drawMySphere(param) {
        clearSphere();
        var myCanvas = canvasCylindr.current;
        var myContext = myCanvas.getContext("2d");
        var w = myCanvas.width;
        var h = myCanvas.height;
        drawSphere(myContext, w, h, 50, 200, -30, 240, param);
        playSound();
    }

    function drawSphere(ctx, w, h, eleft, etop, ang1, ang2, param) {

        try {
            var d1, d2;
            var a = (w - 2 * eleft) / 2;
            var b = (h - 2 * etop) / 2;
            var higher = 0;
            var eleft1, etop1, a1, b1;
            var t, x1, y1, x2, y2, fi, i;
            var x1vt, y1vt, x2vt, y2vt;
            var fx = w / 2; // tâm x
            var fy; // tâm y
            var H, S, L;
            var x_, y_;
            var hRandom = Math.floor(Math.random() * 360);
            ctx.lineWidth = 4;

            switch (param) {
                case 1:
                    d1 = -a; d2 = 0;
                    break;
                case 2:
                    d1 = 0; d2 = a;
                    break;
                case 3:
                    d1 = -a; d2 = a;
                    break;
                default:
            }

            for (higher = d1; higher <= d2; higher++) {
                fy = (etop + b) - higher;
                if (higher == 0) {
                    //l = 55; // xích đạo
                } else {
                    L = 50 + 50 * higher / a;
                }
                a1 = Math.sqrt(a * a - higher * higher);
                eleft1 = eleft + (a - a1);
                b1 = a1 / a * b;
                etop1 = (etop + b) - higher - b1;
                S = a1 / a * 100;

                t = ang1; // góc toạ độ của hình tròn xạ ảnh (ellipse)
                H = 0;
                fi = t / 360 * Math.PI * 2; // góc toạ độ của hình tròn gốc
                x1 = eleft1 + a1 + a1 * Math.cos(fi);
                y1 = etop1 + b1 - b1 * Math.sin(fi);
                x1vt = eleft + a + (a * 1.2) * Math.cos(fi);
                y1vt = etop + b - (b * 1.2) * Math.sin(fi);

                for (t = ang1; t <= ang2; t++) {
                    H = t - ang1 + hRandom;
                    if (H > 360) H = H - 360;
                    if (H < 0) H = H + 360;
                    fi = t / 360 * Math.PI * 2;
                    x2 = eleft1 + a1 + a1 * Math.cos(fi);
                    y2 = etop1 + b1 - b1 * Math.sin(fi);
                    x2vt = eleft + a + (a * 1.2) * Math.cos(fi);
                    y2vt = etop + b - (b * 1.2) * Math.sin(fi);

                    // Lát cắt từ tâm
                    if (t == ang1) {
                        x_ = fx;
                        y_ = fy;
                        for (i = 0; i <= S; i++) {
                            x2 = fx + (i / 100 * (x1 - fx));
                            y2 = fy + (i / 100 * (y1 - fy));
                            ctx.beginPath();
                            if (higher !== 0) {
                                ctx.lineWidth = 4;
                                ctx.strokeStyle = "HSL(" + H + ", " + i + "%, " + L + "%)";
                            } else {
                                ctx.lineWidth = 2;
                                ctx.strokeStyle = "lightgray";
                            }
                            ctx.moveTo(x_, y_);
                            ctx.lineTo(x2, y2);
                            ctx.stroke();
                            ctx.closePath();
                            x_ = x2;
                            y_ = y2;
                        }
                        //Vẽ thêm
                        if (higher == 0) {
                            x2 = fx + (x1 - fx) * 1.4;
                            y2 = fy + (y1 - fy) * 1.4;
                            ctx.lineWidth = 2;
                            ctx.strokeStyle = "lightgray";
                            ctx.beginPath();
                            ctx.moveTo(x_, y_);
                            ctx.lineTo(x2, y2);
                            ctx.stroke();
                            ctx.closePath();
                            ctx.fillStyle = "white";
                            ctx.fillText("Saturation", x2 - 100, y2 + 10);
                            ctx.lineWidth = 4;
                        }
                    }

                    ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";

                    ctx.beginPath();
                    ctx.moveTo(x1, y1);
                    ctx.lineTo(x2, y2);
                    ctx.stroke();
                    ctx.closePath();

                    // Vẽ thêm vệ tinh
                    if (higher == 0) {
                        ctx.beginPath();
                        ctx.moveTo(x1vt, y1vt);
                        ctx.lineTo(x2vt, y2vt);
                        ctx.strokeStyle = "lightgray";
                        ctx.stroke();
                        ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)";
                        ctx.closePath();
                    }

                    // Lát cắt vào tâm
                    if (t < ang2) {
                        x1 = x2;
                        y1 = y2;
                        x1vt = x2vt;
                        y1vt = y2vt;
                    } else {
                        x_ = x2;
                        y_ = y2;
                        for (i = S; i >= 0; i--) {
                            x2 = fx + (i / 100 * (x1 - fx));
                            y2 = fy + (i / 100 * (y1 - fy));
                            ctx.beginPath();
                            ctx.strokeStyle = "HSL(" + H + ", " + i + "%, " + L + "%)";
                            ctx.moveTo(x_, y_);
                            ctx.lineTo(x2, y2);
                            ctx.stroke();
                            ctx.closePath();
                            x_ = x2;
                            y_ = y2;
                        }
                    }
                }
            }

            // Trục đứng
            // Create gradient
            var grd = ctx.createLinearGradient(fx - 1, h / 2 - a - 25, fx + 1, h / 2 + a + 25);
            grd.addColorStop(0, "white");
            grd.addColorStop(1, "black");

            // Fill with gradient
            ctx.fillStyle = grd;
            ctx.fillRect(fx - 1, h / 2 - a - 25, 2, 2 * a + 50); // x1, y1, width, height
            ctx.fillStyle = "white";
            ctx.fillText("Lightness", fx + 10, h / 2 - a - 25);

            ctx.fillText("Hue", 10, h / 2 - 20);
        }
        catch (e) {
            alert(e.message);
        }
    }

    function clearSphere() {
        var myCanvas = canvasCylindr.current;
        var myContext = myCanvas.getContext("2d");
        myContext.clearRect(0, 0, myCanvas.width, myCanvas.height);
    }

    function playSound() {
        try {
            var context = new AudioContext();
            var o = context.createOscillator();
            var g = context.createGain();
            o.connect(g);
            g.connect(context.destination);
            o.start(0);
            g.gain.exponentialRampToValueAtTime(
                0.00001, context.currentTime + 1
            );
        }
        catch (e) {
            alert(e.message);
        }
    }

    return (
        <>
            <div>
                <canvas width={382} height={450} ref={canvasCylindr} style={{ background: 'black' }}>
                </canvas>
            </div>
            <div className='flex-between'>
                <button className='btn btn-primary' onClick={() => drawMySphere(1)}>Nửa dưới</button>
                <button className='btn btn-primary' onClick={() => drawMySphere(2)}>Nửa trên</button>
                <button className='btn btn-primary' onClick={() => drawMySphere(3)}>Toàn bộ</button>
                <button className='btn btn-primary' onClick={() => clearSphere()}>Xoá</button>
            </div>
            <div>Figure h.4: The HSL Color Model scripted by MiukaFoto</div>
        </>
    )

}