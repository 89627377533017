import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_FAILURE } from '../ReduxAction'

export function* googleLoginAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: GOOGLE_LOGIN_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/users/google-login', { 
                tokenId: action.payload.tokenId, 
                rememberMe: action.payload.rememberMe,
            }, axiosConfig)
            return res
        })

        if (response?.status === 200) {
            yield put({ type: GOOGLE_LOGIN_SUCCESS, payload: response.data })
        } else {
            yield put({ type: GOOGLE_LOGIN_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: GOOGLE_LOGIN_FAILURE, payload: error.message })
    }
}