import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Load from '../components/Load'
import Pagetitle from '../components/Pagetitle'
import { IDeviceTokenState } from '../Redux/ReduxReducer/deviceTokenReducer'
import { DEVICE_TOKEN_LIST, FCM_SEND_NOTIFICATION } from '../Redux/ReduxSaga/triggers'
import ItemCard from './ItemCard'


const FirebaseSDK = (props: any) => {
    const [app, setApp] = useState('miukam2023')
    const [title, setTitle] = useState('A FCM Message')
    const [body, setBody] = useState('')
    const [imageUri, setImageUri] = useState('https://miukafoto.com/Content/shared_pictures/20210815061433_Kyiv20Feb04.jpg')
    const [initialRoute, setInitialRoute] = useState('ChatRoom')
    const [initialId, setInitialId] = useState(0)

    const { dispatch, fcm } = props
    const deviceToken: IDeviceTokenState = props.deviceToken

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value)
    }

    const handleBodyChange = (e: any) => {
        setBody(e.target.value)
    }
    const handleImageUriChange = (e: any) => {
        setImageUri(e.target.value)
    }
    const handleInitialRouteChange = (e: any) => {
        setInitialRoute(e.target.value)
    }
    const handleInitialIdChange = (e: any) => {
        setInitialId(e.target.value)
    }

    const handleClickSend = () => {
        const tokens: string[] = []
        deviceToken.data?.list?.map(x => {
            tokens.push(x.token)
        })
        dispatch({
            type: FCM_SEND_NOTIFICATION,
            payload: {
                clientTokens: tokens,
                title,
                body,
                imageUri,
                navigationPath: initialRoute,
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: DEVICE_TOKEN_LIST,
            payload: {
                app: app ? app : null,
                skip: 0,
                take: 1000
            }
        })
    }, [app])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: `100%` }}>
                    <div className="row">
                        <div className="col-lg-12">

                            <Pagetitle title="Firebase SDK" />

                            <select className="form-control" value={app} onChange={(e) => setApp(e.target.value)}>
                                <option value={'miukam2023'} >miukam2023</option>
                                <option value={''} >others</option>
                            </select>

                            <div className='pt-10'>
                                {
                                    deviceToken.loading ? <Load /> :
                                        <>
                                            {
                                                deviceToken.data.list.map((x: any, index: number) => (
                                                    <ItemCard key={index} item={x} />
                                                ))
                                            }


                                            <label className='label text mt-3'>Title</label>
                                            <input className='form-control' value={title} onChange={handleTitleChange} />

                                            <label className='label text mt-3'>Body</label>
                                            <input className='form-control' value={body} onChange={handleBodyChange} />

                                            <label className='label text mt-3'>ImageUri</label>
                                            <textarea className='form-control textarea-small' value={imageUri} onChange={handleImageUriChange} />

                                            <label className='label text mt-3'>Route</label>
                                            <input className='form-control' value={initialRoute} onChange={handleInitialRouteChange} />

                                            <label className='label text mt-3'>Route Id</label>
                                            <input className='form-control' value={initialId} onChange={handleInitialIdChange} />

                                            <>
                                                {fcm.loading ? <Load /> :
                                                    <button className='btn btn-primary mt-3' onClick={handleClickSend}>Send Notification</button>
                                                }
                                            </>
                                        </>
                                }
                            </div>
                            <div className='h100' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    deviceToken: state.deviceToken,
    fcm: state.fcm,
})
const ConnectedPage = connect(mapStateToProps)(FirebaseSDK)
export default ConnectedPage