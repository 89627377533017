import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import {
    ACTIVATE_ACCOUNT_REQUEST,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_ACCOUNT_FAILURE,
} from '../ReduxAction'

export function* activateAccountAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: ACTIVATE_ACCOUNT_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/users/active-account', { EncryptedEmail: action.payload }, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ACTIVATE_ACCOUNT_SUCCESS, payload: response.data })
        } else {
            yield put({ type: ACTIVATE_ACCOUNT_FAILURE, payload: response?.data?.message })
        }
    } catch (error) {
        yield put({ type: ACTIVATE_ACCOUNT_FAILURE, payload: error.message })
    }
}