import React, { useState, useRef, useEffect } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { drawing_script } from './drawingCode'

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const ColorSphere = () => {
  const [myWorker, setMyWorker] = useState()

  const _WIDTH = 320

  //36 frames
  const [frameSources, setFrameSources] = useState([])
  const [canvases, setCanvases] = useState([useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),])
  const [htmlCanvases, setHtmlCanvases] = useState([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null])

  const index = useRef(0)
  const setIndex = state => index.current = state

  const [message, setMessage] = useState('')
  const [frameNumber, setFrameNumber] = useState(0)

  useEffect(() => {
    if (myWorker) myWorker.postMessage(300)
  }, [myWorker])

  useEffect(() => {
    const id = setInterval(() => {
      if (index.current === 35) {
        setIndex(0)
      } else {
        setIndex(index.current + 1)
      }
      for (let i = 0; i < 36; i++) {
        if (i !== index.current) {
          canvases[i].current.style.display = 'none'
        } else {
          canvases[i].current.style.display = 'block'
        }
      }
    }, 100)

    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    const newHtmlCanvases = []
    canvases.map(item => {
      if (item.current) {
        newHtmlCanvases.push(item.current.getContext('bitmaprenderer'))
      }
    })
    setHtmlCanvases(newHtmlCanvases)
  }, [canvases])

  useEffect(() => {
    if (frameSources.length === 36)
      htmlCanvases.map((item, i) => {
        item.transferFromImageBitmap(frameSources[i])
      })
  }, [frameSources])

  useEffect(() => {
    const handleData = e => {
      setFrameNumber(e.data.frame)
      setMessage(`Loading... ${Math.round((e.data.frame)/36*100)}%`)
      if (e.data.result) {
        setFrameSources(e.data.result)
      }
    }

    const handleError = e => {
      console.log("Worker error: ", e)
    }

    const worker = new Worker(drawing_script)

    worker.addEventListener("message", handleData)
    worker.addEventListener("error", handleError)

    setMyWorker(worker)

    return () => {
      console.log("Terminating web worker")
      worker.removeEventListener("message", handleData)
      worker.removeEventListener("error", handleError)
      worker.terminate()
    }
  }, [])

  return (
    <div>
      <div style={{ backgroundColor: 'black', borderRadius: '5px' }}>
        {
          canvases.map((item, key) => {
            return (
              <canvas key={key} ref={item} width={_WIDTH} height={_WIDTH}>
              </canvas>
            )
          })
        }
      </div>
      {frameSources.length === 0 && <p>{message}</p>}
      {frameSources.length === 0 && <ProgressBar now={frameNumber / 36 * 100} />}

    </div>
  )
}


