export const LOG_INFO = 'LOG_INFO'
export const CRUD = 'CRUD'
export const NOTIFICATION = 'NOTIFICATION'
export const ROUTE_VISITED = 'ROUTE_VISITED'
export const ONLINE = 'ONLINE'
export const CHAT = 'CHAT'
export const PARTNER_CHAT_RED_MESSAGES = 'PARTNER_CHAT_RED_MESSAGES'

// WEB RTC
export const CALL_OFFER = 'CALL_OFFER'
export const CALL_OFFER_CANCEL = 'CALL_OFFER_CANCEL'
export const CALL_ANSWER = 'CALL_ANSWER'
export const CALL_ANSWER_REFUSE= 'CALL_ANSWER_REFUSE'
export const NEW_OFFER_ICE_CANDIDATE = 'NEW_OFFER_ICE_CANDIDATE'
export const NEW_ANSWER_ICE_CANDIDATE = 'NEW_ANSWER_ICE_CANDIDATE'