import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { CREATE, UPDATE, DELETE } from '../../_services'
import { put, call } from 'redux-saga/effects'
import {
    FLOW_CHART_LIST_REQUEST,
    FLOW_CHART_LIST_SUCCESS,
    FLOW_CHART_LIST_FAILURE,
    FLOW_CHART_INSERT_REQUEST,
    FLOW_CHART_INSERT_SUCCESS,
    FLOW_CHART_INSERT_FAILURE,
    FLOW_CHART_UPDATE_REQUEST,
    FLOW_CHART_UPDATE_SUCCESS,
    FLOW_CHART_UPDATE_FAILURE,
    FLOW_CHART_DELETE_REQUEST,
    FLOW_CHART_DELETE_SUCCESS,
    FLOW_CHART_DELETE_FAILURE,
} from '../ReduxAction'
import { CRUD } from '../../_services'
import { store } from '../../index'
import { randomAvatarSrc } from '../../_helpers'

export function* getFlowChartListAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: FLOW_CHART_LIST_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/flowchart/flowchart-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: FLOW_CHART_LIST_SUCCESS, payload: response.data })
        } else {
            yield put({ type: FLOW_CHART_LIST_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: FLOW_CHART_LIST_FAILURE, payload: error.message })
    }
}

export function* insertFlowChartAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: FLOW_CHART_INSERT_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/flowchart/flowchart-insert', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: FLOW_CHART_INSERT_SUCCESS, payload: response.data.list_data })
            const activity = {
                type: CRUD,
                user: store.getState().auth.data?.username,
                avatar: store.getState().auth.data?.avatarUrl || store.getState().auth.data?.googleProfile?.Picture || randomAvatarSrc(),
                message: 'A new flowchart added',
                data: {
                    crudType: CREATE,
                    dboName: 'FlowChart'
                }
            }
            const hubConnection = store.getState().hubConnection
            if (hubConnection && hubConnection.state === 'Connected') {
                hubConnection.origin?.invoke("SendMessage", activity)
            }
            
        } else {
            yield put({ type: FLOW_CHART_INSERT_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: FLOW_CHART_INSERT_FAILURE, payload: error.message })
    }
}

export function* updateFlowChartAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: FLOW_CHART_UPDATE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/flowchart/flowchart-update', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: FLOW_CHART_UPDATE_SUCCESS, payload: response.data.list_data })

            const activity = {
                type: CRUD,
                user: store.getState().auth.data?.username,
                avatar: store.getState().auth.data?.avatarUrl || store.getState().auth.data?.googleProfile?.Picture || randomAvatarSrc(),
                message: 'The flowchart updated',
                data: {
                    crudType: UPDATE,
                    dboName: 'FlowChart'
                }
            }
            const hubConnection = store.getState().hubConnection
            if (hubConnection && hubConnection.state === 'Connected') {
                hubConnection.origin?.invoke("SendMessage", activity)
            }

        } else {
            yield put({ type: FLOW_CHART_UPDATE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: FLOW_CHART_UPDATE_FAILURE, payload: error.message })
    }
}

export function* deleteFlowChartAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: FLOW_CHART_DELETE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/flowchart/flowchart-delete', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: FLOW_CHART_DELETE_SUCCESS, payload: response.data.list_data })

            const activity = {
                type: CRUD,
                user: store.getState().auth.data?.username,
                avatar: store.getState().auth.data?.avatarUrl || store.getState().auth.data?.googleProfile?.Picture || randomAvatarSrc(),
                message: 'The flowchart deleted',
                data: {
                    crudType: DELETE,
                    dboName: 'FlowChart'
                }
            }
            const hubConnection = store.getState().hubConnection
            if (hubConnection && hubConnection.state === 'Connected') {
                hubConnection.origin?.invoke("SendMessage", activity)
            }

        } else {
            yield put({ type: FLOW_CHART_DELETE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: FLOW_CHART_DELETE_FAILURE, payload: error.message })
    }
}