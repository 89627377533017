import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Form, Formik, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AUTHOR_INSERT, AUTHOR_UPDATE } from '../Redux/ReduxSaga/triggers'

const SubmitArea = () => {
    const { submitForm, errors } = useFormikContext()

    const handleClickSubmit = () => {
        if (Object.keys(errors).length > 0) {
            alert(JSON.stringify(errors, null, 2))
        } else {
            submitForm()
        }
        
    }

    return (
        <div className="col-lg-12 submit">
            <a onClick={handleClickSubmit} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
        </div>
    )
}

const AuthorDetail = props => {
    const location = useLocation()
    const navigate = useNavigate()
    const { author } = location.state
    const { dispatch } = props
    const [imageholder, setImageholder] = useState(author?.portraitLink || '')

    const initialValues = {
        authorID: author?.authorID || 0,
        author: author?.author || '',
        born: author?.born || '',
        dead: author?.dead || '',
        portraitLink: author?.portraitLink || '',
    }

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            author: Yup.string().required('Required'),
            born: Yup.number().min(1, 'Required'),
            dead: Yup.number().nullable(),
            portraitLink: Yup.string().required('Required'),
        })
    })

    const handleSubmit = values => {

        if (values.authorID) {
            dispatch({
                type: AUTHOR_UPDATE,
                payload: values
            })
        } else {
            dispatch({
                type: AUTHOR_INSERT,
                payload: values
            })
        }

        navigate('/authors')
        
    }

    const handleImageErrror = () => {
        setImageholder("https://via.placeholder.com/300x300.png")
    }

    return (
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/authors" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Author Details</h4>
                            </div>
                            <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 text-center">
                                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img onError={handleImageErrror} src={imageholder} alt="avater" className="shadow-sm rounded-3 w-100" /></figure>
                                        <h2 className="fw-700 font-sm text-grey-900 mt-3">{author?.author}</h2>
                                    </div>
                                </div>

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    <Form>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Author Name</label>
                                                    <Field name="author" type="text" className="form-control" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Born</label>
                                                    <Field name="born" type="number" className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Dead</label>
                                                    <Field name="dead" type="number" className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Portrait Image Source</label>
                                                    <Field name="portraitLink" type="text" as="textarea" className="text form-control textarea-small" />
                                                </div>
                                            </div>

                                            <SubmitArea />

                                        </div>

                                    </Form>
                                </Formik>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    authorList: state.author.data?.list_data
})
const ConnectedPage = connect(mapStateToProps)(AuthorDetail)
export default ConnectedPage