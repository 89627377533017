import React, { useRef, useEffect, useState } from 'react'
import { Observable, fromEvent, from } from 'rxjs'
import { startWith, map, flatMap, combineLatest, merge } from 'rxjs/operators'

export const SuggestedArticles = (props: any) => {

    const [refreshClickStream, setRefreshClickStream] = useState(new Observable())
    const [close1ClickStream, setClose1ClickStream] = useState(new Observable())
    const [close2ClickStream, setClose2ClickStream] = useState(new Observable())
    const [close3ClickStream, setClose3ClickStream] = useState(new Observable())
    const [requestStream, setRequestStream] = useState(new Observable())
    const [responseStream, setResponseStream] = useState(new Observable())

    const [suggestion1Stream, setSuggestion1Stream] = useState(new Observable())
    const [suggestion2Stream, setSuggestion2Stream] = useState(new Observable())
    const [suggestion3Stream, setSuggestion3Stream] = useState(new Observable())

    const [suggestedArticle1, setSuggestedArticle1] = useState()
    const [suggestedArticle2, setSuggestedArticle2] = useState()
    const [suggestedArticle3, setSuggestedArticle3] = useState()


    const refreshButton = useRef()
    const closeButton1 = useRef()
    const closeButton2 = useRef()
    const closeButton3 = useRef()

    function createSuggestionStream(closeClickStream: Observable<any>) {
        return closeClickStream.pipe(
            startWith('startup click'),
            combineLatest(responseStream,
                function (click, listUsers: any) {
                    return listUsers[Math.floor(Math.random() * listUsers.length)];
                }
            ),
            merge(
                refreshClickStream.pipe(
                    map(function () {
                        return null;
                    }))
            ),
            startWith(null)
        )
    }

    useEffect(() => {
        console.log("useEffect1")
        setRefreshClickStream(fromEvent(refreshButton.current, 'click'))
        setClose1ClickStream(fromEvent(closeButton1.current, 'click'))
        setClose2ClickStream(fromEvent(closeButton2.current, 'click'))
        setClose3ClickStream(fromEvent(closeButton3.current, 'click'))
    }, [])

    useEffect(() => {
        console.log("useEffect2")
        setRequestStream(refreshClickStream.pipe(
            startWith('startup click'),
            map(function () {
                const randomOffset = Math.floor(Math.random() * 500)
                return '/api/art/public-list?since=' + randomOffset
            })))
    }, [refreshClickStream])

    useEffect(() => {
        console.log("useEffect3")
        setResponseStream(requestStream.pipe(
            map(function (requestUrl) {
                return from(JSON.stringify(requestUrl))
            })))

        setSuggestion1Stream(createSuggestionStream(close1ClickStream))
        setSuggestion2Stream(createSuggestionStream(close2ClickStream))
        setSuggestion3Stream(createSuggestionStream(close3ClickStream))
    }, [requestStream])

    useEffect(() => {
        console.log("useEffect4")
        suggestion1Stream.subscribe((x: any) => {
            setSuggestedArticle1(x)
        })
    }, [suggestion1Stream])

    useEffect(() => {
        console.log("useEffect5")
        suggestion2Stream.subscribe((x: any) => {
            setSuggestedArticle2(x)
        })
    }, [suggestion2Stream])

    useEffect(() => {
        console.log("useEffect6")
        suggestion3Stream.subscribe((x: any) => {
            setSuggestedArticle3(x)
        })
    }, [suggestion3Stream])

    console.log("suggestion1Stream", suggestion1Stream)
    console.log("suggestedArticle1", suggestedArticle1)
    console.log("suggestedArticle2", suggestedArticle2)
    console.log("suggestedArticle3", suggestedArticle3)

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="row feed-body">
                        <div className="col-lg-12">

                            <div className="container">
                                <div className="header">
                                    <h2>Who to follow</h2><a href="#" className="refresh" ref={refreshButton}>Refresh</a>
                                </div>
                                <ul className="suggestions">
                                    <li className="suggestion1">
                                        <img />
                                        <a href="#" target="_blank" className="username">this will not be displayed</a>
                                        <a href="#" className="close close1" ref={closeButton1}>x</a>
                                    </li>
                                    <li className="suggestion2">
                                        <img />
                                        <a href="#" target="_blank" className="username">neither this</a>
                                        <a href="#" className="close close2" ref={closeButton2}>x</a>
                                    </li>
                                    <li className="suggestion3">
                                        <img />
                                        <a href="#" target="_blank" className="username">nor this</a>
                                        <a href="#" className="close close3" ref={closeButton3}>x</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
