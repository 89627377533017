import { NAVIGATION_SET } from '../ReduxAction'

const initialState = {
    isOpen: false,
}

export const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATION_SET:
            return {
                isOpen: action.payload,
            }
        default:
            return state
    }
}