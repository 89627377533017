import { configuration } from './constants'
import { NEW_OFFER_ICE_CANDIDATE } from '../_services'
import { store } from '../index'
import { randomAvatarSrc } from '../_helpers'
import { PEER_HUB_CONNECTION_SET, CONNECTION_STATE_SET } from '../Redux/ReduxAction'

export const createOfferConnection = (partnerName, remoteVideoRef) => {
    const pc = new RTCPeerConnection(configuration)
    const { auth, hubConnection } = store.getState()

    pc.addEventListener('icecandidate', e => {
        if (e.candidate) {
            const activity = {
                type: NEW_OFFER_ICE_CANDIDATE,
                userId: auth?.data?.id,
                user: auth?.data?.username,
                avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
                message: `${auth?.data?.username} offer's connection has a new candidate`,
                data: e.candidate,
            }
            hubConnection.origin?.invoke("SendPrivateMessage", activity, partnerName)
        }
    })

    /*
    new, connecting, connected, disconnected, failed, or closed
    */
    pc.addEventListener('connectionstatechange', e => {
        //console.log(`Caller's connectionstatechange is ${pc.connectionState}`, e)
        store.dispatch({
            type: CONNECTION_STATE_SET,
            payload: pc.connectionState
        })
    })

    pc.addEventListener('track', async (e) => {
        const [remoteStream] = e.streams
        remoteVideoRef.current.srcObject = remoteStream
    })

    store.dispatch({
        type: PEER_HUB_CONNECTION_SET,
        payload: pc
    })
}