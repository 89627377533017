import { APP_FOOTER_SET } from '../ReduxAction'

const initialState = {
    height: null,
}

export const appFooterReducer = (state = initialState, action) => {
    switch (action.type) {
        case APP_FOOTER_SET:
            return {
                height: action.payload.height,
            }
        default:
            return state
    }
}