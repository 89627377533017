import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ImageList as ImageContainer, ImageListItem } from '@mui/material'
import { IMAGE_OFFLINE_LIST } from '../../Redux/ReduxSaga/triggers'
import Pagetitle from '../../components/Pagetitle'

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image.replaceAll(' ', '%20').replaceAll(',', '%2c')}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image.replaceAll(' ', '%20').replaceAll(',', '%2c')}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const ImageOfflineList = props => {
    const [imageList, setImageList] = useState([])
    const { dispatch, imageOffline, navHeaderHeight, appFooterHeight } = props

    const handleClickReload = () => {
        handleClickCancel()
        dispatch({ type: IMAGE_OFFLINE_LIST })
    }

    const handleClickCancel = () => {
        try {
            imageOffline?.currentXHR?.source?.cancel()
        } catch (e) {
            console.log("handleClickCancel e: ", e)
        }
    }

    useEffect(() => {
        if (!imageOffline.data.length) return
        const newImageList = []
        imageOffline.data.map(item => {
            newImageList.push({
                img: item.imageUrl,
                title: item.name,
                rows: 1, //Math.ceil(Math.random() * 2),
                cols: 1, //Math.ceil(Math.random() * 2),
            })
        })
        setImageList(newImageList)
    }, [imageOffline.data])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">
                            <Pagetitle title="Offline Images" />
                            {imageOffline.loading ?
                                <div>
                                    <div className='flex-between' style={{ paddingTop: '100px', textAlign: 'justify' }}>
                                        Offline image list is being loaded in the background, or has been stopped. Please go back to this page a bit later...
                                    </div>
                                    <div className='flex-between' style={{ paddingTop: '20px' }}>
                                        <button className='btn btn-primary' onClick={handleClickReload}>Reload</button>
                                        <button className='btn btn-danger' onClick={handleClickCancel}>Cancel</button>
                                    </div>
                                </div>
                                :
                                <>
                                    <ImageContainer
                                        sx={{ width: `calc(100vw) - 10px`, height: `calc(100vh - ${navHeaderHeight}px - ${appFooterHeight}px)` }}
                                        variant="quilted"
                                        cols={4}
                                        rowHeight={121}
                                    >
                                        {imageList.map((item, key) => {
                                            return (
                                                <ImageListItem key={key} cols={item.cols} rows={item.rows}>
                                                    <Link to='/image-view' state={{ image: { name: item.title, imageUrl: item.img } }}>
                                                        <img
                                                            {...srcset(item.img, 50, item.rows, item.cols)}
                                                            alt={item.title}
                                                            loading="lazy"
                                                            style={{ maxWidth: '100%' }} // important!!
                                                        />
                                                    </Link>
                                                </ImageListItem>
                                            )
                                        })}
                                    </ImageContainer>
                                    {!imageOffline.currentXHR &&
                                        <div className='flex-between' style={{ paddingTop: '20px' }}>
                                            <button className='btn btn-primary' onClick={() => dispatch({ type: IMAGE_OFFLINE_LIST })}>Reload</button>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        imageOffline: state.imageOffline,
        navHeaderHeight: state.navHeader.height,
        appFooterHeight: state.appFooter.height,
    }
}
const ConnectedImageOfflineList = connect(mapStateToProps)(ImageOfflineList)
export { ConnectedImageOfflineList as ImageOfflineList }