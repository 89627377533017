import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Form, Formik, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CAT_INSERT, CAT_UPDATE } from '../Redux/ReduxSaga/triggers'
import { randomImageSrc } from '../_helpers'

const SubmitArea = () => {
    const { submitForm, errors } = useFormikContext()

    const handleClickSubmit = () => {
        if (Object.keys(errors).length > 0) {
            alert(JSON.stringify(errors, null, 2))
        } else {
            submitForm()
        }

    }

    return (
        <div className="col-lg-12 submit">
            <a onClick={handleClickSubmit} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
        </div>
    )
}

const CatDetail = props => {
    const location = useLocation()
    const navigate = useNavigate()
    const { cat } = location.state
    const { dispatch } = props

    const initialValues = {
        catID: cat?.catID || 0,
        category: cat?.category || '',
        catOrder: cat?.catOrder || 0,
        catDescription: cat?.catDescription || '',
        featherClass: cat?.featherClass || '',
        active: cat?.active || false,
        adminOnly: cat?.adminOnly || false,
    }

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            category: Yup.string().required('Required'),
            featherClass: Yup.string().required('Required'),
        })
    })

    const handleSubmit = values => {

        if (values.catID) {
            dispatch({
                type: CAT_UPDATE,
                payload: values
            })
        } else {
            dispatch({
                type: CAT_INSERT,
                payload: values
            })
        }

        navigate('/categories')

    }

    return (
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/categories" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Category Details</h4>
                            </div>
                            <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 text-center">
                                        <i className={cat?.featherClass}></i>
                                        <h2 className="fw-700 font-sm text-grey-900 mt-3">{cat?.category}</h2>
                                    </div>
                                </div>

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    <Form>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Category</label>
                                                    <Field name="category" type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Order Number</label>
                                                    <Field name="catOrder" type="number" className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Description</label>
                                                    <Field name="catDescription" type="text" as="textarea" className="text form-control textarea-small" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Feather Class</label>
                                                    <Field name="featherClass" type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">
                                                        <Field name="active" type="checkbox" />
                                                        {` Active`}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">
                                                        <Field name="adminOnly" type="checkbox" />
                                                        {` Admin Only`}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>



                                        <SubmitArea />

                                    </Form>
                                </Formik>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    catList: state.cat.data?.list_data
})
const ConnectedPage = connect(mapStateToProps)(CatDetail)
export default ConnectedPage