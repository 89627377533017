const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const normalizeFullname = string => {
    const parts = string.split(' ')
    let result = ''

    parts.forEach(element => {
        result += ` ${capitalizeFirstLetter(element)}`
    })

    return result.slice(1)
}