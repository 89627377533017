import { useMemo } from 'react'
import * as Yup from 'yup'

export const Validation = () => {

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            catId: Yup.number().min(1, 'Required'),
            artCaption: Yup.string().required('Required'),
            artImgLink: Yup.string().required('Required'),
            artBody: Yup.string().required('Required'),
        })
    })

    return validationSchema
}
