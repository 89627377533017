import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useFormikContext } from 'formik'

const ImagePicker = props => {
    const { image, fieldName } = props
    const [list, setList] = useState([])
    const [keyword, setKeyword] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const formik = useFormikContext()
    const { setFieldValue } = formik

    const initialRef = useRef(true)

    const handleKeywordChange = e => {
        setKeyword(e.target.value)
    }

    const handleClick = value => {
        setFieldValue(fieldName, value)
        setIsOpen(false)
        setKeyword('')
    }

    useEffect(() => {
        let mounted = true
        let i
        if (mounted) {

            if (initialRef.current) {
                initialRef.current = false
                return
            }
            if (keyword.length) {
                setIsOpen(true)
            } else {
                setIsOpen(false)
            }
            i = setTimeout(() => {
                const newList = image.data?.filter(item => item.name.toUpperCase().indexOf(keyword.toUpperCase()) >= 0)
                setList(newList)
            }, 500)
        }

        return () => {
            if (i) clearTimeout(i)
            mounted = false
        }

    }, [keyword])

    return (
        <>
            <input
                className='form-control'
                type='text'
                value={keyword}
                onChange={handleKeywordChange}
                placeholder='Search Here...'
            />
            {isOpen &&
                <div>
                    {
                        list.map((item, key) => {
                            return (
                                <div key={key} className='picker-listitem text' onClick={() => handleClick(item.imageUrl)}>{item.name.slice(15)}</div>
                            )
                        })
                    }
                </div>
            }
        </>
    )
}
const mapStateToProps = state => ({
    image: state.image
})
const ConnectedPage = connect(mapStateToProps)(ImagePicker)
export default ConnectedPage