import React from 'react'

export const LoadingScreen = props => {

    const { catId } = props
    const gifs = [
        '/Content/camera01.gif',
        '/Content/camera01.gif',
        '/Content/musician01.gif',
        '/Content/camera01.gif',
        '/Content/camera01.gif',
        '/Content/camera01.gif',
        '/Content/camera01.gif',
    ]

    return (
        <div style={{
            width: '100%',
            height: 'calc(100vh)',
            display: 'flex',
            alignItems: 'start',
            alignContent: 'start',
            justifyContent: 'center'
        }}>
            <img src={gifs[catId - 1]} alt='Loading...' width={'50%'} />
        </div>
    )
}