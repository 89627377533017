import axios from 'axios'
import updateHeaderInterceptor from "../_interceptors/updateHeaderInterceptor"
import errorInterceptor from "../_interceptors/errorInterceptor"
import { CURRENT_NETWORK_ENV, NETWORK_ENV_LOCALHOST, NETWORK_ENV_SERVER } from '../Constants/networkEnv'

export let baseUrl
switch (CURRENT_NETWORK_ENV) {
	case NETWORK_ENV_LOCALHOST:
		baseUrl = `${window.location.origin}/api`
		break
	case NETWORK_ENV_SERVER:
		baseUrl = `https://miukafoto.com/api`
		break
	default:
		baseUrl = `${window.location.origin}/api`
		break
}

export const apiObject = axios.create({
	baseURL: baseUrl,
	headers: {
		"Content-Type": 'application/json',
	},
	timeout: 600000,
})

export const apiUpload = axios.create({
	baseURL: baseUrl,
	headers: { 'Content-Type': 'multipart/form-data' },
	timeout: 60000,
})

export const axiosConfig = {
	withCredentials: true
}

updateHeaderInterceptor(apiObject)
updateHeaderInterceptor(apiUpload)
errorInterceptor(apiObject)
errorInterceptor(apiUpload)