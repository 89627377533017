import React, { useState, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Pagination, LocalImage } from '../_components'

import Pagetitle from '../components/Pagetitle'

import Friends from '../components/Friends'
import Contacts from '../components/Contacts'
import Group from '../components/Group'
import Events from '../components/Events'
import Profilephoto from '../components/Profilephoto'
import { TagActive } from '../_components/Tags/TagActive'

import { ART_LIST } from '../Redux/ReduxSaga/triggers'

const ArticlesPage = props => {
    const { dispatch, auth, navHeader, art } = props
    const location = useLocation()
    const cat = location.state?.cat

    const [tagId, setTagId] = useState(location.state?.tagId)

    // Assign currentPage and pageSize from store to state
    // If catId from location and catId from store are different => reset currentPage to 1
    let suggestedCurrentPage = 1
    if (!art.loading && !art.error && art.data?.payload?.catId === cat?.catID && art.data?.payload?.currentPage) {
        suggestedCurrentPage = art.data?.payload?.currentPage
    }

    const [currentPage, setCurrentPage] = useState(suggestedCurrentPage)
    const [pageSize, setPageSize] = useState(art.data?.payload?.pageSize || 10)
    //====================================================

    const [dispatchCounter, setDispatchCounter] = useState(0)

    const artList = art.data?.list_data
    const previousPayload = art.data?.payload
    const { keyword } = navHeader

    const [payload, setPayload] = useState({
        catId: cat?.catID,
        tagId,
        keyword,
        currentPage,
        pageSize
    })

    const propsOfPagination = {
        currentStoreSlide: art,
        currentPage, setCurrentPage,
        pageSize, setPageSize,
        pageCount: Math.ceil(art.data?.total_row / pageSize) || 0,
    }

    const navigate = useNavigate()

    const orderedPayload = Object.keys(payload).sort().reduce(
        (obj, key) => {
            obj[key] = payload[key];
            return obj;
        },
        {}
    )

    const orderedPreviousPayload = previousPayload ? Object.keys(previousPayload).sort().reduce(
        (obj, key) => {
            obj[key] = previousPayload[key];
            return obj;
        },
        {}
    ) : {}

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (!cat) navigate('/home')
        }

        return () => mounted = false
    }, [])

    useEffect(() => {
        //if (art.loading) return
        let mounted = true
        if (mounted) {
            if ((art.data?.total_row / pageSize) < (currentPage - 1)) {
                setCurrentPage(1)
            }
        }

        return () => {
            mounted = false
        }
    }, [art.data?.total_row, pageSize, currentPage])

    useEffect(() => {
        let mounted = true

        if (mounted) {
            setPayload({
                ...payload,
                catId: cat?.catID, tagId, currentPage, pageSize
            })
        }

        return () => {
            mounted = false
        }

    }, [cat, tagId, currentPage, pageSize])

    useEffect(() => {
        let mounted = true
        let id
        if (mounted) {
            id = setTimeout(() => {
                setPayload({
                    ...payload,
                    keyword
                })
            }, 500)
        }

        return () => {
            mounted = false
            if (id) clearTimeout(id)
        }

    }, [keyword])

    useEffect(() => {
        const json1 = JSON.stringify(orderedPayload)
        const json2 = JSON.stringify(orderedPreviousPayload)
        let mounted = true
        if (mounted) {
            if (json1 !== json2) {
                dispatch({
                    type: ART_LIST,
                    payload
                })
                setDispatchCounter(dispatchCounter + 1)
            }
        }

        return () => {
            mounted = false
        }
    }, [JSON.stringify(orderedPayload), JSON.stringify(orderedPreviousPayload)])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="row feed-body">
                        <div className="col-lg-12">
                            <Pagetitle
                                title={(
                                    <div className="container ps-0 pe-0">
                                        <div className="flex-start bg-white bg-transparent-card rounded-xxl shadow-xss pt-1 pb-1 mb-2 mt-2">
                                            <i className={`${cat?.featherClass} btn-round-md me-3`}></i>
                                            <span className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center text">{cat?.category?.toUpperCase()}</span>
                                        </div>
                                    </div>
                                )}
                                showAdd={{
                                    to: '/art-detail',
                                    state: {
                                        article: {
                                            catID: cat?.catID
                                        },
                                        cat
                                    }
                                }}
                            />

                            <Pagination {...propsOfPagination} />
                            <>
                                {tagId && !art.loading && <TagActive id={tagId} setTagId={setTagId} />}
                                <div>
                                    {
                                        !art.loading && artList?.map((item, key) => {
                                            const src = `${item.artImgLink || ''}`
                                            return (
                                                <div key={key}>
                                                    <Pagetitle
                                                        title={item.artCaption}
                                                        showModify={{
                                                            to: '/art-detail',
                                                            state: {
                                                                article: item,
                                                                cat
                                                            }
                                                        }}
                                                    />
                                                    <Link to='/article' state={{ article: item }} >
                                                        <div style={{
                                                            paddingTop: '20px',
                                                            paddingBottom: '20px'
                                                        }}>
                                                            <div>
                                                                <LocalImage style={{
                                                                    width: '100%'
                                                                }}
                                                                    src={src}
                                                                    alt={item.artCaption}
                                                                />
                                                            </div>
                                                            <div
                                                                className='art-body'
                                                                style={{
                                                                    textAlign: 'justify'
                                                                }}>
                                                                {item.artBody}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        </div>
                        <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                            <Friends />
                            <Contacts />
                            <Group />
                            <Events />
                            <Profilephoto />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = state => ({
    navHeader: state.navHeader,
    auth: state.auth,
    art: state.art,
})
const ConnectedArticlesPage = connect(mapStateToProps)(ArticlesPage)
export { ConnectedArticlesPage as ArticlesPage }