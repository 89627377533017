import { takeEvery, takeLatest } from 'redux-saga/effects'
import { activateAccountAsync } from './activateAccountSaga'
import { refreshTokenExistsAsync } from './refreshTokenExistsSaga'
import { refreshTokenAsync } from './refreshTokenSaga'
import { silentlyRefreshTokenAsync } from './silentlyRefreshTokenSaga'
import { loginAsync, sendActivateLinkAsync } from './loginSaga'
import { googleLoginAsync } from './googleLoginSaga'
import { logoutAsync } from './logoutSaga'
import {
    getCatListAsync,
    insertCatAsync,
    updateCatAsync,
    deleteCatAsync,
} from './catSaga'
import {
    getParagTemplateListAsync,
    insertParagTemplateAsync,
    updateParagTemplateAsync,
    deleteParagTemplateAsync,
} from './paragTemplateSaga'
import {
    getAuthorListAsync,
    insertAuthorAsync,
    updateAuthorAsync,
    deleteAuthorAsync
} from './authorSaga'
import {
    getAlbumListAsync,
    insertAlbumAsync,
    updateAlbumAsync,
    deleteAlbumAsync
} from './albumSaga'
import {
    getPictureListAsync,
    insertPictureAsync,
    updatePictureAsync,
    deletePictureAsync
} from './pictureSaga'
import { getQuoteListAsync, insertQuoteAsync, updateQuoteAsync, deleteQuoteAsync } from './quoteSaga'
import {
    getAuthorImageListAsync,
    insertAuthorImageAsync,
    updateAuthorImageAsync,
    deleteAuthorImageAsync
} from './authorImageSaga'
import {
    getArtListAsync,
    insertArtAsync,
    updateArtAsync,
    updateArtCreatedDateAsync,
    deleteArtAsync,
} from './artSaga'
import { getParagListAsync, insertParagAsync, updateParagAsync, deleteParagAsync } from './paragSaga'
import { getImageListAsync } from './imageSaga'
import { getImageOfflineListAsync } from './imageOfflineSaga'
import { getAudioListAsync } from './audioSaga'
import { uhaSaveAsync } from './uhaSaveSaga'
import { artHistoryAsync, artHistoryInsertAsync } from './artHistorySaga'
import { artTimeAsync } from './artTimeSaga'
import { artTaListAsync, artTaSaveAsync } from './artTaSaga'
import {
    getFlowChartListAsync,
    insertFlowChartAsync,
    updateFlowChartAsync,
    deleteFlowChartAsync
} from './flowChartSaga'
import { uploadFileAsync } from './uploadFileSaga'
import { deviceTokenList } from './deviceTokenSaga'
import {
    ACTIVATE_ACCOUNT,
    LOGIN,
    SEND_ACTIVATE_LINK,
    GOOGLE_LOGIN,
    LOGOUT,
    CAT_LIST, CAT_INSERT, CAT_UPDATE, CAT_DELETE,
    AUTHOR_LIST, AUTHOR_INSERT, AUTHOR_UPDATE, AUTHOR_DELETE,
    ALBUM_LIST, ALBUM_INSERT, ALBUM_UPDATE, ALBUM_DELETE,
    PICTURE_LIST, PICTURE_INSERT, PICTURE_UPDATE, PICTURE_DELETE,
    AUTHOR_IMAGE_LIST, AUTHOR_IMAGE_INSERT, AUTHOR_IMAGE_UPDATE, AUTHOR_IMAGE_DELETE,
    QUOTE_LIST, QUOTE_INSERT, QUOTE_UPDATE, QUOTE_DELETE,

    ART_LIST, ART_INSERT, ART_UPDATE, ART_UPDATE_CREATED_DATE, ART_DELETE,
    PARAG_LIST, PARAG_INSERT, PARAG_UPDATE, PARAG_DELETE,
    PARAG_TEMPLATE_LIST, PARAG_TEMPLATE_INSERT, PARAG_TEMPLATE_UPDATE, PARAG_TEMPLATE_DELETE,
    FLOW_CHART_LIST, FLOW_CHART_INSERT, FLOW_CHART_UPDATE, FLOW_CHART_DELETE,
    IMAGE_LIST,
    IMAGE_OFFLINE_LIST,
    AUDIO_LIST,
    UHA_SAVE,
    ART_HISTORY,
    ART_HISTORY_INSERT,
    ART_TIME,
    ART_TA_LIST,
    ART_TA_SAVE,
    REFRESH_TOKEN_EXISTS,
    REFRESH_TOKEN,
    SILENTLY_REFRESH_TOKEN,
    UPLOAD_FILE,
    DEVICE_TOKEN_LIST,
    FCM_SEND_NOTIFICATION,
} from './triggers'
import { fcmSendNotification } from './fcmSaga'

export function* rootSaga() {
    yield takeEvery(ACTIVATE_ACCOUNT, activateAccountAsync)
    yield takeEvery(REFRESH_TOKEN_EXISTS, refreshTokenExistsAsync)
    yield takeEvery(REFRESH_TOKEN, refreshTokenAsync)
    yield takeLatest(SILENTLY_REFRESH_TOKEN, silentlyRefreshTokenAsync)
    yield takeEvery(LOGIN, loginAsync)
    yield takeEvery(SEND_ACTIVATE_LINK, sendActivateLinkAsync)
    yield takeEvery(GOOGLE_LOGIN, googleLoginAsync)
    yield takeEvery(LOGOUT, logoutAsync)

    yield takeEvery(CAT_LIST, getCatListAsync)
    yield takeEvery(CAT_INSERT, insertCatAsync)
    yield takeEvery(CAT_UPDATE, updateCatAsync)
    yield takeEvery(CAT_DELETE, deleteCatAsync)

    yield takeEvery(AUTHOR_LIST, getAuthorListAsync)
    yield takeEvery(AUTHOR_INSERT, insertAuthorAsync)
    yield takeEvery(AUTHOR_UPDATE, updateAuthorAsync)
    yield takeEvery(AUTHOR_DELETE, deleteAuthorAsync)

    yield takeEvery(ALBUM_LIST, getAlbumListAsync)
    yield takeEvery(ALBUM_INSERT, insertAlbumAsync)
    yield takeEvery(ALBUM_UPDATE, updateAlbumAsync)
    yield takeEvery(ALBUM_DELETE, deleteAlbumAsync)

    yield takeEvery(PICTURE_LIST, getPictureListAsync)
    yield takeEvery(PICTURE_INSERT, insertPictureAsync)
    yield takeEvery(PICTURE_UPDATE, updatePictureAsync)
    yield takeEvery(PICTURE_DELETE, deletePictureAsync)

    yield takeEvery(QUOTE_LIST, getQuoteListAsync)
    yield takeEvery(QUOTE_INSERT, insertQuoteAsync)
    yield takeEvery(QUOTE_UPDATE, updateQuoteAsync)
    yield takeEvery(QUOTE_DELETE, deleteQuoteAsync)

    yield takeEvery(AUTHOR_IMAGE_LIST, getAuthorImageListAsync)
    yield takeEvery(AUTHOR_IMAGE_INSERT, insertAuthorImageAsync)
    yield takeEvery(AUTHOR_IMAGE_UPDATE, updateAuthorImageAsync)
    yield takeEvery(AUTHOR_IMAGE_DELETE, deleteAuthorImageAsync)

    yield takeEvery(ART_LIST, getArtListAsync)
    yield takeEvery(ART_INSERT, insertArtAsync)
    yield takeEvery(ART_UPDATE, updateArtAsync)
    yield takeEvery(ART_UPDATE_CREATED_DATE, updateArtCreatedDateAsync)
    yield takeEvery(ART_DELETE, deleteArtAsync)

    yield takeEvery(PARAG_LIST, getParagListAsync)
    yield takeEvery(PARAG_INSERT, insertParagAsync)
    yield takeEvery(PARAG_UPDATE, updateParagAsync)
    yield takeEvery(PARAG_DELETE, deleteParagAsync)

    yield takeEvery(PARAG_TEMPLATE_LIST, getParagTemplateListAsync)
    yield takeEvery(PARAG_TEMPLATE_INSERT, insertParagTemplateAsync)
    yield takeEvery(PARAG_TEMPLATE_UPDATE, updateParagTemplateAsync)
    yield takeEvery(PARAG_TEMPLATE_DELETE, deleteParagTemplateAsync)

    yield takeEvery(FLOW_CHART_LIST, getFlowChartListAsync)
    yield takeEvery(FLOW_CHART_INSERT, insertFlowChartAsync)
    yield takeEvery(FLOW_CHART_UPDATE, updateFlowChartAsync)
    yield takeEvery(FLOW_CHART_DELETE, deleteFlowChartAsync)

    yield takeEvery(IMAGE_LIST, getImageListAsync)
    yield takeLatest(IMAGE_OFFLINE_LIST, getImageOfflineListAsync)
    yield takeEvery(AUDIO_LIST, getAudioListAsync)
    yield takeEvery(UHA_SAVE, uhaSaveAsync)
    yield takeEvery(ART_HISTORY, artHistoryAsync)
    yield takeEvery(ART_HISTORY_INSERT, artHistoryInsertAsync)
    yield takeEvery(ART_TIME, artTimeAsync)
    yield takeEvery(ART_TA_LIST, artTaListAsync)
    yield takeEvery(ART_TA_SAVE, artTaSaveAsync)

    yield takeEvery(UPLOAD_FILE, uploadFileAsync)
    
    yield takeEvery(DEVICE_TOKEN_LIST, deviceTokenList)
    yield takeEvery(FCM_SEND_NOTIFICATION, fcmSendNotification)
}