import { combineReducers } from 'redux'
import { pendingActionReducer } from './pendingActionReducer'
import { visibilityStateReducer } from './visibilityStateReducer'
import { refreshTokenExistsReducer } from './refreshTokenExistsReducer'
import { initialScreenReducer } from './initialScreenReducer'
import { loginReducer } from './loginReducer'
import { hubConnectionReducer } from './hubConnectionReducer'
import { silentlyRefreshTokenReducer } from './silentlyRefreshTokenReducer'
import { authorReducer } from './authorReducer'
import { albumReducer } from './albumReducer'
import { quoteReducer } from './quoteReducer'
import { authorImageReducer } from './authorImageReducer'
import { catReducer } from './catReducer'
import { artReducer } from './artReducer'
import { paragReducer } from './paragReducer'
import { paragTemplateReducer } from './paragTemplateReducer'
import { imageReducer } from './imageReducer'
import { imageOfflineReducer } from './imageOfflineReducer'
import { pictureReducer } from './pictureReducer'
import { audioReducer } from './audioReducer'
import { navigationReducer } from './navigationReducer'
import { navHeaderReducer } from './navHeaderReducer'
import { appFooterReducer } from './appFooterReducer'
import { userActivityReducer } from './userActivityReducer'
import { artHistoryReducer } from './artHistoryReducer'
import { artTimeReducer } from './artTimeReducer'
import { artTaReducer } from './artTaReducer'
import { flowChartReducer } from './flowChartReducer'
import { chatReducer } from './chatReducer'
import { callReducer } from './callReducer'
import { uploadFileReducer } from './uploadFileReducer'
import { deviceTokenReducer } from './deviceTokenReducer'
import { fcmReducer } from './fcmReducer'

export const rootReducer = combineReducers({
    pendingAction: pendingActionReducer,
    refreshTokenExists: refreshTokenExistsReducer,
    visibilityState: visibilityStateReducer,
    auth: loginReducer,
    hubConnection: hubConnectionReducer,
    silentlyRT: silentlyRefreshTokenReducer,
    initialScreen: initialScreenReducer,
    author: authorReducer,
    album: albumReducer,
    quote: quoteReducer,
    authorImage: authorImageReducer,
    cat: catReducer,
    art: artReducer,
    parag: paragReducer,
    paragTemplate: paragTemplateReducer,
    image: imageReducer,
    imageOffline: imageOfflineReducer,
    picture: pictureReducer,
    audio: audioReducer,
    uploadFile: uploadFileReducer,
    artHistory: artHistoryReducer,
    artTime: artTimeReducer,
    navigation: navigationReducer,
    navHeader: navHeaderReducer,
    appFooter: appFooterReducer,
    userActivity: userActivityReducer,
    artTa: artTaReducer,
    flowChart: flowChartReducer,
    chat: chatReducer,
    call: callReducer,
    deviceToken: deviceTokenReducer,
    fcm: fcmReducer,
})
