import { create, CancelToken } from 'apisauce'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { authHeader } from '../../_services'
import { axiosConfig, baseUrl } from '../../Constants'
import { IMAGE_OFFLINE_LIST_REQUEST, IMAGE_OFFLINE_LIST_SUCCESS, IMAGE_OFFLINE_LIST_FAILURE } from '../ReduxAction'

export function* getImageOfflineListAsync(action) {
    try {
        action = actionIntercept(action)
        const apiObject = create({
            baseURL: baseUrl,
            headers: {
                "Content-Type": 'application/json',
            },
            timeout: 600000,
        })
        apiObject.setHeaders(authHeader())
        const source = CancelToken.source()
        // const naviMonitor = response => console.log('hey!  listen! ', response)
        // apiObject.addMonitor(naviMonitor)
        yield put({
            type: IMAGE_OFFLINE_LIST_REQUEST,
            payload: { apiObject, source }
        })
        const response = yield call(async () => {

            const res = await apiObject.post('/file/image-list', { notUsed: true },
                {
                    ...axiosConfig,
                    cancelToken: source.token,
                })
            return res
        })

        if (response.ok && response.status === 200) {
            yield put({ type: IMAGE_OFFLINE_LIST_SUCCESS, payload: response.data.fileList })
        } else {
            yield put({ type: IMAGE_OFFLINE_LIST_FAILURE, payload: response })
        }

    } catch (error) {
        yield put({ type: IMAGE_OFFLINE_LIST_FAILURE, payload: error.message })
    }
}