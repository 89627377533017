import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { IMAGE_LIST_REQUEST, IMAGE_LIST_SUCCESS, IMAGE_LIST_FAILURE } from '../ReduxAction'

export function* getImageListAsync(action) {
        try {
        action = actionIntercept(action)
        yield put({ type: IMAGE_LIST_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/file/image-list', action.payload, axiosConfig)
            return res
        })

        yield put({ type: IMAGE_LIST_SUCCESS, payload: response.data.fileList })

    } catch (error) {

        yield put({ type: IMAGE_LIST_FAILURE, payload: error.message })

    }
}