import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  useEdgesState,
  updateEdge,
  useNodesState,
  useReactFlow,
} from 'react-flow-renderer'
import { useReactToPrint } from 'react-to-print'
import { Context } from './Context.js'
import { FloatingEdge } from './FloatingEdge.js'
import { ConnectionLine } from './ConnectionLine.js'

import './style.css'
import './shapes.css'

import { ButtonDropdown } from './ButtonDropdown'
import { FloatButtonEdge } from './FloatButtonEdge.js'
import { nodeTypes, nodeTypeColors } from './symbols'

const getNodeId = () => `node_${+new Date()}`

const edgeTypes = {
  floating: FloatingEdge,
  buttonedge: FloatButtonEdge,
}

const FlowChartDraw = props => {
  const { data, flowChartItem, handleSave } = props
  const langCode = 'en'
  const [showMiniMap, setShowMiniMap] = useState(true)
  const [showControls, setShowControls] = useState(true)
  const [showTopTool, setShowTopTool] = useState(true)
  const [showBackground, setShowBackground] = useState(true)
  const [printLoading, setPrintLoading] = useState(false)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [rfInstance, setRfInstance] = useState(null)
  const [nodrag, setNodrag] = useState(false)

  const { setViewport } = useReactFlow()

  const flowRef = useRef()
  const onBeforeGetContentResolve = useRef(null)

  const addNodeItems = useMemo(() => [
    {
      translateCode: langCode === 'en' ? 'Start' : 'Bắt đầu',
      onClick: () => onAdd('startNode')
    },
    {
      translateCode: langCode === 'en' ? 'Process' : 'Tiến triển / Tính toán',
      onClick: () => onAdd('processNode')
    },
    {
      translateCode: langCode === 'en' ? 'Decision' : 'Lựa chọn / Quyết định',
      onClick: () => onAdd('decisionNode')
    },
    {
      translateCode: langCode === 'en' ? 'Input' : 'Nhập dữ liệu',
      onClick: () => onAdd('inputNode')
    },
    {
      translateCode: langCode === 'en' ? 'Output' : 'Xuất dữ liệu',
      onClick: () => onAdd('outputNode')
    },
    {
      translateCode: langCode === 'en' ? 'Stop' : 'Kết thúc',
      onClick: () => onAdd('stopNode')
    },
  ], [langCode])

  const onConnect = params => {
    const { source, target } = params
    const newEdges = [...edges]
    const id = `e_${source}-${target}`
    const filter = newEdges.filter(item => item.id === id)

    if (filter.length === 0) {
      newEdges.push({
        id: id,
        source: source,
        target: target,
        type: 'buttonedge',
        animated: true,
        markerEnd: {
          type: 'arrowclosed'
        }
      })
    }
    setEdges(newEdges)
  }

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject()
      handleSave(flow, flowChartItem)
    }
  }, [rfInstance, flowChartItem])

  const onAdd = useCallback((type = 'default') => {
    const newNode = {
      id: getNodeId(),
      type,
      data: {
        label: `New ${type}`
      },
      position: {
        x: 0, //Math.random() * window.innerWidth / 2,
        y: 0, //Math.random() * window.innerHeight,
      },
    }
    setNodes(nds => nds.concat(newNode))
  }, [setNodes])

  const handleExport = () => {
    setShowMiniMap(false)
    setShowControls(false)
    setShowTopTool(false)
    setShowBackground(false)
    setTimeout(() => handlePrint(), 10)
  }

  const handleOnBeforeGetContent = useCallback(() => {
    setPrintLoading(true)
    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve
      setTimeout(() => {
        setPrintLoading(false)
        resolve()
      }, 1000)
    })
  }, [setPrintLoading])

  const handlePrint = useReactToPrint({
    content: () => flowRef.current,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: () => {
      setShowMiniMap(true)
      setShowControls(true)
      setShowTopTool(true)
      setShowBackground(true)
      setPrintLoading(false)
    },
    removeAfterPrint: true,
  })

  const toggleMiniMap = () => {
    setShowMiniMap(!showMiniMap)
  }

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current()
    }
  }, [onBeforeGetContentResolve.current])

  useEffect(() => {
    if (data) {
      setNodes(data.nodes)
      setEdges(data.edges)
      setViewport(data.viewport)
    } else {
      setNodes([])
      setEdges([])
      setViewport({ x: 0, y: 0, zoom: 1 })
    }
  }, [data])

  const onEdgeUpdate = (oldEdge, newConnection) => setEdges(els => updateEdge(oldEdge, newConnection, els))

  const initialContextValue = {
    langCode,
    nodes, setEdges,
    edges, setNodes,
    nodrag, setNodrag,
  }

  return (
    <Context.Provider value={initialContextValue}>
      {printLoading && <div>Loading print preview</div>}
      <div style={{ height: 'calc(100vh - 150px)' }} ref={flowRef} >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          onConnect={onConnect}
          onInit={setRfInstance}
          nodeTypes={nodeTypes}
          fitView
          edgeTypes={edgeTypes}
          snapGrid={[20, 20]}
          connectionLineComponent={ConnectionLine}
          connectOnClick={true}
          className="touchdevice-flow"
        >
          {showMiniMap && <MiniMap
            nodeStrokeColor={node => nodeTypeColors[node.type]}
            nodeColor={node => nodeTypeColors[node.type]}
            nodeBorderRadius={2}
          />}
          {showControls && <Controls />}
          {showTopTool &&
            <div className="save__controls">
              <div className='flex-between'>
                <div className="flowchart__dropdown">
                  <ButtonDropdown
                    langCode={langCode}
                    translateCode={langCode === 'en' ? 'New step' : 'Thêm bước'}
                    itemList={addNodeItems}
                  />
                </div>
                <div className='flex-end w200'>
                  <button className='btn btn-primary' onClick={onSave} disabled={nodes.length === 0}>
                    <i className='feather-save w25'></i>
                  </button>
                  <button className='btn btn-primary' onClick={handleExport} disabled={nodes.length === 0}>
                    <i className='feather-printer w25'></i>
                  </button>
                  <button className='btn btn-primary' onClick={toggleMiniMap} >
                    <i className='feather-monitor w25'></i>
                  </button>
                </div>
              </div>
            </div>
          }
          {showBackground && <Background color="#aaa" gap={16} />}
        </ReactFlow>
      </div>
    </Context.Provider>
  )
}
const ProvidedFlowChart = props => (
  <ReactFlowProvider>
    <FlowChartDraw {...props} />
  </ReactFlowProvider>
)
const MemoizedProvidedFlowChart = React.memo(ProvidedFlowChart)
export { MemoizedProvidedFlowChart as FlowChartDraw }