import React, { useState, useEffect, useMemo } from 'react'

const LoadingText = props => {

    const [step, setStep] = useState(1)
    const surfixes = useMemo(() => {
        return {
            s1: '|',
            s2: '/',
            s3: '—',
            s4: '\\',
            s5: '|',
            s6: '/',
            s7: '—',
            s8: '\\'
        }
    })

    useEffect(() => {
        let mounted = true
        let timer
        if (mounted) {
            setInterval(() => {
                setStep(prevStep => {
                    if (prevStep < 8) { return prevStep + 1 } else { return 1 }
                })
            }, 100)
        }

        return () => {
            mounted = false
            clearInterval(timer)
        }
    }, [])

    return (
        <span>{`${props.text} ${surfixes[`s${step}`]}`}</span>
    )

}
export default LoadingText