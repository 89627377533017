import React from 'react'

const ItemCard = (props: any) => {
    const { item } = props

    return (
        <div className='position-relative ph'>
            <div
                className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 pe-4 pt-4 pb-4 pl-30 pr30 cursor-pointer"
            >
                <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">
                    {`${item.app}`}
                </h4>
                <div className="font-xssss mb-2 text-grey-500 fw-600 align-middle flex-between">
                    <span className="text-grey-900 font-xssss text-dark align-middle">{item.userName}</span>
                    <span className="text-grey-900 font-xssss text-dark align-middle">{new Date(item.createdDate).toLocaleString()}</span>
                </div>
                <h6 className="font-xssss mb-2 text-grey-500 fw-600 align-middle">
                    <span className="text-grey-900 font-xssss text-dark align-middle">{item.token}</span>
                </h6>
                <h6 className="font-xssss mb-2 text-grey-500 fw-600 align-middle">
                    <span className="text-grey-900 font-xssss text-dark align-middle">
                        {`${item.deviceName} ${item.os} ${item.platform || ''}`}
                    </span>
                </h6>
            </div>
        </div>
    )
}
export default ItemCard