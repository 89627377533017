import { ColorSphere } from '../ColorSphere/ColorSphere'
import { ColorHarmony } from '../ColorHarmony/ColorHarmony'
import { TheSpectrum } from '../TheSpectrum/TheSpectrum'
import { DavidNovick } from '../DavidNovick/DavidNovick'

export const paragComponents = {
    ColorSphere: <ColorSphere />,
    ColorHarmony: <ColorHarmony />,
    TheSpectrum: <TheSpectrum />,
    DavidNovick: <DavidNovick />,
}