import React, { useState, useEffect, useRef, useMemo } from "react"
import { connect } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { randomAvatarSrc, formatAMPM } from "../_helpers"
import { CHAT } from "../_services"
import { CHAT_SET, CHAT_MARK_AS_RED_SET } from "../Redux/ReduxAction"

const Chat = props => {
    const [message, setMessage] = useState('')
    const { dispatch, auth, hubConnection, navHeader, appFooter, chat } = props
    const location = useLocation()
    const partner = location.state?.partner
    const thisChat = useMemo(() => {
        return chat.filter(item => item.userId === auth.data?.id || item.userId === partner.userId)
    }, [chat, auth.data?.id, partner.userId])

    const endRef = useRef()

    const handleTextChange = e => {
        setMessage(e.target.value)
    }

    const handleSend = async () => {
        const activity = {
            type: CHAT,
            userId: auth.data?.id,
            user: auth.data.username,
            avatar: auth.data.avatarUrl || auth.data.googleProfile?.Picture || randomAvatarSrc(),
            message: `${auth.data?.username} send a message to ${partner.name}`,
            data: {
                message,
                time: new Date().getTime(),
                red: false,
            }
        }

        if (hubConnection && hubConnection.state === 'Connected') {
            await hubConnection.origin?.invoke("SendPrivateMessage", activity, partner.name)
            dispatch({
                type: CHAT_SET,
                payload: activity,
            })
        }

        setMessage('')
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13 && !!message.length) handleSend()
    }

    useEffect(() => {

        let mounted = true

        if (mounted) {
            endRef.current?.scrollIntoView({ behavior: "smooth" })
            endRef.current?.scrollIntoView({ behavior: "smooth" })
            dispatch({
                type: CHAT_MARK_AS_RED_SET,
                payload: {
                    userId: partner?.userId,
                    username: partner?.name,
                    auth, hubConnection,
                }
            })
        }

        return () => {
            mounted = false
        }

    }, [JSON.stringify(thisChat)])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-lg-12 position-relative">

                            {partner &&
                                <>

                                    <div className="chat-header dark-bg p-3 shadow-none theme-dark-bg flex-between"
                                        style={{
                                            height: navHeader.height,
                                        }}
                                    >
                                        <div className="flex-start">
                                            <figure className="avatar float-left mb-0 me-2">
                                                <img src={partner?.imageUrl} alt="avater" className="w35" />
                                            </figure>
                                            <h3 className="fw-700 mb-0 mt-0">
                                                <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer">{partner?.name}</span>
                                            </h3>
                                            <span className={`${partner?.status} ml-10 btn-round-xss`}></span>
                                        </div>
                                        <div className="flex-between w100">
                                            <Link to="/audiocall" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark"><i className="font-md text-success feather-phone me-2"></i></Link>
                                            <Link to="/videooffer" state={{ partner }} className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark"><i className="font-md text-danger feather-video me-2"></i></Link>
                                        </div>
                                    </div>

                                    <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-body p-3 ">
                                            <div className="messages-content pb-5">
                                                {
                                                    thisChat.map((item, key) => {
                                                        if (item.user === auth.data.username) {
                                                            return (
                                                                <div key={key} className="message-item outgoing-message">
                                                                    <div className="message-user">
                                                                        <figure className="avatar">
                                                                            <img src={item.avatar} alt="avater" />
                                                                        </figure>
                                                                        <div>
                                                                            <h5>{item.user}</h5>
                                                                            <div className="time">{formatAMPM(new Date(item.data.time))}
                                                                                {!!item.data.red &&
                                                                                    <i className="ti-double-check text-info"></i>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="message-wrap" style={{ marginBottom: "90px" }}>{item.data.message}</div>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div key={key} className="message-item">
                                                                    <div className="message-user">
                                                                        <figure className="avatar">
                                                                            <img src={item.avatar} alt="avater" />
                                                                        </figure>
                                                                        <div>
                                                                            <h5>{item.user}</h5>
                                                                            <div className="time">{formatAMPM(new Date(item.data.time))}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="message-wrap">{item.data.message}</div>
                                                                </div>

                                                            )
                                                        }
                                                    })
                                                }

                                                <div ref={endRef} className="clearfix"></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-bottom dark-bg p-3 shadow-none theme-dark-bg" style={{ width: "98%", bottom: appFooter.height }}>
                                        <div className="chat-form">

                                            <div className="flex-between">
                                                <button className="bg-transparent float-left"><i className="feather-mic text-current"></i></button>
                                                <input className="chat-input" type="text" value={message} onChange={handleTextChange} onKeyDown={handleKeyDown} placeholder="Start typing..." />
                                                <button className="bg-current ml-10" onClick={handleSend} style={{ width: '54px' }}><i className="ti-arrow-right text-white"></i></button>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    hubConnection: state.hubConnection,
    chat: state.chat,
    navHeader: state.navHeader,
    appFooter: state.appFooter,
})
const ConnectedPage = connect(mapStateToProps)(Chat)
export default ConnectedPage