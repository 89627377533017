import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { PICTURE_LIST } from '../Redux/ReduxSaga/triggers'
import PictureCard from './PictureCard'
import Pagetitle from '../components/Pagetitle'

import './style.scss'

const PictureList = props => {

    document.title = 'Pictures'

    const { dispatch, picture, navHeader } = props
    const initialRef = useRef(true)

    useEffect(() => {
        let mounted = true
        let id

        if (mounted) {
            if (initialRef.current) {
                initialRef.current = false
                return
            }

            id = setTimeout(() => {
                dispatch({
                    type: PICTURE_LIST,
                    payload: {
                        keyword: navHeader.keyword
                    }
                })
            }, 500)
        }

        return () => {
            mounted = false
            if (id) clearTimeout(id)
        }
    }, [navHeader.keyword])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (initialRef.current) {
                initialRef.current = false
                return
            }

            if (!picture?.changing) {
                dispatch({
                    type: PICTURE_LIST
                })
            }
        }

        return () => {
            mounted = false
        }
    }, [picture?.changing])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-12 chat-left scroll-bar">

                            <Pagetitle title="PICTURES"
                                showAdd={{
                                    to: '/picturedetail',
                                    state: {
                                        picture: null
                                    }
                                }}
                            />
                            <div className='row'>
                                {
                                    !picture.loading && picture?.data?.list_data?.map((item, key) => {
                                        return (
                                            <div className='col-lg-6' key={key}>
                                                <div className="shadow-none mb-0 p-0 bg-transparent">
                                                    <div className="card-body p-0">
                                                        <PictureCard picture={item} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className='h100' />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    picture: state.picture,
    navHeader: state.navHeader,
})
const ConnectedPage = connect(mapStateToProps)(PictureList)
export default ConnectedPage