import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from '../ReduxAction'

export function* refreshTokenAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: REFRESH_TOKEN_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/users/refresh-token', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {

            yield put({
                type: REFRESH_TOKEN_SUCCESS,
                payload: response.data
            })
        } else {
            yield put({ type: REFRESH_TOKEN_FAILURE, payload: response?.data })
        }

    } catch (error) {

        yield put({ type: REFRESH_TOKEN_FAILURE, payload: error.message })

    }
}