import React, { useMemo, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { APP_FOOTER_SET } from '../Redux/ReduxAction'
import DarkMode from './Darkbutton'

const Appfooter = props => {

    const { dispatch, auth, appFooter } = props
    const appFooterRef = useRef()

    useEffect(() => {
        if (appFooterRef.current) {
            const rect = appFooterRef.current.getBoundingClientRect()
            dispatch({
                type: APP_FOOTER_SET,
                payload: {
                    ...appFooter,
                    height: rect.height
                }
            })
        }

    }, [appFooterRef])

    return (
        <div ref={appFooterRef} className="app-footer border-0 shadow-lg bg-primary-gradiant">
            <Link to="/home" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
            <Link to="/defaultvideo" className="nav-content-bttn"><i className="feather-package"></i></Link>
            <Link to="/defaultlive" className="nav-content-bttn" data-tab="chats"><i className="feather-layout"></i></Link>
            <DarkMode />
            <Link to="/account-settings" className="nav-content-bttn"><i className="feather-user"></i></Link>
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    appFooter: state.appFooter,
})
const ConnectedAppfooter = connect(mapStateToProps)(Appfooter)
export { ConnectedAppfooter as Appfooter }