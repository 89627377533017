import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { call } from 'redux-saga/effects'


export function* uhaSaveAsync(action) {
    try {
        action = actionIntercept(action)
        //yield put({ type:  })
        const response = yield call(async () => {
            const res = await apiObject.post('/uha/uha-save', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            //yield put({ type: , payload: response.data })
        } else {
            //yield put({ type: , payload: response })
        }
    } catch (error) {
        //alert(JSON.stringify(error, null, 2))
        //yield put({ type: , payload: error.message })
    }

}