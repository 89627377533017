import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { ART_HISTORY_REQUEST, ART_HISTORY_SUCCESS, ART_HISTORY_FAILURE } from '../ReduxAction'


export function* artHistoryAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: ART_HISTORY_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/ArtHistory/art-history-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: ART_HISTORY_SUCCESS, payload: response.data.data })
        } else {
            yield put({ type: ART_HISTORY_FAILURE, payload: response?.data?.message })
        }

    } catch (error) {

        yield put({ type: ART_HISTORY_FAILURE, payload: error.message })

    }
}

export function* artHistoryInsertAsync(action) {
    try {
        action = actionIntercept(action)
        yield call(async () => {
            await apiObject.post('/ArtHistory/art-history-insert', action.payload, axiosConfig)
        })
    } catch (error) {
        console.log(error)
    }
}