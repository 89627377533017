import { connect } from 'react-redux'

const Indicator = ({
    type,
    callOffer,
    callAnswer,
    peerConnection,
    connectionState,
    localDescription,
    localStream
}) => {

    return (
        <div className="video-indicator">
            {peerConnection && connectionState === 'connecting' &&
                <img src='simpleload.gif' alt='connecting' width={18} height={18} className='text-white' />
            }
            {peerConnection && <i className="feather-share-2 large-icon text-success"></i>}
            {connectionState && connectionState !== 'connecting' &&
                <i className={`${
                    connectionState === 'new' ? 'feather-star' 
                    //: connectionState === 'connecting' ? 'feather-loader text-primary' 
                    : connectionState === 'connected' ? 'feather-sun text-success' 
                    : connectionState === 'disconnected' ? 'feather-sunset text-warning' 
                    : connectionState === 'failed' ? 'feather-x-circle text-danger' 
                    : connectionState === 'closed' ? 'feather-slash' : 'ti-help-alt'
                 } large-icon`}></i>
            }
            {localStream && <i className="feather-activity large-icon text-danger" ></i>}
            {localDescription && <i className="feather-log-out large-icon text-warning"></i>}
            {peerConnection?.remoteDescription && <i className="feather-log-in large-icon text-primary"></i>}
            {type === 'callee' && callOffer.iceCandidates.map((item, key) => {
                const protocol = item.candidate.split(' ')[2] // tcp || udp
                return <i key={key} className={`${item.stateCode === 2 ? 'feather-check-circle' : 'feather-circle'} large-icon ${protocol === 'tcp' ? 'text-warning' : 'text-success'}`}></i>
            })}
            {type === 'caller' && callAnswer.iceCandidates.map((item, key) => {
                const protocol = item.candidate.split(' ')[2]
                return <i key={key} className={`${item.stateCode === 2 ? 'feather-check-circle' : 'feather-circle'} large-icon ${protocol === 'tcp' ? 'text-warning' : 'text-success'}`}></i>
            })}
        </div>
    )
}
const mapStateToProps = state => ({
    peerConnection: state.call.connection.peerConnection,
    connectionState: state.call.connection.connectionState,
    localDescription: state.call.connection.localDescription,
    remoteDescription: state.call.connection.remoteDescription,
    callOffer: state.call.offer,
    callAnswer: state.call.answer,
})
const ConnectedComponent = connect(mapStateToProps)(Indicator)
export default ConnectedComponent