import React, { useState, useContext } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import { Context } from './Context'
import './style.css'

export const EdgeMenu = props => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = !!anchorEl
    const { langCode, edges, setEdges } = useContext(Context)

    const handleClickMenuButton = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClickEditLabel = () => {
        const newLabel = prompt(langCode === 'en' ? 'Input new label' : 'Tiêu đề mới', props.label) || props.label
        const filter1 = edges.filter(item => item.id === props.id)
        const filter2 = edges.filter(item => item.id !== props.id)
        const currentEdge = filter1[0]
        currentEdge.label = newLabel
        filter2.push(currentEdge)
        setEdges(filter2)
    }

    const handleClickDeleteEdge = () => {
        const filter = edges.filter(item => item.id !== props.id)
        setEdges(filter)
    }

    const handleClickSwitchAnimation = () => {
        const filter1 = edges.filter(item => item.id === props.id)
        const filter2 = edges.filter(item => item.id !== props.id)
        const currentEdge = filter1[0]
        currentEdge.animated = !currentEdge.animated
        filter2.push(currentEdge)
        setEdges(filter2)
    }

    return (
        <>
            <Tooltip title={langCode === 'en' ? 'Edit' : 'Tùy chỉnh'}>
                <button className="edgebutton" onClick={handleClickMenuButton}>
                    {props.label}
                </button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClickDeleteEdge}>
                    {langCode === 'en' ? 'Delete edge' : 'Xóa đường nối'}
                </MenuItem>
                <MenuItem onClick={handleClickEditLabel}>
                    {langCode === 'en' ? 'Edit label' : 'Chỉnh tiêu đề'}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClickSwitchAnimation}>
                    {langCode === 'en' ? 'Switch animation on/off' : 'Chuyển tĩnh/động'}
                </MenuItem>
            </Menu>
        </>
    )
}
