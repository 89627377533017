import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { randomAvatarSrc } from "../_helpers"
import Pagetitle from '../components/Pagetitle'
import { Pagination } from '../_components'
import { ART_TIME } from "../Redux/ReduxSaga/triggers"
import { Article } from "@mui/icons-material"

const ArtTime = props => {

    const { dispatch, navHeader, artTime } = props

    // Assign currentPage and pageSize from store to state
    // If catId from location and catId from store are different => reset currentPage to 1
    let suggestedCurrentPage = 1
    if (!artTime.loading && !artTime.error && artTime.data?.payload?.currentPage) {
        suggestedCurrentPage = artTime.data?.payload?.currentPage
    }

    const [currentPage, setCurrentPage] = useState(suggestedCurrentPage)
    const [pageSize, setPageSize] = useState(artTime.data?.payload?.pageSize || 10)

    const artTimeList = artTime.data?.list_data
    const previousPayload = artTime.data?.payload
    const { keyword } = navHeader

    const [payload, setPayload] = useState({
        keyword,
        currentPage,
        pageSize
    })

    console.log("payload", payload)

    const propsOfPagination = {
        currentStoreSlide: artTime,
        currentPage, setCurrentPage,
        pageSize, setPageSize,
        pageCount: Math.ceil(artTime.data?.total_row / pageSize) || 0,
    }

    const orderedPayload = Object.keys(payload).sort().reduce(
        (obj, key) => {
            obj[key] = payload[key];
            return obj;
        },
        {}
    )

    const orderedPreviousPayload = previousPayload ? Object.keys(previousPayload).sort().reduce(
        (obj, key) => {
            obj[key] = previousPayload[key];
            return obj;
        },
        {}
    ) : {}

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (Math.ceil (artTime.data?.total_row / pageSize) < currentPage) {
                setCurrentPage(1)
            }
        }

        return () => {
            mounted = false
        }
    }, [artTime.data?.total_row, pageSize, currentPage])

    useEffect(() => {
        let mounted = true

        if (mounted) {
            setPayload({
                ...payload,
                currentPage, pageSize
            })
        }

        return () => {
            mounted = false
        }

    }, [currentPage, pageSize])

    useEffect(() => {
        let mounted = true
        let id
        if (mounted) {
            id = setTimeout(() => {
                setPayload({
                    ...payload,
                    keyword
                })
            }, 500)
        }

        return () => {
            mounted = false
            if (id) clearTimeout(id)
        }

    }, [keyword])

    useEffect(() => {
        const json1 = JSON.stringify(orderedPayload)
        const json2 = JSON.stringify(orderedPreviousPayload)
        let mounted = true
        if (mounted) {
            if (json1 !== json2) {
                dispatch({
                    type: ART_TIME,
                    payload
                })
            }
        }

        return () => {
            mounted = false
        }
    }, [JSON.stringify(orderedPayload), JSON.stringify(orderedPreviousPayload)])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: `100%` }}>
                    <div className="row">
                        <div className="col-lg-12">

                            <Pagetitle title="Article Hit Time" showSearch={true} />
                            <Pagination {...propsOfPagination} />

                            <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                <ul className="email-message"
                                    style={{
                                        paddingInlineStart: '0px !important',
                                        marginBlockStart: '0px !important',
                                        marginBlockEnd: '0px !important',
                                    }}>

                                    {!!artTime && artTimeList?.map((item, index) => {
                                        let avatarUrl = randomAvatarSrc()
                                        if (item.googleProfile) {
                                            const googleProfile = JSON.parse(item.googleProfile)
                                            avatarUrl = googleProfile.Picture
                                        }

                                        return (

                                            <li key={index} >
                                                <Link to='/' state={{ id: item.artID }} className={`rounded-3 bg-lightblue theme-light-bg`} >
                                                    <div className="email-user">
                                                        <span className="btn-round-xss ms-0 bg-success me-2"></span>
                                                        <img src={avatarUrl} alt="user" className="w35 me-2" />
                                                        <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700">{`${item.username}`}</h6>
                                                        <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700">{`${item.LastUhaUsername} (${item.userHostAddress})`}</h6>
                                                    </div>
                                                    <div className="email-subject text-grey-900 text-dark fw-600 font-xssss">
                                                        <i className="feather-star font-xss text-warning me-2"></i>
                                                        {item.region_name}
                                                    </div>
                                                    <div className="email-text text-grey-500 fw-600 font-xssss">{item.artCaption}</div>
                                                    <div className="email-text text-grey-500 fw-600 font-xssss">{item.city}</div>
                                                    <span className="email-file">
                                                        <i className="feather-paperclip font-xss btn-round-sm text-grey-500 me-2 p-0"></i>
                                                    </span>
                                                    <div className="email-time text-grey-500 fw-600">
                                                        {new Date(item.hitTime).toLocaleDateString('vi-VI', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: '2-digit',
                                                        })}
                                                    </div>
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        navHeader: state.navHeader,
        artTime: state.artTime,
    }
}
const ConnectedArtTime = connect(mapStateToProps)(ArtTime)
export { ConnectedArtTime as ArtTime }