import React from 'react'
import { connect } from 'react-redux'
import { Header } from '../../components/Header'
import { Appfooter } from '../../components/Appfooter'
import { Leftnav } from '../Leftnav'
import { CallListener } from '../../_services'
import Rightchat from '../../components/Rightchat'
import Popupchat from '../../components/Popupchat'

const Layout = props => {
    return (
        <>
            <Header />
            <CallListener />
            <Leftnav />
            <Rightchat />
            <>
                {props.children}
            </>
            <Popupchat />
            <Appfooter />
        </>
    )
}

const mapStateToProps = state => {
    return { authData: state.auth.data }
}
const ConnectedLayout = connect(mapStateToProps)(Layout)
export { ConnectedLayout as Layout }