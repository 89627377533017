import {
    ACTIVATE_ACCOUNT_REQUEST, ACTIVATE_ACCOUNT_SUCCESS, ACTIVATE_ACCOUNT_FAILURE,
    SEND_ACTIVATE_LINK_REQUEST, SEND_ACTIVATE_LINK_SUCCESS, SEND_ACTIVATE_LINK_FAILURE,
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE,
    GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_FAILURE,
    REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE,
    SILENTLY_REFRESH_TOKEN_SUCCESS,
    LOGOUT,
    SILENTLY_REFRESH_TOKEN_REQUEST,
} from '../ReduxAction'

const initialState = {
    loading: false,
    error: false,
    message: '',
    data: null,
    activeAccountStatus: 0,
    sendActivateLinkStatus: 0,
    loginStatus: 0,
    tokenStatus: 1,
    googleStatus: 0,
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'request',
                loginStatus: 1,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'fulfil',
                data: {
                    ...action.payload,
                    googleProfile: JSON.parse(action.payload.googleProfile)
                },
                loginStatus: 2,
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                data: null,
                loginStatus: 3,
            }
        case GOOGLE_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'request',
                data: null,
                googleStatus: 1,
            }
        case GOOGLE_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'fulfil',
                data: {
                    ...action.payload,
                    googleProfile: JSON.parse(action.payload.googleProfile)
                },
                loginStatus: 2,
                googleStatus: 2,
            }
        case GOOGLE_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                data: null,
                googleStatus: 3,
            }
        case REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'request with refresh token',
                data: null,
                tokenStatus: 1,
            }
        case SILENTLY_REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                hubConnectionSelfRestart: false,
            }
        case REFRESH_TOKEN_SUCCESS:
        case SILENTLY_REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'fulfil with refresh token',
                data: {
                    ...action.payload,
                    googleProfile: JSON.parse(action.payload.googleProfile)
                },
                tokenStatus: 2,
            }
        case REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                data: null,
                tokenStatus: 3,
            }
        case ACTIVATE_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'active account request sent',
                data: null,
                activeAccountStatus: 1,
            }
        case ACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'active account success',
                data: {
                    ...action.payload,
                    googleProfile: JSON.parse(action.payload.googleProfile)
                },
                loginStatus: 2,
                activeAccountStatus: 2
            }
        case ACTIVATE_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                data: null,
                activeAccountStatus: 3,
            }

        case SEND_ACTIVATE_LINK_REQUEST:
            return {
                ...state,
                message: 'send activate link request sent',
                sendActivateLinkStatus: 1
            }
        case SEND_ACTIVATE_LINK_SUCCESS:
            return {
                ...state,
                message: 'send activate link success',
                sendActivateLinkStatus: 2
            }
        case SEND_ACTIVATE_LINK_FAILURE:
            return {
                ...state,
                message: 'send activate link failuer',
                sendActivateLinkStatus: 3
            }

        case LOGOUT:
            const userConfigString = localStorage.getItem('userConfig')
            let userConfig
            if (userConfigString) {
                userConfig = JSON.parse(userConfigString)
                if (userConfig && userConfig.rememberGoogleLogin) {
                    localStorage.setItem('userConfig', JSON.stringify({
                        ...userConfig,
                        rememberGoogleLogin: false
                    }))
                }
            }
            return {
                ...initialState,
                hubConnectionSelfRestart: false,
                message: 'logged out',
                tokenStatus: 0,
            }
        default:
            return state
    }
}