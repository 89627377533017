import React from 'react'
import { getSmoothStepPath } from 'react-flow-renderer'
import { getEdgeParams } from './utils.js'

export const ConnectionLine = props => {

  const { targetX, targetY, sourcePosition, targetPosition, sourceNode } = props

  if (!sourceNode) {
    return null
  }

  const targetNode = {
    id: 'connection-target',
    width: 1, height: 1, position: { x: targetX, y: targetY },
  }

  const { sx, sy } = getEdgeParams(sourceNode, targetNode)
  
  const d = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition,
    targetPosition,
    targetX,
    targetY,
  })

  return (
    <g>
      <path fill="none" stroke="#222" strokeWidth={1} className="animated" d={d} />
      <circle cx={targetX} cy={targetY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
    </g>
  )
}