import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { ART_TIME_REQUEST, ART_TIME_SUCCESS, ART_TIME_FAILURE } from '../ReduxAction'


export function* artTimeAsync(action) {
        try {
        action = actionIntercept(action)
        yield put({ type: ART_TIME_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/ArtTime/art-time-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({
                type: ART_TIME_SUCCESS,
                payload: {
                    ...response.data,
                    payload: action.payload,
                }
            })

        } else {
            yield put({ type: ART_TIME_FAILURE, payload: response })
        }

    } catch (error) {

        yield put({ type: ART_TIME_FAILURE, payload: error.message })

    }
}