import React from 'react'

import './style.css'

export const PageNotFound = () => {
  return (
    <section className='page-not-found'>
      <div className="page-not-found-content">
        <span className='page-not-found-text'>Page Not Found</span>
        <span className='page-not-found-text'>Page Not Found</span>
      </div>
    </section>
  )
}
