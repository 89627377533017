import { 
    REFRESH_TOKEN_EXISTS_REQUEST, 
    REFRESH_TOKEN_EXISTS_SUCCESS, 
    REFRESH_TOKEN_EXISTS_FAILURE 
} from '../ReduxAction'

const initialState = {
    loading: false,
    error: false,
    message: '',
    data: null,
    loginStatus: 0,
    tokenStatus: 1,
    tokenCheckingStatus: 0,
}

export const refreshTokenExistsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REFRESH_TOKEN_EXISTS_REQUEST:
            return {
                loading: true,
                error: false,
                message: 'Refresh token exists request sent',
                data: null,
                loginStatus: 0,
                tokenStatus: 1,
                tokenCheckingStatus: 1,
            }
        case REFRESH_TOKEN_EXISTS_SUCCESS:
            return {
                loading: false,
                error: false,
                message: 'Refresh token exists request success',
                data: action.payload,
                loginStatus: 0,
                tokenStatus: 1,
                tokenCheckingStatus: 2,
            }
        case REFRESH_TOKEN_EXISTS_FAILURE:
            return {
                loading: false,
                error: false,
                message: 'Refresh token exists request failure',
                data: null,
                loginStatus: 0,
                tokenStatus: 1,
                tokenCheckingStatus: 3,
            }
        default:
            return state
    }
}