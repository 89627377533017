import React from 'react'
import { PageNotFound }  from '../_components'
import { SuggestedArticles } from '../SuggestedArticles/SuggestedArticles'

export const publicComponents = [
    {
        path: 'suggested-articles',
        element: <SuggestedArticles />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
]