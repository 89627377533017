import React from 'react'
import { Link } from 'react-router-dom'
import { CAT_DELETE } from '../Redux/ReduxSaga/triggers'

const CatCard = props => {
    const { cat } = props
    return (
        <div className='position-relative'>
            <Link to={'/categorydetail'} state={{ cat }} >
                <div className="container ps-0 pe-0">
                    <div className="nav-content">
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                            <ul className="mb-1 top-content">
                                <li>
                                    <i className={`${cat?.featherClass} btn-round-md me-3`}></i>
                                    <span className='text'>{cat?.category}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Link>

            <Link
                className='position-absolute top-0 right-15 p-2'
                to={'/deleteconfirm'}
                state={{
                    action: {
                        type: CAT_DELETE,
                        payload: cat
                    },
                    dboName: 'Cat',
                    slide: 'cat',
                    title: cat.category
                }}
            >
                <i className="feather-x font-xl text-grey-500"></i>
            </Link>
        </div>
    )
}
export default CatCard