import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Form, Formik, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ImagePicker from '../_components/ImagePicker'
import { PICTURE_INSERT, PICTURE_UPDATE } from '../Redux/ReduxSaga/triggers'
import { randomImageSrc } from '../_helpers'

const SubmitArea = () => {
    const { submitForm, errors } = useFormikContext()

    const handleClickSubmit = () => {
        if (Object.keys(errors).length > 0) {
            alert(JSON.stringify(errors, null, 2))
        } else {
            submitForm()
        }

    }

    return (
        <div className="col-lg-12 submit">
            <a onClick={handleClickSubmit} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
        </div>
    )
}

const PictureDetail = props => {
    const location = useLocation()
    const navigate = useNavigate()
    const { picture } = location.state
    const { dispatch, albumList } = props
    const [imageholder, setImageholder] = useState(picture?.picture_source || '')

    const initialValues = {
        pictureId: picture?.picture_id || 0,
        pictureSource: picture?.picture_source || '',
        pictureCaption: picture?.picture_caption || '',
        pictureDescription: picture?.picture_description || '',
        datetimeTaken: picture?.datetime_taken || '',
        latitude: picture?.latitude || '',
        longitude: picture?.longitude || '',
        album: picture?.album || 0,
        isColor: picture?.is_color || false,
        isDigital: picture?.is_digital || false,
    }

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            pictureSource: Yup.string().required('Required'),
            album: Yup.number().min(1, 'Required'),
        })
    })

    const handleSubmit = values => {

        if (values.pictureId) {
            dispatch({
                type: PICTURE_UPDATE,
                payload: values
            })
        } else {
            dispatch({
                type: PICTURE_INSERT,
                payload: values
            })
        }

        navigate('/pictures')

    }

    const handleImageErrror = () => {
        setImageholder(randomImageSrc(1200, 800))
    }

    const compare = (a, b) => {
        if (a.album_name < b.album_name) {
            return -1
        }
        if (a.album_name > b.album_name) {
            return 1
        }
        return 0
    }

    return (
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/pictures" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Picture Details</h4>
                            </div>
                            <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 text-center">
                                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img onError={handleImageErrror} src={imageholder} alt="picture" className="shadow-sm rounded-3 w-100" /></figure>
                                        <h2 className="fw-700 font-sm text-grey-900 mt-3">{picture?.album_name}</h2>
                                    </div>
                                </div>

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ values }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">Album</label>
                                                        <Field name="album" type="number" as="select" className="form-control">
                                                            {
                                                                albumList?.sort(compare).map((item, key) => {
                                                                    return (
                                                                        <option key={key} value={item.aid}>{item.aCaption}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">Picture Source</label>
                                                        <Field name="pictureSource" type="text" as="textarea" className="form-control textarea-small" />
                                                        <ImagePicker className="mb3" fieldName="pictureSource" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">Caption</label>
                                                        <Field name="pictureCaption" type="text" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">Description</label>
                                                        <Field name="pictureDescription" type="text" as="textarea" className="form-control textarea-small" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">Latitude</label>
                                                        <Field name="latitude" type="number" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">Longitude</label>
                                                        <Field name="longitude" type="number" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">
                                                            <Field name="isColor" type="checkbox" />
                                                            {` Color`}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss mb-2">
                                                            <Field name="isDigital" type="checkbox" />
                                                            {` Digital`}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <SubmitArea />
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    pictureList: state.picture.data?.list_data,
    albumList: state.album.data?.list_data,
})
const ConnectedPage = connect(mapStateToProps)(PictureDetail)
export default ConnectedPage