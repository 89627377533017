import { HUB_CONNECTION_SET, HUB_CONNECTION_STATE_SET, HUB_CONNECTION_AUTOSTART_SET, VISIBILITY_STATE_VISIBLE, VISIBILITY_STATE_HIDDEN, } from '../ReduxAction'

const initialState = {
    origin: null,
    state: null,
    autoStart: null,
}

export const hubConnectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case HUB_CONNECTION_SET:
            return {
                origin: action.payload,
                state: action.payload?.connection?._connectionState,
                autoStart: true,
            }
        case HUB_CONNECTION_STATE_SET:
            return {
                ...state,
                state: action.payload,
            }
        case HUB_CONNECTION_AUTOSTART_SET:
            return {
                ...state,
                autoStart: action.payload,
            }
        case VISIBILITY_STATE_VISIBLE:
            return {
                ...state,
                autoStart: true,
            }
        case VISIBILITY_STATE_HIDDEN:
            return {
                ...state,
                autoStart: false,
            }
        default:
            return state
    }
}