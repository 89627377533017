import { VISIBILITY_STATE_VISIBLE, VISIBILITY_STATE_HIDDEN } from '../ReduxAction'

const initialState = 'visible'

export const visibilityStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case VISIBILITY_STATE_VISIBLE:
            return 'visible'
        case VISIBILITY_STATE_HIDDEN:
            return 'hidden'
        default:
            return state
    }
}