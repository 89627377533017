import React, { useState, useEffect, useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { ProgressBar } from 'react-bootstrap'

export const WavePlayer = props => {
    const [wp, setWp] = useState()
    const [playButtonCaption, setPlayButtonCaption] = useState('Play')
    const [loading, setLoading] = useState(false)
    const [now, setNow] = useState(0)

    const [currentTime, setCurrentTime] = useState(0)
    const duration = useRef()
    const setDuration = value => duration.current = value


    const { title, description, paragId } = props
    let { path } = props

    if (path?.indexOf('/') === 0) {
        path = window.location.origin + path
    }

    const waveform = useRef()

    const togglePlayPause = () => {
        wp && wp.playPause()
    }

    useEffect(() => {
        const ws = WaveSurfer.create({
            container: waveform.current,
            waveColor: "#46a6d8",
            progressColor: "#FFF",
            barWidth: 3,
            barGap: 2,
            height: 130,
            cursorWidth: 1,
            cursorColor: "white",
            responsive: 1000,
        })

        setWp(ws)

        function handleLoading(e) {
            setNow(e)
        }

        function handleReady() {
            setLoading(false)
            props.handleReady()
            setPlayButtonCaption('Play')
            setDuration(ws.getDuration())
        }

        function handlePlay() {
            setPlayButtonCaption('Pause')
        }

        function handlePause() {
            const currentTime_ = ws.getCurrentTime()
            const duration_ = ws.getDuration()

            if (currentTime_ === duration_) {
                ws.stop()
                setCurrentTime(0)
                setPlayButtonCaption('Play')
            } else {
                setPlayButtonCaption('Resume')
            }
        }

        function handleAudioprocess(e) {
            setCurrentTime(e)
        }

        function handleSeek(e) {
            setCurrentTime(e * duration.current)
        }

        ws.on('loading', handleLoading)
        ws.on('ready', handleReady)
        ws.on('play', handlePlay)
        ws.on('pause', handlePause)
        ws.on('audioprocess', handleAudioprocess)
        ws.on('seek', handleSeek)
        setLoading(true)
        ws.load(path)

        return () => {
            ws.un('loading', handleLoading)
            ws.un('ready', handleReady)
            ws.un('play', handlePlay)
            ws.un('pause', handlePause)
            ws.un('audioprocess', handleAudioprocess)
            ws.un('seek', handleSeek)
            ws && ws.destroy()
        }

    }, [])

    return (
        <>
            <div className='parag' style={{
                paddingTop: '20px',
                paddingBottom: '10px',
                fontSize: '18px',
                wordWrap: 'break-word',
            }}>{title || ''}</div>
            <div className='parag'>{description || ''}</div>
            <div id={`waveform${paragId}`} ref={waveform} />
            {loading && <ProgressBar now={now} />}

            {!loading &&
                <div style={{ padding: '20px 0px 20px 0px' }}>
                    <span>
                        <button className='btn btn-primary' onClick={() => togglePlayPause()}>
                            {playButtonCaption}
                        </button>
                    </span>
                    {currentTime > 0 && <span>{` ${new Date(currentTime * 1000).toISOString().substring(14, 19)}`}</span>}
                </div>
            }
        </>
    )
}

