import { IMAGE_LIST_REQUEST, IMAGE_LIST_SUCCESS, IMAGE_LIST_FAILURE } from '../ReduxAction'

const initialState = {
    loading: false,
    error: false,
    message: '',
    data: [],
}

export const imageReducer = (state = initialState, action) => {
    switch (action.type) {
        case IMAGE_LIST_REQUEST:
            return {
                loading: true,
                error: false,
                message: 'request',
                data: [],
            };
        case IMAGE_LIST_SUCCESS:
            return {
                loading: false,
                error: false,
                message: 'fulfil',
                data: action.payload,
            };
        case IMAGE_LIST_FAILURE:
            return {
                loading: false,
                error: true,
                message: action.payload,
                data: [],
            };
        default:
            return state;
    }
}