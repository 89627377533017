import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { CRUD } from './_services'

const DeleteConfirm = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const initialRef = useRef(true)
    const { action, dboName, slide, title, goBackPath, goBackState } = location.state

    const currentSlide = useSelector(state => state[slide])
    const hubConnection = useSelector(state => state.hubConnection)

    useEffect(() => {
        if (initialRef.current) {
            initialRef.current = false
            return
        }
        if (!currentSlide.changing) {
            const activity = {
                type: CRUD,
                text: 'Changing complete',
                crudType: 'DELETE',
                dboName: dboName,
            }

            if (hubConnection && hubConnection.state === 'Connected') {
                hubConnection.origin?.invoke("SendMessage", activity)
            }

            if (goBackPath) {
                navigate(goBackPath, goBackState)
            } else {
            navigate(-1)
            }
        }
    }, [currentSlide.changing])

    const handleConfirmDelete = () => {
        dispatch(action)
    }

    const handleCancelDelete = () => {
        navigate(-1)
    }

    return (
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className='position-fixed-center rounded-3 bg-white px-4 py-4 text-center dialog-confirm'>
                                <span className='fw-700 mb-0 mt-0 font-sm text-grey-900 pb-4 dialog-confirm'>Are you sure you want to delete?</span>
                                <div className='h10' />
                                {title}
                                <div className='h10' />
                                <div className='d-flex justify-content-around'>
                                    <button
                                        className='bg-current text-center text-white font-xsss fw-600 p-3 w100 rounded-3 d-inline-block border-0 mx-4'
                                        onClick={handleConfirmDelete}
                                    >Delete</button>
                                    <button
                                        className='bg-current text-center text-white font-xsss fw-600 p-3 w100 rounded-3 d-inline-block border-0 mx-4'
                                        onClick={handleCancelDelete}
                                    >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirm