import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'

import { put, call } from 'redux-saga/effects'
import {
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
} from '../ReduxAction'

export function* uploadFileAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: UPLOAD_FILE_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/file/upload-file', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: UPLOAD_FILE_SUCCESS, payload: response.data })
        } else {
            yield put({ type: UPLOAD_FILE_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: UPLOAD_FILE_FAILURE, payload: error.message })
    }
}