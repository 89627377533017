import { 
    IMAGE_OFFLINE_LIST_REQUEST, 
    IMAGE_OFFLINE_LIST_SUCCESS, 
    IMAGE_OFFLINE_LIST_FAILURE 
} from '../ReduxAction'

const initialState = {
    loading: false,
    error: false,
    message: '',
    data: [],
    currentXHR: null,
}

export const imageOfflineReducer = (state = initialState, action) => {
    switch (action.type) {
        case IMAGE_OFFLINE_LIST_REQUEST:
            return {
                loading: true,
                error: false,
                message: 'request',
                data: [],
                currentXHR: action.payload,
            };
        case IMAGE_OFFLINE_LIST_SUCCESS:
            return {
                loading: false,
                error: false,
                message: 'fulfil',
                data: action.payload,
                currentXHR: null,
            };
        case IMAGE_OFFLINE_LIST_FAILURE:
            return {
                loading: false,
                error: true,
                message: action.payload,
                data: [],
                currentXHR: null,
            };
        default:
            return state;
    }
}