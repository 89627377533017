import {
    PENDING_ACTION_ADD,
    PENDING_ACTION_REMOVE,
} from '../ReduxAction'

const initialState = []

export const pendingActionReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case PENDING_ACTION_ADD:
            
            const exist = state.filter(x => JSON.stringify(x) === JSON.stringify(action.payload)).length > 0
            if (!exist) {
                const newState = [...state]
                newState.push(action.payload)
                return newState 
            } else {
                return state
            }
        case PENDING_ACTION_REMOVE:
            const newState = state.filter(x => JSON.stringify(x) !== JSON.stringify(action.payload))
            return newState
        default:
            return state
    }
}