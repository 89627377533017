import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'

import { 
    SILENTLY_REFRESH_TOKEN_REQUEST,
    SILENTLY_REFRESH_TOKEN_SUCCESS,
    SILENTLY_REFRESH_TOKEN_FAILURE,
} from '../ReduxAction'

export function* silentlyRefreshTokenAsync(action) {

    try {
        action = actionIntercept(action)
        yield put({ type: SILENTLY_REFRESH_TOKEN_REQUEST })

        const response = yield call(async () => {
            const res = await apiObject.post('/users/silently-refresh-token', action.payload, axiosConfig)
            return res
        })

        if (response?.status === 200) {
            yield put({
                type: SILENTLY_REFRESH_TOKEN_SUCCESS,
                payload: response.data
            })
        } else {
            yield put({ type: SILENTLY_REFRESH_TOKEN_FAILURE, payload: response?.data })
        }

    } catch (error) {
        yield put({ type: SILENTLY_REFRESH_TOKEN_FAILURE, payload: error.message })
    }
}