import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { ALBUM_LIST } from '../Redux/ReduxSaga/triggers'
import AlbumCard from './AlbumCard'
import Pagetitle from '../components/Pagetitle'

import './style.scss'

const AlbumList = props => {

    document.title = 'Albums'

    const { dispatch, album, navHeader } = props
    const initialRef = useRef(true)

    useEffect(() => {
        let mounted = true
        let id

        if (mounted) {
            if (initialRef.current) {
                initialRef.current = false
                return
            }

            id = setTimeout(() => {
                dispatch({
                    type: ALBUM_LIST,
                    payload: {
                        keyword: navHeader.keyword
                    }
                })
            }, 500)
        }

        return () => {
            mounted = false
            if (id) clearTimeout(id)
        }
    }, [navHeader.keyword])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (initialRef.current) {
                initialRef.current = false
                return
            }

            if (!album?.changing) {
                dispatch({
                    type: ALBUM_LIST
                })
            }
        }

        return () => {
            mounted = false
        }
    }, [album?.changing])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-12 chat-left scroll-bar">
                            <Pagetitle title="ALBUMS"
                                showAdd={{
                                    to: '/albumdetail',
                                    state: {
                                        album: null
                                    }
                                }}
                            />
                            <div className='row'>
                                {
                                    !album.loading && album?.data?.list_data?.map((item, key) => {
                                        return (
                                            <div className='col-lg-6' key={key}>
                                                <div className="shadow-none mb-0 p-0 bg-transparent">
                                                    <div className="card-body p-0">
                                                        <AlbumCard album={item} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    album: state.album,
    navHeader: state.navHeader,
})
const ConnectedPage = connect(mapStateToProps)(AlbumList)
export default ConnectedPage