import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { CALL_ANSWER_REFUSE } from './MessageTypes'
import { CALL_OFFER_UNSET } from '../Redux/ReduxAction'
import { randomAvatarSrc } from '../_helpers'

const CallListener = props => {

    const { dispatch, auth, hubConnection, callOffer, navHeader } = props
    const { offer } = callOffer
    const location = useLocation()

    const handleAbort = async () => {
        const activity = {
            type: CALL_ANSWER_REFUSE,
            userId: auth?.data?.id,
            user: auth?.data?.username,
            avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
            message: `${auth?.data?.username} refused the offer`,
        }
        await hubConnection.origin?.invoke("SendPrivateMessage", activity, offer.user)

        dispatch({
            type: CALL_OFFER_UNSET
        })

    }

    useEffect(() => {
        let mounted = true
        let id
        if (mounted) {
            if (!!offer && location.pathname !== '/videoanswer') {
                const ring = new Audio('ericsson_medium.mp3')
                id = setInterval(() => ring.play(), 2000)
            } else {
                if (id) clearInterval(id)
            }
        }
        return () => {
            mounted = false
            if (id) clearInterval(id)
        }
    }, [offer, location.pathname])

    return (
        <>
            {!!offer && location.pathname !== '/videoanswer' &&
                <div className='incoming-call bg-primary-gradiant flex-between' style={{
                    top: `${navHeader.height}px`,
                    height: `${navHeader.height}px`,
                    padding: '10px'
                }}>
                    <div className="flex-start">
                        <figure className="avatar float-left mb-0 me-2">
                            <img src={offer.avatar} alt="avater" className="w35" />
                        </figure>
                        <h3 className="fw-700 mb-0 mt-0">
                            <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer">{`${offer.user} is calling you...`}</span>
                        </h3>
                        <span className={`${''} ml-10 btn-round-xss`}></span>
                    </div>
                    <div className="flex-between w100">
                        <div className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark"><i onClick={handleAbort} className={`feather-x bg-primary-gradiant large-icon  btn-round-md me-3`}></i></div>
                        <Link to="/videoanswer" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark"><i className={`feather-phone bg-gold-gradiant btn-round-md me-3`}></i></Link>
                    </div>
                </div>
            }
        </>
    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    hubConnection: state.hubConnection,
    callOffer: state.call.offer,
    navHeader: state.navHeader,
})
const ConnectedPacge = connect(mapStateToProps)(CallListener)
export { ConnectedPacge as CallListener }