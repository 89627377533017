import React from 'react'
import { connect } from 'react-redux'

const TagActive = props => {
    const { artTa, id, setTagId } = props

    console.log("id, artTa", id, artTa)

    const tag = artTa.data?.list_data?.filter(item => item.taid === id)[0]
    const text = tag?.ta

    const handleClickX = () => {
        setTagId(null)
    }

    return (
        <div className='flex-between tag-active w200'>
            <div className='ti-tag text-grey-500 mb-0 mt-0'>
                {text}
            </div>
            <div><i
                className='feather-x font-xl text-current'
                style={{ cursor: 'pointer' }}
                onClick={handleClickX}
            ></i></div>
        </div>
    )
}
const mapStateToProps = state => ({
    artTa: state.artTa
})
const ConnectedPage = connect(mapStateToProps)(TagActive)
export { ConnectedPage as TagActive}