import React, { useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, useFormikContext } from 'formik'
import { FLOW_CHART_INSERT, FLOW_CHART_UPDATE } from '../Redux/ReduxSaga/triggers'

const SubmitArea = () => {
    const { submitForm, errors, values } = useFormikContext()

    const handleClickSubmit = () => {
        if (Object.keys(errors).length > 0) {
            alert(JSON.stringify(errors, null, 2))
        } else {
            submitForm()
        }
    }

    return (
        <div className="col-lg-12 submit">
            <a onClick={handleClickSubmit} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
        </div>
    )
}

const FlowChartSave = props => {
    const location = useLocation()
    const { flowChart } = location.state
    const navigate = useNavigate()
    const { dispatch } = props

    const initialValues = {
        id: flowChart?.id || 0,
        flowName: flowChart?.flowName || '',
		jsonData: flowChart?.jsonData || '',
        description: flowChart?.description || '',
    }

    const validationSchema = useMemo(() => {
		return Yup.object().shape({
            flowName: Yup.string().required("Required"),
			jsonData: Yup.string().required("Required"),
        })
    })

    const handleSubmit = values => {

        if (values.id) {
            dispatch({
                type: FLOW_CHART_UPDATE,
                payload: values
            })
        } else {
            dispatch({
                type: FLOW_CHART_INSERT,
                payload: values
            })
        }
        navigate(-1)
    }

    return (
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/flowchart" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">FlowChart Details</h4>
                            </div>
                            <div className="card-body p-lg-5 p-4 w-100 border-0 ">

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    <Form>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Flow Name</label>
                                                    <Field name="flowName" type="text" className="form-control" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Description</label>
                                                    <Field name="description" type="text" as="textarea" className="text form-control textarea-small" />
                                                </div>
                                            </div>

                                            <SubmitArea />

                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    flowChartList: state.flowChart.data?.list_data
})
const ConnectedPage = connect(mapStateToProps)(FlowChartSave)
export default ConnectedPage