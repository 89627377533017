import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { Notifications } from 'react-push-notification'
import { privateComponents } from './privateComponents'
import { publicComponents } from './publicComponents'
import { Layout } from '../_components'
import { RouteTracker, LOG_INFO } from '../_services'
import { randomAvatarSrc } from '../_helpers'
import { Login } from '../pages/Login'
import HubConnectionHolder from '../_services/HubConnectionHolder'
import ResendActionCenter from '../_services/ResendActionCenter'
import { Register } from '../pages/Register'
import Forgot from '../pages/Forgot'
import { LoginViaGoogle } from '../pages/LoginViaGoogle'
import {
    GOOGLE_LOGIN,
    ACTIVATE_ACCOUNT,
    CAT_LIST,
    AUTHOR_LIST,
    PARAG_TEMPLATE_LIST,
    ALBUM_LIST,
    QUOTE_LIST,
    AUTHOR_IMAGE_LIST,
    REFRESH_TOKEN_EXISTS,
    REFRESH_TOKEN,
    IMAGE_LIST,
    IMAGE_OFFLINE_LIST,
    PICTURE_LIST,
    AUDIO_LIST,
    ART_HISTORY,
    ART_TIME,
    ART_TA_LIST,
    FLOW_CHART_LIST,
} from '../Redux/ReduxSaga/triggers'
import { AppContext } from './AppContext'
import './appStyle.css'
import './style.css'
import './imaging.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { WaittingScreen } from '../components/WaittingScreen'

const googleClientId = '520974910345-960dha19n9all640nvlektujh0k6bbuq.apps.googleusercontent.com'

const App = props => {

    const { dispatch, refreshTokenExists, auth, cat, hubConnection, silentlyRT, initialScreen, userConfig } = props

    const href = document.location.href
    const paths = href.split('/')
    const lastPath = paths[paths.length - 1]
    const toActiveAccount = lastPath.indexOf('activate-account?') === 0
    const account = lastPath.substring(lastPath.indexOf('?') + 1)

    const remember = userConfig?.remember || JSON.parse(localStorage.getItem('userConfig'))?.remember || false
    const rememberGoogleLogin = userConfig?.rememberGoogleLogin || JSON.parse(localStorage.getItem('userConfig'))?.rememberGoogleLogin || false

    const handleGoogleSuccess = response => {
        dispatch({
            type: GOOGLE_LOGIN,
            payload: {
                tokenId: response.tokenId,
                profileObj: response.profileObj,
            }
        })
    }

    useEffect(() => {
        if (toActiveAccount) {
            dispatch({
                type: ACTIVATE_ACCOUNT,
                payload: account,
            })
        }
    }, [toActiveAccount])

    useEffect(() => {
        dispatch({ type: REFRESH_TOKEN_EXISTS })
        dispatch({ type: ART_TA_LIST })
    }, [])

    useEffect(() => {
        if (refreshTokenExists.data?.status === 'Found') {
            dispatch({ type: REFRESH_TOKEN })
        }
    }, [refreshTokenExists])

    // load category list first
    useEffect(() => {
        if (auth.data && !cat.data) dispatch({ type: CAT_LIST })
    }, [auth.data])

    // after category list loaded
    useEffect(() => {
        if (cat.data && auth.data?.errorCode === 0 &&
            typeof hubConnection?.origin?.invoke === 'function' &&
            silentlyRT.count === 0) {

            dispatch({ type: AUTHOR_LIST })
            dispatch({ type: AUTHOR_IMAGE_LIST })
            dispatch({ type: ALBUM_LIST })
            dispatch({ type: QUOTE_LIST })

            hubConnection?.origin?.invoke("JoinGroup", auth.data?.id.toString())

            if (auth.data.isAdmin) {
                dispatch({ type: PARAG_TEMPLATE_LIST })
                dispatch({ type: IMAGE_LIST })
                dispatch({ type: IMAGE_OFFLINE_LIST })
                dispatch({ type: PICTURE_LIST })
                dispatch({ type: AUDIO_LIST })
                dispatch({ type: ART_HISTORY })
                dispatch({ type: ART_TIME })
                dispatch({ type: FLOW_CHART_LIST })
            }
        }

    }, [cat.data, hubConnection?.origin?.invoke, silentlyRT.count])


    useEffect(() => {
        if (
            !!auth.data?.id && hubConnection.origin &&
            typeof hubConnection?.origin?.invoke === 'function' &&
            silentlyRT.count === 0
        ) {

            const activity = {
                type: LOG_INFO,
                userId: auth.data?.id,
                user: auth.data?.username,
                avatar: auth.data?.avatarUrl || auth.data?.googleProfile?.Picture || randomAvatarSrc(),
                message: `${auth.data?.username} has logged in`,
                data: null,
            }
            if (hubConnection && hubConnection.state === 'Connected') {
                hubConnection.origin?.invoke("SendMessage", activity)
            }

        }
    }, [auth.data?.id, hubConnection.origin?.connection?.connectionId, silentlyRT.count])

    return (

        <>
            {/* <div className='startup-debug'>
                <div>{`rememberGoogleLogin: ${rememberGoogleLogin}`}</div>
                <div>{`auth.googleStatus: ${auth.googleStatus}`}</div>
                <div>{`refreshTokenExists.tokenCheckingStatus: ${refreshTokenExists.tokenCheckingStatus}`}</div>
                <div>{`auth.tokenStatus: ${auth.tokenStatus}`}</div>
                <div>{`auth.data?.errorCode: ${auth.data?.errorCode}`}</div>
            </div> */}

            {rememberGoogleLogin &&
                <div className='google-hidden'>
                    <LoginViaGoogle
                        clientId={googleClientId}
                        remember={remember}
                        render={props => {
                            return (
                                <button onClick={props.onClick} disabled={props.disabled} className='form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2'><img src='assets/images/icon-1.png' alt='icon' className='ms-2 w40 mb-1 me-5' />Sign in with Google</button>
                            )
                        }}
                        buttonText='Đăng nhập với Google'
                        onSuccess={handleGoogleSuccess}
                        onFailure={() => { }}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={remember}
                    />
                </div>
            }
            {
                (auth.googleStatus === 1) ||
                    (rememberGoogleLogin && auth.googleStatus === 0 && refreshTokenExists.tokenCheckingStatus === 3 && auth.tokenStatus === 1 && !!!auth.data?.errorCode) ||
                    (refreshTokenExists.tokenCheckingStatus !== 3 && auth.tokenStatus === 1) ?
                    <WaittingScreen /> :
                    ((auth.loginStatus !== 2 && auth.tokenStatus !== 2) || auth.data?.errorCode !== 0) ?
                        (
                            initialScreen === 'Login' ? <Login /> :
                                initialScreen === 'Register' ? <Register /> :
                                    initialScreen === 'Forgot' ? <Forgot /> : null
                        ) :
                        cat.data ?
                            <>
                                <Notifications />
                                <ResendActionCenter />
                                <div>
                                    <AppContext.Provider value={{}}>
                                        <HubConnectionHolder />
                                        <Layout>
                                            <RouteTracker />
                                            <Routes>
                                                {
                                                    publicComponents.map((item, key) => {
                                                        return <Route key={key} path={item.path} element={item.element} />
                                                    })
                                                }
                                                {
                                                    (auth.loginStatus === 2 || auth.tokenStatus === 2) &&
                                                    privateComponents.map((item, key) => {
                                                        return <Route key={key} path={item.path} element={item.element} />
                                                    })
                                                }
                                            </Routes>
                                        </Layout>
                                    </AppContext.Provider>
                                </div>
                            </> : <WaittingScreen />
            }
        </>
    )
}
const mapStateToProps = state => state
const ConnectedApp = connect(mapStateToProps)(App)
export { ConnectedApp as App }
