import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { 
    REFRESH_TOKEN_EXISTS_REQUEST, 
    REFRESH_TOKEN_EXISTS_SUCCESS, 
    REFRESH_TOKEN_EXISTS_FAILURE 
} from '../ReduxAction'

export function* refreshTokenExistsAsync(action) {
    try {
        action = actionIntercept(action)
        yield put({ type: REFRESH_TOKEN_EXISTS_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.get('/users/refresh-token-exists', action.payload, axiosConfig)
            return res
        })

        if (response.ok && response.data.status === 'Found') {
            yield put({ type: REFRESH_TOKEN_EXISTS_SUCCESS, payload: response.data })
        } else {
            yield put({ type: REFRESH_TOKEN_EXISTS_FAILURE, payload: response?.data.message })
        }

    } catch (error) {

        yield put({ type: REFRESH_TOKEN_EXISTS_FAILURE, payload: error.message })

    }
}