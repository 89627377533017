export const configuration = {
    iceServers: [{
        urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
    }]
}

export const mediaStreamConstraints = {
    audio: {
        sampleSize: 16,
        channelCount: 2,
        echoCancellation: true,
    },
    video: true,
}
