import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import update from 'immutability-helper'
import { apiObject, axiosConfig } from '../../Constants'

import { Card } from './Card'
import Load from '../../components/Load'
const style = { width: '100%' }

import { ART_TA_SAVE } from '../../Redux/ReduxSaga/triggers'

const Container = props => {
    const [cards, setCards] = useState(null)
    const [keyword, setKeyword] = useState('')

    const { dispatch, artId, artTa } = props

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            }),
        )
    }, [])

    const renderCard = useCallback((card, index) => {
        return (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                text={card.text}
                moveCard={moveCard}
                cards={cards}
                setCards={setCards}
            />
        )
    }, [cards])

    const handleClickSuggest = data => {
        const newCards = [...cards]
        newCards.push(data)
        setCards(newCards)
    }

    const handleKeywordChange = e => {
        setKeyword(e.target.value)
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13 && keyword.trim() !== '') {
            if (!cards || cards.filter(item => item.text === keyword).length === 0) {
                const newCards = [...cards]
                newCards.push({
                    id: new Date().getTime(),
                    text: keyword,
                })
                setCards(newCards)
                setKeyword('')
            }
        }
    }

    const handleSave = () => {
        const cardTexts = []
        cards.map(item => {
            cardTexts.push(item.text)
        })

        const tags = cardTexts.reduce((previousValue, currentValue) => previousValue + (previousValue ? ";" : "") + currentValue, "")

        dispatch({
            type: ART_TA_SAVE,
            payload: {
                artId,
                tags
            }
        })
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            
            apiObject.post('/artta/list', { artId: artId }, axiosConfig).then(response => {
                if (response?.status === 200) {
                    const newCards = []
                    response.data?.data?.list_data.map(item => {
                        newCards.push({
                            id: item.taid,
                            text: item.ta,
                        })
                    })
                    setCards(newCards)
                }
            })
        }

        return () => mounted = false
    }, [artId])

    return (
        <>
            <button onClick={handleSave} className="btn btn-primary m-2">Save</button>
            {
                cards &&
                <div className='theme-dark-bg text-dark' style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
            }
            <div className='flex-start'>
                <input
                    className='form-control ti-tag w200 text-grey-500 mb-0 mt-0'
                    type="text"
                    placeholder="Enter a Tag..."
                    value={keyword}
                    onChange={handleKeywordChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
            {artTa.loading && <Load />}
            {
                cards && keyword.length > 0 &&
                <div>
                    {
                        artTa.data?.list_data?.filter(item => {
                            const notInList = cards?.filter(card => card.id === item.taid)
                            return (
                                notInList.length === 0 &&
                                item.ta.toUpperCase().indexOf(keyword.toUpperCase()) > -1
                            )
                        }).map((suggest, key) => {
                            return (
                                <div key={key} onClick={() => handleClickSuggest({
                                    id: suggest.taid,
                                    text: suggest.ta,
                                })}
                                    className='ti-tag w200 text-grey-500 mb-0 mt-0'
                                    style={{ cursor: 'pointer' }}
                                >
                                    {suggest.ta}
                                </div>
                            )
                        })
                    }
                </div>
            }
        </>
    )
}
const mapStateToProps = state => {
    return {
        artTa: state.artTa
    }
}
const ConnectedContainer = connect(mapStateToProps)(Container)
export { ConnectedContainer as Container }