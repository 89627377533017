const drawingCode = () => {
    self.onmessage = function (e) {
        const width = e.data
        let resultFrames = []
        const offscreen = new OffscreenCanvas(width, width)
        const ctx = offscreen.getContext('2d')

        for (let i = 1; i <= 36; i++) {
            drawSphere(i)
            resultFrames.push(offscreen.transferToImageBitmap())
            ctx.clearRect(0, 0, width, width)
            self.postMessage({
                frame: i,
                result: null,
            })
        }
        self.postMessage({
            message: `All done`,
            result: resultFrames,
        })

        function drawSphere(i) {
            var w = width
            var eleft = 50
            var ang1 = -30
            var ang2 = 240
            var param = 4
            var startH = i * 10
            var abRatio = 0.5
            var step = 2
            var d1, d2
            let a = (w - 2 * eleft) / 2
            var b = 0
            if (abRatio === 0) {
                if (!noratio) {
                    while (b < a / 2 || b > a * 3 / 5)
                        b = Math.random() * a
                    abRatio = b / a
                } else {
                    abRatio = 0
                    b = 0
                }
            } else b = a * abRatio
            let etop = w / 2 - b
            let tiltAng = Math.asin(b / a) // the tilt angle
            let sinOfTiltAng = Math.sin(tiltAng)
            let cosOfTiltAng = Math.cos(tiltAng)

            let rY = a * Math.cos(tiltAng) // the projection height of the axis rY (in viewer's eye)
            var higher = 0 // running radius projection
            var eleft1, etop1, a1, b1
            var t, x1, y1, x2, y2, fi, i
            var fx = w / 2 // tâm x
            var H, S, L
            var x_, y_
            var ang1xMax, ang1yMax, ang2xMax, ang2yMax
            let hRandom = 0
            hRandom = Math.floor(Math.random() * 360)
            if (startH >= 0) hRandom = startH
            if (startH == -1000) {
                hRandom = Math.floor(Math.random() * 360)
            }
            ctx.lineWidth = 2

            switch (param) {
                case 1:
                    d1 = -rY
                    d2 = 0
                    break
                case 2:
                    d1 = 0
                    d2 = rY
                    break
                case 3:
                    d1 = -rY * 2 / 3
                    d2 = rY * 2 / 3
                    break
                case 4:
                    d1 = -rY
                    d2 = rY
                    break
                case 5:
                    d1 = 0
                    d2 = 0
                    break
                default:
            }
            fi = ang1 / 360 * Math.PI * 2
            ang1xMax = eleft + a + a * Math.cos(fi)
            ang1yMax = etop + b - b * Math.sin(fi)
            fi = ang2 / 360 * Math.PI * 2
            ang2xMax = eleft + a + a * Math.cos(fi)
            ang2yMax = etop + b - b * Math.sin(fi)

            for (higher = d1; higher <= d2; higher += step) {
                L = 50 + 50 * higher / rY
                a1 = Math.sqrt(a * a - (higher / cosOfTiltAng) * (higher / cosOfTiltAng)) //Pythagorean theorem
                eleft1 = eleft + (a - a1)
                b1 = a1 * sinOfTiltAng
                etop1 = w / 2 - (higher + b1) // The top and the left of the Sphere are equal
                S = a1 / a * 100
                t = ang1 // góc toạ độ của hình tròn xạ ảnh (ellipse)
                H = 0
                fi = t / 360 * Math.PI * 2 // góc toạ độ của hình tròn gốc
                x1 = eleft1 + a1 + a1 * Math.cos(fi)
                y1 = etop1 + b1 - b1 * Math.sin(fi)

                for (t = ang1; t <= ang2; t++) {
                    H = t - ang1 + hRandom
                    if (H > 360) H = H - 360
                    if (H < 0) H = H + 360
                    fi = t / 360 * Math.PI * 2
                    x2 = eleft1 + a1 + a1 * Math.cos(fi)
                    y2 = etop1 + b1 - b1 * Math.sin(fi)

                    ctx.strokeStyle = "HSL(" + H + ", " + S + "%, " + L + "%)"

                    ctx.beginPath()
                    ctx.moveTo(x1, y1)
                    ctx.lineTo(x2, y2)
                    ctx.stroke()
                    ctx.closePath()
                    x1 = x2
                    y1 = y2
                }
            }

            ctx.lineWidth = 2

            // The first radius
            x_ = w / 2
            y_ = etop + b
            for (i = 0; i <= 140; i++) {
                x2 = x_ + (ang1xMax - eleft - a) / 100
                y2 = y_ + (ang1yMax - etop - b) / 100
                ctx.strokeStyle = "HSL(" + hRandom + ", " + i + "%, 50%)"

                ctx.beginPath()
                ctx.moveTo(x_, y_)
                ctx.lineTo(x2, y2)
                ctx.stroke()
                ctx.closePath()
                x_ = x2
                y_ = y2
            }

            // The second radius
            x_ = w / 2
            y_ = etop + b
            for (i = 0; i <= 140; i++) {
                x2 = x_ + (ang2xMax - eleft - a) / 100
                y2 = y_ + (ang2yMax - etop - b) / 100
                ctx.strokeStyle = "HSL(" + (ang2 - ang1 + hRandom) + ", " + i + "%, 50%)"

                ctx.beginPath()
                ctx.moveTo(x_, y_)
                ctx.lineTo(x2, y2)
                ctx.stroke()
                ctx.closePath()
                x_ = x2
                y_ = y2
            }

            // Trục đứng
            // Create gradient
            var grd = ctx.createLinearGradient(fx - 1, w / 2 - rY - 30, fx + 1, w / 2 + rY + 10)
            grd.addColorStop(0, "white")
            grd.addColorStop(1, "black")

            // Fill with gradient
            ctx.fillStyle = grd
            ctx.fillRect(fx - 1, w / 2 - rY - 30, 2, w / 2 + rY + 10) // x1, y1, width, height
            ctx.fillStyle = "white"
        }
    }
}
let code = drawingCode.toString()
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))
const blob = new Blob([code], { type: 'application/javascript' })
export const drawing_script = URL.createObjectURL(blob)