import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'

import { put, call } from 'redux-saga/effects'
import { 
    AUTHOR_IMAGE_LIST_REQUEST, 
    AUTHOR_IMAGE_LIST_SUCCESS, 
    AUTHOR_IMAGE_LIST_FAILURE,
    AUTHOR_IMAGE_INSERT_REQUEST, 
    AUTHOR_IMAGE_INSERT_SUCCESS, 
    AUTHOR_IMAGE_INSERT_FAILURE,
    AUTHOR_IMAGE_UPDATE_REQUEST, 
    AUTHOR_IMAGE_UPDATE_SUCCESS, 
    AUTHOR_IMAGE_UPDATE_FAILURE,
    AUTHOR_IMAGE_DELETE_REQUEST, 
    AUTHOR_IMAGE_DELETE_SUCCESS, 
    AUTHOR_IMAGE_DELETE_FAILURE,
} from '../ReduxAction'

export function* getAuthorImageListAsync(action) {
        try {
        action = actionIntercept(action)
        yield put({ type: AUTHOR_IMAGE_LIST_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/authorimage/author-image-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_IMAGE_LIST_SUCCESS, payload: response.data })
        } else {
            yield put({ type: AUTHOR_IMAGE_LIST_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_IMAGE_LIST_FAILURE, payload: error.message })
    }
}

export function* insertAuthorImageAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: AUTHOR_IMAGE_INSERT_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/authorimage/author-image-insert', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_IMAGE_INSERT_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: AUTHOR_IMAGE_INSERT_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_IMAGE_INSERT_FAILURE, payload: error.message })
    }
}

export function* updateAuthorImageAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: AUTHOR_IMAGE_UPDATE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/authorimage/author-image-update', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_IMAGE_UPDATE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: AUTHOR_IMAGE_UPDATE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_IMAGE_UPDATE_FAILURE, payload: error.message })
    }
}

export function* deleteAuthorImageAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: AUTHOR_IMAGE_DELETE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/authorimage/author-image-delete', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: AUTHOR_IMAGE_DELETE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: AUTHOR_IMAGE_DELETE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: AUTHOR_IMAGE_DELETE_FAILURE, payload: error.message })
    }
}