import React from 'react'
import { connect } from 'react-redux'
import { useFormikContext } from 'formik'

const TemplateDropdown = props => {

    const { paragTemplate } = props
    const { setFieldValue } = useFormikContext()

    const handleChange = e => {
        setFieldValue("paragBody", e.target.value)
    }

    return (
        <div>
            <select className='form-control' onChange={handleChange}>
                <option value="0">Select template...</option>
                { paragTemplate.data?.list_data?.map((item, key) => {
                    return (<option key={key} value={item.paragBody}>{item.templateName}</option>)
                })}
            </select>
        </div>
    )

}
const mapStateToProps = state => ({ paragTemplate: state.paragTemplate })
const ConnectedPage = connect(mapStateToProps)(TemplateDropdown)
export { ConnectedPage as TemplateDropdown}