import { CHAT_SET, CHAT_MARK_AS_RED_SET, UPDATE_PARTNER_CHAT_RED_MESSAGES } from '../ReduxAction'
import { PARTNER_CHAT_RED_MESSAGES } from '../../_services/MessageTypes'
import { randomAvatarSrc } from '../../_helpers'

const initialState = []

export const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHAT_SET:
            return [...state, action.payload]
        case CHAT_MARK_AS_RED_SET:
            const redMessages = []
            state.map(item => {
                if (item.userId === action.payload.userId && !item.data.red) {
                    item.data.red = true
                    redMessages.push(item.data.time)
                }
            })
            if (!!redMessages.length) {
                const auth = action.payload.auth
                const hubConnection = action.payload.hubConnection
                const activity = {
                    type: PARTNER_CHAT_RED_MESSAGES,
                    userId: auth.data?.id,
                    user: auth.data.username,
                    avatar: auth.data.avatarUrl || auth.data.googleProfile?.Picture || randomAvatarSrc(),
                    message: `${auth.data?.username} has red some message`,
                    data: {
                        messages: redMessages
                    }
                }
                console.log("CHAT_MARK_AS_RED_SET auth", auth)
                if (hubConnection && hubConnection.state === 'Connected') {
                    console.log("CHAT_MARK_AS_RED_SET activity", activity)
                    hubConnection?.origin?.invoke("SendPrivateMessage", activity, action.payload.username)
                }
            }
        case UPDATE_PARTNER_CHAT_RED_MESSAGES:
            const partnerRedMessages = action.payload.activity?.data?.messages
            const myId = action.payload.myId
            const filter = state.filter(item => item.userId === myId && partnerRedMessages?.indexOf(item.data.time) >= 0)
            const theRest = state.filter(item => !(item.userId === myId && partnerRedMessages?.indexOf(item.data.time) >= 0))
            filter.map(item => {
                console.log(item.data.time)
                item.data.red = true
            })
            return [
                ...theRest,
                ...filter
            ]
        default:
            return state
    }
}