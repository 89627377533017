import React, { useState, useRef, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import DarkButton from './Darkbutton'
import { NAVIGATION_SET, NAV_HEADER_SET } from '../Redux/ReduxAction'
import { randomAvatarSrc } from '../_helpers'
import SignalStateIndicator from '../_components/SignalStateIndicator'
import LoadingText from '../_components/LoadingText'

const Header = props => {
    const [isNoti, setIsNoti] = useState(false)
    const { dispatch, auth, silentlyRT, cat, navigation, navHeader, chatCount } = props
    const expiresInText = new Date(auth.data.expires).toLocaleTimeString()

    const { isOpen } = navigation
    const { keyword, searchActive } = navHeader
    const navHeaderRef = useRef()

    const userAvatar = useMemo(() => {
        if (auth.data?.googleProfile) {
            return auth.data?.googleProfile.Picture
        } else {
            return randomAvatarSrc()
        }
    }, [props.data])

    const toggleOpen = () => {
        dispatch({
            type: NAVIGATION_SET,
            payload: !isOpen
        })
    }
    const closeMenu = () => {
        dispatch({
            type: NAVIGATION_SET,
            payload: false
        })
    }
    const hanleKeywordChange = e => {
        dispatch({
            type: NAV_HEADER_SET,
            payload: {
                ...navHeader,
                keyword: e.target.value
            }
        })
    }
    const toggleActive = () => {
        dispatch({
            type: NAV_HEADER_SET,
            payload: {
                keyword: searchActive ? '' : keyword,
                searchActive: !searchActive
            }
        })
    }
    const toggleisNoti = () => setIsNoti(!isNoti)

    const navClass = isOpen ? " nav-active" : ""
    const buttonClass = isOpen ? " active" : ""
    const searchClass = searchActive ? " show" : ""
    const notiClass = isNoti ? " show" : ""

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (navHeaderRef.current) {
                const rect = navHeaderRef.current.getBoundingClientRect()
                dispatch({
                    type: NAV_HEADER_SET,
                    payload: {
                        ...navHeader,
                        height: rect.height
                    }
                })
            }
        }
        return () => mounted = false
    }, [navHeaderRef])

    return (
        <div className="nav-header bg-white shadow-xs border-0" ref={navHeaderRef}>
            <div className="nav-top">
                <div className='logo-block'>
                    <Link to="/" className='pe-2 h72'>
                        <div>
                            <img src="favicon.ico" alt='MiukaFoto' width={25} />
                        </div>
                        <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0 miuka-text-logo">{`MiukaFoto. `}</span>

                    </Link>
                    <div className='d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500 pl15'>
                        <SignalStateIndicator />
                        {
                            silentlyRT.loading ? <LoadingText text='Renew token' /> : `Token expires at ${expiresInText}`
                        }
                    </div>
                </div>
                <Link to="/onlineusers" className="mob-menu ms-auto me-2 chat-active-btn"><i className="feather-users text-grey-900 font-sm btn-round-md bg-greylight with-notification">
                    {!!chatCount && <span className="badge-above">{chatCount}</span>}
                </i></Link>
                <span onClick={toggleActive} className="me-2 menu-search-icon mob-menu"><i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></span>
                <button onClick={toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
            </div>

            <form action="#" className="float-left header-search ms-3">
                <div className="form-group mb-0 icon-input">
                    <i className="feather-search font-sm text-grey-400"></i>
                    <input
                        type="text"
                        value={navHeader.keyword}
                        onChange={hanleKeywordChange}
                        placeholder="Start typing to search.."
                        className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
                    />
                </div>
            </form>

            <NavLink to="/home" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink to="/defaultstorie" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink to="/defaultvideo" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink to="/defaultgroup" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
            <NavLink to="/shop2" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>

            <span className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`} id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleisNoti}><span className="dot-count bg-warning"></span><i className="feather-bell font-xl text-current"></i></span>

            <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`} aria-labelledby="dropdownMenu3">
                <h4 className="fw-700 font-xss mb-4">Notification</h4>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Hendrix Stamp <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">There are many variations of pass..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>

                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>
            </div>
            <Link to="/defaultmessage" className="p-2 text-center ms-3 menu-icon chat-active-btn"><i className="feather-message-square font-xl text-current"></i></Link>

            <DarkButton />
            <Link to="/account-settings" className="p-0 ms-3 menu-icon">
                <img
                    src={userAvatar}
                    alt="user" className="w40 mt--1"
                />
            </Link>

            <nav className={`navigation scroll-bar ${navClass}`}>
                <div className="container ps-0 pe-0">
                    <div className="nav-content">
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>New </span>Feeds</div>
                            <ul className="mb-1 top-content">
                                <li className="logo d-none d-xl-block d-lg-block"></li>

                                <li onClick={closeMenu}><Link to="/home" className="nav-content-bttn open-font">
                                    <i className={`feather-home bg-red-gradiant btn-round-md me-3`}></i>
                                    <span>Home</span></Link></li>
                                <li onClick={closeMenu}><Link to="/suggested-articles" className="nav-content-bttn open-font">
                                    <i className={`feather-home bg-red-gradiant btn-round-md me-3`}></i>
                                    <span>Suggested Articles</span></Link></li>
                                {
                                    auth.data && cat.data?.list_data?.map((item, key) => {
                                        return (
                                            <li key={key} onClick={closeMenu}><Link to="/articles" state={{ cat: item }} className="nav-content-bttn open-font">
                                                <i className={`${item.featherClass} btn-round-md me-3`}></i>
                                                <span>{item.category}</span></Link></li>
                                        )
                                    })
                                }

                            </ul>
                        </div>

                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Admin </span>Pages</div>
                            <ul className="mb-3">
                                <li><Link to="/defaultemailbox" className="nav-content-bttn open-font"><i className="font-xl text-current feather-inbox me-3"></i><span>Email Box</span><span className="circle-count bg-warning mt-1">584</span></Link></li>
                                <li onClick={closeMenu}><Link to="/mp3-recorder" className="nav-content-bttn open-font"><i className="font-xl text-current feather-mic me-3"></i><span>Mp3 Recorder</span></Link></li>
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/categories" className="nav-content-bttn open-font"><i className="font-xl text-current feather-flag me-3"></i><span>Category List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/upload-file" className="nav-content-bttn open-font"><i className="font-xl text-current feather-upload me-3"></i><span>Upload File</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/image-list" className="nav-content-bttn open-font"><i className="font-xl text-current feather-image me-3"></i><span>Image List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/image-offline-list" className="nav-content-bttn open-font"><i className="font-xl text-current feather-eye-off me-3"></i><span>Offline Image List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/audio-list" className="nav-content-bttn open-font"><i className="font-xl text-current feather-headphones me-3"></i><span>Audio List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/parag-template-list" className="nav-content-bttn open-font"><i className="font-xl text-current feather-copy me-3"></i><span>Paragraph Template List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/art-history" className="nav-content-bttn open-font"><i className="font-xl text-current feather-book me-3"></i><span>History</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/art-time" className="nav-content-bttn open-font"><i className="font-xl text-current feather-map-pin me-3"></i><span>Art Time</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/authors" className="nav-content-bttn open-font"><i className="font-xl text-current feather-user me-3"></i><span>Author List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/quotes" className="nav-content-bttn open-font"><i className="font-xl text-current feather-message-circle me-3"></i><span>Quote List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/authorimages" className="nav-content-bttn open-font"><i className="font-xl text-current feather-heart me-3"></i><span>Author Image List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/albums" className="nav-content-bttn open-font"><i className="font-xl text-current feather-folder me-3"></i><span>Album List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/pictures" className="nav-content-bttn open-font"><i className="font-xl text-current feather-aperture me-3"></i><span>Picture List</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/reduxstore" className="nav-content-bttn open-font"><i className="font-xl text-current feather-eye me-3"></i><span>Redux Store</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/flowchart" className="nav-content-bttn open-font"><i className="font-xl text-current ti-direction me-3"></i><span>Flow Chart</span></Link></li>}
                                {auth.data?.isAdmin && <li onClick={closeMenu}><Link to="/firebase-sdk" className="nav-content-bttn open-font"><i className="font-xl text-current ti-announcement me-3"></i><span>Firebase SDK</span></Link></li>}
                                <li><Link to="/defaultevent" className="nav-content-bttn open-font"><i className="font-xl text-current feather-map-pin me-3"></i><span>Latest Event</span></Link></li>
                                <li><Link to="/defaultlive" className="nav-content-bttn open-font"><i className="font-xl text-current feather-youtube me-3"></i><span>Live Stream</span></Link></li>
                            </ul>
                        </div>
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span></span> Account</div>
                            <ul className="mb-1">
                                <li className="logo d-none d-xl-block d-lg-block"></li>
                                <li onClick={closeMenu}><Link to="/account-settings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500"></i><span>Settings</span></Link></li>
                                <li><Link to="/defaultanalytics" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-pie-chart me-3 text-grey-500"></i><span>Analytics</span></Link></li>
                                <li><Link to="/defaultmessage" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-message-square me-3 text-grey-500"></i><span>Chat</span><span className="circle-count bg-warning mt-0">23</span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            <div className={`app-header-search ${searchClass}`}>
                <form className="search-form">
                    <div className="form-group searchbox mb-0 border-0 p-1">
                        <input
                            type="text"
                            className="form-control border-0"
                            placeholder="Search..."
                            value={navHeader.keyword}
                            onChange={hanleKeywordChange}
                        />
                        <i className="input-icon">
                            <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
                        </i>
                        <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                            <i className="ti-close font-xs cursor-pointer" onClick={toggleActive}></i>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
        silentlyRT: state.silentlyRT,
        cat: state.cat,
        chatCount: state.chat?.filter(item => item.userId !== state.auth.data?.id && !item.data.red).length,
        navigation: state.navigation,
        navHeader: state.navHeader,
    }
}
const ConnectedHeader = connect(mapStateToProps)(Header)
export { ConnectedHeader as Header }