import { store } from '../index'
import { CALL_OFFER } from '../_services'
import { randomAvatarSrc } from '../_helpers'
import { LOCAL_DESCRIPTION_SET, CALL_ANSWER_PENDING_SET } from '../Redux/ReduxAction'

export const createOffer = async ({ peerConnection, calleeId, calleeName }) => {

    const { auth, hubConnection } = store.getState()
    const offer = await peerConnection.createOffer()
    await peerConnection.setLocalDescription(offer)
    
    store.dispatch({
        type: LOCAL_DESCRIPTION_SET,
        payload: peerConnection.localDescription
    })

    store.dispatch({
        type: CALL_ANSWER_PENDING_SET,
        payload: {
            pending: true,
            calleeId
        }
    })

    const activity = {
        type: CALL_OFFER,
        userId: auth?.data?.id,
        user: auth?.data?.username,
        avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
        message: `${auth?.data?.username} offers you a call`,
        data: offer,
    }
    hubConnection.origin?.invoke('SendPrivateMessage', activity, calleeName)
}
