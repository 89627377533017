import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { 
    DEVICE_TOKEN_LIST_REQUEST,
    DEVICE_TOKEN_LIST_SUCCESS,
    DEVICE_TOKEN_LIST_FAILURE,

} from '../ReduxAction/index'

interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
}

export function* deviceTokenList(action: { type: string; payload: any; }) {
    try {
        action = actionIntercept(action)
        yield put({ type: DEVICE_TOKEN_LIST_REQUEST })

        const response: ResponseGenerator = yield call(async () => {
            const res = await apiObject.get('/GoogleFirebase/device-token-list', {
                ...axiosConfig,
                params: action.payload
            })
            return res
        })

        if (response?.status === 200) {
            yield put({ type: DEVICE_TOKEN_LIST_SUCCESS, payload: response.data, })
        } else {
            yield put({ type: DEVICE_TOKEN_LIST_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: DEVICE_TOKEN_LIST_FAILURE, payload: error })
    }
}