import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { MiuzikSideBarContext } from './MiuzikSideBar'
import { NavLink } from 'react-router-dom'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { style } from '../../App/appStyle'

export const RenderChildren = props => {

    const { childrenItems, currentTier, parentKey } = props

    const { menuItemWidth, stepIndent, nodesOpen, setNodesOpen } = useContext(MiuzikSideBarContext)

    const handleClickParentItem = nodeKey => {
        const copy = [...nodesOpen]
        if (nodesOpen.includes(nodeKey)) {
            const filter = copy.filter(item => item !== nodeKey)
            setNodesOpen(filter)
        } else {
            copy.push(nodeKey)
            setNodesOpen(copy)
        }
    }

    return (
        <div>
            {childrenItems.map((item, key) => {
                return (
                    <div key={key}>
                        {item.to &&
                            <div
                                style={{
                                    ...style.menuParentItemContainer,
                                    width: `${menuItemWidth}px`
                                }}
                            >
                                <NavLink to={item.to}>
                                    <div
                                        style={{
                                            ...style.flexContainerFlexStart,
                                            paddingLeft: `${(currentTier - 1) * stepIndent / (isMobile ? 10 : 1)}px`
                                        }}
                                    >
                                        <div
                                            style={{
                                                ...style.menuItemIconWrapper,
                                                backgroundColor: currentTier === 1 ? 'lightgray' : null,
                                                border: currentTier === 1 ? 'solid 2px black' : null,
                                            }}
                                        >
                                            <item.icon />
                                        </div>
                                        <div
                                            style={{
                                                ...style.menuCaption,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {item.caption}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        }
                        {!item.to && !item.onClick &&
                            <div style={{ width: 'fit-content' }}>
                                <div
                                    style={{
                                        ...style.menuParentItemContainer,
                                        width: `${menuItemWidth}px`
                                    }}
                                >
                                    <div
                                        style={{
                                            ...style.flexContainerFlexStart,
                                            paddingLeft: `${(currentTier - 1) * stepIndent / (isMobile ? 10 : 1)}px`,
                                            cursor: `pointer`,
                                        }}
                                        onClick={() => handleClickParentItem(`${parentKey}.${key}`)}
                                    >
                                        <div
                                            style={{
                                                ...style.menuItemIconWrapper,
                                                backgroundColor: currentTier === 1 ? 'lightgray' : null,
                                                border: currentTier === 1 ? 'solid 2px black' : null,
                                            }}
                                        >
                                            <item.icon />
                                        </div>
                                        <div
                                            style={{
                                                ...style.menuCaption,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {item.caption}
                                        </div>
                                    </div>
                                    <div style={style.menuParentItemArrow}>
                                        {nodesOpen.includes(`${parentKey}.${key}`) ?
                                            <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowRight />
                                        }
                                    </div>
                                </div>
                                {item.childrenComponent && nodesOpen.includes(`${parentKey}.${key}`) &&
                                    <div
                                        style={{
                                            marginLeft: `${currentTier * stepIndent}px`
                                        }}
                                    >
                                        {item.childrenComponent}
                                    </div>
                                }
                                {item.children && nodesOpen.includes(`${parentKey}.${key}`) &&
                                    <RenderChildren childrenItems={item.children} currentTier={currentTier + 1} parentKey={`${parentKey}.${key}`} />
                                }
                            </div>
                        }
                        {!item.to && item.onClick &&
                            <div
                                style={{
                                    ...style.menuParentItemContainer,
                                    cursor: 'pointer',
                                    width: `${menuItemWidth}px`,
                                }}
                                onClick={item.onClick}
                            >
                                <div
                                    style={{
                                        ...style.flexContainerFlexStart,
                                        paddingLeft: `${(currentTier - 1) * stepIndent / (isMobile ? 10 : 1)}px`
                                    }}
                                >
                                    <div
                                        style={{
                                            ...style.menuItemIconWrapper,
                                            backgroundColor: currentTier === 1 ? 'lightgray' : null,
                                            border: currentTier === 1 ? 'solid 2px black' : null,
                                        }}
                                    >
                                        <item.icon />
                                    </div>
                                    <div
                                        style={{
                                            ...style.menuCaption,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {item.caption}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    )
}