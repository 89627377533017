import { height } from '@mui/system';
import React from 'react'

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '', errorInfo: '' }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error: error }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
    }

    render() {
        if (this.state.hasError) {

            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh)',
                }}>
                    <div className='text-gray'>Something went wrong</div>
                </div>
            )
        }
        return this.props.children
    }
}