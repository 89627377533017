import React, { Fragment } from "react"

import { Header } from '../components/Header'
import Leftnav from '../components/Leftnav'
import Rightchat from '../components/Rightchat'
import { Appfooter } from '../components/Appfooter'
import Popupchat from '../components/Popupchat'


import Friends from '../components/Friends'
import Contacts from '../components/Contacts'
import Group from '../components/Group'
import Events from '../components/Events'
import Createpost from '../components/Createpost'
import Memberslider from '../components/Memberslider'
import Friendsilder from '../components/Friendsilder'
import Storyslider from '../components/Storyslider'
import Postview from '../components/Postview'
import QuoteView from '../components/QuoteView'
import Load from '../components/Load'
import Profilephoto from '../components/Profilephoto'

import { randomAvatarSrc, randomImageSrc } from "../_helpers"

export const Home = () => {

    document.title = 'Home'

    return (
        <Fragment>
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row feed-body">
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <Storyslider />
                                <Createpost />
                                <QuoteView id="32" postvideo="" postimage={randomImageSrc(1200, 800)} avater={randomAvatarSrc()} user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                <Postview id="32" postvideo="" postimage={randomImageSrc(1200, 800)} avater={randomAvatarSrc()} user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                <Postview id="31" postvideo="" postimage={randomImageSrc(1200, 800)} avater={randomAvatarSrc()} user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                <Postview id="33" postvideo="" postimage={randomImageSrc(1200, 800)} avater={randomAvatarSrc()} user="Anthony Daugloi" time="2 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                <Memberslider />
                                <Postview id="35" postvideo="" postimage={randomImageSrc(1200, 800)} avater={randomAvatarSrc()} user="Victor Exrixon" time="3 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                <Friendsilder />
                                <Postview id="36" postvideo="" postimage={randomImageSrc(1200, 800)} avater={randomAvatarSrc()} user="Victor Exrixon" time="12 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                <Load />
                                <div className="col-md-12">
                                    <div className="fb-like" data-href="http://miukafoto.com" data-layout="box_count" data-action="like" data-size="small" data-show-faces="true" data-share="true" style={{ maxWidth: '100%' }}></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                <Friends />
                                <Contacts />
                                <Group />
                                <Events />
                                <Profilephoto />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popupchat />
            <Appfooter />
        </Fragment>
    )
}

export default Home