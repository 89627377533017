import React, { useEffect, useRef } from 'react'

export const DavidNovick = () => {

    const gray = useRef()
    const censor = useRef()

    const y1 = useRef(Math.floor(Math.random() * 254))
    const setY1 = value => y1.current = value
    const y2 = useRef(Math.floor(Math.random() * 254))
    const setY2 = value => y2.current = value
    const y3 = useRef(Math.floor(Math.random() * 254))
    const setY3 = value => y3.current = value

    const di1 = useRef(1)
    const setDi1 = value => di1.current = value
    const di2 = useRef(1)
    const setDi2 = value => di2.current = value
    const di3 = useRef(1)
    const setDi3 = value => di3.current = value


    function drawIllustration2() {
        let f = gray.current
        let c1 = f.getContext('2d')
        let h = 5
        loadCensor(c1, h)

        function loadCensor(ctx, h) {
            ctx.lineWidth = 0
            ctx.strokeStyle = "transparent"
            let k = 0, d = 0

            while (k < 254) {
                ctx.fillStyle = "HSL(" + d + ", 100%, 50%)"
                ctx.fillRect(0, k, 380, h)
                k += h
                if (d == 0) d = 120
                else if (d == 120) d = 240
                else d = 0
            }
        }
    }

    useEffect(() => {
        drawIllustration2()
    }, [])

    useEffect(() => {
        const c2 = censor.current.getContext('2d')

        function loadBall(ctx, x, y, r, h = 5, color) {
            ctx.fillStyle = 'lightgray'
            ctx.beginPath()
            ctx.arc(x, y, r, 0, 2 * Math.PI)
            ctx.fill()
            ctx.closePath()

            let d = 0, k = 0
            ctx.beginPath()
            let y0 = y - r
            let n = (y0 - (y0 % h)) / h
            if ((n % 3) == 0) d = 0
            else if ((n % 3) == 1) d = 120
            else if ((n % 3) == 2) d = 240

            k = n * h
            while (k < y + r) {
                if (d == color) {
                    ctx.fillStyle = "HSL(" + d + ", 100%, 50%)"
                    ctx.fillRect(x - r, k, 2 * r, h)
                }
                k += h
                if (d == 0) d = 120
                else if (d == 120) d = 240
                else d = 0
            }

            ctx.closePath()
        }

        function loop(h = 5) {
            c2.clearRect(0, 0, 380, 330)
            loadBall(c2, 75, y1.current, 50, h, 0)
            loadBall(c2, 190, y2.current, 50, h, 120)
            loadBall(c2, 305, y3.current, 50, h, 240)
            setY1(y1.current + di1.current)
            setY2(y2.current + di2.current)
            setY3(y3.current + di3.current)
            if (y1.current < 50) {
                setY1(50)
                setDi1(1)
            } else if (y1.current > 254 - 50) {
                setY1(254 - 50)
                setDi1(-1)
            }
            if (y2.current < 50) {
                setY2(50)
                setDi2(1)
            } else if (y2.current > 254 - 50) {
                setY2(254 - 50)
                setDi2(-1)
            }
            if (y3.current < 50) {
                setY3(50)
                setDi3(1)
            } else if (y3.current > 254 - 50) {
                setY3(254 - 50)
                setDi3(-1)
            }
        }

        const id = setInterval(loop, 10)

        return () => clearInterval(id)

    }, [])

    return (
        <div style={{ textIndent: 0, width: 381, height: 254 }}>
            <canvas ref={gray} width={381} height={254} style={{ position: 'absolute' }} >
            </canvas>
            <canvas ref={censor} width={381} height={254} style={{ position: 'absolute' }} >
            </canvas>
        </div>
    )
}
