import React, { useCallback } from 'react'
import { useStore, getEdgeCenter, getSmoothStepPath } from 'react-flow-renderer'
import { EdgeMenu } from './EdgeMenu'
import { getEdgeParams } from './utils'
import './style.css'

const foreignObjectSize = 40

export const FloatButtonEdge = props => {
    const {
        id,
        source,
        target,
        style = { "stroke": "green", "strokeWidth": "4" },
        markerEnd,
    } = props

    const sourceNode = useStore(useCallback(store => store.nodeInternals.get(source), [source]))
    const targetNode = useStore(useCallback(store => store.nodeInternals.get(target), [target]))

    if (!sourceNode || !targetNode) {
        return null
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode)

    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX: sx,
        sourceY: sy,
        targetX: tx,
        targetY: ty,
    })

    const d = getSmoothStepPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
    })

    return (
        <>
            <g className="react-flow__connection">
                <path
                    id={id}
                    className="react-flow__edge-path"
                    d={d}
                    markerEnd={markerEnd}
                    style={style}
                />
            </g>
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                {/* eslint-disable-next-line */}
                <body>
                    <EdgeMenu {...props} />
                </body>
            </foreignObject>
        </>
    )
}
