import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

export const SwaggerPage = () => {
    return (
        <>
            <h1>SwaggerUI</h1>
            <SwaggerUI url={`${window.location.origin}/swagger/v1/swagger.json`}/>
        </>
    )
}