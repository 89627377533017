import React, { useState, useContext } from 'react'
import { Handle } from 'react-flow-renderer'
import { NodeMenu } from './NodeMenu'
import { Context } from './Context'
import './shapes.css'

const renderHandlers = id => {
    return (
        <>
            <Handle
                type="source"
                position="right"
                id={`${id}.right`}
                className='flow-source-right'
                style={{
                    width: '20px',
                    height: '20px',
                }}
            />
            <Handle
                type="target"
                position="left"
                id={`${id}.left`}
                className='flow-target-left'
                style={{
                    width: '20px',
                    height: '20px',
                }}
            />
        </>
    )
}

const Bugger = props => {
    const { showMenu } = props
    return <>
        {showMenu && <NodeMenu {...props} />}
    </>
}

const Node = props => {
    const { data, id, className } = props
    const [showMenu, setShowMenu] = useState(false)

    const { nodrag, setNodrag } = useContext(Context)

    return (
        <div className={`${className} flex${nodrag ? ' nodrag' : ''}`}>
            <label
                onClick={() => setShowMenu(!showMenu)}
                onMouseOver={() => setNodrag(true)}
                onMouseLeave={() => setNodrag(false)}
                style={{ cursor: 'pointer' }}
            >
                {data.label}
            </label>
            <Bugger {...props} showMenu={showMenu} setShowMenu={setShowMenu} />
            {renderHandlers(id)}
        </div>
    )
}

export const Start = props => <Node {...props} className='start' />
export const Process = props => <Node {...props} className='process' />
export const Decision = props => <Node {...props} className='decision' />
export const Input = props => <Node {...props} className='input' />
export const Output = props => <Node {...props} className='output' />
export const Connector = props => <Node {...props} className='connector' />
export const Stop = props => <Node {...props} className='stop' />

export const nodeTypes = {
    startNode: Start,
    processNode: Process,
    decisionNode: Decision,
    inputNode: Input,
    outputNode: Output,
    connectorNode: Connector,
    stopNode: Stop,
}

export const nodeTypeColors = {
    startNode: '#4b8c62',
    processNode: '#06768a',
    decisionNode: '#c46f01',
    inputNode: '#a01f5e',
    outputNode: '#9c7085',
    connectorNode: '#2a9d48',
    stopNode: '#4b8c62',
}