import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { 
    FCM_SEND_NOTIFICATION_REQUEST,
    FCM_SEND_NOTIFICATION_SUCCESS,
    FCM_SEND_NOTIFICATION_FAILURE,
} from '../ReduxAction'

interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
}

export function* fcmSendNotification(action: { type: string; payload: any; }) {
    try {
        action = actionIntercept(action)
        yield put({ type: FCM_SEND_NOTIFICATION_REQUEST })

        const response: ResponseGenerator = yield call(async () => {
            const res = await apiObject.post('/googlefirebase/send-notification', action.payload, axiosConfig)
            return res
        })

        if (response?.status === 200) {
            yield put({ type: FCM_SEND_NOTIFICATION_SUCCESS, payload: response.data, })
        } else {
            yield put({ type: FCM_SEND_NOTIFICATION_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: FCM_SEND_NOTIFICATION_FAILURE, payload: error })
    }
}

