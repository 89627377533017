import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GoogleLogin } from 'react-google-login'
import { GOOGLE_LOGIN } from '../Redux/ReduxSaga/triggers'
import { GOOGLE_LOGIN_FAILURE } from '../Redux/ReduxAction'

export const googleClientId = '520974910345-960dha19n9all640nvlektujh0k6bbuq.apps.googleusercontent.com'

const getUserConfigRememberGoogleLogin = () => {
    const userConfig = localStorage.getItem('userConfig')
    if (userConfig) {
        const rememberGoogleLogin = JSON.parse(userConfig)?.rememberGoogleLogin
        return rememberGoogleLogin || false
    } else {
        return false
    }
}

const saveUserConfigRememberGoogleLogin = remember => {
    const currentUserConfig = localStorage.getItem('userConfig')
    if (currentUserConfig) {
        const newUserConfig = JSON.parse(currentUserConfig)
        newUserConfig.rememberGoogleLogin = remember
        localStorage.setItem('userConfig', JSON.stringify(newUserConfig))
    } else {
        localStorage.setItem('userConfig', JSON.stringify({ rememberGoogleLogin: remember }))
    }
}

export const LoginViaGoogle = props => {
    const [isSignedIn, setIsSignedIn] = useState(null)
    const { remember } = props
    const dispatch = useDispatch()

    const handleGoogleSuccess = response => {
        dispatch({
            type: GOOGLE_LOGIN,
            payload: {
                tokenId: response.tokenId,
                rememberMe: remember,
                profileObj: response.profileObj,
            },
        })
    }

    const handleGoogleFailure = response => {
        dispatch({
            type: GOOGLE_LOGIN_FAILURE,
            payload: response.data
        })
    }

    useEffect(() => {

        let mounted = true
        if (mounted) {
            const rememberGoogleLogin = getUserConfigRememberGoogleLogin()

            setIsSignedIn(rememberGoogleLogin)
        }

        return () => mounted = false
    }, [])

    return (
        <>
            {
                isSignedIn !== null &&
                <GoogleLogin
                    clientId={googleClientId}
                    render={props => {
                        return (
                            <button onClick={() => {
                                saveUserConfigRememberGoogleLogin(remember)
                                props.onClick()
                            }} disabled={props.disabled} className='form-control text-left style2-input text-white fw-300 bg-facebook border-0 p-0 mb-2'><img src='assets/images/icon-1.png' alt='icon' className='ms-2 w40 mb-1 me-5' />
                                Đăng nhập với Google
                            </button>
                        )
                    }}
                    onSuccess={handleGoogleSuccess}
                    onFailure={handleGoogleFailure}
                    cookiePolicy='single_host_origin'
                    isSignedIn={isSignedIn}
                />

            }
        </>
    )
}