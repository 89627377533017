import React, { useState } from 'react'
import { connect } from 'react-redux'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const Profilephoto = props => {
    const [tab1, setTab1] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const { picture } = props
    const TabOne = picture?.data?.list_data || []

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center  p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Photos</h4>
                <a href="/home" className="fw-600 ms-auto font-xssss text-primary">See all</a>
            </div>
            <div className="card-body d-block pt-0 pb-2">
                <div className="row ps-3 pe-3">
                    {TabOne.map((item, key) => (
                        <div className="col-6 mb-1 p-1" key={key}>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={TabOne[tab1].picture_source}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                        setTab1((tab1 + TabOne.length - 1) % TabOne.length)
                                    }
                                    onMoveNextRequest={() => setTab1((tab1 + 1) % TabOne.length)}
                                />
                            )}

                            <div onClick={() => {
                                setIsOpen(true)
                                setTab1(key)
                            }}>
                                <a href="#portfolio-details">
                                    <img src={`${item.picture_source}`} alt="Portfolio" className="img-fluid rounded-3 w-100" />
                                </a>

                            </div>

                        </div>
                    ))}
                </div>
            </div>
            <div className="card-body d-block w-100 pt-0">
                <a href="/home" className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl"><i className="feather-external-link font-xss me-2"></i> More</a>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        picture: state.picture
    }
}
const ConnectedProfilephoto = connect(mapStateToProps)(Profilephoto)
export default ConnectedProfilephoto