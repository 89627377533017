import {
    INITIAL_SCREEN_SET,
} from '../ReduxAction'

const initialState = 'Login'

export const initialScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIAL_SCREEN_SET:
            return action.payload
        default:
            return state
    }
}