import React, { createRef, forwardRef } from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker'
import { FiCalendar, FiClock } from 'react-icons/fi'
import 'react-datepicker/dist/react-datepicker.css'
import vi from 'date-fns/locale/vi'
import en from 'date-fns/locale/en-US'
import './style.css'
registerLocale('vi', vi)
registerLocale('en', en)


const DatePickerCustomInput = forwardRef((props, ref) => {
  return (
    <div className='dpci-holder' ref={ref} >
      <input
        {...props}
        type='text'
        autoComplete='off'
        className='form-control' />
      <FiCalendar className='date-icon' size={22} onClick={props.onClick} cursor='pointer' />
    </div>
  )
})

const TimePickerCustomInput = forwardRef((props, ref) => {
  return (
    <div className='dpci-holder' ref={ref} >
      <input
        {...props}
        type='text'
        autoComplete='off'
        className='form-control' />
      <FiClock className='date-icon' size={22} onClick={props.onClick} cursor='pointer' />
    </div>
  )
})

export const DatePickerContainer = (props) => {
  return (
    <div className='datepicker-container' style={{ backgroundColor: 'white' }}>
      <CalendarContainer className='children-wrapper'>
        {props.children}
      </CalendarContainer>
    </div>
  )
}

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  const ref = createRef()

  return (
    <DatePicker
      {...field}
      {...props}
      dateFormat='dd/MM/yyyy'
      customInput={<DatePickerCustomInput ref={ref} />}
      calendarContainer={DatePickerContainer}
      locale='vi-VI'
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val)
      }}
    />
  )
}

export const TimePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  const ref = createRef()

  return (
    <DatePicker
      {...field}
      {...props}
      timeFormat="HH:mm"
      dateFormat="HH:mm"

      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption='Time'

      customInput={<TimePickerCustomInput ref={ref} />}
      calendarContainer={DatePickerContainer}
      locale='vi-VI'
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val)
      }}
    />
  )
}

export const DatePickerInput = ({ ...props }) => {

  const ref = createRef()

  return (
    <DatePicker
      {...props}
      dateFormat='dd/MM/yyyy'
      customInput={<DatePickerCustomInput ref={ref} />}
      calendarContainer={DatePickerContainer}
      locale='vi-VI'
      onChange={val => props.onChange(val)}
    />
  )
}