import React, { useEffect, useRef } from 'react'

export const ReflectModel = () => {

    const genDiv = useRef()
    const vCanvas = useRef()
    const staticCanvas = useRef()
    const rays = useRef()
    const reflection = useRef()
    const myCanvas = useRef()


    function DrawExam19() {
        var ctx = myCanvas.current.getContext('2d');
        var scx = staticCanvas.current.getContext('2d');
        var r = rays.current.getContext('2d');
        var rf = reflection.current.getContext('2d');
        var i;

        scx.beginPath();

        scx.arc(248, 62, 10, 0, 2 * Math.PI);

        scx.moveTo(12, 320);
        scx.lineTo(12, 120);
        scx.lineTo(50, 130);
        scx.lineTo(50, 220);
        scx.lineTo(12, 320);
        scx.lineTo(150, 320);
        scx.lineTo(110, 220);
        scx.lineTo(50, 220);

        scx.stroke();
        scx.closePath();

        scx.strokeStyle = "pink";
        scx.fillStyle = "RGBA(255, 0, 0, 0.9)";
        scx.beginPath();
        scx.moveTo(60, 260);
        scx.bezierCurveTo(60, 280, 100, 280, 100, 260);
        scx.lineTo(100, 200);
        scx.lineTo(90, 190);
        scx.moveTo(90, 190);
        scx.bezierCurveTo(90, 195, 70, 195, 70, 190);
        scx.lineTo(60, 200);
        scx.lineTo(60, 260);
        scx.stroke();
        scx.fill();
        scx.closePath();

        scx.strokeStyle = "lightblue";
        scx.fillStyle = "blue";
        scx.beginPath();

        scx.moveTo(90, 190);
        scx.bezierCurveTo(90, 195, 70, 195, 70, 190);
        scx.lineTo(70, 150);
        scx.bezierCurveTo(70, 155, 90, 155, 90, 150);

        scx.stroke();
        scx.fill();
        scx.closePath();

        scx.strokeStyle = "blue";
        scx.fillStyle = "blue";
        scx.beginPath();

        scx.moveTo(90, 150);
        scx.bezierCurveTo(90, 145, 70, 145, 70, 150);
        scx.bezierCurveTo(70, 155, 90, 155, 90, 150);

        scx.stroke();
        scx.fill();
        scx.closePath();

        var img = new Image();
        img.addEventListener("load", function () {
            scx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 210, 280, 845 / 7, 561 / 7 );
        }, false);

        scx.font = "12px Tahoma";
        ctx.font = "12px Tahoma";
        scx.fillStyle = "black";
        scx.fillText("1", 250, 39);
        scx.fillText("2", 81, 126);
        ctx.fillText("3", 236, 306);
        ctx.fillText("4", 319, 303);
        scx.fillText("5", 355, 155);

        genDiv.current.style.background = "white";
        var img1 = new Image();
        img1.addEventListener("load", function () {
            scx.drawImage(img1, 0, 0, img1.naturalWidth, img1.naturalHeight, 250, 140, img1.naturalWidth / 7, img1.naturalHeight / 7);
        }, false);
        img1.src =
            "/Content/shared_pictures/20190708062859_Screenshot_20190708-102227-01.jpeg";

        ctx.font = "10px Tahoma";

        var ray = setInterval(loop1, 300);
        var phanxa = setInterval(loop2, 100);

        function loop1() {
            r.clearRect(0, 0, 380, 380);
            let rainbow = [
                "Red", "Orange", "Yellow", "Green", "Blue", "Indigo", "Violet"
            ];
            let n = rainbow.length;
            for (i = 0; i < n; i++) {
                r.beginPath();
                r.strokeStyle = rainbow[Math.floor(Math.random() * 7)];
                r.moveTo(248, 62);
                r.lineTo(103, 138 + 20 * i);
                r.stroke();
                r.closePath();
            }
        }

        function loop2() {
            rf.clearRect(0, 0, 380, 380);

            rf.beginPath();
            rf.strokeStyle = "blue";
            rf.moveTo(103, 151);
            rf.setLineDash([Math.random() * 3, Math.random() * 5]);
            rf.lineTo(246, 190);
            rf.stroke();
            rf.closePath();

            rf.beginPath();
            rf.strokeStyle = "red";
            rf.moveTo(103, 270);
            rf.setLineDash([Math.random() * 30, Math.random() * 50]);
            rf.lineTo(246, 220);
            rf.stroke();
            rf.closePath();
        }
    }

    useEffect(() => {
        DrawExam19();
    }, [])

    return (
        <>
            <div ref={genDiv} style={{ width: 380, height: 380, background: '#F9FEF7' }}>
                <canvas ref={vCanvas} width={380} height={380} style={{ position: 'absolute', background: 'transparent' }}></canvas>
                <canvas ref={staticCanvas} width={380} height={380} style={{ position: 'absolute', background: 'transparent' }}></canvas>
                <canvas ref={rays} width={380} height={380} style={{ position: 'absolute', background: 'transparent' }} ></canvas >
                <canvas ref={reflection} width={380} height={380} style={{ position: 'absolute', background: 'transparent' }}></canvas>
                <canvas ref={myCanvas} width={380} height={380} style={{ position: 'absolute', background: 'transparent' }}></canvas >
            </div >
            <div style={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                Các điều kiện cần thiết để nhận thức một đối tượng màu đỏ có phần trên màu lam. Cần có nguồn sáng, không gian được chiếu sáng và một người quan sát có tầm nhìn màu sắc tương đối bình thường. Sơ đồ cũng chỉ ra sự hình thành của hình ảnh trên võng mạc, vị trí của vỏ não, các cơ và tuyến.
            </div>
        </>
    )
}