import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { WavePlayer } from '../WavePlayer'
import { apiObject, axiosConfig } from '../../Constants'
import Pagetitle from '../../components/Pagetitle'
import { AUDIO_LIST } from '../../Redux/ReduxSaga/triggers'

export const AudioView = () => {
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [paragList, setParagList] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { path, title } = location.state

    const handleClickLocation = () => {
        
        apiObject.post('/file/audio-location', { fileName: title }, axiosConfig).then(response => {
            if (response?.status === 200) {
                setParagList(response.data.paragList)
                if (response.data.paragList.length) {
                    setMessage('')
                } else {
                    setMessage('No Location Found')
                }
            } else {
                setMessage(JSON.stringify(response.originalError, null, 2))
            }
        })
    }

    const handleClickDelete = () => {
        
        apiObject.post('/file/delete-audio', { fileName: title }, axiosConfig).then(response => {
            if (response?.status === 200) {
                setMessage('Deleted!')
                dispatch({ type: AUDIO_LIST })
                navigate('/audio-list')

            } else {
                setMessage(JSON.stringify(response.originalError, null, 2))
            }
        })
    }

    const handleClickDownload = () => {

    }

    const renderParagList = () => {
        return (
            <div style={{ paddingTop: '20px', wordWrap: 'break-word', }}>{paragList.map((item, key) => {
                return (
                    <Link key={key} to='/parag-detail' state={{ id: item.paragID, artID: item.artID }}>
                        <div>
                            <div>{item.paragBody}</div>
                            <div>{item.paragImgLink}</div>
                        </div>
                    </Link>
                )
            })
            }
            </div>
        )
    }

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">
                            <Pagetitle title="Audio viewer" />
                            <WavePlayer path={path} title={title} handleReady={() => setLoading(false)} />
                            {!loading &&
                                <div className='flex-between' style={{ paddingTop: '50px' }}>
                                    <button className='btn btn-secondary' onClick={handleClickDownload}>Download</button>
                                    <button className='btn btn-primary' onClick={handleClickLocation}>Location</button>
                                    {
                                        paragList
                                        && !paragList?.length
                                        && <button className='btn btn-danger' onClick={handleClickDelete}>Delete</button>
                                    }
                                </div>
                            }
                            {message.length > 0 && <div style={{ paddingTop: '20px' }}>{message}</div>}
                            {paragList && paragList?.length > 0 && renderParagList()}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}