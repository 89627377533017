import { store } from '../index'
import { CALL_ANSWER } from '../_services'
import { randomAvatarSrc } from '../_helpers'
import { LOCAL_DESCRIPTION_SET } from '../Redux/ReduxAction'

export const createAnswer = async ({peerConnection, callerName }) => {
    const { auth, hubConnection } = store.getState()
    const answer = await peerConnection.createAnswer()
    await peerConnection.setLocalDescription(answer)

    store.dispatch({
        type: LOCAL_DESCRIPTION_SET,
        payload: peerConnection.localDescription
    })

    const activity = {
        type: CALL_ANSWER,
        userId: auth?.data?.id,
        user: auth?.data?.username,
        avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
        message: `${auth?.data?.username} answer your offer`,
        data: answer,
    }
    await hubConnection.origin?.invoke('SendPrivateMessage', activity, callerName)
}
