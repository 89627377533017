import React, { useState, useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Formik, Form, Field, useFormikContext } from 'formik'
import { Validation } from './Validation'
import { EditModeIndicator } from '../_components'
import ImagePicker from '../_components/ImagePicker'
import AudioPicker from '../_components/AudioPicker'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Pagetitle from '../components/Pagetitle'
import { TemplateDropdown } from './TemplateDropdown'
import { PARAG_INSERT, PARAG_UPDATE, PARAG_DELETE } from '../Redux/ReduxSaga/triggers'

const FormikContextArea = ({ sector }) => {

	const { submitForm, errors, values, dirty, setFieldValue } = useFormikContext()

	const handleClickSubmit = () => {
		if (Object.keys(errors).length > 0) {
			alert(JSON.stringify(errors, null, 2))
		} else {
			submitForm()
		}
	}

	const getSelectedRange = useCallback(() => {
		let textRange
		if (typeof window.getSelection != "undefined") {
			textRange = window.getSelection()
		} else if (typeof document.selection != "undefined" && document.selection.type == "Text") {
			textRange = document.selection.createRange()
		}
		return textRange
	}, [])

	const hotKeyModify = useCallback(keyCode => {
		const textRange = getSelectedRange()
		const keyword = textRange.toString() || textRange.text
		let newKeyword
		switch (keyCode) {
			case "KeyS":
				submitForm()
				return
			case "KeyC":
				newKeyword = "||1code>" + keyword + "||1/code>"
				break
			case "KeyD":
				newKeyword = "||1code class=lang-dos>" + keyword + "||1/code>"
				break
			case "KeyU":
				newKeyword = "||1u>" + keyword + "||1/u>"
				break
			case "KeyB":
				newKeyword = "||1b>" + keyword + "||1/b>"
				break
			case "KeyI":
				newKeyword = "||1i>" + keyword + "||1/i>"
				break
			default:
				break
		}
		setFieldValue("paragBody", values["paragBody"].replaceAll(keyword, newKeyword))
	}, [values["paragBody"]])


	useEffect(() => {
		function hotKeysListenner(e) {

			if (!e.ctrlKey || !e.altKey || ['KeyS', 'KeyC', 'KeyD', 'KeyU', 'KeyB', 'KeyI'].indexOf(e.code) < 0) return

			if (e.ctrlKey && e.altKey && e.code === "KeyS") {
				submitForm()
			} else {
				hotKeyModify(e.code)
			}

		}

		window.addEventListener('keydown', hotKeysListenner)

		return () => {
			window.removeEventListener('keydown', hotKeysListenner)
		}
	}, [values["paragBody"]])


	return (
		<>
			{sector === 1 &&
				<div className='button-div'>
					<button className='btn btn-primary' type='button' onClick={()=> hotKeyModify("KeyS")}>S</button>
					<button className='btn btn-primary' type='button' onClick={()=> hotKeyModify("KeyC")}>C</button>
					<button className='btn btn-primary' type='button' onClick={()=> hotKeyModify("KeyD")}>D</button>
					<button className='btn btn-primary' type='button' onClick={()=> hotKeyModify("KeyU")}>U</button>
					<button className='btn btn-primary' type='button' onClick={()=> hotKeyModify("KeyB")}>B</button>
					<button className='btn btn-primary' type='button' onClick={()=> hotKeyModify("KeyI")}>I</button>
				</div>
			}

			{sector === 2 &&
				<div className='button-div'>
					<button className='btn btn-primary' type='button' onClick={handleClickSubmit} hidden={!dirty}>{values.paragID ? 'Save' : 'Add'}</button>
				</div>
			}
		</>
	)
}

const ParagDetailPage = props => {
	const location = useLocation()
	const { paragraph, article } = location.state
	const [editMode, setEditMode] = useState(paragraph.paragID ? 'read' : 'add')
	const { dispatch } = props
	const navigate = useNavigate()
	const validationSchema = Validation()

	const beginArea = useRef()
	const initRef = useRef(true)

	useEffect(() => {
		initRef.current = false
	}, [])

	useEffect(() => {
		let mounted = true
		if (mounted && initRef.current) {
			beginArea.current?.scrollIntoView({ behavior: "smooth" })
		}
		return () => mounted = false
	}, [initRef])

	const initialValues = {
		paragID: paragraph.paragID || 0,
		artID: paragraph.artID || 0,
		paragClass: paragraph.paragClass || '',
		paragBody: paragraph.paragBody || '',
		paragImgLink: paragraph.paragImgLink || '',
		paragAlign: paragraph.paragAlign || 0,
		paragQueue: paragraph.paragQueue || 0,
		nobr: paragraph.nobr || false,
		wavesurfer: paragraph.wavesurfer || false,
		wavesurferPath: paragraph.wavesurferPath || '',
		wavesurferTitle: paragraph.wavesurferTitle || '',
		wavesurferDescription: paragraph.wavesurferDescription || '',
		component: paragraph.component || false,
		componentName: paragraph.componentName || '',
		// Dto
		artCaption: article.artCaption || ''
	}

	const handleClickClose = () => {
		navigate('/article', { state: { article } })
	}

	const handleClickEdit = () => {
		if (editMode === 'read') {
			if (article.id) {
				setEditMode('modify')
			} else {
				setEditMode('add')
			}
		} else {
			setEditMode('read')
		}
	}

	const handleSubmitForm = values => {
		if (values.paragID) {
			dispatch({
				type: PARAG_UPDATE,
				payload: values
			})
		} else {
			dispatch({
				type: PARAG_INSERT,
				payload: values
			})
		}
		navigate('/article', { state: { article } })
	}

	return (
		<div className="main-content right-chat-active">
			<div className="middle-sidebar-bottom">
				<div className="middle-sidebar-left">
					<div className="row feed-body">
						<div className="col-lg-12">
							<Pagetitle title="Paragraph detail" />
							<button style={{ margin: '5px' }} className='btn btn-secondary' type='button' onClick={handleClickEdit}>{editMode === 'read' ? 'Edit' : 'X'}</button>

							<Formik
								enableReinitialize
								initialValues={initialValues}
								onSubmit={handleSubmitForm}
								validationSchema={validationSchema}
								validateOnBlur={true}
								validateOnChange={true}
							>
								<Form>
									<div style={{
										paddingLeft: '5px',
										paddingRight: '5px',
									}}>

										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text">Article</label>
													<Field name="artCaption" type="text" className="form-control" disabled={true} />
												</div>
											</div>
										</div>

										<div className='row' ref={beginArea}>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text required">Paragraph Body</label>
													{editMode === 'add' ? <TemplateDropdown /> : <div>{` `}</div>}

													<FormikContextArea sector={1} />

													<Field name="paragBody" type="text" as="textarea" className="form-control textarea-body" disabled={editMode === 'read'} />
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text required">Paragraph Image</label>
													<Field name="paragImgLink" type="text" as="textarea" className="form-control textarea-small" disabled={editMode === 'read'} />
													{editMode !== 'read' && <ImagePicker className="mb3" fieldName="paragImgLink" />}
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text required">Queue</label>
													<Field name="paragQueue" type="number" className="form-control" disabled={editMode === 'read'} />
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-6 mb-3">
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2">
														<Field name="nobr" type="checkbox" disabled={editMode === 'read'} />
														{` No Break`}
													</label>
												</div>
											</div>
											<div className="col-lg-6 mb-3">
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2">
														<Field name="wavesurfer" type="checkbox" disabled={editMode === 'read'} />
														{` Is Wavesurfur`}
													</label>
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text required">Wavesurfur Source</label>
													<Field name="wavesurferPath" type="text" as="textarea" className="form-control textarea-small" disabled={editMode === 'read'} />
													{editMode !== 'read' && <AudioPicker className="mb3" fieldName="wavesurferPath" />}
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text">Wavesurfur Title</label>
													<Field name="wavesurferTitle" type="text" className="form-control" disabled={editMode === 'read'} />
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text">Wavesurfur Description</label>
													<Field name="wavesurferDescription" type="text" as="textarea" className="form-control textarea-small" disabled={editMode === 'read'} />
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-12 mb-3">
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2">
														<Field name="component" type="checkbox" disabled={editMode === 'read'} />
														{` Is Component Type`}
													</label>
												</div>
											</div>
										</div>

										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className="form-group">
													<label className="mont-font fw-600 font-xsss mb-2 text">Component Name</label>
													<Field name="componentName" type="text" className="form-control" disabled={editMode === 'read'} />
												</div>
											</div>
										</div>

										<div style={{
											display: 'flex',
											alignContent: 'center',
											alignItems: 'center',
											justifyContent: 'space-between',
											justifyItems: 'space-between',
										}}>
											<EditModeIndicator editMode={editMode} />
											<div style={{
												display: 'flex',
												alignContent: 'center',
												alignItems: 'center',
												justifyContent: 'flex-end',
												justifyItems: 'flex-end',
											}}>
												<FormikContextArea sector={2} />
												<div style={{ margin: '5px' }}>
													<Link
														to={'/deleteconfirm'}
														state={{
															action: {
																type: PARAG_DELETE,
																payload: paragraph
															},
															dboName: 'Parag',
															slide: 'parag',
															title: paragraph.paragBody,
															goBackPath: '/article',
															goBackState: {
																state: { article }
															},
														}}
													>
														<button className='btn btn-danger' type='button' hidden={!paragraph.id} >Delete</button>
													</Link>
												</div>
												<button style={{ marginLeft: '5px' }} className='btn btn-secondary' type='button' onClick={handleClickClose}>Close</button>
											</div>
										</div>
									</div>

								</Form>
							</Formik>
							<div className='h100' />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	auth: state.auth
})
const ConnectedPage = connect(mapStateToProps)(ParagDetailPage)
export default ConnectedPage