import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'

import { put, call } from 'redux-saga/effects'
import { 
    PICTURE_LIST_REQUEST, 
    PICTURE_LIST_SUCCESS, 
    PICTURE_LIST_FAILURE,
    PICTURE_INSERT_REQUEST, 
    PICTURE_INSERT_SUCCESS, 
    PICTURE_INSERT_FAILURE,
    PICTURE_UPDATE_REQUEST, 
    PICTURE_UPDATE_SUCCESS, 
    PICTURE_UPDATE_FAILURE,
    PICTURE_DELETE_REQUEST, 
    PICTURE_DELETE_SUCCESS, 
    PICTURE_DELETE_FAILURE,
} from '../ReduxAction'

export function* getPictureListAsync(action) {
        try {
        action = actionIntercept(action)
        yield put({ type: PICTURE_LIST_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/picture/picture-list', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: PICTURE_LIST_SUCCESS, payload: response.data })
        } else {
            yield put({ type: PICTURE_LIST_FAILURE, payload: response?.data })
        }
    } catch (error) {
        yield put({ type: PICTURE_LIST_FAILURE, payload: error.message })
    }
}

export function* insertPictureAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: PICTURE_INSERT_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/picture/picture-insert', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: PICTURE_INSERT_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: PICTURE_INSERT_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: PICTURE_INSERT_FAILURE, payload: error.message })
    }
}

export function* updatePictureAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: PICTURE_UPDATE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/picture/picture-update', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: PICTURE_UPDATE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: PICTURE_UPDATE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: PICTURE_UPDATE_FAILURE, payload: error.message })
    }
}

export function* deletePictureAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: PICTURE_DELETE_REQUEST })
        const response = yield call(async () => {
            
            const res = await apiObject.post('/picture/picture-delete', action.payload || {}, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: PICTURE_DELETE_SUCCESS, payload: response.data.list_data })
        } else {
            yield put({ type: PICTURE_DELETE_FAILURE, payload: response })
        }
    } catch (error) {
        yield put({ type: PICTURE_DELETE_FAILURE, payload: error.message })
    }
}