import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ReactFlowProvider } from 'react-flow-renderer'
import { FlowChartDraw } from './flowchart-draw'
import { Context } from './Context'
import Load from '../components/Load'
import { FLOW_CHART_DELETE } from '../Redux/ReduxSaga/triggers'

const FlowChart = props => {
    const [flowKey, setFlowKey] = useState(0)
    const [data, setData] = useState()
    const [defaultValues, setDefaultValues] = useState()
    const [flowChartItem, setFlowChartItem] = useState(null)

    const { flowChart, appFooter } = props
    const flowChartList = flowChart?.data?.list_data

    const bottomRef = useRef()

    const navigate = useNavigate()

    const handleSave = (flow, flowChartItem) => {
        setDefaultValues({ json_data: JSON.stringify(flow) })
        navigate('/flowchart-save', {
            state: {
                flowChart: {
                    id: flowChartItem?.id || 0,
                    flowName: flowChartItem?.flowName || '',
                    jsonData: JSON.stringify(flow),
                    description: flowChartItem?.description || '',
                }
            }
        })
    }

    const onRestore = () => {
        if (flowKey) {
            const restoreFlow = async () => {
                const filter = flowChartList.filter(item => item.id === Number(flowKey))
                if (filter.length === 1) {
                    try {
                        const record = filter[0]
                        setData(JSON.parse(record.jsonData))
                        setFlowChartItem(record)
                    } catch (e) {
                        console.log("onRestore error", e.message)
                    }
                }
            }
            restoreFlow()
        } else {
            setData(null)
            setFlowChartItem({
                id: 0,
                flowName: '',
                jsonData: '',
                description: '',
            })
        }
    }

    const handleFlowKeyChange = e => {
        setFlowKey(Number(e.target.value))
    }

    const handleClickAddNew = () => {
        setData(null)
        setFlowKey(0)
    }

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        onRestore()
    }, [flowKey])

    return (
        <>
            {flowChart.loading ? <Load /> :
                <div style={{
                    height: `calc(100vh - ${appFooter.height})`
                }}>
                    <Context.Provider value={{
                        flowKey, defaultValues
                    }}>
                        <div className='flex-between'>
                            <div className='flowchart__saved-list'>
                                <select className='form-control' value={flowKey} onChange={handleFlowKeyChange} >
                                    <option value={0}>Saved List...</option>
                                    {!!flowChartList?.length && flowChartList.map((item, key) => {
                                        return (
                                            <option key={key} value={item.id}>{item.flowName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="flex-end w200 button-group">
                                <button className='btn btn-primary' onClick={handleClickAddNew}>
                                    <i className='feather-plus'></i>
                                </button>
                                {!!flowKey &&
                                    <div>
                                        <Link
                                            to={'/deleteconfirm'}
                                            state={{
                                                action: {
                                                    type: FLOW_CHART_DELETE,
                                                    payload: flowChartItem
                                                },
                                                dboName: 'FlowChart',
                                                slide: 'flowChart',
                                                title: flowChartItem?.flowName,
                                                goBackPath: '/flowchart',
                                                goBackState: {},
                                            }}
                                        >
                                            <button className='btn btn-danger'>
                                                <i className='feather-delete'></i>
                                            </button>
                                        </Link>
                                    </div>
                                }
                                <button className="btn btn-secondary" onClick={scrollToBottom}>
                                    <i className="feather-corner-right-down"></i>
                                </button>
                            </div>
                        </div>
                        <FlowChartDraw {...props} data={data} flowChartItem={flowChartItem} handleSave={handleSave} />
                        <div className='h100 text' />
                        <div ref={bottomRef}></div>

                    </Context.Provider>
                </div>
            }
        </>
    )
}

const ProvidedFlowChart = props => (
    <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                <div className="row">
                    <div className="col-xl-12 chat-left scroll-bar">
                        <ReactFlowProvider>
                            <FlowChart {...props} />
                        </ReactFlowProvider>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
const mapStateToProps = state => ({
    flowChart: state.flowChart,
    appFooter: state.appFooter,
})
const ConnectedPage = connect(mapStateToProps)(ProvidedFlowChart)
export default ConnectedPage