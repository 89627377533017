import { 
    CALL_OFFER_SET, 
    CALL_OFFER_UNSET,
    NEW_OFFER_ICE_CANDIDATE_SET,
    NEW_OFFER_ICE_CANDIDATE_ADDED_SUCCESS,
} from '../ReduxAction'

const initialState = {
    offer: null,
    iceCandidates: [],
}

export const callOfferReducer = (state = initialState, action) => {
    switch (action.type) {
        case CALL_OFFER_SET:
            return {
                ...state,
                offer: action.payload
            }
        case CALL_OFFER_UNSET:
            return initialState
        case NEW_OFFER_ICE_CANDIDATE_SET:
            return {
                ...state,
                iceCandidates: [
                    ...state.iceCandidates,
                    {
                        ...action.payload,
                        state: 'incoming',
                        stateCode: 1,
                    }
                ]
            }
        case NEW_OFFER_ICE_CANDIDATE_ADDED_SUCCESS:
            const candidatesFilter = state.iceCandidates.filter(item => JSON.stringify(item) === JSON.stringify(action.payload))
            if (candidatesFilter.length) {
                candidatesFilter[0].state = 'added'
                candidatesFilter[0].stateCode = 2
                const newCandidates = state.iceCandidates.filter(item => JSON.stringify(item) !== JSON.stringify(action.payload))
                newCandidates.push(candidatesFilter[0])
                return {
                    ...state,
                    iceCandidates: newCandidates
                }
            } else {
                return state
            }
        default:
            return state
    }
}