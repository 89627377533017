import * as lamejs from 'lamejs'

export const audioConvert = arrayBuffer => {
    const wavHdr = lamejs.WavHeader.readHeader(new DataView(arrayBuffer))
    //Stereo
    let data = new Int16Array(arrayBuffer, wavHdr.dataOffset, wavHdr.dataLen / 2)
    let leftData = []
    let rightData = []
    for (let i = 0; i < data.length; i += 2) {
        leftData.push(data[i]);
        rightData.push(data[i + 1]);
    }
    const left = new Int16Array(leftData)
    const right = new Int16Array(rightData)

    let result

    //STEREO
    if (wavHdr.channels === 2)
        result = bufferToMp3(wavHdr.channels, wavHdr.sampleRate, left, right)
    //MONO
    else if (wavHdr.channels === 1)
        result = bufferToMp3(wavHdr.channels, wavHdr.sampleRate, data)

    return result
}

function bufferToMp3(channels, sampleRate, left, right = null) {
    const buffer = []
    const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
    let remaining = left.length
    const samplesPerFrame = 1152
    let mp3buf

    for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
        if (!right) {
            const mono = left.subarray(i, i + samplesPerFrame)
            mp3buf = mp3enc.encodeBuffer(mono, mono)
        } else {
            const leftChunk = left.subarray(i, i + samplesPerFrame)
            const rightChunk = right.subarray(i, i + samplesPerFrame)
            mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk)
        }
        if (mp3buf.length > 0) {
            buffer.push(mp3buf) //new Int8Array(mp3buf)
        }
        remaining -= samplesPerFrame
    }
    var d = mp3enc.flush()
    if (d.length > 0) {
        buffer.push(new Int8Array(d))
    }

    var blob = new Blob(buffer, { type: 'audio/mp3' })
    var bUrl = window.URL.createObjectURL(blob)
    return {
        blob, bUrl
    }
}
