import {
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
} from '../ReduxAction'

const initialState = {
    loading: false,
    error: false,
    message: '',
    data: null,
}

export const uploadFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_FILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'upload file request',
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'upload file fulfil',
                data: action.payload,
            };
        case UPLOAD_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                data: null,
            };
        default:
            return state;
    }
}