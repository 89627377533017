import {
    ART_LIST_REQUEST,
    ART_LIST_SUCCESS,
    ART_LIST_FAILURE,
    ART_INSERT_REQUEST,
    ART_INSERT_SUCCESS,
    ART_INSERT_FAILURE,
    ART_UPDATE_REQUEST,
    ART_UPDATE_SUCCESS,
    ART_UPDATE_FAILURE,
    ART_DELETE_REQUEST,
    ART_DELETE_SUCCESS,
    ART_DELETE_FAILURE,
    ART_UPDATE_CREATED_DATE_REQUEST,
    ART_UPDATE_CREATED_DATE_SUCCESS,
    ART_UPDATE_CREATED_DATE_FAILURE,
} from '../ReduxAction'

const initialState = {
    loading: false,
    changing: false,
    error: false,
    message: '',
    data: null,
}

export const artReducer = (state = initialState, action) => {
    switch (action.type) {
        case ART_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: 'list request',
            };
        case ART_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: 'list fulfil',
                data: action.payload,
            };
        case ART_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
                data: null,
            };
        case ART_INSERT_REQUEST:
        case ART_UPDATE_REQUEST:
        case ART_UPDATE_CREATED_DATE_REQUEST:
        case ART_DELETE_REQUEST:
            return {
                ...state,
                changing: true
            };
        case ART_INSERT_SUCCESS:
        case ART_UPDATE_SUCCESS:
        case ART_UPDATE_CREATED_DATE_SUCCESS:
        case ART_DELETE_SUCCESS:
        case ART_INSERT_FAILURE:
        case ART_UPDATE_FAILURE:
        case ART_UPDATE_CREATED_DATE_FAILURE:
        case ART_DELETE_FAILURE:
            return {
                ...state,
                changing: false
            };

        default:
            return state;
    }
}