import { apiObject, axiosConfig } from '../../Constants'
import { actionIntercept } from '../../_helpers'
import { put, call } from 'redux-saga/effects'
import { 
    LOGIN_REQUEST, 
    LOGIN_SUCCESS, 
    LOGIN_FAILURE,
    SEND_ACTIVATE_LINK_REQUEST, 
    SEND_ACTIVATE_LINK_SUCCESS, 
    SEND_ACTIVATE_LINK_FAILURE,
} from '../ReduxAction'

export function* loginAsync(action) {

    try {
        action = actionIntercept(action)
        yield put({ type: LOGIN_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/users/authenticate', action.payload, axiosConfig)
            return res
        })

        if (response?.status === 200) {
            yield put({ type: LOGIN_SUCCESS, payload: response.data, })
        } else {
            yield put({ type: LOGIN_FAILURE, payload: response?.data.message })
        }
    } catch (error) {
        yield put({ type: LOGIN_FAILURE, payload: error.message })
    }
}

export function* sendActivateLinkAsync(action) {
    try {
        action.payload.__actionType = action.type
        yield put({ type: SEND_ACTIVATE_LINK_REQUEST })
        const response = yield call(async () => {
            const res = await apiObject.post('/users/send-register-link', action.payload, axiosConfig)
            return res
        })
        if (response?.status === 200) {
            yield put({ type: SEND_ACTIVATE_LINK_SUCCESS, payload: response.data, })
        } else {
            yield put({ type: SEND_ACTIVATE_LINK_FAILURE, payload: response?.data.message })
        }
    } catch (error) {
        yield put({ type: SEND_ACTIVATE_LINK_FAILURE, payload: error.message })
    }
}