import React from 'react'
import { connect } from 'react-redux'
import ReactJson from 'react-json-view'

const ReduxStoreViewer = props => {

    const { state } = props

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">
                            <ReactJson src={state} collapsed={true} displayDataTypes={false} />
                            <div className='h100' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({ state })
const ConnectedPage = connect(mapStateToProps)(ReduxStoreViewer)
export default ConnectedPage
