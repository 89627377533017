import React from 'react'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { Container } from './Container'

export const TagInput = props => {

  const { artId } = props

  return (
    <DndProvider backend={TouchBackend}>
      <Container artId={artId} />
    </DndProvider>
  )
}
