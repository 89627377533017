import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
//import registerServiceWorker from './registerServiceWorker'
import 'bootstrap/dist/css/bootstrap.css'
import { ErrorBoundary } from './_components'

//redux && saga
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

import { rootReducer } from './Redux/ReduxReducer/rootReducer'
import { rootSaga } from './Redux/ReduxSaga/rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(rootSaga)

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const container = document.getElementById('root')
const root = createRoot(container)

root.render(<Provider store={store}>
  <BrowserRouter basename={baseUrl}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </BrowserRouter>
</Provider>
)

//registerServiceWorker()
