import React, { useState, useEffect } from 'react'
import Prism from 'prismjs'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { WavePlayer, LocalImage } from '../_components'
import { paragComponents } from '../App/paragComponents'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/line-highlight/prism-line-highlight'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-swift'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-php'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/line-highlight/prism-line-highlight.css'
import 'prismjs/themes/prism-coy.css'

const ParagItem = props => {
    const { auth, parag, paragraph, article, inFoundList } = props
    const [nextQueue, setNexQueue] = useState(paragraph?.paragQueue)
    const data = auth.data
    const isAdmin = data?.isAdmin || false

    useEffect(() => {
        Prism.manual = true
        //Prism.highlightAll()
        setTimeout(() => {
            Prism.highlightAll();
        }, 500);
    }, [])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (parag.loading) return
            const theNextQueue = parag.data?.list_data?.reduce((prev, curr) => {
                return prev.paragQueue < curr.paragQueue ? prev.paragQueue : curr.paragQueue;
            }) + 10
            setNexQueue(theNextQueue)
        }

        return () => mounted = false
    }, [parag])

    const renderParag = () => {

        if (paragraph.paragImgLink && paragraph.paragImgLink.length > 0) {
            let src = paragraph.paragImgLink
            if (src.includes('/')) src = src.substring(src.lastIndexOf('/') + 1)
            return (
                <>
                    <LocalImage alt='' src={paragraph.paragImgLink} />
                    {isAdmin &&
                        <div style={{ paddingTop: '10px' }}>
                            <span><Link to='/parag-detail' state={{ paragraph, article }}>[New]</Link></span>
                            <span><Link to='/parag-detail' state={{ paragraph, article }}>[Edit]</Link></span>
                        </div>
                    }
                </>
            )
        }

        if (paragraph.paragBody?.length > 0) {
            let text = paragraph.paragBody
            text = text.replaceAll('||1', '<')
            text = text.replaceAll('2||', '>')

            return (
                <div className='parag' dangerouslySetInnerHTML={{ __html: text }} />
            )
        }
    }

    return (
        <>
            {renderParag()}
            {paragraph.wavesurfer && <WavePlayer paragId={paragraph.id} path={paragraph.wavesurferPath} title={paragraph.wavesurferTitle} description={paragraph.wavesurferDescription} />}
            {paragraph.component && paragComponents[paragraph.componentName]}
            {isAdmin &&
                <div style={{ paddingTop: '5px', paddingBottom: '20px', backgroundColor: !!inFoundList ? 'yellow' : 'transparent' }}>
                    <code className='language-text admin-modify'>{`[${paragraph.paragQueue}]`}</code>
                    <Link to='/parag-detail' state={{ paragraph: { paragID: null, paragQueue: nextQueue, artID: article.id }, article }}><span className='admin-modify'>[New]</span></Link>
                    <Link to='/parag-detail' state={{ paragraph: { paragID: null, paragQueue: paragraph.paragQueue + 1, artID: article.id }, article }}><span className='admin-modify'>[Insert]</span></Link>
                    <Link to='/parag-detail' state={{ paragraph, article }}><span className='admin-modify'>[Edit]</span></Link>
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    parag: state.parag,
})
const ConnectedParagItem = connect(mapStateToProps)(ParagItem)
export { ConnectedParagItem as ParagItem } 