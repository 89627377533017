import {
    DEVICE_TOKEN_LIST_REQUEST,
    DEVICE_TOKEN_LIST_SUCCESS,
    DEVICE_TOKEN_LIST_FAILURE,
} from '../ReduxAction/index'

export interface IDeviceToken {
    app: string | null;
    id: number | null;
    deviceId: string | null;
    deviceName: string | null;
    platform: string | null;
    os: string | null;
    userName: string | null;
    token: string | null;
    createdDate: Date | null;
}

export interface IDeviceTokenState {
    loading: boolean;
    error: boolean;
    message: string;
    data: {
        count: number;
        list: IDeviceToken[];
    } | null;
}

const initialState: IDeviceTokenState = {
    loading: false,
    error: false,
    message: '',
    data: {
        count: 0,
        list: []
    }
}

export const deviceTokenReducer = (state: IDeviceTokenState = initialState, action: { type: string; payload: any;}): IDeviceTokenState => {
    switch (action.type) {
        case DEVICE_TOKEN_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                message: 'List Request Sent'
            }
        case DEVICE_TOKEN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                message: 'List Request Success',
                data: action.payload
            }
        case DEVICE_TOKEN_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload,
            }
        default:
            return state
    }
}