export const style = {
    appContainer: {
        padding: '5px',
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    },
    artCaption: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'bacl',
        TextDecoder: 'none',
    },
    divisionLow: {
        paddingTop: '10px',
    },
    toolbar: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '15px',
        menuButton: {
            color: 'white',
            width: '50px',
            height: '35px',
            backgroundColor: 'black',
        }
    },
    sideBar: {
        position: 'relative',
    },
    statyPage: {
        width: '100%',
    },
    footer: {
        paddingTop: '50px',
    },
    sideBarContainer: {
        padding: '10px',
        backgroundColor: 'white',
        width: 'fit-content',
        borderRight: '1px solid lightgray',
        overflow: 'hidden',
    },
    sideBarLogo: {
        fontWeight: 'bold',
        borderBottom: '1px solid lightgray',
        paddingBottom: '100px',
    },
    toogleLeftIconWrapper: {
        marginRight: '0px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        width: '26px',
        height: '26px',
        marginTop: '-13px',
        borderRadius: '50%',
        backgroundColor: 'white',
    },
    toogleRightIconWrapper: {
        marginLeft: '5px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        width: '26px',
        height: '26px',
        marginTop: '-13px',
        borderRadius: '50%',
        backgroundColor: 'white',
    },
    arrowForward: {
        cursor: 'pointer',
    },
    arrowBackward: {
        cursor: 'pointer',
    },
    flexContainerFlexStart: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
    },
    flexContainerFlexEnd: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'flex-end',
        whiteSpace: 'nowrap',
    },
    menuItemIconWrapper: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        width: '38px',
        height: '38px',
    },
    menuParentItemArrow: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        width: '38px',
        height: '38px',
    },
    menuParentItemContainer: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        justifyItems: 'space-between',
        paddingTop: '5px',
    },
    menuCaption: {
        paddingLeft: '25px',
        whiteSpace: 'nowrap',
    },
    login: {
        padding: '20px',
    }
}