import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import Pagetitle from '../../components/Pagetitle'

const AudioList = props => {
    const [list, setList] = useState([])
    const { dispatch, audio, navHeader } = props
    const { loading, data } = audio
    const { keyword, searchActive } = navHeader

    useEffect(() => {
        if (searchActive && keyword.length > 0) {
            const filter = data.filter(item => item.name.toUpperCase().indexOf(keyword.toUpperCase()) >= 0)
            setList(filter)
        } else {
            setList(data)
        }
    }, [data, keyword, searchActive])

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0" style={{ maxWidth: "100%" }}>
                    <div className="row">
                        <div className="col-xl-6 chat-left scroll-bar">
                            <Pagetitle title="Audio list" />

                            {loading ?
                                <div className='flex-between' style={{ paddingTop: '100px' }}>
                                    <img src='simpleLoad.gif' alt='Loading...' width={30} />
                                </div>
                                :
                                <ListGroup>
                                    {data && list.map((item, key) => {
                                        return (
                                            <ListGroup.Item key={key}>
                                                <Link to='/audio-view' state={{
                                                    path: item.audioUrl,
                                                    title: item.name,
                                                }}>
                                                    <div
                                                        style={{
                                                            wordWrap: 'break-word',
                                                            margin: '5px, 0px, 0px, 0px',
                                                            borderRadius: '5px',
                                                            backgroundColor: key % 2 ? 'HSL(120, 50%, 70%)' : 'HSL(120, 70%, 90%)'
                                                        }}>
                                                        {item.name}
                                                    </div>
                                                </Link>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        audio: state.audio,
        navHeader: state.navHeader,
    }
}
const ConnectedAudioList = connect(mapStateToProps)(AudioList)
export { ConnectedAudioList as AudioList }