import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { PENDING_ACTION_REMOVE } from '../Redux/ReduxAction'

const ResendActionCenter = props => {
    const { dispatch, silentlyRT, pendingAction } = props
    const startRef = useRef(null)

    // when "loading" value of silentlyRT become false, we free this refreshTokenBusy
    // it is important to do this with delay 1s to bypass timer interval
    useEffect(() => {
        if (!startRef) {
            startRef.current = true
            return
        }

        if (!silentlyRT.loading && !silentlyRT.error) {
            let action
            pendingAction.map(item => {

                if (item.__actionType) {

                    action = {
                        type: item.__actionType,
                        payload: item
                    }

                    dispatch({
                        type: PENDING_ACTION_REMOVE,
                        payload: item
                    })

                    dispatch(action)
                } else {
                    console.log("The store pendingAction array contains an action without the __actionType property")
                }
            })
        }

    }, [silentlyRT.loading])

    return (
        <></>
    )
}
const mapStateToProps = state => ({
    silentlyRT: state.silentlyRT,
    pendingAction: state.pendingAction
})
const Connected = connect(mapStateToProps)(ResendActionCenter)
export default Connected