import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ONLINE } from './MessageTypes'
import { useLocation } from 'react-router-dom'
import { normalizeFullname, randomAvatarSrc } from '../_helpers'

const RouteTracker = props => {
    const location = useLocation()
    const { auth, hubConnection, userActivity } = props

    useEffect(() => {
        let mounted = true

        if (mounted) {
            if (!hubConnection.origin || !hubConnection.state === 'Connected') return
            const newUserActivity = userActivity.filter(item => item.userId !== auth?.data?.id)
            newUserActivity.push({
                userId: auth?.data?.id,
                userName: normalizeFullname(auth?.data?.username),
                pathname: location.pathname,
                avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
                hubConnectionId: auth?.hubConnection?.connectionId,
                accessTime: new Date().getTime(),
            })
            const activity = {
                type: ONLINE,
                userId: auth?.data?.id,
                user: normalizeFullname(auth?.data?.username),
                avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
                message: `${auth?.data?.username} navigated to ${location.pathname}`,
                data: {
                    userId: auth?.data?.id,
                    userName: auth?.data?.username,
                    pathname: location.pathname,
                    avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
                    hubConnectionId: auth?.hubConnection?.connectionId,
                    accessTime: new Date().getTime(),
                    userActivity: newUserActivity,
                },
            }
            if (hubConnection && hubConnection.state === 'Connected') {
                hubConnection.origin?.invoke("SendMessage", activity)
            }
        }

        return () => mounted = false

    }, [location.pathname, hubConnection])

    return <></>
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
        hubConnection: state.hubConnection,
        userActivity: state.userActivity
    }
}
const ConnectedRouteTracker = connect(mapStateToProps)(RouteTracker)
export { ConnectedRouteTracker as RouteTracker }