import React, { useEffect, useState, useRef } from 'react'
import { wavelengthToColor } from '../_helpers'

export const Reflect = () => {
    const [xy111Content, setXy111Content] = useState('')
    const [graphDataContent, setGraphDataContent] = useState('')

    const gradient = useRef()
    const curves = useRef()
    const criGraph = useRef()
    const iPad = useRef()
    const nm470 = useRef()
    const rotatedText = useRef()
    const frmColor = useRef()
    const xy111 = useRef()
    const graphData = useRef()

    useEffect(() => {

        if (!criGraph.current || !iPad.current) return

        const handleToucth = e => {
            if (e.target === criGraph.current || e.target === iPad.current) {
                e.preventDefault();
            }
        }

        document.body.addEventListener("touchstart", handleToucth, { passive: false });
        document.body.addEventListener("touchend", handleToucth, { passive: false });
        document.body.addEventListener("touchmove", handleToucth, { passive: false });

        return () => {
            document.body.removeEventListener("touchstart", handleToucth, { passive: false });
            document.body.removeEventListener("touchend", handleToucth, { passive: false });
            document.body.removeEventListener("touchmove", handleToucth, { passive: false });
        }

    }, [criGraph, iPad])

    function DrawExam111() {
        let cri = criGraph.current;
        let cx = cri.getContext("2d");
        cx.lineWidth = 1;
        var mousePos = { x: 0, y: 0 };
        cx.font = "10px Tahoma";
        var ix = 0, iy = 0;

        cri.addEventListener("mousedown", function (e) {
            mousePos = getMousePos(cri, e);
            let x = Math.floor(mousePos.x);
            let y = Math.floor(mousePos.y);
            ix = x; iy = y;
            var sx, sy;
            if (x > 0 && y > 0 && x < 1000 && y < 1000) {
                if (x < 10) sx = "00" + x;
                else if (x < 100) sx = "0" + x;
                else sx = x.toString();
                if (y < 10) sy = "00" + y;
                else if (y < 100) sy = "0" + y;
                else sy = y.toString();
                setXy111Content(sx + ";" + sy)
            } else {
                setXy111Content('')
            }
            goLines(cx, x, y);
        }, false);

        cri.addEventListener("mousemove", function (e) {
        }, false);

        cri.addEventListener("mouseup", function (e) {
        }, false);

        cri.addEventListener("touchstart", function (e) {
            var touch = e.touches[0];
            var mouseEvent = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            cri.dispatchEvent(mouseEvent);
        }, false);

        cri.addEventListener("touchmove", function (e) {
            var touch = e.touches[0];
            var mouseEvent = new MouseEvent("mousemove", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            cri.dispatchEvent(mouseEvent);
        }, false);

        cri.addEventListener("touchend", function (e) {
            var mouseEvent = new MouseEvent("mouseup", {});
            cri.dispatchEvent(mouseEvent);
        }, false);

        var iPadLast = { x: 0, y: 0 };
        var iPadPos = { x: 0, y: 0 };

        iPad.current.addEventListener("mousedown", function (e) {
            iPadLast = getMousePos(iPad, e);
        }, false);

        iPad.current.addEventListener("mousemove", function (e) {
            iPadPos = getMousePos(iPad, e);
            ix += Math.floor((iPadPos.x - iPadLast.x) / 30);
            iy += Math.floor((iPadPos.y - iPadLast.y) / 30);

            if (ix > 0 && iy > 0 && ix < 1000 && iy < 1000) {
                if (ix < 10) sx = "00" + ix;
                else if (ix < 100) sx = "0" + ix;
                else sx = ix.toString();
                if (iy < 10) sy = "00" + iy;
                else if (iy < 100) sy = "0" + iy;
                else sy = iy.toString();
                setXy111Content(sx + ";" + sy);
            } else {
                setXy111Content("");
            }
            goLines(cx, ix, iy);
        }, false);

        iPad.current.addEventListener("mouseup", function (e) {
        }, false);

        iPad.current.addEventListener("touchstart", function (e) {
            var touch = e.touches[0];
            var mouseEvent = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            iPad.dispatchEvent(mouseEvent);
        }, false);

        iPad.current.addEventListener("touchmove", function (e) {
            var touch = e.touches[0];
            var mouseEvent = new MouseEvent("mousemove", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            iPad.dispatchEvent(mouseEvent);
        }, false);

        iPad.current.addEventListener("touchend", function (e) {
            var mouseEvent = new MouseEvent("mouseup", {});
            iPad.dispatchEvent(mouseEvent);
        }, false);

        let c = curves.current.getContext("2d");

        c.beginPath();
        c.moveTo(52, 10);
        c.lineTo(52, 264);
        c.lineTo(370, 264);
        c.stroke();
        c.closePath();

        c.strokeStyle = "gray";
        c.beginPath();
        c.moveTo(52, 17);
        c.lineTo(349, 17);
        c.lineTo(349, 264)
        c.fillText("400", 52, 275);

        c.moveTo(142, 17);
        c.lineTo(142, 20);
        c.moveTo(142, 261);
        c.lineTo(142, 264);
        c.fillText("500", 136, 275);

        c.moveTo(238, 17);
        c.lineTo(238, 20);
        c.moveTo(238, 261);
        c.lineTo(238, 264);
        c.fillText("600", 232, 275);

        c.moveTo(332, 17);
        c.lineTo(332, 20);
        c.moveTo(332, 261);
        c.lineTo(332, 264);
        c.fillText("700", 326, 275);

        c.fillText("Bước sóng, nm", 160, 295);
        c.stroke();
        c.closePath();

        c.lineWidth = 5;
        for (var k = 52; k <= 352; k++) {
            c.beginPath();
            c.strokeStyle = wavelengthToColor(k - 52 + 400)[0];
            c.moveTo(k, 280);
            c.lineTo(k + 1, 280);
            c.stroke();
            c.closePath();
        }
        c.strokeStyle = "gray";
        c.lineWidth = 1;

        let rt = rotatedText.current;
        let ctx3 = rt.getContext("2d");
        ctx3.rotate(270 * Math.PI / 180);
        ctx3.fillText("Hệ số phản xạ, %", -170, 25);

        let ctxg = gradient.current.getContext("2d");
        let c470 = nm470.current.getContext("2d");

        var grd = ctxg.createLinearGradient(0, 0, 0, 306);
        grd.addColorStop(0, "black");
        grd.addColorStop(1, "white");

        ctxg.fillStyle = grd;
        ctxg.fillRect(0, 0, 380, 306);

        c.fillText("0", 37, 241 + (241 - 17) / 9 - 2);
        var m = 10;
        for (var k = 241; k > 32; k -= (241 - 17) / 9) {
            c.moveTo(52, k);
            c.lineTo(55, k);
            c.moveTo(349, k);
            c.lineTo(346, k);
            c.fillStyle = "HSL(0, 0%, " + m + "%)";
            c.fillText(m, 32, k + 2);
            m += 10;
        }
        c.fillStyle = "HSL(0, 0%, 100%)";
        c.fillText("100", 27, 17 + 2);

        c.stroke();
        c.closePath();

        var loop1k = 1;
        var colortmp = "";
        var id1 = setInterval(function loop1() {
            if (loop1k == 1) {
                colortmp = wavelengthToColor(550)[0];
                c.fillStyle = colortmp;
                c.strokeStyle = colortmp;
                loop1k = 0;
            } else {
                c.fillStyle = "red";
                c.strokeStyle = "red";
                loop1k = 1;
            }
            c.beginPath();
            c.arc(191, 90, 1, 0, 2 * Math.PI);
            c.stroke();
            c.fill();
            c.closePath();
        }, 550);

        var loop2k = 1;
        var colortmp2 = "";
        var id2 = setInterval(function loop2() {
            if (loop2k == 1) {
                colortmp2 = wavelengthToColor(470)[0];
                c470.fillStyle = colortmp2;
                c470.strokeStyle = colortmp2;
                loop2k = 0;
            } else {
                c470.fillStyle = "orange";
                c470.strokeStyle = "orange";
                loop2k = 1;
            }
            c470.beginPath();
            c470.arc(114, 149, 3, 0, 2 * Math.PI);
            c470.stroke();
            c470.fill();
            c470.closePath();
        }, 470);

        let whites =
            [
                [52, 59], [56, 56], [62, 54], [66, 53], [73, 52], [82, 50], [91, 49], [103, 48], [111, 47], [126, 46], [146, 45], [293, 43], [326, 45], [334, 45], [339, 46], [343, 46], [347, 50]
            ];
        let blacks =
            [
                [52, 258], [333, 258], [340, 256], [346, 255], [350, 253]
            ];
        let lightbrowns =
            [

                [52, 176], [58, 163], [63, 156], [67, 153], [72, 150], [77, 149], [82, 149], [89, 149], [100, 149], [109, 149], [117, 149], [131, 146], [137, 142], [153, 128], [166, 112], [174, 100], [178, 96], [182, 93], [186, 91], [191, 90], [196, 90], [203, 90], [212, 86], [218, 81], [226, 72], [232, 64], [237, 58], [243, 53], [246, 51], [254, 48], [263, 47], [283, 46], [319, 46], [332, 48], [339, 50], [344, 54], [348, 58]
            ];
        let reds =
            [
                [52, 254], [232, 254], [240, 253], [245, 250], [251, 244], [260, 231], [319, 126], [324, 118], [331, 109], [338, 103], [343, 100], [349, 99]
            ];
        let browns =
            [
                [52, 253], [191, 253], [205, 253], [224, 250], [235, 246], [248, 242], [256, 237], [264, 230], [269, 224], [278, 210], [286, 200], [293, 191], [299, 185], [308, 176], [317, 169], [325, 164], [333, 159], [341, 156], [350, 154]
            ];

        drawLine("white", whites);
        drawLine("black", blacks);
        drawLine("rosybrown", lightbrowns);
        drawLine("red", reds);
        drawLine("brown", browns);

    }

    function getMousePos(canvasDom, mouseEvent) {
        var rect = canvasDom.getBoundingClientRect();
        return {
            x: mouseEvent.clientX - rect.left,
            y: mouseEvent.clientY - rect.top
        };
    }

    function goLines(c, x, y) {
        c.clearRect(0, 0, 380, 306);
        c.beginPath();
        c.moveTo(0, y);
        c.lineTo(380, y);
        c.moveTo(x, 0);
        c.lineTo(x, 306);
        c.stroke();
        c.closePath();
    }

    function registerPos() {
        var curs = xy111Content.split(";");
        var arrText = graphDataContent;
        var arr;
        if (arrText.length > 0)
            arr = arrText.split(", ");
        else
            arr = [];
        arr.push("[" + curs[0] + "," + curs[1] + "]");
        let arr1 = new Set(arr);
        let arr2 = Array.from(arr1);
        arr2.sort();
        let n = arr2.length;

        let s = "";

        for (var i = 0; i < n; i++) {
            s += arr2[i] + ", ";
        }

        if (s.length > 0)
            s = s.substring(0, s.length - 2);

        setGraphDataContent(s);

    }

    function clearGraphData() {
        setGraphDataContent("");
    }

    function changeData(o) {
        let whites =
            [
                [52, 59], [56, 56], [62, 54], [66, 53], [73, 52], [82, 50], [91, 49], [103, 48], [111, 47], [126, 46], [146, 45], [293, 43], [326, 45], [334, 45], [339, 46], [343, 46], [347, 50]
            ];
        let blacks =
            [
                [52, 258], [333, 258], [340, 256], [346, 255], [350, 253]
            ];
        let lightbrowns =
            [

                [52, 176], [58, 163], [63, 156], [67, 153], [72, 150], [77, 149], [82, 149], [89, 149], [100, 149], [109, 149], [117, 149], [131, 146], [137, 142], [153, 128], [166, 112], [174, 100], [178, 96], [182, 93], [186, 91], [191, 90], [196, 90], [203, 90], [212, 86], [218, 81], [226, 72], [232, 64], [237, 58], [243, 53], [246, 51], [254, 48], [263, 47], [283, 46], [319, 46], [332, 48], [339, 50], [344, 54], [348, 58]
            ];
        let reds =
            [
                [52, 254], [232, 254], [240, 253], [245, 250], [251, 244], [260, 231], [319, 126], [324, 118], [331, 109], [338, 103], [343, 100], [349, 99]
            ];
        let browns =
            [
                [52, 253], [191, 253], [205, 253], [224, 250], [235, 246], [248, 242], [256, 237], [264, 230], [269, 224], [278, 210], [286, 200], [293, 191], [299, 185], [308, 176], [317, 169], [325, 164], [333, 159], [341, 156], [350, 154]
            ];
        var s = "";
        var n;
        var x, y, sx, sy;
        try {
            switch (o) {
                case "whites":
                    drawLine("yellow", whites);
                    n = whites.length;
                    for (var k = 0; k < n; k++) {
                        x = whites[k][0];
                        y = whites[k][1];
                        s += textIt(x, y);
                    }
                    if (s !== "") s = s.substring(0, s.length - 2);
                    break;
                case "blacks":
                    drawLine("black", blacks);
                    n = blacks.length;
                    for (var k = 0; k < n; k++) {
                        x = blacks[k][0];
                        y = blacks[k][1];
                        s += textIt(x, y);
                    }
                    if (s !== "") s = s.substring(0, s.length - 2);
                    break;
                case "lightbrowns":
                    drawLine("rosybrown", lightbrowns);
                    n = lightbrowns.length;
                    for (var k = 0; k < n; k++) {
                        x = lightbrowns[k][0];
                        y = lightbrowns[k][1];
                        s += textIt(x, y);
                    }
                    if (s !== "") s = s.substring(0, s.length - 2);
                    break;
                case "reds":
                    drawLine("red", reds);
                    n = reds.length;
                    for (var k = 0; k < n; k++) {
                        x = reds[k][0];
                        y = reds[k][1];
                        s += textIt(x, y);
                    }
                    if (s !== "") s = s.substring(0, s.length - 2);
                    break;
                case "browns":
                    drawLine("brown", browns);
                    n = browns.length;
                    for (var k = 0; k < n; k++) {
                        x = browns[k][0];
                        y = browns[k][1];
                        s += textIt(x, y);
                    }
                    if (s !== "") s = s.substring(0, s.length - 2);
                    break;
                default:
            }
            setGraphDataContent(s);
        }
        catch (e) {
            alert(e.message);
        }
    }

    function textIt(x, y) {
        var sx = "", sy = "";
        if (x < 10) sx = "00" + x;
        else if (x < 100) sx = "0" + x;
        else sx = x.toString();
        if (y < 10) sy = "00" + y;
        else if (y < 100) sy = "0" + y;
        else sy = y.toString();
        return "[" + sx + "," + sy + "], ";
    }

    function drawLine(color, d) {
        let c = curves.current.getContext("2d");
        c.strokeStyle = color;
        c.lineWidth = 4;
        let n = d.length;
        c.beginPath();
        c.moveTo(d[0][0], d[0][1]);
        for (var k = 1; k < n; k++) {
            c.lineTo(d[k][0], d[k][1]);
        }
        c.stroke();
        c.closePath();
    }

    useEffect(() => {
        if (!criGraph.current || !iPad.current || !gradient.current || !nm470.current || !curves.current) return
        DrawExam111()
    }, [criGraph, iPad, gradient, nm470, curves])

    return (
        <>
            {/* <div style={{ textIndent: 0 }}>
                <form ref={frmColor}>
                    <label className="radio-inline">
                        <input type="radio" name="colors" onClick={() => changeData('whites')} />
                        <span className="smallbox white"></span>
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="colors" onClick={() => changeData('blacks')} />
                        <span className="smallbox black"></span>
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="colors" onClick={() => changeData('lightbrowns')} />
                        <span className="smallbox lightbrown"></span>
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="colors" onClick={() => changeData('reds')} />
                        <span className="smallbox red"></span>
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="colors" onClick={() => changeData('browns')} />
                        <span className="smallbox brown"></span>
                    </label>
                </form>
            </div> */}

            <div style={{ textIndent: 0, position: 'relative', width: '380px', height: '306px' }}>

                <canvas ref={gradient} width={380} height={306} style={{ position: 'absolute', background: 'transparent', opacity: 1 }}>
                </canvas>

                <canvas ref={curves} width={380} height={306} style={{ position: 'absolute', background: 'transparent' }}>
                </canvas>

                <canvas ref={nm470} width={380} height={306} style={{ position: 'absolute', background: 'transparent' }}>
                </canvas>

                <canvas ref={rotatedText} width={380} height={306} style={{ position: 'absolute', background: 'transparent' }}>
                </canvas>

                <canvas ref={criGraph} width={380} height={306} style={{ position: 'absolute', background: 'transparent' }}>
                </canvas>

            </div>

            <canvas ref={iPad} width={380} height={100} style={{ position: 'relative', background: 'lightgray', display: 'none' }}>
            </canvas>
            {/* <input type='button' value="Capture" onClick={registerPos} />
            <input type='button' value="Clear" onClick={clearGraphData} />
            <span ref={xy111}>{xy111Content}</span>
            <p style={{ width: '380px', height: '200px', background: 'rosybrown' }} ref={graphData} >{graphDataContent}</p> */}
        </>
    )
}