export const ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST'
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS'
export const ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE'

export const REFRESH_TOKEN_EXISTS_REQUEST = 'REFRESH_TOKEN_EXISTS_REQUEST'
export const REFRESH_TOKEN_EXISTS_SUCCESS = 'REFRESH_TOKEN_EXISTS_SUCCESS'
export const REFRESH_TOKEN_EXISTS_FAILURE = 'REFRESH_TOKEN_EXISTS_FAILURE'

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE'

export const SILENTLY_REFRESH_TOKEN_REQUEST = 'SILENTLY_REFRESH_TOKEN_REQUEST'
export const SILENTLY_REFRESH_TOKEN_SUCCESS = 'SILENTLY_REFRESH_TOKEN_SUCCESS'
export const SILENTLY_REFRESH_TOKEN_FAILURE = 'SILENTLY_REFRESH_TOKEN_FAILURE'
export const SILENTLY_RT_SIGNAL_STATE_SET = 'SILENTLY_RT_SIGNAL_STATE_SET'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const SEND_ACTIVATE_LINK_REQUEST = 'SEND_ACTIVATE_LINK_REQUEST'
export const SEND_ACTIVATE_LINK_SUCCESS = 'SEND_ACTIVATE_LINK_SUCCESS'
export const SEND_ACTIVATE_LINK_FAILURE = 'SEND_ACTIVATE_LINK_FAILURE'

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST'
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS'
export const GOOGLE_LOGIN_FAILURE = 'GOOGLE_LOGIN_FAILURE'

export const CAT_LIST_REQUEST = 'CAT_LIST_REQUEST'
export const CAT_LIST_SUCCESS = 'CAT_LIST_SUCCESS'
export const CAT_LIST_FAILURE = 'CAT_LIST_FAILURE'
export const CAT_INSERT_REQUEST = 'CAT_INSERT_REQUEST'
export const CAT_INSERT_SUCCESS = 'CAT_INSERT_SUCCESS'
export const CAT_INSERT_FAILURE = 'CAT_INSERT_FAILURE'
export const CAT_UPDATE_REQUEST = 'CAT_UPDATE_REQUEST'
export const CAT_UPDATE_SUCCESS = 'CAT_UPDATE_SUCCESS'
export const CAT_UPDATE_FAILURE = 'CAT_UPDATE_FAILURE'
export const CAT_DELETE_REQUEST = 'CAT_DELETE_REQUEST'
export const CAT_DELETE_SUCCESS = 'CAT_DELETE_SUCCESS'
export const CAT_DELETE_FAILURE = 'CAT_DELETE_FAILURE'

export const ALBUM_LIST_REQUEST = 'ALBUM_LIST_REQUEST'
export const ALBUM_LIST_SUCCESS = 'ALBUM_LIST_SUCCESS'
export const ALBUM_LIST_FAILURE = 'ALBUM_LIST_FAILURE'
export const ALBUM_INSERT_REQUEST = 'ALBUM_INSERT_REQUEST'
export const ALBUM_INSERT_SUCCESS = 'ALBUM_INSERT_SUCCESS'
export const ALBUM_INSERT_FAILURE = 'ALBUM_INSERT_FAILURE'
export const ALBUM_UPDATE_REQUEST = 'ALBUM_UPDATE_REQUEST'
export const ALBUM_UPDATE_SUCCESS = 'ALBUM_UPDATE_SUCCESS'
export const ALBUM_UPDATE_FAILURE = 'ALBUM_UPDATE_FAILURE'
export const ALBUM_DELETE_REQUEST = 'ALBUM_DELETE_REQUEST'
export const ALBUM_DELETE_SUCCESS = 'ALBUM_DELETE_SUCCESS'
export const ALBUM_DELETE_FAILURE = 'ALBUM_DELETE_FAILURE'

export const PICTURE_LIST_REQUEST = 'PICTURE_LIST_REQUEST'
export const PICTURE_LIST_SUCCESS = 'PICTURE_LIST_SUCCESS'
export const PICTURE_LIST_FAILURE = 'PICTURE_LIST_FAILURE'
export const PICTURE_INSERT_REQUEST = 'PICTURE_INSERT_REQUEST'
export const PICTURE_INSERT_SUCCESS = 'PICTURE_INSERT_SUCCESS'
export const PICTURE_INSERT_FAILURE = 'PICTURE_INSERT_FAILURE'
export const PICTURE_UPDATE_REQUEST = 'PICTURE_UPDATE_REQUEST'
export const PICTURE_UPDATE_SUCCESS = 'PICTURE_UPDATE_SUCCESS'
export const PICTURE_UPDATE_FAILURE = 'PICTURE_UPDATE_FAILURE'
export const PICTURE_DELETE_REQUEST = 'PICTURE_DELETE_REQUEST'
export const PICTURE_DELETE_SUCCESS = 'PICTURE_DELETE_SUCCESS'
export const PICTURE_DELETE_FAILURE = 'PICTURE_DELETE_FAILURE'

export const AUTHOR_LIST_REQUEST = 'AUTHOR_LIST_REQUEST'
export const AUTHOR_LIST_SUCCESS = 'AUTHOR_LIST_SUCCESS'
export const AUTHOR_LIST_FAILURE = 'AUTHOR_LIST_FAILURE'
export const AUTHOR_INSERT_REQUEST = 'AUTHOR_INSERT_REQUEST'
export const AUTHOR_INSERT_SUCCESS = 'AUTHOR_INSERT_SUCCESS'
export const AUTHOR_INSERT_FAILURE = 'AUTHOR_INSERT_FAILURE'
export const AUTHOR_UPDATE_REQUEST = 'AUTHOR_UPDATE_REQUEST'
export const AUTHOR_UPDATE_SUCCESS = 'AUTHOR_UPDATE_SUCCESS'
export const AUTHOR_UPDATE_FAILURE = 'AUTHOR_UPDATE_FAILURE'
export const AUTHOR_DELETE_REQUEST = 'AUTHOR_DELETE_REQUEST'
export const AUTHOR_DELETE_SUCCESS = 'AUTHOR_DELETE_SUCCESS'
export const AUTHOR_DELETE_FAILURE = 'AUTHOR_DELETE_FAILURE'

export const AUTHOR_IMAGE_LIST_REQUEST = 'AUTHOR_IMAGE_LIST_REQUEST'
export const AUTHOR_IMAGE_LIST_SUCCESS = 'AUTHOR_IMAGE_LIST_SUCCESS'
export const AUTHOR_IMAGE_LIST_FAILURE = 'AUTHOR_IMAGE_LIST_FAILURE'
export const AUTHOR_IMAGE_INSERT_REQUEST = 'AUTHOR_IMAGE_INSERT_REQUEST'
export const AUTHOR_IMAGE_INSERT_SUCCESS = 'AUTHOR_IMAGE_INSERT_SUCCESS'
export const AUTHOR_IMAGE_INSERT_FAILURE = 'AUTHOR_IMAGE_INSERT_FAILURE'
export const AUTHOR_IMAGE_UPDATE_REQUEST = 'AUTHOR_IMAGE_UPDATE_REQUEST'
export const AUTHOR_IMAGE_UPDATE_SUCCESS = 'AUTHOR_IMAGE_UPDATE_SUCCESS'
export const AUTHOR_IMAGE_UPDATE_FAILURE = 'AUTHOR_IMAGE_UPDATE_FAILURE'
export const AUTHOR_IMAGE_DELETE_REQUEST = 'AUTHOR_IMAGE_DELETE_REQUEST'
export const AUTHOR_IMAGE_DELETE_SUCCESS = 'AUTHOR_IMAGE_DELETE_SUCCESS'
export const AUTHOR_IMAGE_DELETE_FAILURE = 'AUTHOR_IMAGE_DELETE_FAILURE'

export const QUOTE_LIST_REQUEST = 'QUOTE_LIST_REQUEST'
export const QUOTE_LIST_SUCCESS = 'QUOTE_LIST_SUCCESS'
export const QUOTE_LIST_FAILURE = 'QUOTE_LIST_FAILURE'
export const QUOTE_INSERT_REQUEST = 'QUOTE_INSERT_REQUEST'
export const QUOTE_INSERT_SUCCESS = 'QUOTE_INSERT_SUCCESS'
export const QUOTE_INSERT_FAILURE = 'QUOTE_INSERT_FAILURE'
export const QUOTE_UPDATE_REQUEST = 'QUOTE_UPDATE_REQUEST'
export const QUOTE_UPDATE_SUCCESS = 'QUOTE_UPDATE_SUCCESS'
export const QUOTE_UPDATE_FAILURE = 'QUOTE_UPDATE_FAILURE'
export const QUOTE_DELETE_REQUEST = 'QUOTE_DELETE_REQUEST'
export const QUOTE_DELETE_SUCCESS = 'QUOTE_DELETE_SUCCESS'
export const QUOTE_DELETE_FAILURE = 'QUOTE_DELETE_FAILURE'

export const ART_LIST_REQUEST = 'ART_LIST_REQUEST'
export const ART_LIST_SUCCESS = 'ART_LIST_SUCCESS'
export const ART_LIST_FAILURE = 'ART_LIST_FAILURE'
export const ART_INSERT_REQUEST = 'ART_INSERT_REQUEST'
export const ART_INSERT_SUCCESS = 'ART_INSERT_SUCCESS'
export const ART_INSERT_FAILURE = 'ART_INSERT_FAILURE'
export const ART_UPDATE_REQUEST = 'ART_UPDATE_REQUEST'
export const ART_UPDATE_SUCCESS = 'ART_UPDATE_SUCCESS'
export const ART_UPDATE_FAILURE = 'ART_UPDATE_FAILURE'
export const ART_UPDATE_CREATED_DATE_REQUEST = 'ART_UPDATE_CREATED_DATE_REQUEST'
export const ART_UPDATE_CREATED_DATE_SUCCESS = 'ART_UPDATE_CREATED_DATE_SUCCESS'
export const ART_UPDATE_CREATED_DATE_FAILURE = 'ART_UPDATE_CREATED_DATE_FAILURE'
export const ART_DELETE_REQUEST = 'ART_DELETE_REQUEST'
export const ART_DELETE_SUCCESS = 'ART_DELETE_SUCCESS'
export const ART_DELETE_FAILURE = 'ART_DELETE_FAILURE'

export const PARAG_LIST_REQUEST = 'PARAG_LIST_REQUEST'
export const PARAG_LIST_SUCCESS = 'PARAG_LIST_SUCCESS'
export const PARAG_LIST_FAILURE = 'PARAG_LIST_FAILURE'
export const PARAG_INSERT_REQUEST = 'PARAG_INSERT_REQUEST'
export const PARAG_INSERT_SUCCESS = 'PARAG_INSERT_SUCCESS'
export const PARAG_INSERT_FAILURE = 'PARAG_INSERT_FAILURE'
export const PARAG_UPDATE_REQUEST = 'PARAG_UPDATE_REQUEST'
export const PARAG_UPDATE_SUCCESS = 'PARAG_UPDATE_SUCCESS'
export const PARAG_UPDATE_FAILURE = 'PARAG_UPDATE_FAILURE'
export const PARAG_DELETE_REQUEST = 'PARAG_DELETE_REQUEST'
export const PARAG_DELETE_SUCCESS = 'PARAG_DELETE_SUCCESS'
export const PARAG_DELETE_FAILURE = 'PARAG_DELETE_FAILURE'

export const PARAG_TEMPLATE_LIST_REQUEST = 'PARAG_TEMPLATE_LIST_REQUEST'
export const PARAG_TEMPLATE_LIST_SUCCESS = 'PARAG_TEMPLATE_LIST_SUCCESS'
export const PARAG_TEMPLATE_LIST_FAILURE = 'PARAG_TEMPLATE_LIST_FAILURE'
export const PARAG_TEMPLATE_INSERT_REQUEST = 'PARAG_TEMPLATE_INSERT_REQUEST'
export const PARAG_TEMPLATE_INSERT_SUCCESS = 'PARAG_TEMPLATE_INSERT_SUCCESS'
export const PARAG_TEMPLATE_INSERT_FAILURE = 'PARAG_TEMPLATE_INSERT_FAILURE'
export const PARAG_TEMPLATE_UPDATE_REQUEST = 'PARAG_TEMPLATE_UPDATE_REQUEST'
export const PARAG_TEMPLATE_UPDATE_SUCCESS = 'PARAG_TEMPLATE_UPDATE_SUCCESS'
export const PARAG_TEMPLATE_UPDATE_FAILURE = 'PARAG_TEMPLATE_UPDATE_FAILURE'
export const PARAG_TEMPLATE_DELETE_REQUEST = 'PARAG_TEMPLATE_DELETE_REQUEST'
export const PARAG_TEMPLATE_DELETE_SUCCESS = 'PARAG_TEMPLATE_DELETE_SUCCESS'
export const PARAG_TEMPLATE_DELETE_FAILURE = 'PARAG_TEMPLATE_DELETE_FAILURE'

export const FLOW_CHART_LIST_REQUEST = 'FLOW_CHART_LIST_REQUEST'
export const FLOW_CHART_LIST_SUCCESS = 'FLOW_CHART_LIST_SUCCESS'
export const FLOW_CHART_LIST_FAILURE = 'FLOW_CHART_LIST_FAILURE'
export const FLOW_CHART_INSERT_REQUEST = 'FLOW_CHART_INSERT_REQUEST'
export const FLOW_CHART_INSERT_SUCCESS = 'FLOW_CHART_INSERT_SUCCESS'
export const FLOW_CHART_INSERT_FAILURE = 'FLOW_CHART_INSERT_FAILURE'
export const FLOW_CHART_UPDATE_REQUEST = 'FLOW_CHART_UPDATE_REQUEST'
export const FLOW_CHART_UPDATE_SUCCESS = 'FLOW_CHART_UPDATE_SUCCESS'
export const FLOW_CHART_UPDATE_FAILURE = 'FLOW_CHART_UPDATE_FAILURE'
export const FLOW_CHART_DELETE_REQUEST = 'FLOW_CHART_DELETE_REQUEST'
export const FLOW_CHART_DELETE_SUCCESS = 'FLOW_CHART_DELETE_SUCCESS'
export const FLOW_CHART_DELETE_FAILURE = 'FLOW_CHART_DELETE_FAILURE'

export const IMAGE_LIST_REQUEST = 'IMAGE_LIST_REQUEST'
export const IMAGE_LIST_SUCCESS = 'IMAGE_LIST_SUCCESS'
export const IMAGE_LIST_FAILURE = 'IMAGE_LIST_FAILURE'

export const AUDIO_LIST_REQUEST = 'AUDIO_LIST_REQUEST'
export const AUDIO_LIST_SUCCESS = 'AUDIO_LIST_SUCCESS'
export const AUDIO_LIST_FAILURE = 'AUDIO_LIST_FAILURE'

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'

export const IMAGE_OFFLINE_LIST_REQUEST = 'IMAGE_OFFLINE_LIST_REQUEST'
export const IMAGE_OFFLINE_LIST_SUCCESS = 'IMAGE_OFFLINE_LIST_SUCCESS'
export const IMAGE_OFFLINE_LIST_FAILURE = 'IMAGE_OFFLINE_LIST_FAILURE'

export const ART_HISTORY_REQUEST = 'ART_HISTORY_REQUEST'
export const ART_HISTORY_SUCCESS = 'ART_HISTORY_SUCCESS'
export const ART_HISTORY_FAILURE = 'ART_HISTORY_FAILURE'

export const ART_TIME_REQUEST = 'ART_TIME_REQUEST'
export const ART_TIME_SUCCESS = 'ART_TIME_SUCCESS'
export const ART_TIME_FAILURE = 'ART_TIME_FAILURE'

export const ART_TA_LIST_REQUEST = 'ART_TA_LIST_REQUEST'
export const ART_TA_LIST_SUCCESS = 'ART_TA_LIST_SUCCESS'
export const ART_TA_LIST_FAILURE = 'ART_TA_LIST_FAILURE'

export const ART_TA_SAVE_REQUEST = 'ART_TA_SAVE_REQUEST'
export const ART_TA_SAVE_SUCCESS = 'ART_TA_SAVE_SUCCESS'
export const ART_TA_SAVE_FAILURE = 'ART_TA_SAVE_FAILURE'

export const VISIBILITY_STATE_VISIBLE = 'VISIBILITY_STATE_VISIBLE'
export const VISIBILITY_STATE_HIDDEN = 'VISIBILITY_STATE_HIDDEN'
export const LOGOUT = 'LOGOUT'
export const INITIAL_SCREEN_SET = 'INITIAL_SCREEN_SET'

export const HUB_CONNECTION_SET = 'HUB_CONNECTION_SET'
export const HUB_CONNECTION_STATE_SET = 'HUB_CONNECTION_STATE_SET'
export const HUB_CONNECTION_AUTOSTART_SET = 'HUB_CONNECTION_AUTOSTART_SET'

export const NAVIGATION_SET = 'NAVIGATION_SET'
export const NAV_HEADER_SET = 'NAV_HEADER_SET'
export const APP_FOOTER_SET = 'APP_FOOTER_SET'
export const USER_ACTIVITY_SET = 'USER_ACTIVITY_SET'

// pendingAction
export const PENDING_ACTION_ADD = 'PENDING_ACTION_ADD'
export const PENDING_ACTION_REMOVE = 'PENDING_ACTION_REMOVE'
export const PENDING_ACTION_STATE_SET = 'PENDING_ACTION_STATE_SET'

// CHAT
export const CHAT_SET = 'CHAT_SET'
export const CHAT_MARK_AS_RED_SET = 'CHAT_MARK_AS_RED_SET'
export const UPDATE_PARTNER_CHAT_RED_MESSAGES = 'UPDATE_PARTNER_CHAT_RED_MESSAGES'

export const PEER_HUB_CONNECTION_SET = 'PEER_HUB_CONNECTION_SET'
export const CONNECTION_STATE_SET = 'CONNECTION_STATE_SET'
export const LOCAL_DESCRIPTION_SET = 'LOCAL_DESCRIPTION_SET'
export const REMOTE_DESCRIPTION_SET = 'REMOTE_DESCRIPTION_SET'
export const PEER_CONNECTION_UNSET = 'PEER_CONNECTION_UNSET'

export const CALL_OFFER_SET = 'CALL_OFFER_SET'
export const CALL_OFFER_UNSET = 'CALL_OFFER_UNSET'
export const CALL_ANSWER_PENDING_SET = 'CALL_ANSWER_PENDING_SET'
export const CALL_ANSWER_SET = 'CALL_ANSWER_SET'
export const CALL_ANSWER_UNSET = 'CALL_ANSWER_UNSET'
export const NEW_OFFER_ICE_CANDIDATE_SET = 'NEW_OFFER_ICE_CANDIDATE_SET'
export const NEW_ANSWER_ICE_CANDIDATE_SET = 'NEW_ANSWER_ICE_CANDIDATE_SET'
export const NEW_OFFER_ICE_CANDIDATE_ADDED_SUCCESS = 'NEW_OFFER_ICE_CANDIDATE_ADDED_SUCCESS'
export const NEW_ANSWER_ICE_CANDIDATE_ADDED_SUCCESS = 'NEW_ANSWER_ICE_CANDIDATE_ADDED_SUCCESS'

export const DEVICE_TOKEN_LIST_REQUEST = 'DEVICE_TOKEN_LIST_REQUEST'
export const DEVICE_TOKEN_LIST_SUCCESS = 'DEVICE_TOKEN_LIST_SUCCESS'
export const DEVICE_TOKEN_LIST_FAILURE = 'DEVICE_TOKEN_LIST_FAILURE'
export const FCM_SEND_NOTIFICATION_REQUEST = 'FCM_SEND_NOTIFICATION_REQUEST'
export const FCM_SEND_NOTIFICATION_SUCCESS = 'FCM_SEND_NOTIFICATION_SUCCESS'
export const FCM_SEND_NOTIFICATION_FAILURE = 'FCM_SEND_NOTIFICATION_FAILURE'

