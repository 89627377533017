import React from 'react'

export const ButtonDropdown = props => {

    const { itemList } = props
    const [value, setValue] = React.useState('')

    React.useEffect(() => {
        itemList.filter(item => item.translateCode === value)[0]?.onClick()
    }, [value])

    return (
        <select className="form-control" value={value} onChange={(e) => setValue(e.target.value)}>
            <option value={''} >Add node...</option>
            {
                itemList.map((item, key) => {
                    return <option key={key} value={item.translateCode} >{item.translateCode}</option>
                })
            }

        </select>
    )
}