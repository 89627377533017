import React, { useState, useEffect, useMemo, useRef } from "react"
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { CALL_OFFER_CANCEL } from '../_services'
import { NEW_ANSWER_ICE_CANDIDATE_ADDED_SUCCESS, REMOTE_DESCRIPTION_SET, PEER_CONNECTION_UNSET, CALL_ANSWER_UNSET } from "../Redux/ReduxAction"
import { randomAvatarSrc } from "../_helpers"
import { createOfferConnection } from "./createOfferConnection"
import { startMediaStream } from './startMediaStream'
import { createOffer } from './createOffer'
import Indicator from './Indicator'

/*
    Processes queue:
    1. Create peerConnection
    2. startMediaStream
    3. offer a call

*/

const VideoOffer = props => {
    const [tracksAdded, setTracksAdded] = useState(false)
    const [localStream, setLocalStream] = useState(null)
    const { dispatch, auth, hubConnection, appFooter, callAnswer, peerConnection, remoteDescription } = props

    const location = useLocation()
    const partner = location.state?.partner

    const navigate = useNavigate()
    const localVideoRef = useRef()
    const remoteVideoRef = useRef()

    const handleHangup = () => {
        stopLocalStream()

        const activity = {
            type: CALL_OFFER_CANCEL,
            user: auth?.data?.id.toString(),
            avatar: auth?.data?.avatarUrl || auth?.data?.googleProfile?.Picture || randomAvatarSrc(),
            message: `${auth?.data?.username} stopped calling you...`,
        }
        hubConnection.origin?.invoke("SendPrivateMessage", activity, partner?.name)
        navigate(-1)
    }

    const stopLocalStream = () => {
        if (localStream) {
            localStream.getTracks().forEach(function (track) {
                track.stop()
            })
            setLocalStream(null)
        }
        peerConnection.close()
        dispatch({ type: PEER_CONNECTION_UNSET })
        dispatch({ 
            type: CALL_ANSWER_UNSET,
            payload: {
                userId: partner?.userId
            }
        })
    }

    // autoconnection
    useEffect(() => {
        let mounted = true
        if (mounted) {
            createOfferConnection(partner?.name, remoteVideoRef)
        }
        return () => mounted = false
    }, [])

    // auto add tracks    
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (peerConnection) {
                startMediaStream({ peerConnection, localVideoRef, setLocalStream, setTracksAdded })
            }
        }
        return () => mounted = false
    }, [peerConnection])


    // auto make call
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (tracksAdded && hubConnection) {
                createOffer({ peerConnection, calleeId: partner?.userId, calleeName: partner?.name })
            }
        }
        return () => mounted = false
    }, [tracksAdded, hubConnection])

    // listening to answer
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (callAnswer.answer) {
                const remoteDesc = new RTCSessionDescription(callAnswer.answer.data)
                peerConnection?.setRemoteDescription(remoteDesc).then(() => {
                    dispatch({
                        type: REMOTE_DESCRIPTION_SET,
                        payload: peerConnection?.remoteDescription
                    })
                })
            }
        }
        return () => mounted = false
    }, [callAnswer.answer])

    // listening to answer pending
    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (!callAnswer.pending && localStream) {
                stopLocalStream()
                navigate(-1)
            }
        }
        return () => mounted = false
    }, [callAnswer.pending])

    const onAddIceCandidateSuccess = iceCandidate => {
        dispatch({
            type: NEW_ANSWER_ICE_CANDIDATE_ADDED_SUCCESS,
            payload: iceCandidate
        })
    }

    useEffect(() => {
        let mounted = true

        if (mounted) {
            // Listen for remote ICE candidates and add them to the local RTCPeerConnection
            if (callAnswer.iceCandidates && remoteDescription) {
                try {
                    callAnswer.iceCandidates.filter(el => el.stateCode === 1).map(item => {
                        peerConnection.addIceCandidate(item)
                            .then(() => onAddIceCandidateSuccess(item))
                            .catch(e => console.log(e))
                    })
                } catch (e) {
                    console.error('Error adding received ice candidate from the answer', e);
                }
            }
        }

        return () => mounted = false

    }, [callAnswer.iceCandidates, remoteDescription])

    return (

        <div className="video-call">
            <Indicator
                type='caller'
                localStream={localStream}
            />
            <video ref={localVideoRef} muted="muted" className="local-video" autoPlay playsInline></video>
            <video ref={remoteVideoRef} className="remote-video" autoPlay playsInline></video>

            <div className="video-call-button-group"
                style={{
                    bottom: `${appFooter.height + 10}px`
                }}
            >
                <button className="btn btn-danger" onClick={handleHangup} disabled={!!!localStream}>Hang Up</button>
            </div>

        </div>

    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    hubConnection: state.hubConnection,
    chat: state.chat,
    navHeader: state.navHeader,
    appFooter: state.appFooter,
    peerConnection: state.call.connection.peerConnection,
    localDescription: state.call.connection.localDescription,
    remoteDescription: state.call.connection.remoteDescription,
    callAnswer: state.call.answer,
})
const ConnectedPage = connect(mapStateToProps)(VideoOffer)
export default ConnectedPage